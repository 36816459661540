<mat-toolbar>
  <small style="font-size: 17px;">&nbsp;{{locale.data.receipt_history}}</small>
  <span class="fill-remaining-space"></span>
  <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1">
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar>
<mat-card>
  <div class="export-Refund">
    <button mat-raised-button style="background-color: #FFFFFF;color:#0082BE;text-transform: none;height: 24px;width: 98px;font-size: 10px;" class="small"  (click)="toggle()">{{allRowsExpanded ? this.locale.data.collapse_all : this.locale.data.expand_all}}</button>
    &nbsp;
    <mat-icon mat-icon-button (click)="downloadPdf()" style="cursor: pointer;" matTooltip="PDF"><img
        src="../assets/images/pdfmeduim.png" style="vertical-align: middle ;height: 28px;width: 35px;margin-top: -6px;" /></mat-icon>
    &nbsp; <mat-icon mat-icon-button (click)="exportTable()" style="cursor: pointer;" matTooltip="EXCEL"> <img
        src="../assets/images/excel.png" style="vertical-align: middle ;height: 23px;margin-top: -6px;" /></mat-icon>
  </div>
</mat-card>
<mat-card>

  <div class="example-container">
    <div class="mat-elevation-z8">
      <table mat-table id="ReceiptHistoryDetails" [dataSource]="dataSource" multiTemplateDataRows matSort>

        <!-- collapsepanelarrow Column -->
        <ng-container matColumnDef="collapsepanelarrow" sticky>
          <th mat-header-cell *matHeaderCellDef class="_collapse">
          </th>
          <td mat-cell *matCellDef="let element" class="_collapse"
            (click)="element.isExpanded=!element.isExpanded">
           
            <mat-icon  class="cell-icon moveleft" style="cursor: pointer;">
              {{element.isExpanded?'keyboard_arrow_down':'keyboard_arrow_right'}}</mat-icon>
          </td>
        </ng-container>

        <!-- Order# Column -->
        <ng-container matColumnDef="VTXTransaction_ID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{locale.data.order_hash}}</th>
          <td mat-cell *matCellDef="let element" style="font-weight:bold;"> {{element.VTXTransaction_ID}} </td>
        </ng-container>

        <!-- Order Total Column-->
        <ng-container matColumnDef="Payment_DtTm">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{locale.data.order_datetime}}</th>
          <td mat-cell *matCellDef="let element"> {{element.Payment_DtTm | date:'MM/dd/yyyy hh:mm a'}} </td>
        </ng-container>
        <!-- Phone Column -->
        <ng-container matColumnDef="Amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{locale.data.order_total}}</th>
          <td mat-cell *matCellDef="let element"> {{element.Amount | currency}} </td>
        </ng-container>
        <!-- Previous Balance Column -->
        <ng-container matColumnDef="OverdueAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>&nbsp;{{locale.data.previous_bal}} </th>
          <td mat-cell *matCellDef="let element">&nbsp;{{element.OverdueAmount | currency}} </td>
        </ng-container>

        <!-- Payment Type  Column -->
        <ng-container matColumnDef="PayType_Dsc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{locale.data.pay_type}}</th>
          <td mat-cell *matCellDef="let element"> {{element.PayType_Dsc}} </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail" [@detailExpand]="element.isExpanded  ? 'expanded' : 'collapsed'">
              <mat-card>
                <app-expandcollapsepanel [containerdata]="element.ConInventories"></app-expandcollapsepanel>
              </mat-card>


            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
          [class.example-expanded-row]="element.isExpanded">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
          style="height:0px !important"></tr>

      </table>

    </div>

  </div>
  <mat-paginator [pageSizeOptions]="[10,20,50]" showFirstLastButtons></mat-paginator>
  <span style="margin-left: 2%; color: gray;font-size: 12px;">{{locale.data.Count}}: {{ receipt_History.length}}</span>