import { Component, Input, OnInit } from '@angular/core';
import { WebapidataService } from '../common/services/webapidata.service';
import { WebContentType } from '../common/typesinfo/typesinfo';
import { debuglog } from '../ppcommon/pp-global-constants';

@Component({
  selector: 'app-about-pierpass',
  templateUrl: './about-pierpass.component.html',
  styleUrls: ['./about-pierpass.component.scss']
})
export class AboutPierpassComponent implements OnInit {

  @Input() webcontent: WebContentType[];
  AboutPierPASSContent: any;

  constructor(private dataService:  WebapidataService,) { 
    this.webcontent= [];
  }

  ngOnInit(): void {

    this.getAboutPierPASSContent();

  }

  getAboutPierPASSContent() {
      this.dataService.GetAboutPierPASS().subscribe((data: any) => {
      if (data.result == "error") {
      
      }
      else
      {
        this.AboutPierPASSContent =   data.AboutPierPASS[0].content;
        // debuglog(this.AboutPierPASSContent);

      }
    });
  }

}
