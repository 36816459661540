<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">

<div class="fixActionRow">
    <div mat-dialog-header class="vesseluploadheader">
        <div fxLayout="row">
            <div fxFlex="98">
                {{locale.data.customizeview}}
            </div>
            <!-- <span class="spacer"></span> -->
            <div>
                <mat-icon aria-label="close" style="cursor: pointer;" (click)="onNoClick()">close</mat-icon>
            </div>
            <!-- <button mat-icon-button >

            </button> -->
        </div>
    </div>
    <div mat-dialog-content class="filter-dialog-content-container">
        <div>
            <mat-form-field style="min-width: 210px; margin-left: 30px;">
                <mat-select placeholder="{{locale.data.savedviews}}" [(ngModel)]="selectedView"
                    (selectionChange)="ViewChange(selectedView)">
                    <mat-option [value]="v" *ngFor="let v of SavedNames">{{v}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-icon-button class="icon-btn remove-icon-btn" (click)="RemoveView(selectedView)"
                [disabled]="!selectedView">
                <mat-icon aria-label="delete">delete</mat-icon>
            </button>
        </div>
        <div>
            <mat-tab-group mat-align-tabs="start">
                <mat-tab label="{{locale.data.filteroptions | uppercase}}">
                    <div [style.max-height.px]="_MaxFilterOptionsheight" style="overflow-y:auto;">
                        <div fxLayout="row">
                            <span class="spacer"></span>
                            <button mat-button (click)="ResetAllFields()" class="clear-all">
                                {{locale.data.clearall}}
                            </button>
                        </div>
                        <div style="padding-left:30px;padding-right:15px;">
                            <div *ngIf="_userViewModel.UserView_Json.Filters" style="overflow-y:auto;">
                                <div fxLayout="row" class="filterclass">

                                <!-- ImportClaimList view  -->

                                  <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Container'] && _userViewModel.UserView_Json.Filters['Container'].Visible_Flg==true && this.viewName=='importclaimlist'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Container'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Container'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Reference'] && _userViewModel.UserView_Json.Filters['Reference'].Visible_Flg==true && this.viewName=='importclaimlist'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Reference'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Reference'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['VesselVoyage'] && _userViewModel.UserView_Json.Filters['VesselVoyage'].Visible_Flg==true && this.viewName=='importclaimlist'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['VesselVoyage'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['VesselVoyage'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Status'] && _userViewModel.UserView_Json.Filters['Status'].Visible_Flg==true && this.viewName=='importclaimlist' ">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Status'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Status'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['MTO'] && _userViewModel.UserView_Json.Filters['MTO'].Visible_Flg==true && this.viewName=='importclaimlist'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['MTO'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['MTO'].Selected_Value">
                                    </mat-form-field>
                                    </div>
                                    <!-- Export ClaimList view  -->

                                  <div fxFlex="50%"
                                  *ngIf="_userViewModel.UserView_Json.Filters['Booking'] && _userViewModel.UserView_Json.Filters['Booking'].Visible_Flg==true && this.viewName=='exportclaimlist'">
                                  <mat-form-field class="filter-column">
                                      <input matInput
                                          placeholder="{{_userViewModel.UserView_Json.Filters['Booking'].Column_Alias}}"
                                          [(ngModel)]="_userViewModel.UserView_Json.Filters['Booking'].Selected_Value">
                                  </mat-form-field>
                                  </div>

                                  <div fxFlex="50%"
                                  *ngIf="_userViewModel.UserView_Json.Filters['Ref'] && _userViewModel.UserView_Json.Filters['Ref'].Visible_Flg==true && this.viewName=='exportclaimlist'">
                                  <mat-form-field class="filter-column">
                                      <input matInput
                                          placeholder="{{_userViewModel.UserView_Json.Filters['Ref'].Column_Alias}}"
                                          [(ngModel)]="_userViewModel.UserView_Json.Filters['Ref'].Selected_Value">
                                  </mat-form-field>
                                  </div>

                                  <div fxFlex="50%"
                                  *ngIf="_userViewModel.UserView_Json.Filters['VesselVoyage'] && _userViewModel.UserView_Json.Filters['VesselVoyage'].Visible_Flg==true && this.viewName=='exportclaimlist'">
                                  <mat-form-field class="filter-column">
                                      <input matInput
                                          placeholder="{{_userViewModel.UserView_Json.Filters['VesselVoyage'].Column_Alias}}"
                                          [(ngModel)]="_userViewModel.UserView_Json.Filters['VesselVoyage'].Selected_Value">
                                  </mat-form-field>
                                  </div>

                                  <div fxFlex="50%"
                                  *ngIf="_userViewModel.UserView_Json.Filters['Status'] && _userViewModel.UserView_Json.Filters['Status'].Visible_Flg==true && this.viewName=='exportclaimlist'">
                                  <mat-form-field class="filter-column">
                                      <input matInput
                                          placeholder="{{_userViewModel.UserView_Json.Filters['Status'].Column_Alias}}"
                                          [(ngModel)]="_userViewModel.UserView_Json.Filters['Status'].Selected_Value">
                                  </mat-form-field>
                                  </div>
                                  <div fxFlex="50%"
                                  *ngIf="_userViewModel.UserView_Json.Filters['MTO'] && _userViewModel.UserView_Json.Filters['MTO'].Visible_Flg==true && this.viewName=='exportclaimlist'">
                                  <mat-form-field class="filter-column">
                                      <input matInput
                                          placeholder="{{_userViewModel.UserView_Json.Filters['MTO'].Column_Alias}}"
                                          [(ngModel)]="_userViewModel.UserView_Json.Filters['MTO'].Selected_Value">
                                  </mat-form-field>
                                  </div>
                                    <!-- Export ClaimList for Container view  -->

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Container'] && _userViewModel.UserView_Json.Filters['Container'].Visible_Flg==true && this.viewName=='exportclaimlistcontainer'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Container'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Container'].Selected_Value">
                                    </mat-form-field>
                                    </div>
  

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Booking'] && _userViewModel.UserView_Json.Filters['Booking'].Visible_Flg==true && this.viewName=='exportclaimlistcontainer'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Booking'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Booking'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                   
                                    <!-- Import FeesDue view  -->

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Container#'] && _userViewModel.UserView_Json.Filters['Container#'].Visible_Flg==true && this.viewName=='importfeesdue'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Container#'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Container#'].Selected_Value">
                                    </mat-form-field>
                                    </div>
  

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Status'] && _userViewModel.UserView_Json.Filters['Status'].Visible_Flg==true && this.viewName=='importfeesdue'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Status'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Status'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['MTO'] && _userViewModel.UserView_Json.Filters['MTO'].Visible_Flg==true && this.viewName=='importfeesdue'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['MTO'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['MTO'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Vessel/Voyage'] && _userViewModel.UserView_Json.Filters['Vessel/Voyage'].Visible_Flg==true && this.viewName=='importfeesdue'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Vessel/Voyage'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Vessel/Voyage'].Selected_Value">
                                    </mat-form-field>
                                    </div>


                                    <!-- Export FeesDue view  -->

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Container#'] && _userViewModel.UserView_Json.Filters['Container#'].Visible_Flg==true && this.viewName=='exportfeesdue'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Container#'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Container#'].Selected_Value">
                                    </mat-form-field>
                                    </div>
  

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Booking#'] && _userViewModel.UserView_Json.Filters['Booking#'].Visible_Flg==true && this.viewName=='exportfeesdue'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Booking#'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Booking#'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Ref#'] && _userViewModel.UserView_Json.Filters['Ref#'].Visible_Flg==true && this.viewName=='exportfeesdue'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Ref#'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Ref#'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Vessel/Voyage'] && _userViewModel.UserView_Json.Filters['Vessel/Voyage'].Visible_Flg==true && this.viewName=='exportfeesdue'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Vessel/Voyage'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Vessel/Voyage'].Selected_Value">
                                    </mat-form-field>
                                    </div>
  
                                <!-- Export PendingList view  -->

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Booking#'] && _userViewModel.UserView_Json.Filters['Booking#'].Visible_Flg==true && this.viewName=='exportpendinglist'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Booking#'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Booking#'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Status'] && _userViewModel.UserView_Json.Filters['Status'].Visible_Flg==true && this.viewName=='exportpendinglist'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Status'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Status'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Reference#'] && _userViewModel.UserView_Json.Filters['Reference#'].Visible_Flg==true && this.viewName=='exportpendinglist'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Reference#'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Reference#'].Selected_Value">
                                    </mat-form-field>
                                    </div>


                                    <!-- Import PendingList view  -->

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Container#'] && _userViewModel.UserView_Json.Filters['Container#'].Visible_Flg==true && this.viewName=='importpendinglist'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Container#'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Container#'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Status'] && _userViewModel.UserView_Json.Filters['Status'].Visible_Flg==true && this.viewName=='importpendinglist'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Status'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Status'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Reference#'] && _userViewModel.UserView_Json.Filters['Reference#'].Visible_Flg==true && this.viewName=='importpendinglist'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Reference#'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Reference#'].Selected_Value">
                                    </mat-form-field>
                                    </div>


                                    <!-- CSR BookingHistory view  -->

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Booking#'] && _userViewModel.UserView_Json.Filters['Booking#'].Visible_Flg==true && this.viewName=='csr/csrbookinghistory'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Booking#'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Booking#'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Status'] && _userViewModel.UserView_Json.Filters['Status'].Visible_Flg==true && this.viewName=='csr/csrbookinghistory'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Status'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Status'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['MTO'] && _userViewModel.UserView_Json.Filters['MTO'].Visible_Flg==true && this.viewName=='csr/csrbookinghistory'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['MTO'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['MTO'].Selected_Value">
                                    </mat-form-field>
                                    </div>

                                    <div fxFlex="50%"
                                    *ngIf="_userViewModel.UserView_Json.Filters['Vessel/Voyage'] && _userViewModel.UserView_Json.Filters['Vessel/Voyage'].Visible_Flg==true && this.viewName=='csr/csrbookinghistory'">
                                    <mat-form-field class="filter-column">
                                        <input matInput
                                            placeholder="{{_userViewModel.UserView_Json.Filters['Vessel/Voyage'].Column_Alias}}"
                                            [(ngModel)]="_userViewModel.UserView_Json.Filters['Vessel/Voyage'].Selected_Value">
                                    </mat-form-field>
                                    </div>
  


                                </div>
                                   
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="{{locale.data.columnoptions | uppercase}}">
                    <div class="fixActionRow">
                        <div class="dragdrop-selectall">
                            <mat-checkbox [(ngModel)]="checked" (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                                {{locale.data.select_deselectall}}
                            </mat-checkbox>

                        </div>
                        <mat-divider style="width:100%"></mat-divider>
                        <div cdkDropList class="column-option-list" (cdkDropListDropped)="drop($event)"
                            style="padding-top: 5px;overflow-y: auto;" [style.max-height.px]="_MaxColumnOptionsheight">
                            <div class="dragdrop-box" *ngFor="let column of _userViewModel.UserView_Json.ColumnOptions"
                                cdkDrag>
                                <button mat-icon-button class="header-right-menu-button row-drag-btn"
                                    style="cursor: move;" [disableRipple]="true" cdkDragHandle>
                                    <mat-icon aria-label="move item">menu</mat-icon>
                                </button>
                                <mat-checkbox [(ngModel)]="column.Visible_Flg" (click)="$event.stopPropagation()"
                                   
                                    (change)="$event ? selection.toggle(column) : null"
                                    [checked]="selection.isSelected(column)">{{column.Column_Alias}}
                                </mat-checkbox>
                                <!-- <span class="spacer"></span> -->

                            </div>
                        </div>
                    </div>

                </mat-tab>
            </mat-tab-group>
        </div>

    </div>
    <!-- <div class="spacer-dialog"></div> -->
    <div>
        <mat-divider style="width:100%"></mat-divider>

        <div class="alignbuttonright">
            <button mat-button (click)="onNoClick()" style="min-width: 100px;">{{locale.data.cancel |
                uppercase}}</button>
            <button [mat-dialog-close]="applyView()" cdkFocusInitial mat-raised-button
               class="btn-submit">{{locale.data.apply |
                uppercase}}</button>
        </div>
    </div>

</div>
