import { Component, Inject, Input, OnInit, AfterViewInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
  MatDialog,
  MatDialogConfig,
  MatIconRegistry,
  MatTableDataSource,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { TableUtil } from "../Account/table-util";
import { TableUtilPDF } from "../Account/table-util-pdf";
import { WebapidataService } from "../common/services/webapidata.service";

import { ViewChild } from "@angular/core";
// import { MatIconRegistry } from '@angular/material/icon';
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material";
import {
  AccountStatementDetailsType,
  GetInvoiceResultType,
  InvoiceDocDetailsRequest,
  InvoicehistoryMatDialogData,
  InvoicehistoryType,
} from "./get-account-status-and-history-details";
import { UserInfoType } from "../common/typesinfo/typesinfo";
import { PpGlobalConstants } from "../ppcommon/pp-global-constants";

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { CurrencyPipe } from "@angular/common";
import { objectlog, stringlog } from "../csr-containerhistory/csr-containerhistory-type";
import { TranslateService } from "../common/services/translate.service";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Component({
  selector: "app-account-summary-invoice-list-dialog",
  templateUrl: "./account-summary-invoice-list-dialog.component.html",
  styleUrls: ["./account-summary-invoice-list-dialog.component.scss"],
})
export class AccountSummaryInvoiceListDialogComponent implements OnInit {
  @Input() childMessage: string;
  @ViewChild(MatSort, { static: false }) sort: MatSort | any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | any;
  gridHistorydata: any;
  showTable: boolean = false;
  serversideErrorMessage: any;
  // Company_Id: any;
  Company_Nm: any;
  dataSource: any;
  TotalRecords: any;

  pdfcolumnNames: { title: string; dataKey: string }[];

  displayedColumns: string[] = [
    "InvoiceDate",
    "InvoiceId",
    "Amount",
    "DueDate",
    "Download",
  ];

  ShowLoader: boolean;
  // CompanyId: any;
  invoicehistorylist: InvoicehistoryType[];
  InvDataSource: any;
  statementinfo: AccountStatementDetailsType;
  AccountStmtError: boolean;
  AcctStmtErrorMessage: any;
  invoicehistory: InvoicehistoryType[];
  OriginalData: any[] & InvoicehistoryType[];

  constructor(
    private dataService: WebapidataService,
    fb: FormBuilder,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public locale: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: AccountStatementDetailsType
  ) {}
  ngOnInit(): void {
    this.statementinfo = this.data;

    stringlog("Ng On Init");

    this.ShowLoader = true;

    this.dataService
      .PostAccountInvoiceHistDetails(this.statementinfo)
      .subscribe((response: GetInvoiceResultType) => {
        this.ShowLoader = false;

        //API success Message Code
        if (response.result == "ok") {
          stringlog(response.data);
          objectlog(response.invoicehistory);

          this.invoicehistory = response.invoicehistory;

          stringlog("In Child Component:");

          // this.InvDataSource = new MatTableDataSource(response.invoicehistory);
          // this.InvDataSource.sort = this.sort;
          // this.InvDataSource.paginator = this.paginator;
          this.InvDataSource= Object.assign([],this.invoicehistory);
          this.OriginalData = Object.assign([],this.invoicehistory);
          this.dataSource = Object.assign([],this.invoicehistory);

          this.TotalRecords = response.invoicehistory.length;

          this.showTable = true;
          //enabling the Table
          this.ShowLoader = false;
        } else if (response.result == "ndf") {
          // NO DATA FOUND
        } else if (response.result == "error") {
        }
      });

    this.AccountStmtError = false;
  }

  DownloadReceipt(row: any) {
    this.AccountStmtError = false;

    // stringlog("download");
    stringlog(row);
    // stringlog(row.InvoiceId);

    let UserInfo_Get: UserInfoType = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
    );

    let AccountId: string = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AccountId) || "{}"
    );
    stringlog(AccountId);
    const InvoiceDocReq: InvoiceDocDetailsRequest = {
      // Company_Id = this.Company_Id,
      User_Id: this.statementinfo.User_Id,
      Company_Id: this.statementinfo.Company_Id,
      FeeTypeCode: this.statementinfo.FeeTypeCode,
      InvoiceDocNo: row.InvoiceId,
      VTXAccount_Id: AccountId,
    };

    objectlog(InvoiceDocReq);

    this.ShowLoader = true;

    this.dataService
      .GetInvoiceDetailsforInvoiceDoc(InvoiceDocReq)
      .subscribe((response: GetInvoiceResultType) => {
        this.ShowLoader = false;

        //API success Message Code
        if (response.result == "ok") {
          const byteArray = new Uint8Array(
            atob(response.data)
              .split("")
              .map((char) => char.charCodeAt(0))
          );

          const blob = new Blob([byteArray], { type: "application/pdf" });
          FileSaver.saveAs(
            blob,
            "Invoice - " + InvoiceDocReq.InvoiceDocNo + ".pdf",
            { autoBOM: true }
          );
        } else if (response.result == "ndf") {
          this.AccountStmtError = true;
          this.AcctStmtErrorMessage = response.data;
        } else if (response.result == "error") {

          if(response.data == PpGlobalConstants.VTX_TIMEOUTCODE)
          this.AcctStmtErrorMessage = this.locale.data.as_vtx_unavailable;
        else  
          this.AcctStmtErrorMessage = response.errormessage;
          this.AccountStmtError = true;
          // this.AcctStmtErrorMessage = response.data;
        }
      });
  }

  exportTable() {
    // TableExcelUtil.exportTableToExcel('FeesDueData');
    // TableUtil.exportTableToExcel("InvoiceHistoryList", "Invoice History List");
    this.downloadtoxlsx();
  }

  onClose() {
    this.dialog.closeAll();
    // window.location.reload();
  }

  downloadPdf(): void {
    // TableUtilPDF.downloadtopdf('InvoiceHistoryList','Invoice History List')

    if (this.invoicehistory.length > 0) {
      var doc = new jsPDF("l", "in", [1200, 1401]);
      var col = ["InvoiceDate", "InvoiceId", "Amount", "DueDate"];
      var rows = [];
      this.invoicehistory.forEach((item) => {
        let duedate = moment(item.DueDate).format("MM/DD/YYYY");
        let invdate = moment(item.InvoiceDate).format("MM/DD/YYYY");
        var temp = [
          // item.InvoiceDate,
          invdate,
          item.InvoiceId,
          item.Amount,
          duedate,
          // item.DueDate,
        ];
        rows.push(temp);
      });
      doc.autoTable(col, rows);
      doc.save("Invoice History List.pdf");
    }
  }

  downloadtoxlsx(): void {
    let xlsxcolumnNames = [];
    this.pdfcolumnNames = [];
    this.pdfcolumnNames.push({
      title: "Invoice Date",
      dataKey: "InvoiceDate",
    });
    this.pdfcolumnNames.push({
      title: "Invoice Id",
      dataKey: "InvoiceId",
    });
    this.pdfcolumnNames.push({
      title: "Amount",
      dataKey: "Amount",
    });
    this.pdfcolumnNames.push({
      title: "Due Date",
      dataKey: "DueDate",
    });

    if (this.invoicehistory.length > 0) {
      this.invoicehistory.forEach((element) => {
        let row = [];

        let duedate = moment(element.DueDate).format("MM/DD/YYYY");
        let invdate = moment(element.InvoiceDate).format("MM/DD/YYYY");

        //  let duedate = this._date.transform(element.DueDate, 'MM/dd/yyyy')
        //  let invdate = this._date.transform(element.InvoiceDate, 'MM/dd/yyyy')

        // let duedate = moment(element.DueDate).format("MM/DD/YYYY");
        // let invdate = moment(element.InvoiceDate).format("MM/DD/YYYY");
        //let date= this._date.transform(element.SvcEvent_DtTm, 'MM/dd/yyyy HH:mm a')
        for (let j = 0; j < this.pdfcolumnNames.length; j++) {
          switch (this.pdfcolumnNames[j].dataKey) {
            case "InvoiceDate":
              row.push(invdate);
              break;
            case "InvoiceId":
              row.push(element.InvoiceId);
              break;
            case "Amount":
              row.push(element.Amount);
              break;
            case "DueDate":
              row.push(duedate);
              break;
          }
        }
        xlsxcolumnNames.push(row);
      });

      this.exportToExcelFile(
        xlsxcolumnNames,
        "Invoice History List",
        this.pdfcolumnNames
      );
    }
  }

  public exportToExcelFile(
    json: any[],
    excelFileName: string,
    columnNames: any
  ): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    var range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (var C = range.s.r; C < columnNames.length; ++C) {
      var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
      if (!worksheet[address]) continue;
      worksheet[address].v = columnNames[worksheet[address].v].title;
    }
    const workbook: XLSX.WorkBook = {
      Sheets: { DisplayList: worksheet },
      SheetNames: ["DisplayList"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  sortData(sort: MatSort) {

    // stringlog("sort direction: dir:" + sort.direction + " , active:" + sort.active);

    this.OriginalData = Object.assign([],this.invoicehistory);
    
    if(sort.direction==""){
      this.InvDataSource= Object.assign([],this.invoicehistory);
    }else{
      switch (sort.active) {
        case "InvoiceId":
          if(sort.direction=="asc")
          {
            this.InvDataSource=this.OriginalData.sort((a, b) => ((a.InvoiceId?a.InvoiceId.toUpperCase():"") < (b.InvoiceId?b.InvoiceId.toUpperCase():"") ? -1 : 1));
          }else if(sort.direction=="desc"){
            this.InvDataSource=this.OriginalData.sort((a, b) => ((a.InvoiceId?a.InvoiceId.toUpperCase():"") > (b.InvoiceId?b.InvoiceId.toUpperCase():"") ? -1 : 1));
          }
          break;
          case "InvoiceDate":
            if(sort.direction=="asc")
          {
            this.InvDataSource=this.OriginalData.sort((a, b) => (new Date(a.InvoiceDate) < new Date(b.InvoiceDate) ? -1 : 1));
          }else if(sort.direction=="desc"){
            this.InvDataSource=this.OriginalData.sort((a, b) => (new Date(a.InvoiceDate) > new Date(b.InvoiceDate) ? -1 : 1));
          }
            break;
         
            case "Amount":
              if(sort.direction=="asc")
              {
                this.InvDataSource=this.OriginalData.sort((a, b) => ((a.Amount?a.Amount:"") < (b.Amount?b.Amount:"") ? -1 : 1));
              }else if(sort.direction=="desc"){
                this.InvDataSource=this.OriginalData.sort((a, b) => ((a.Amount?a.Amount:"") > (b.Amount?b.Amount:"") ? -1 : 1));
              }
              break;
              case "DueDate":
                if(sort.direction=="asc")
              {
                this.InvDataSource=this.OriginalData.sort((a, b) => (new Date(a.DueDate) < new Date(b.DueDate) ? -1 : 1));
              }else if(sort.direction=="desc"){
                this.InvDataSource=this.OriginalData.sort((a, b) => (new Date(a.DueDate) > new Date(b.DueDate) ? -1 : 1));
              }
                break;
             
      }
this.dataSource =this.InvDataSource;
      
    }
  }
  
}
