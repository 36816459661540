import { WebapidataService } from './webapidata.service';
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
//import { CookieService } from "ngx-cookie-service";
import { APIService } from "./httpservicefactory.service";
import { log } from "util";
import { IInterfacesAccess } from "../models/userinfomodel";
import { url } from "inspector";
import{SharedService} from "./globalvariable.service";

@Injectable()
export class AuthGuardService implements CanActivate {
  expiredDate: Date;
  instanceSuffix:string
  constructor(
    private router: Router,
    private aPIService: APIService,
    private authService: WebapidataService,
    private globalvariableService: SharedService
  ) {

    let instanceConfig = environment.portsconfigs.find(a => a.clientid == localStorage.getItem("appInstance_Name").trim());
    this.instanceSuffix = "_" + instanceConfig.clientid;
   }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (this.authService.isAuthenticated() && this.authService.isAuthorized()) {
      return true;
    } else {
      // this.router.navigate(['login']);
      this.router.navigate(['pphome']);
      return false;
    }
  }

  HasPermission(path: string): boolean {
    this.globalvariableService.accessInterfaces = [];
    if (!localStorage.getItem("userIntefaces" + this.instanceSuffix)) {
      return false;
    }
    this.globalvariableService.accessInterfaces = JSON.parse(
      localStorage.getItem("userIntefaces" + this.instanceSuffix)
    );
    let hasPermission: boolean = false;
    if (
      !!this.globalvariableService.accessInterfaces &&
      this.globalvariableService.accessInterfaces.length > 0
    ) {
      let accessData = this.globalvariableService.accessInterfaces.filter(
        x => x.url == path
      );
      if (accessData.length > 0) {
        hasPermission = true;
      } else {
        hasPermission = false;
      }
    }
    return hasPermission;
  }
  getSessionData() {
    if (!!localStorage.getItem("appInstance_Name")) {
      localStorage.setItem("authorizationResult_" + localStorage.getItem("appInstance_Name"), sessionStorage.getItem("authorizationResult_" + localStorage.getItem("appInstance_Name")));
      localStorage.setItem("session_state_" + localStorage.getItem("appInstance_Name"), sessionStorage.getItem("session_state_" + localStorage.getItem("appInstance_Name")));
      localStorage.setItem("authorizationDataIdToken_" + localStorage.getItem("appInstance_Name"), sessionStorage.getItem("authorizationDataIdToken_" + localStorage.getItem("appInstance_Name")));
      localStorage.setItem("_isAuthorized_" + localStorage.getItem("appInstance_Name"), sessionStorage.getItem("_isAuthorized_" + localStorage.getItem("appInstance_Name")));
      localStorage.setItem("authorizationData_" + localStorage.getItem("appInstance_Name"), sessionStorage.getItem("authorizationData_" + localStorage.getItem("appInstance_Name")));
      localStorage.setItem("userData_" + localStorage.getItem("appInstance_Name"), sessionStorage.getItem("userData_" + localStorage.getItem("appInstance_Name")));
    }
  }
}
