import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from 'src/app/common/services/translate.service';

@Component({
  selector: 'app-genericpopup',
  templateUrl: './genericpopup.component.html',
  styleUrls: ['./genericpopup.component.scss']
})
export class GenericpopupComponent implements OnInit {
  message: any;
  Content: any;
  Title: any;

  constructor(public locale: TranslateService, 
    // private dialog: MatDialog,
    private dialog: MatDialogRef<GenericpopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit() {
    this.Content =  this.data.Content;
    this.Title = this.data.Title;
  }

  onClose() {
    this.dialog.close();
    
  }

}
