import { NomultipletabsComponent } from './page-not-found/nomultipletabs.component';
import { FromExternalLandingComponent } from './login/ExternalAccess/from-external-landing.component';
import { PierpasshomeComponent } from './pierpasshome/pierpasshome.component';
import { PierpasslayoutComponent } from './pierpasshome/pierpasslayout.component';

import { PpHelpApptSystemsComponent } from './preauth-landing/pp-help/pp-help-appt-systems.component';

import { ResetPasswordComponent } from './fullscreen-features/reset-password/reset-password.component';
import { PpUserRegistrationComponent } from './pp-user-registration/pp-user-registration.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';


import { PpHelpComponent } from './preauth-landing/pp-help/pp-help.component';
import { PpTerminalInfoComponent } from './preauth-landing/pp-terminal-info/pp-terminal-info.component';
import { PpRfidTagsComponent } from './preauth-landing/pp-rfid-tags/pp-rfid-tags.component';
import { PpRegisterComponent } from './preauth-landing/pp-register/pp-register.component';


import { PreauthLandingComponent } from "./preauth-landing/preauth-landing.component";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { PpHomeComponent } from './preauth-landing/pp-home/pp-home.component';

import { LoginChangepasswordComponent } from './login/login-changepassword.component';

import { SelectTmfctfComponent } from './login/select-tmfctf.component';
import { SelectTmfctfchildComponent } from './login/select-tmfctfchild.component';
import { LoginAcceptCtfTermsconditionsComponent } from './login/login-accept-ctf-termsconditions.component';
import { PortchecklayoutComponent } from './portcheck/portchecklayout/portchecklayout.component';
import { PortcheckhomeComponent } from './portcheck/portcheckhome/portcheckhome.component';
import { BackgroundPreloadStrategy } from './background-preload-strategy';


const appRoutes: Routes = [

  { path: "preauthlanding", component: PreauthLandingComponent, pathMatch: "full" },
  { path: "landing", component: FromExternalLandingComponent },
  // { path: "", component: FromExternalLandingComponent },
  { path: "resetpwd", component: ResetPasswordComponent },
  { path: "pphelp", component: PpHelpComponent },
  { path: "pphelpappt", component: PpHelpApptSystemsComponent },
  { path: "firstlogin", component: LoginChangepasswordComponent },
  { path: "ppuserregistration", component: PpUserRegistrationComponent }, 
  { path: "selecttmfctflanding", component: SelectTmfctfComponent }, 

  { path: "", component: PpHomeComponent, pathMatch: "full" },
  { path: "pphome", component: PpHomeComponent,},
  { path: "nomultipetabs", component: NomultipletabsComponent },

  {
    path: "",
    component: PierpasslayoutComponent,
    children: [
      // { path: "", component: PortcheckhomeComponent },
      { path: "pierpasshome", component: PierpasshomeComponent },
      { path: "ppregister", component: PpRegisterComponent },
      { path: "pprfidtags", component: PpRfidTagsComponent },
      { path: "ppterminalinfo", component: PpTerminalInfoComponent },
     
    ],
  },

  {
    path: "",
    component: PortchecklayoutComponent,
    children: [
      // { path: "", component: PortcheckhomeComponent },
      { path: "portcheckhome", component: PortcheckhomeComponent },
      // { path: "ppregister", component: PpRegisterComponent },
      // { path: "pprfidtags", component: PpRfidTagsComponent },
      // { path: "ppterminalinfo", component: PpTerminalInfoComponent },
     
    ],
  },

  {
    path: "",
    component: SelectTmfctfComponent,
    children: [
      { path: "", component: SelectTmfctfchildComponent },
      { path: "tmfctfselect", component: SelectTmfctfchildComponent },
      { path: "acceptctftermsconditions", component: LoginAcceptCtfTermsconditionsComponent }, 
    ],
  },
  
  { path: "", loadChildren: () => import('./routingmodules/bco.module').then(m => m.BcoModule)}, 
  { path: "csr", loadChildren: () => import('./routingmodules/edi.module').then(m => m.EdiModule) }, 
  { path: "registernewaccount", loadChildren: () => import('./routingmodules/newcompanyreg.module').then(m => m.NewcompanyregModule) }, 
 
  { path: "pagenotfound", component: PageNotFoundComponent },
 
  //  { path: "**", redirectTo: "pphome" }
  { path: "**", component: PageNotFoundComponent }

  ///// till here
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: BackgroundPreloadStrategy })],
  // imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [BackgroundPreloadStrategy]
})
export class AppRoutingModule { }
