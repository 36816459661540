
<div class="preauth-parent-container">
  <mat-toolbar><div class="logo"></div></mat-toolbar>

<div class="sub-container">
  <app-pp-terminal-info></app-pp-terminal-info>
  <!-- <app-pp-terminalinfo-appointment-systems></app-pp-terminalinfo-appointment-systems> -->
</div>
<div class="sub-container" *ngIf=false>
  <div>
    <h2>Appointment Systems</h2>
  </div>
  <div>
    <div>
      PierPass does not manage the terminals' appointment systems.<br />Please
      select the terminal below for information about their appointment system.
    </div>
  </div>
  <div>
      <br>
    <div>
      <h2>Los Angeles Terminals</h2>
    </div>
    <div>
      <a
        href="http://termpoint.namapmterminals.com/default.aspx"
        target="HelpPopout"
        >APM Terminals (APMT)</a
      >
    </div>
    <div>
      <a href="http://www.emodal.com/" target="HelpPopout"
        >Fenix Marine Services (FMS)</a
      >
    </div>
    <div>
      <a href="http://voyagertrack.portsamerica.com/" target="HelpPopout"
        >West Basin Container Terminal (WBCT)</a
      >
    </div>
    <div>
      <a href="http://www.emodal.com/" target="HelpPopout"
        >Everport Terminal Services (ETS)</a
      >
    </div>
    <div>
      <a href="http://www.emodal.com/" target="HelpPopout">TraPac, LLC</a>
    </div>
    <div>
      <a href="http://voyagecontrol.com/yti" target="HelpPopout"
        >Yusen Terminals, LLC</a
      >
    </div>
    <br>
    <br>
    <div>
        <h2>Long Beach Terminals</h2>
    </div>
    <div>
      <a href="https://portal.lbct.com/" target="HelpPopout"
        >Long Beach Container Terminal (LBCT)</a
      >
    </div>
    <div>
      <a href="http://www.ttilgb.com/main/index.do" target="HelpPopout"
        >Total Terminal Inc (TTI)</a
      >
    </div>
    <div>
      <a href="http://www.emodal.com/" target="HelpPopout"
        >International Transportation Service (ITS)</a
      >
    </div>
    <div>
      <a
        href="https://www.emodal.com/anondefault.aspx?ReturnUrl=%2f"
        target="HelpPopout"
        >Pacific Container Terminal (PCT)</a
      >
    </div>
    <div>
      <a
        href="https://www.emodal.com/anondefault.aspx?ReturnUrl=%2f"
        target="HelpPopout"
        >SSA Marine Pier A</a
      >
    </div>
    <div>
      <a
        href="http://www.ssamarine.com/locations/terminal-c60/"
        target="HelpPopout"
        >C60 Matson</a
      >
    </div>
  </div>
  
</div>
<div class="lfooter1">
  <span>
    &nbsp;&nbsp; Copyright
    <mat-icon inline="true">copyright</mat-icon> 2008-2021 PierPASS. All
    Rights Reserved.
  </span>
</div>

</div>
