import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Router } from "@angular/router";
import { SharedService } from "src/app/common/services/globalvariable.service";
import { APIService } from "src/app/common/services/httpservicefactory.service";
import { TranslateService } from "src/app/common/services/translate.service";
import { CustomViewInputModel, SaveUserViewModel, UserViewModel } from "src/app/Interfaces/customview.interface";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-customviewsave",
    templateUrl: "./customviewsave.component.html",
    styleUrls: ["./customviewsave.component.scss"]
  })
  export class CustomviewsaveComponent implements OnInit {
    Interface_Uid: string = "";
    View_Data: any = {};
    customViewModel: CustomViewInputModel;
    _userViewModel: UserViewModel;
    errorMessage: any;
    ShowLoader: boolean = false;
    showDuplicateViewMsg: boolean = false;
    iscustomviewempty: boolean = false;
    viewname = "";
    isDefaultView = false;
    loading = false;
    interfaceName:any;
    constructor(
      public locale: TranslateService,
      private sharedService: SharedService,
      public dialogRef: MatDialogRef<CustomviewsaveComponent>,
      @Inject(MAT_DIALOG_DATA) public data: CustomViewInputModel,
      private ApiService: APIService,
      private router: Router
    ) {
      //this.locale.use("en", "emodal");      
      this.customViewModel = data;
      this._userViewModel = data.UserInterfaceView;
      this.Interface_Uid = data.Interface_Uid;
      dialogRef.disableClose = true;
  
      dialogRef.backdropClick().subscribe(result => {
        dialogRef.close(this.customViewModel);
      });
    }
  
    ngOnInit() {
      let element = document.getElementsByClassName("cdk-overlay-backdrop")[0];
      element.setAttribute("style", "opacity:0");
      this.viewname = this._userViewModel.userViewName;
      this.isDefaultView = this._userViewModel.Default_Flg;
    }
  
    onNoClick(): void {
      this._userViewModel.userViewName = this.viewname;
      this._userViewModel.Default_Flg = this.isDefaultView;
      this.customViewModel.UserInterfaceView.userViewName = this.viewname;
      this.customViewModel.UserInterfaceView.Default_Flg = this.isDefaultView;
      this.dialogRef.close(this.customViewModel);
    }
  
    SaveView(overrideName: boolean): void {
      this._userViewModel.userViewName = this._userViewModel.userViewName.trim();
      if (this._userViewModel.userViewName != "") {
        this.loading = true;
        this.iscustomviewempty = false;
        this.ShowLoader = true;
        let customjson = this._userViewModel;
        
        if(customjson.hasOwnProperty('AppInterface_Id'))
        {
          customjson.appInterface_Id=customjson.AppInterface_Id;
        }

        let requestjson: SaveUserViewModel = {
          UserView_Id: customjson.userViewId,
          AppInterface_Id: customjson.appInterface_Id,
          User_Uid: this.customViewModel.User_Uid,
          UserView_Nm: customjson.userViewName,
          UserView_Json: JSON.stringify(customjson.UserView_Json),
          Default_Flg: customjson.Default_Flg,
          Active_Flg: true
        };
        if (!overrideName) {
          var duplicaterecord = this.customViewModel.UserSavedViews.find(
            v =>
              v.userViewName == customjson.userViewName &&
              v.userViewId != customjson.userViewId
          );
          if (duplicaterecord) {
            this.showDuplicateViewMsg = true;
            this.ShowLoader = false;
            return;
          }
        }
        this.ApiService.saveUserInterfaceView(requestjson).subscribe(
          data => {
            
            if(customjson.appInterface_Id==6)
            {
             this.interfaceName = "exportclaimlistcontainer";
            }
            else
            {
              this.interfaceName = this.router.url.replace("/", "");

            }
            this._userViewModel = JSON.parse(data);
            this._userViewModel.UserView_Json = JSON.parse(
              this._userViewModel.UserView_Json
            );
            this._userViewModel.userViewName=this._userViewModel.UserView_Nm;        

            this.customViewModel.UserInterfaceView = this._userViewModel;
            localStorage.setItem("customView_" + this.interfaceName+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid, "");
            this.loadUserSavedViews(
              this.customViewModel.AppInterface_Id,
              this.customViewModel.Interface_Uid,
              this.interfaceName
            );
            this.loading = false;
          },
          error => {
            this.loading = false;
            this.errorMessage = <any>error;
            return false;
          }
        );
      } else {
        this.iscustomviewempty = true;
      }
    }
  
    loadUserSavedViews(
      lAppInterface_Id: number,
      Interface_Uid: string,
      InterfaceName: string
    ) {
      try {
        this.sharedService.loadCustomViewData(
          lAppInterface_Id,
          Interface_Uid,
          InterfaceName
        );
        setTimeout(() => {
          if (!!localStorage.getItem("customView_" + InterfaceName+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid)) {
            let customViewData = JSON.parse(
              localStorage.getItem("customView_" + InterfaceName+"_"+environment.portsconfigs.find(a=>a.clientid==localStorage.getItem("appInstance_Name").trim()).clientid)
            );
            this.customViewModel.UserSavedViews = customViewData.UserSavedViews;
            this.dialogRef.close(this.customViewModel);
            this.ShowLoader = false;
          }
        }, 1000);
      } catch (error) {
        this.errorMessage = <any>error;
        this.ShowLoader = false;
      } finally {
        this.ShowLoader = false;
      }
    }
  
    onValueChange(value:string){
      if(value.length > 0)
      this.iscustomviewempty=false;
      else
      this.iscustomviewempty=true;
  
      }
  }
  