import { stringlog } from 'src/app/csr-containerhistory/csr-containerhistory-type';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  public link: string = "";
  url: string = "asdf";
  message: string;
  document: boolean;
  docname = "";

  constructor(
    private router : Router
  ) { }

  ngOnInit() {

    this.link = this.router.url;
    this.link = this.router.url;
    stringlog(this.link)

    if(this.link.includes('assets/Documents'))
    {
      this.document = true;
      this.docname = this.link.substring(this.link.lastIndexOf('/')+1)
      this.docname = decodeURIComponent(this.docname);
      this.message = "The Document <bold>'" + decodeURIComponent(this.docname) +  "'</bold> you are looking is NOT found";
    }
    else
    {
      this.document = false;
      this.message = "The resource you are looking is not found";
    }

  }

}
