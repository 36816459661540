<!-- <div class="aboutpp">
  <div *ngFor="let wcontent of webcontent"> 
    <div *ngIf="wcontent.WebContentType_Cd == 'A'" [innerHTML]="wcontent.Content_Txt"></div>
  </div>

</div> -->

<div class="aboutpp">
  <span [innerHTML]="AboutPierPASSContent | noSanitize"></span>
</div>
