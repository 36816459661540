import { TranslateService } from './../../common/services/translate.service';
import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AfterViewInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AccountReceiptHistoryService } from '../account-receipt-history.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-expandcollapsepanel',
  templateUrl: './expandcollapsepanel.component.html',
  styleUrls: ['./expandcollapsepanel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpandcollapsepanelComponent implements OnInit {
  displayedColumns: string[] = [
    'FullConNumber',
    'ConStatus_Dsc',
    'Carrier',
    'CargoRef_Num',
    'Ref_Num',
    'FeePaid',
  ];
  dataSource: any;
  breakpoint: number = 7;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: any;
  @Input() containerdata: any;
  constructor(
    public locale: TranslateService
  ) { }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit() {

    this.resize(window.innerWidth);
    this.dataSource = new MatTableDataSource(this.containerdata);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  onResize(event) {
    this.resize(event.target.innerWidth);
  }
  resize(width: number): void {

    if (width > 1298) {
      this.breakpoint = 4;
    }
    else {
      this.breakpoint = 1;
    }
  }
}
