import { environment } from "src/environments/environment";


export class PpGlobalConstants {
  
    public static ProgramType: string = 'ProgramType';
    public static PierPASS: string = 'PierPASS';
    public static Portcheck: string = 'Portcheck';
    public static AUTH: string = 'authinfo';
    public static AdditionalUserInfo: string = 'AdditionalUserInfo';   
    public static AccountId: string = 'AccountId';
    public static AccountType: string = 'AccountType';
    public static ModuleNavigation: string = 'ModuleNavigation';
    public static LoginAttemptsCount: string = 'LoginAttemptsCount';
    public static appInstance_Name: string = "appInstance_Name";
    public static app_instance: string = "app_instance";
    public static VTX_TIMEOUTCODE: number = 120007;
    public static BCOInfo: string = 'BCOInfo';   
    public static TokenExpired: string = 'TokenExpired';
    public static PreviousUserName: string = 'PreviousUserName';
    public static FeeTypeCTFTMF: string = 'FeeTypeCTFTMF';
    public static ShowTMFCTFForkInPage: string = 'ShowTMFCTFForkInPage';
    public static BCOInfoList: string = 'BCOInfoList';
    public static AvoidTMFCTFForkInPage: string = 'AvoidTMFCTFForkInPage';
    public static UserAccounTypeInfo: string = 'UserAccounTypeInfo';
    public static CSRInContext: string = 'CSRInContext';
    public static AccessPrivileges: string = 'AccessPrivileges';
    public static CSRImpersonation_VTXAccountInfo: string = 'CSRImpersonation_VTXAccountInfo';
    public static CSRImpersonationLandingPage: string = '/csr/csraccountsummary';
    public static CSRCompanySearchPage: string = '/csr/csrcompanysearch';
    public static DisabledButtonsList: string = 'DisabledButtonsList';
    public static CSRAccountInContext: string = 'CSRAccountInContext';
}

export function debuglog(message : string){
  
    if(environment.name == "localhost" && message != undefined && message != null)
    {
      console.log(message)
    }
  
  }
  

