export enum ParentScreen {
    Container,
    Booking,
    GroupCode,
    Jobs,
    Dashboard,
    Notification,
    VesselSchedule,
    GateOrder,
    None
}