<div class="sub-container">
  <!-- ***********  Text bulletins  ******** -->
  <div>
    <span *ngFor="let bullet of bulletins">
      <span class="bulletstyle"
        *ngIf="bullet.BulletinType_Cd == 'T'"
        [innerHTML]="bullet.Content_Txt | noSanitize"
      >
    </span>
    </span>
  </div>
  <!-- <h4>Video Bulletins</h4> -->
  <br *ngIf="videobulletins && videobulletins.length > 0">
  <div class="videostyle" *ngIf="videobulletins && videobulletins.length > 0">
    <span *ngFor="let bullet of bulletins">
      <span
        *ngIf="bullet.BulletinType_Cd == 'V'">
        <a [href]="bullet.Content_Txt" target="_blank" rel="noopener" >{{bullet.Title_Txt}}
        </a>  
        <br>
      </span>
    </span>
  </div>
 
</div>
