import { MediaMatcher } from '@angular/cdk/layout';
import { FlatTreeControl } from '@angular/cdk/tree';
import { JsonPipe } from '@angular/common';
import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource, MatDialog, MatSnackBar, MatDialogConfig } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { slideInAnimation } from '../animation';
import { IUserInfo, IMenu, IModules, IInterfaces, IInterfacesAccess, IInterfaceActions } from '../common/models/userinfomodel';
import { AuthService } from '../common/services/auth.service';
import { SharedService } from '../common/services/globalvariable.service';
import { APIService } from '../common/services/httpservicefactory.service';
import { TranslateService } from '../common/services/translate.service';
import { WebapidataService } from '../common/services/webapidata.service';
import { PopupInfo, WebContentType } from '../common/typesinfo/typesinfo';
import { stringlog } from '../csr-containerhistory/csr-containerhistory-type';
import { UserLandingPageModel, UserLocaleModel, Facility, ShippingLine } from '../Interfaces/customview.interface';
import { GenericpopupComponent } from '../ppcommon/genericpopup/genericpopup.component';
import { PpGlobalConstants } from '../ppcommon/pp-global-constants';
import { Terminals } from '../preauth-landing/preauth-landing.component';


/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

interface Languages {
  key: string;
  name: string;
}

@Component({
  selector: 'app-pierpasslayout',
  templateUrl: './pierpasslayout.component.html',
  styleUrls: ['./pierpasslayout.component.scss'],
  animations: [slideInAnimation],
  providers: [APIService],
})
export class PierpasslayoutComponent implements OnInit {
  showglobalsearch: any;
  // for menu
  @ViewChild("tree", { static: false }) tree: any;
  noofreq = 0;
  reqcount = 0;
  overlay = true;
  WindowsHeight: any = "0px";
  selected = "en";
  errorMessage: any;
  over: any;
  showMobileOptions: boolean = false;
  showMobileSearch: boolean = false;
  SelectedGlobalSearch: string = "";
  SearchValue: string;
  SerachContainerView: any = [];
  containerno: string = "";
  bookingno: string = "";
  isAction: boolean = false;

  userInfo: IUserInfo;
  iMenu: IMenu;
  lstIMenu: IMenu[];
  iModules: IModules[];
  iInterfaces: IInterfaces[];
  // menu
  MENU_TREE :  Terminals[];
  
  iInterfacesAccess: IInterfacesAccess[] = [];
  interfaceActions: IInterfaceActions[] = [];

  username: string;
  email: string;
  userLandingPageModel: UserLandingPageModel = null;
  userLocaleModel: UserLocaleModel = null;
  defaultInterfaceUId: string;
  defaultLocale: string;

  DrayUnitStatues: any[] = [];
  PortFacilities: Facility[] = [];
  PreGateStatuses: any[] = [];
  ShippingLines: ShippingLine[] = [];
  adminTitlename: string = "";
  userLandingPage: string = "";
  loadOutlet: boolean = true;
  // Menus
  private transformer = (node: Terminals, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      moduleName: node.moduleName,
      name: node.name,
      url: node.url,
      interfaceId: node.interfaceId,
      showInMenuFlg: node.showInMenuFlg,
      interfaceCd: node.interfaceCd,
      interfaceUid: node.interfaceUid,
      is_href: node.is_href,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  // menus RS
  binding = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  showclose: boolean = false;
  loading: boolean = false;
  typename: string;
  // showlist: boolean=false;

  landingpagetabscontent : WebContentType[];
  SiteUrl: string;
  pierpassHeaderConfigs: any;
  jsonfile:string;
  Userlanguage:string;
  DisplayPortCheck: boolean;
  ProgramType: string;

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private aPIService: APIService,
    private snackBar: MatSnackBar,
    public locale: TranslateService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private route: ActivatedRoute,
    // private oidcSecurityStorage: OidcSecurityStorage,
    //private cookieService: CookieService,
    private zone: NgZone,
    //public validate: ValidationMethods,
    public apiService: APIService,
    private authService: AuthService,
    public sharedService: SharedService,
    private dataService:  WebapidataService
  ) {
    
    // this.binding.data = this.MENU_TREE;

    this.ProgramType  =  localStorage.getItem(PpGlobalConstants.ProgramType);

    if(this.ProgramType ==  PpGlobalConstants.Portcheck)
      this.router.navigate(["/portcheckhome"]);


    this.CTFEnabled();

    let instanceConfig = environment.portsconfigs.find(a => a.clientid == localStorage.getItem("appInstance_Name").trim());
    this.pierpassHeaderConfigs = instanceConfig.pierpassHeaderConfigs;
    this.jsonfile=instanceConfig.pierpassHeaderConfigs.languagefile;
    this.Userlanguage=instanceConfig.pierpassHeaderConfigs.UserSelectionlanguage;
    this.locale.use(this.Userlanguage,this.jsonfile);
  
    //this.loadScript("./assets/files/text.js");
    this.mobileQuery = media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.WindowsHeight = window.innerHeight;
    // if (this.oidcSecurityService.getToken() != "" || localStorage.getItem("auth_token")) {
    //   this.getUserIdentityData(environment.oidcmoduleconfig.userinfoendpoint);
    // }
    if (this.SearchValue) {
      this.showclose = true;
    } else {
      this.showclose = false;
    }
  }


  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement("script");
    script.innerHTML = "";
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  languages: Languages[] = [
    { key: "en", name: "English" },
    //{ key: "spanish", name: "Spanish" }
  ];

  SelectedOption: string = "";
  path: string = "";
  readonlyvalue: boolean = false;

  ChangeComponent(item: string) {
    this.SelectedGlobalSearch = item;
    this.showclose = false;
    this.SearchValue = "";
  }

  expandMobileOptions() {
    this.showMobileOptions = !this.showMobileOptions;
    this.showMobileSearch = this.showMobileSearch
      ? !this.showMobileSearch
      : false;
  }
  expandMobileSearch() {
    this.showMobileSearch = !this.showMobileSearch;
    this.showMobileOptions = this.showMobileOptions
      ? !this.showMobileOptions
      : false;
  }

  ngOnInit() {
    // this.binding.data = this.MENU_TREE;

    this.CTFEnabled();
   
    this.dataService.getPreAuthMenusFromDB().subscribe((data:any) =>
    {
      let terminals = data;
      this.binding.data = terminals;
   
    });
  
    this.dataService.getWebContentAll().subscribe
    ((data:any) => {
    this.landingpagetabscontent = data[0];
  });

  let _instance = environment.portsconfigs.find(x => x.clientid == localStorage.getItem("appInstance_Name").trim());
  this.SiteUrl = _instance.instance;

  this.ProcessHomePagePopUp();

    this.showglobalsearch = environment.portsconfigs.find(
      (x) => x.showglobalsearch == true
    );
    window.addEventListener(
      "storage",
      this.storageEventListener.bind(this),
      false
    );
  
  }

  ProcessHomePagePopUp() {
    this.dataService.Get_ScreensPopupforDisplay('HP','TMF').subscribe(
      (data: any) => {
        if(data.result == "error")
        {
          stringlog("Error: " + data.errormessage);
        }
        else
        {

          let popupresult : {
            PopContentType_Cd: string;
            FeeType_Cd: string;
            Content_Title: string;
            Content_Txt: string;
            Display_FromDate: Date;
            Display_ToDate: Date;
          } =  data.RG_PopupContent[0];

          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;

          let popcontent : PopupInfo = {
            Title : popupresult.Content_Title,
            Content :  popupresult.Content_Txt,
          }
         
          dialogConfig.data = popcontent;
          this.dialog.open( GenericpopupComponent, dialogConfig);

        }
      }
    );
  }


  private CTFEnabled()  {
    this.dataService.GetTMFCTFForkIn().subscribe((data: any) => {
      // stringlog("CTFEnabled call executed");
      stringlog(JSON.stringify(data));
      if (data.result == "error") {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage,"false");
        this.DisplayPortCheck =  false;
        // stringlog("CTFEnabled call: Error");
      }
      else if(data.result == "good" && data.data == "true")
      {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage,"true");
        this.DisplayPortCheck =  true;
        // stringlog("CTFEnabled call: true");
      }
      else if(data.result == "good" && data.data == "false")
      {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage,"false");
        this.DisplayPortCheck =  false;
        // stringlog("CTFEnabled call: false");
      }
    });

  }

  openTab(){
    
    window.open(this.SiteUrl +  "/pphelp" , '_blank');
    
  }
  private storageEventListener(event: StorageEvent) {
    if (!event.storageArea.userInfo || event.storageArea.userInfo == "") {
      // this.authService.clearsession();
      // window.location.href = "/pierpasshome";
    }
  }
  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  progressbar(loadval: boolean) {
    this.loading = loadval;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  changeView(nav: any) {
    nav.toggle();
    this.SearchValue = "";
    this.showclose = false;
  }

  setLang(language: string) {
    this.locale.use(language, "emodal");
  }

  appendedtext: string[] = [];


  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  LeftSideNavClicked(item) {
    item.toggle();
  }

  navigateUser(interfaceurl: string) {
    this.router.navigate(["/adminsettings"], {
      queryParams: { name: interfaceurl },
    });
  }

  logout() {
    // this.authService.clearsession();
    // this.oidcSecurityService.logoff();
  }
  async getMenus(userInfoforMenu: IUserInfo): Promise<void> {
    if (userInfoforMenu) {
      this.username = userInfoforMenu.claims.fullname;
      this.email = userInfoforMenu.email;
      this.sharedService.email = userInfoforMenu.email;
      this.lstIMenu = userInfoforMenu.claims.menu;
      if (this.lstIMenu) {
        await Promise.all(
          this.lstIMenu.map(async (module) => {
            this.addModulestoMenu(module.modules);
            this.sharedService.interfaceActions = this.getInterfaceActions(
              module.modules
            );
            let lstInterfaceAccess = this.getInterfaces(module.modules);
            // if (lstInterfaceAccess.length > 0)
            //  await this.LoadCustomViews(lstInterfaceAccess);
          })
        );

        // menus
        if (this.MENU_TREE.length > 0) {
          this.binding.data = this.MENU_TREE;
          setTimeout((asd) => {
            try {
              let url = this.router.url;
              if (url.includes("adminsettings")) {
                let stringurl = url.split("name=")[1];
                var re = /%2F/gi;
                url = stringurl.replace(re, "/");
              }
              let node = this.tree.treeControl.dataNodes.filter(
                (a) => a.url == url
              );
              let parentnode = this.tree.treeControl.dataNodes.filter(
                (a) => a.moduleName == node[0].moduleName && a.expandable
              );
              this.tree.treeControl.expand(parentnode[0]);
              if (this.router.url.includes("adminsettings")) {
                this.adminTitlename = "";
                this.adminTitlename = node[0].name;
              } else this.adminTitlename = "";
            } catch {}
          }, 1000);
        }
      }
    }
  }

  getInterfaceActions(imodules: IModules[]): IInterfaceActions[] {
    imodules.forEach((imodule) => {
      if (imodule.interfaces.length > 0) {
        imodule.interfaces.forEach((element) => {
          if (!element.showInMenu_Flg && element.interface_Cd != null) {
            this.interfaceActions.push({
              module_Id: imodule.module_Id,
              module_nm: imodule.moduleName,
              interfaces_Id: element.interfaces_Id,
              interface_UId: element.interface_UId,
              interfaceDsc: element.interfaceDsc,
              interface_Cd: element.interface_Cd,
            });
          }
        });
      }
    });

    return this.interfaceActions;
  }

  addModulestoMenu(imodules: IModules[]) {
    this.zone.run(() => {
      imodules.forEach((imodule) => {
        let modulename: string = imodule.moduleName;
        let children: {
          moduleName: string;
          name: string;
          url: string;
          interfaceId: number;
          interfaceUid: string;
          showInMenuFlg: boolean;
          interfaceCd: string;
          is_href: boolean;
        }[] = [];
        if (
          imodule.interfaces != null &&
          imodule.interfaces != undefined &&
          imodule.interfaces.filter((x) => x.showInMenu_Flg == true).length >= 1
        ) {
          if (imodule.showInMenu_Flg) {
            imodule.interfaces.forEach((element) => {
              if (element.showInMenu_Flg) {
                let appinstance = localStorage.getItem("appInstance_Name");
                console.log("app instance name in Pre Auth:" + appinstance );
                let portconfig = environment.portsconfigs.find(
                  (a) => a.clientid == appinstance
                );

                // console.log("portconfig:" + portconfig );
                if (element.interface_Url.includes(portconfig.instance)) {
                  element.interface_Url = element.interface_Url.replace(
                    portconfig.instance,
                    ""
                  );
                }

                children.push({
                  moduleName: imodule.moduleName,
                  name: element.interfaceDsc,
                  url: element.interface_Url,
                  interfaceId: element.interfaces_Id,
                  interfaceUid: element.interface_UId,
                  showInMenuFlg: element.showInMenu_Flg,
                  interfaceCd: element.interface_Cd,
                  is_href: this.isValid(element.interface_Url) ? true : false,
                });
              }
            });

            // this.MENU_TREE.push({
            //   moduleName: modulename,
            //   name: modulename ,
            //   url: "",
            //   interfaceId: 0,
            //   interfaceUid: "",
            //   showInMenuFlg: true,
            //   interfaceCd: "",
            //   is_href: false,
            //   children: children
            // });
          }
        }
      });
    });
  }

  getInterfaces(imodules: IModules[]): IInterfacesAccess[] {
    imodules.forEach((imodule) => {
      imodule.interfaces.forEach((element) => {
        this.iInterfacesAccess.push({
          modShowInMenu_Flg: imodule.showInMenu_Flg,
          interfacesId: element.interfaces_Id,
          interfaceUId: element.interface_UId,
          interfaceDsc: element.interfaceDsc,
          url: element.interface_Url,
          showInMenu_Flg: element.showInMenu_Flg,
          interfaceCd: element.interface_Cd,
        });
      });
    });
    localStorage.setItem(
      "userIntefaces",
      JSON.stringify(this.iInterfacesAccess)
    );
    return this.iInterfacesAccess;
  }


  isValid(text) {
    return /\b(http|https)/.test(text);
  }
  SaveLandingPage(item: any) {
    this.userLandingPageModel = {} as UserLandingPageModel;
    this.userLandingPageModel.DefaultInterface_Id = 0;
    this.userLandingPageModel.Interface_Uid = item.interfaceUId;
    this.userLandingPageModel.Interface_Uri = item.url;
    this.userLandingPageModel.User_Uid = this.apiService.User_Uid;
    this.apiService.SaveUserLandingPage(this.userLandingPageModel).subscribe(
      (data) => {
        this.defaultInterfaceUId = data.Interface_Uid;
        localStorage.setItem("dv", data.Interface_Uid);
      },
      (error) => {
        this.errorMessage = <any>error;
      }
    );
  }

  SaveLocale(item: any) {
    this.userLocaleModel = {} as UserLocaleModel;
    this.userLocaleModel.DefaultLocale_Id = 0;
    this.userLocaleModel.Locale = item.key;
    this.userLocaleModel.User_Uid = this.apiService.User_Uid;
    this.apiService.SaveUserLocale(this.userLocaleModel).subscribe(
      (data) => {
        this.defaultLocale = data.Locale;
        this.setLang(data.Locale);
      },
      (error) => {
        this.errorMessage = <any>error;
      }
    );
  }

  SetLandingPage() {
    if (!localStorage.getItem("dr")) {
      localStorage.setItem("dr", this.userLandingPage);
    }
  }
  SetLocale() {
    this.apiService.GetUserLocale().subscribe(
      (data) => {
        if (!!data) {
          this.defaultLocale = data.Locale;
          this.setLang(data.Locale);
        } else {
          this.defaultLocale = this.selected;
          this.setLang(this.selected);
        }
      },
      (error) => {
        this.errorMessage = <any>error;
      }
    );
  }
  
  showDefaultViewMenu(iInterfacesAccess: IInterfacesAccess[]): Boolean {
    if (
      iInterfacesAccess.length > 0 &&
      iInterfacesAccess.filter(
        (x) =>
          x.interfaceCd == null && x.showInMenu_Flg == true && x.url != null
      ).length > 0
    )
      return true;
    else return false;
  }

  disableActions(code: string): boolean {
    let lstAction = this.sharedService.interfaceActions.filter(
      (x) => x.interface_Cd == code
    );
    if (lstAction.length > 0) this.isAction = true;
    else this.isAction = false;
    return this.isAction;
  }
}

