<!-- <p>pp-rfid-tags works!</p> -->

<div class="sub-container">
    <h5 style="text-decoration: underline; color:#0082BE; font-size: 16px;font-weight: normal; font-family: sans-serif;">
        {{ locale.data.Rfid_Home }} > {{ locale.data.Regiser_tag }}
     </h5>
    <!-- <div *ngFor="let wcontent of landingpagecontent"> 
        <div *ngIf="wcontent.WebContentType_Cd == 'E'" [innerHTML]="wcontent.Content_Txt"></div>
    </div> -->
    <div>
        <div [innerHTML]="RFIDConent"></div>
    </div>
    <!-- <app-testcontent></app-testcontent> -->
</div>

