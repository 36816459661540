
<app-loading-backdrop *ngIf="ShowLoader"></app-loading-backdrop>

<div>
  <mat-toolbar>
    <small>{{locale.data.invoices}}</small>
    <span class="fill-remaining-space"></span>
    <button
      class="btn-dialog-close"
      mat-stroked-button
      (click)="onClose()"
      tabIndex="-1"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </mat-toolbar>
  <div>
    <mat-card>
      <div>
        <span
          >{{locale.data.from}}
          <span style="font-weight: bold">
            {{ statementinfo.StartDt | date: "dd/MM/yyyy" }}
          </span>
          {{locale.data.to}}
          <span style="font-weight: bold">
            {{ statementinfo.EndDt | date: "dd/MM/yyyy" }}
          </span>
        </span>
        <span class="example-spacer"></span>
      </div>
      <div class="export-result-details">
        <!-- <mat-icon mat-icon-button
          ><img
            src="../assets/images/icon print.svg"
            style="
              vertical-align: middle;
              height: 28px;
              width: 30px;
              cursor: pointer;
            "
        /></mat-icon> -->
        &nbsp;
        <mat-icon mat-icon-button (click)="downloadPdf()" matTooltip="PDF">
          <img
            src="../assets/images/pdfmeduim.png"
            style="
              vertical-align: middle;
              height: 28px;
              width: 30px;
              cursor: pointer;
            "
        /></mat-icon>
        &nbsp;
        <mat-icon (click)="exportTable()" matTooltip="EXCEL">
          <img
            src="../assets/images/excel.png"
            style="vertical-align: middle; height: 23px; cursor: pointer"
          />
        </mat-icon>
      </div>
    </mat-card>
    <mat-card>
      <div class="example-container">
        <div class="mat-elevation-z8">
          <table
            id="InvoiceHistoryList"
            mat-table
            [dataSource]="dataSource"  matSort (matSortChange)="sortData($event)"
            *ngIf="showTable"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              style="font-size: 14px"
            ></td>
            <ng-container matColumnDef="InvoiceDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.invoicedate}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.InvoiceDate | date: "dd/MM/yyyy" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="InvoiceId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.invoicehash}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.InvoiceId }}
              </td>
            </ng-container>
            <ng-container matColumnDef="DueDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.duedate}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.DueDate | date: "dd/MM/yyyy" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{locale.data.amount}}</th>
              <td mat-cell *matCellDef="let element">
                {{ element.Amount | currency }}
              </td>
            </ng-container>
                   
          <ng-container matColumnDef="Download" >
            <th mat-header-cell *matHeaderCellDef>{{locale.data.as_download}}</th>
            <td mat-cell *matCellDef="let element" style="font-size: 10px;"> 
                <button mat-icon-button  color="primary" (click)="DownloadReceipt(element)" PierpassButtonControl="ACTSUMMARYDOWNLOADINVOICE">
                <img src="../../assets/images/download-24px.svg">
                <!-- <mat-icon style="cursor: pointer;color:#0082BE">download</mat-icon> -->
              </button> 
            </td>
          </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
              class="example-first-header-row"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>       -->
          </table>
        </div>
      </div>
      <!-- <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]"
          >Items per page:</mat-paginator
        > -->
    </mat-card>
    <br />
  </div>
  <div style="display: flex; justify-content: flex-start">
    <p *ngIf="AccountStmtError" class="error">
     {{AcctStmtErrorMessage}}
    </p>
</div>
</div>
