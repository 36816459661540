import * as jsPDF from "jspdf";

declare var jsPDF: any;

export class TableUtilPDF {
  static exportToPdf(tableId: string, name?: string) {
    let popupWin: any;
    let printContents = document.getElementById(tableId).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=auto,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
       <html>
         <head>
           <title>Refund History Details</title>       
         </head>
       <body onload="window.print();window.close()">
       <table class="table table-bordered">${printContents}</table>
       </body>
       </html>`);
    popupWin.document.close();
  }
 //downloadto PDF New Logic
  static downloadtopdf(tableId: any, name?: string): void { 

    var doc = new jsPDF("p", "pt", 'letter');  
    var res = doc.autoTableHtmlToJson(document.getElementById(tableId));   
    //doc.autoTable(res.columns, res.data, { margin: { top: 50 } });
    var header = function (data) {
      doc.setFontSize(12);
      doc.setTextColor(40);
      doc.setFontStyle("normal");
      //doc.addImage(headerImgData, 'JPEG', data.settings.margin.left, 20, 50, 50);
      doc.text(name, data.settings.margin.left, 20);
    };

    var options = {
      beforePageContent: header,
      margin: {
        top: 30              
      },
      //startY: doc.autoTableEndPosY()+20,
    };
    
    doc.autoTable(res.columns, res.data, options);
    doc.save(name+".pdf");
  }
}
