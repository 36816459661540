<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<div class="fixActionRow custom-save-view-dialog">
  <div mat-dialog-title class="save-view-dialog-title-container">
    <div fxLayout="row">
      <span>
        {{ locale.data.save_view }}
      </span>
      <span class="spacer"></span>
      <button mat-icon-button (click)="onNoClick()" class="icon-btn">
        <mat-icon aria-label="close">close</mat-icon>
      </button>
    </div>
  </div>

  <div mat-dialog-content class="save-view-dialog-content">
    <div fxLayout="row" *ngIf="!showDuplicateViewMsg">
      <div fxFlex="80%">
        <mat-form-field class="filter-column" style="width:100%;">
          <input matInput cdkFocusInitial placeholder="{{ locale.data.view_name }}"
            (input)="onValueChange($event.target.value)" [(ngModel)]="_userViewModel.userViewName" maxlength="20" />
        </mat-form-field>
        <div style="margin-top:-20px; color:red; font-size: 13px;">
          <span *ngIf="_userViewModel.userViewName.length == 20">
            {{ locale.data.error_message_length }}
          </span>
          <span *ngIf="iscustomviewempty">
            {{ locale.data.error_message_empty }}
          </span>

          <span> </span>
        </div>
      </div>
    </div>
    <div fxLayout="row" *ngIf="showDuplicateViewMsg">
      <div style="width: 40px;">
        <mat-icon style="color:orange">warning</mat-icon>
      </div>
      <div>
        <p style="margin-block-start: 0px;">
          {{ locale.data.a_view_by_the_name }}
          <strong>{{ _userViewModel.userViewName }}</strong> {{ locale.data.already_exist }}
        </p>

        <strong>{{ locale.data.do_you_overwrite }}</strong>
      </div>
    </div>
  </div>
  <div class="spacer-dialog"></div>
  <div mat-dialog-actions style="padding:0px;" align="end" fxLayout="row">
    <mat-divider style="width:100%"></mat-divider>

    <div class="save-view-action-container">
      <mat-checkbox [(ngModel)]="_userViewModel.Default_Flg" *ngIf="!showDuplicateViewMsg"
        class="overflow-deafault-view" fxFlex="50%" [disableRipple]="true">
        <!-- matTooltip="{{ locale.data.make_this_my_default_view }}" [matTooltipPosition]="['below']" -->
        {{ locale.data.make_this_my_default_view }}</mat-checkbox>
      <span class="spacer"></span>
      <button mat-button *ngIf="!showDuplicateViewMsg" (click)="onNoClick()" style="min-width: 70px;">
        {{ locale.data.cancel | uppercase}}
      </button>
      <button mat-raised-button *ngIf="!showDuplicateViewMsg" (click)="SaveView(false)" class="btn-submit">
        {{ locale.data.save | uppercase}}
      </button>
      <button mat-button *ngIf="showDuplicateViewMsg" (click)="showDuplicateViewMsg = false" style="min-width: 70px;">
        {{ locale.data.no | uppercase}}
      </button>
      <button mat-raised-button *ngIf="showDuplicateViewMsg" (click)="SaveView(true)" class="btn-submit"
        [disabled]="!loading">
        <mat-spinner [diameter]="20" class="matspinner" *ngIf="!loading"></mat-spinner>
        {{ locale.data.yes | uppercase}}
      </button>
    </div>
  </div>
</div>