import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "./common/services/auth.service";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import { catchError,  filter,  map,  race,  shareReplay,  switchMap,  switchMapTo,  take,  tap} from "rxjs/operators";
import { slideInAnimation } from './animation';
import { OverlayContainer } from '@angular/cdk/overlay';
import { environment } from "./../environments/environment";
// import "core-js/stable";
import "regenerator-runtime/runtime";
//import { CookieService } from 'ngx-cookie-service';
declare let ga: Function;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
  title = "PierPASS";
  tokens: string[] = [];
  themeClass: string;
  analyticsUri: string;
  analyticsTrackingId: string;
  private cookieDiffer: KeyValueDiffer<string, any>;
  private customer: any;
  private clientId:string="";
  cookieChanges:KeyValueChanges<string, any>;
  hostname: string;
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private router: Router,
    private overlayContainer: OverlayContainer,
    private differs: KeyValueDiffers
  ) {

    this.hostname =  window.location.origin;
    if(this.hostname.includes("www"))
    {
      this.hostname = this.hostname.replace("www.",'');
    }
    this.clientId=environment.portsconfigs.find(a => a.instance == this.hostname ||  a.portcheckinstance == this.hostname).clientid.trim();
  
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log("Google Analytics NavigationEnd "+event.urlAfterRedirects)
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });

    

  }

  ngOnInit() {
    let _instance: any;
    this.customer = {};      

    if (!!localStorage.getItem("appInstance_Name")) {
      _instance = environment.portsconfigs.find(x => x.clientid == localStorage.getItem("appInstance_Name").trim());
      this.themeClass = _instance.theme;
      this.analyticsUri = _instance.analyticsUri;
      this.analyticsTrackingId = _instance.analyticsTrackingId;

      let classList = document.getElementById("root").classList;

      const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
      const themeClassesToRemove = Array.from(classList).filter((item: string) => item.includes('-theme'));
 
      overlayContainerClasses.add(this.themeClass);
    }

  }

  ngOnDestroy(): void {
    // this.oidcSecurityService.resetAuthorizationData(false);
  }

  ngDoCheck(): void {
   
  }

  private read(key: string): any {
    const data = localStorage.getItem(key);
    if (data != null) {
      return JSON.parse(data);
    }

    return;
  }

  private write(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

}
