import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountReceiptHistoryService {
  mydate = new BehaviorSubject({from: '', to: ''});
  constructor() { }
   getRecieprHistorydata= [
     {orderNo: 202655365, orderDate: '25/01/2021 8:17 AM',orderToata: "449.68", PrevBal: "0.00", payMnetType: "Credit Card"},
     {orderNo: 202655365, orderDate: '25/01/2021 8:17 AM',orderToata: "449.68", PrevBal: "0.00", payMnetType: "Credit Card"},
     {orderNo: 202655365, orderDate: '25/01/2021 8:17 AM',orderToata: "449.68", PrevBal: "0.00", payMnetType: "Credit Card"},
     {orderNo: 202655365, orderDate: '25/01/2021 8:17 AM',orderToata: "449.68", PrevBal: "0.00", payMnetType: "Credit Card"}
    ];
    private getRecieptHistorydetailsLoad = [
   {ContainerNo: "DRYU787659", impExp: "Import", vessel: "-0.72W", booking:"ONEYGTy0987889", feeAmount: "54.25"},
   {ContainerNo: "DRYU787659", impExp: "Import", vessel: "-0.72W", booking:"ONEYGTy0987889", feeAmount: "54.25"},
   {ContainerNo: "DRYU787659", impExp: "Import", vessel: "-0.72W", booking:"ONEYGTy0987889", feeAmount: "54.25"},
   {ContainerNo: "DRYU787659", impExp: "Import", vessel: "-0.72W", booking:"ONEYGTy0987889", feeAmount: "54.25"}
 ]

  getRecieprHistory(fromdate: any, todate: any): any{

    return this.getRecieprHistorydata;
  }

  getRecieptHistoryDetails() {

    return this.getRecieptHistorydetailsLoad;
  }
}
