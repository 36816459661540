import { PpGlobalConstants } from './../../ppcommon/pp-global-constants';
import { Injectable } from '@angular/core';
import { CSRAccountInContext } from '../typesinfo/typesinfo';

@Injectable({
  providedIn: 'root'
})
export class ContextinfoService {

  constructor() { }

  IsCSRMode()
  {
    let csr  = localStorage.getItem(PpGlobalConstants.CSRInContext)

    if(JSON.parse(csr) === true)
       return true;
    else
      return false;
  }

  setCSRMode(incontext : boolean)
  {
    localStorage.setItem(PpGlobalConstants.CSRInContext, JSON.stringify(incontext)) ;

  }

  getAccountInfoInContext()
  {
    
    // let account :CSRAccountInfo =  JSON.parse(localStorage.getItem("CSRAccountInfo"),CSRAccountInfo);

    let account: CSRAccountInContext = JSON.parse(localStorage.getItem("CSRAccountInfo" || "{}"));
    return account;

  }

  setCompanyInformation(DisplayRGBCOAccountList: import("../../csr/csr-csrresetpassword/csr-csrresetpwdtype").CSRResetPwdType.RGBCOAccount[]) {

    localStorage.setItem(PpGlobalConstants.CSRImpersonation_VTXAccountInfo,JSON.stringify(DisplayRGBCOAccountList));
    
  }

  getVTXAccountInformation()
  {
    return localStorage.getItem(PpGlobalConstants.CSRImpersonation_VTXAccountInfo);
  }


}
