import { debuglog } from 'src/app/ppcommon/pp-global-constants';
import { userInfo } from 'os';
import { stringlog } from 'src/app/csr-containerhistory/csr-containerhistory-type';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '../common/services/translate.service';
import { PpGlobalConstants } from '../ppcommon/pp-global-constants';
import { RGBCO, UserInfoType } from '../common/typesinfo/typesinfo';
import { WebapidataService } from '../common/services/webapidata.service';
import { debug } from 'console';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-accept-ctf-termsconditions',
  templateUrl: './login-accept-ctf-termsconditions.component.html',
  styleUrls: ['./login-accept-ctf-termsconditions.component.scss']
})
export class LoginAcceptCtfTermsconditionsComponent implements OnInit {

  TCOption: any;
  IsError: boolean;
  ErrorMessage: string;
  ShowLoader: boolean;
  IsTermsChecked: boolean;
  TCCheckErrorMessageDisplay: boolean;

  constructor(public locale: TranslateService,
    private router: Router,
    private dataService: WebapidataService,) {

      this.IsTermsChecked = false;

     }

  ngOnInit() {

    debuglog('LoginAcceptCtfTermsconditionsComponent - INTI');
    this.ShowLoader = false;

  }

  TCChecked()
  {
    if(this.IsTermsChecked == false)
    {
      this.TCCheckErrorMessageDisplay = true;
    }
    else
    {
      this.TCCheckErrorMessageDisplay = false;
    }

  }

  Submit()
  {

    stringlog("submit");
    if(this.IsTermsChecked)
    {
      // Accepting option
      stringlog("TC checked : CTF");

      let UserInfo_Get: UserInfoType = JSON.parse(
        localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
      );

      let SearchRequestObj = <any>{};

      SearchRequestObj.Company_Id = UserInfo_Get.Company_Id;
      SearchRequestObj.User_Id = UserInfo_Get.User_Id;

      
      this.ShowLoader =  true;
      this.dataService.AccountRegister_UpdateCTFTerms(SearchRequestObj).subscribe(
        (data: any) => {
          
          stringlog("AccountRegister_UpdateCTFTerms");
          this.ShowLoader =  false;
          if (data.result == "error") {
           
            this.IsError = true;
            this.ErrorMessage = data.errormessage;
  
            return;
          } else {
            stringlog("AccountRegister_UpdateCTFTerms: success ");

            this.FetchUserInfo();
  
            localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"CTF")
            // acceptctftermsconditions
            environment.logoutURL = environment.WeeblySiteURL;
            this.router.navigate(["/accountsummary"]);
          
          }
          return;
        },
        (error) => {
          console.log("connectivity error: " + error);
          console.log("connectivity error: " + error.message);
          this.ShowLoader = false;
        }
      );

    

    }
    else
    {
      this.TCCheckErrorMessageDisplay = true;
     
    }
    
  }


FetchUserInfo()
{
  // get updated terms & conditions loaded
  this.dataService.getUserInfo().subscribe(
    (data: any) => {
      // this.loginResponse = data;
      const userInfo: UserInfoType = data.RG_User[0];
      let BCOInfoList: RGBCO[] = null;

      if (data.hasOwnProperty("RG_BCO")) {
        BCOInfoList = data.RG_BCO; 
        stringlog( "POST Accept TC -  get BCO Info: " + JSON.stringify(BCOInfoList));
        localStorage.setItem( PpGlobalConstants.BCOInfoList, JSON.stringify(BCOInfoList) );
      }
    },
    (error) => {
      stringlog(error);
    }
  );
}

  // Not using
  OldSubmit()
  {

    stringlog("submit");
    if(this.TCOption == 1)
    {
      // Accepting option
      stringlog("submit : 1 CTF");
      // localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"CTF")
      // this.router.navigate(["/accountsummary"]);

      let UserInfo_Get: UserInfoType = JSON.parse(
        localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
      );

      let SearchRequestObj = <any>{};

      SearchRequestObj.Company_Id = UserInfo_Get.Company_Id;
      SearchRequestObj.User_Id = UserInfo_Get.User_Id;

      
      this.ShowLoader =  true;
      this.dataService.AccountRegister_UpdateCTFTerms(SearchRequestObj).subscribe(
        (data: any) => {
          
          stringlog("AccountRegister_UpdateCTFTerms");
          this.ShowLoader =  false;
          if (data.result == "error") {
           
            this.IsError = true;
            this.ErrorMessage = data.errormessage;
  
            return;
          } else {
            stringlog("AccountRegister_UpdateCTFTerms: success ");
  
            localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"CTF")
            this.router.navigate(["/accountsummary"]);
          
          }
          return;
        },
        (error) => {
          console.log("connectivity error: " + error);
          console.log("connectivity error: " + error.message);
          this.ShowLoader = false;
        }
      );

    }
    else if(this.TCOption == 2)
    {
      //Rejecting option
      stringlog("submit : 2 TMF");
      localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"TMF")
      this.router.navigate(["/accountsummary"]);
    }
    
  }

  Cancel()
  {
    stringlog("Cancel: TMF");
    localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"TMF")
    environment.logoutURL = environment.PierPASSHomeURL;
    this.router.navigate(["/accountsummary"]);
  }

}
