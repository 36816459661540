import { stringlog } from 'src/app/csr-containerhistory/csr-containerhistory-type';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { Router } from "@angular/router";
import {
  CCType,
  CreditCardValidator,
} from "src/app/Account/add-funds/credit-card-validator";
import { GetCountryNamesType, GetStateNamesType } from "src/app/Account/add-funds/get-state-names";
import { getValidationConfigFromCardNo } from "src/app/Account/add-funds/helpers/card.helper";
import { luhnValidator } from "src/app/Account/add-funds/validators/luhnValidator";
import { TableUtilPDF } from "src/app/Account/table-util-pdf";
import { TranslateService } from "src/app/common/services/translate.service";
import { WebapidataService } from "src/app/common/services/webapidata.service";
import { UserInfoType } from "src/app/common/typesinfo/typesinfo";
import { TableExcelUtil } from "src/app/Import/import-fees-due/excel-util";
import { PpGlobalConstants } from "src/app/ppcommon/pp-global-constants";
import {
  FeesDueCreditBalance,
  ImportContainerPaymentFee,
  IS_ConInventory,
  SubmitCheckPaymentDetails,
  TN_ConInventory,
} from "../import-search-types";

import { transition } from "@angular/animations";
import { catchError } from "rxjs/operators";
import { log } from "util";
import { emptyScheduled } from "rxjs/internal/observable/empty";
import { E } from "@angular/cdk/keycodes";
import { Location } from "@angular/common";
import { TNConInventory } from "src/app/Import/import-fees-due/retrieving-fees-due-list";

import { containsValidatorExtension } from "@rxweb/reactive-form-validators/validators-extension";

@Component({
  selector: "app-importsearch-container-payment",
  templateUrl: "./importsearch-container-payment.component.html",
  styleUrls: ["./importsearch-container-payment.component.scss"],
})
export class ImportsearchContainerPaymentComponent
  implements OnInit, AfterViewInit
{
  //Table Data initilizationa and Fields
  @Input() selectedUserFeesDueList: [];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = ["ContainerNumber", "FeeAmt"];
  dataSource: any;
  containerAmount: IS_ConInventory[];
  TotalFeeDue: any;
  directPayment = true;
  paymentReceipt = false;
  lPaymentLogId: any;

  //Second Table data source and Fields
  dataSourceAfterPayment: any;
  displayedColumnsafterpayment: string[] = [
    "ContainerNumber",
    "BLBooking",
    "Carrier",
    "Ref_Num",
    "FeePaid",
  ];

  AccountNumber: any;
  OrderNumber: any;
  OrderDatetime: any;
  OrderTotal: any;
  Confirmation: any;
  isSubmitButton = false;
  PaymentCount: any;

  PAYMENT_RECEIPT: any = "PaymentReceipt";
  paymenttninverntory: IS_ConInventory[];
  paymenttninverntoryCount: any;
  ///////////////////////////

  rdoVisa: string = "";
  rdoMC: string = "";
  rdoAmex: string = "";
  CCreditCardType: string = "";
  today: number = Date.now();
  echeckAmount: Number;
  //isReadonly = true;
  ShowLoader: boolean = true;
  serversideErrorMessage: any;
  private VTX_SuccessCode: Number = 130008;
  private VTX_SuccessUpdateCode: Number = 130001;
  private VTX_eSuccessUpdateCode: Number = 130002;
  isCreditCardNumber: boolean = true;
  myForm: FormGroup;
  States: GetStateNamesType[] = [];
  States1: GetStateNamesType[] = [];
  Countrys: GetCountryNamesType[] = [];
  // accountAndCreditcardInfo: GetAccountStatusAndHistoryDetailsType[] = [];
  getMonths = [];
  Months = [
    { Id: "01", Name: "January" },
    { Id: "02", Name: "February" },
    { Id: "03", Name: "March" },
    { Id: "04", Name: "April" },
    { Id: "05", Name: "May" },
    { Id: "06", Name: "June" },
    { Id: "07", Name: "July" },
    { Id: "08", Name: "August" },
    { Id: "09", Name: "September" },
    { Id: "10", Name: "October" },
    { Id: "11", Name: "November" },
    { Id: "12", Name: "December" },
  ];
  getYears = [];
  Years = [];
  COFCheck: string;
  CreditCardTypeCheck: string;
  paymentTypes: string[] = [
    "Credit Card On File",
    "Credit Card",
    "eCheck",
    "Credit Balance",
  ];
  PersonalCompanyType: string[] = ["Personal", "Company"];
  PCheck: string = "Personal";
  //AccountStatus and Credit card details properties
  AccountId: number;
  AccountBalance: number;
  OverdueBalance: number;
  GetCCOnFileFlg: string;
  HolderName: string;
  CCNum: string;
  CCExpMonth: string;
  CCExpYear: string;
  CCType: string;
  Addr1: string;
  Addr2: string;
  City: string;
  State: string;
  Zip: string;
  visible = false;
  Errvisible = false;
  Message: any;
  errorMessage: any;
  CCheckCreditCardType: any;
  SaveCheck: any;
  personalorcompanycheck: string;
  PayType_CdIndex: number;
  result = [];
  ParentModuleName: string = "Account";
  ChildModuleName: string = "Payment";
  accountAndCreditcardInfo: any;
  FeeTypeCTFTMFCode:any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public locale: TranslateService,
    private dataService: WebapidataService,
    private _location: Location,
    private dialog: MatDialog
  ) {
    this.myForm = this.formBuilder.group({
      // Amount:["", [Validators.required, Validators.pattern("^[0-9]*$")]],

      //Credit Card On File Fields
      PayType: [""],
      PCType: [""],
      CreditCardType: [{ value: "", disabled: true }, Validators.required],
      CreditCardNumber: [{ value: "", disabled: true }, Validators.required],
      CreditCardHolderName: [
        { value: "", disabled: true },
        Validators.required,
      ],
      selectedMonth: [{ value: "", disabled: true }, Validators.required],
      Year: [{ value: "", disabled: true }, Validators.required],
      AddressLine1: [""],
      AddressLine2: [""],
      City: [""],
      StateCd: [""],
      ZipCode: [""],

      //Credit Card Fields
      CCreditCardType: ["", Validators.required],
      CCreditCardNumber: [
        "",
        [Validators.required, Validators.minLength(12), luhnValidator()],
      ],
      CCreditCardHolderName: ["", Validators.required],
      CMonth: ["", Validators.required],
      CYear: ["", Validators.required],
      CVerificationCode: ["", [Validators.required, Validators.maxLength(4)]],
      CAddressLine1: ["", Validators.required],
      CAddressLine2: [""],
      CCity: ["", Validators.required],
      CCountry:["",Validators.required],
      CState: ["", Validators.required],
      CZipCode: ["", [Validators.required, Validators.maxLength(10)]],
      CCheckFuture: [""],

      //eCheck Details
      eName: ["", Validators.required],
      eCheck: ["", Validators.required],
      ePhone:["",[Validators.required,Validators.pattern("^[0-9]{10}$")]],
      eEmail: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      eAddress: ["", Validators.required],
      eCity: ["", Validators.required],
      eCountry:["",Validators.required],
      eStateCd: ["", Validators.required],
      eZipCode: ["", [Validators.required, Validators.maxLength(10)]],
      eBankRoutingNumber: ["", Validators.required],
      eBankAcctNumber: ["", Validators.required],
      PDLSStateCd: [""],
      eDLStateId: [""],
      eCompanyName: [""],
      eCompanyTaxId: [""],
    });
  }

  ngOnInit() {
    this.ShowLoader = true;
    this.dataService.PushModuleNameChange(
      this.ParentModuleName,
      this.ChildModuleName
    );

    let UserInfo_Get: UserInfoType = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
    );

    this.dataSource = new MatTableDataSource(this.selectedUserFeesDueList);

    this.containerAmount = this.selectedUserFeesDueList;

    stringlog("payment screen data:" +  JSON.stringify(this.containerAmount));

    // GET Method for StateNames
    this.dataService.GetStateNames().subscribe((data: any) => {
      if (data.length == 0) {
        alert("Data Not Found");
        return;
      } else {
        this.States1 = data;
      }
    });

     // GET Method for CountryNames
     this.dataService.GetCountrys().subscribe((data: any) => {
      if (data.length == 0) {
        alert("Data Not Found");
        return;
      } else {
        this.Countrys = data;
        
      }
    });

    this.Years = this.LoadYears();
    //Checking FeeTypeCode From UI Side(CTF Implementation)
    this.FeeTypeCTFTMFCode = localStorage.getItem(PpGlobalConstants.FeeTypeCTFTMF);
    //this.Months = this.LoadMonths();
    //Get Account status and Credit card information details
    this.dataService
      .GetAccountStatusAndCreditCardInformation(
        UserInfo_Get.Company_Id,
        UserInfo_Get.User_Id,
        this.FeeTypeCTFTMFCode
      )
      .subscribe((data: any) => {
        if (data.length == 0) {
          alert("Data Not Found");
          this.ShowLoader = false;
          return;
        } else {
          this.ShowLoader = false;
          //
          this.accountAndCreditcardInfo = data;
          this.AccountId = data.AcctStat[0].AccountId;
          this.AccountBalance = (data.AcctStat[0].AccountBalance == null || data.AcctStat[0].AccountBalance == undefined || data.AcctStat[0].AccountBalance == "") ? 0 : data.AcctStat[0].AccountBalance;

          this.GetCCOnFileFlg = data.CConFile[0].GetCCOnFileFlg;
          this.HolderName = data.CConFile[0].HolderName;
          this.CCNum = data.CConFile[0].CCNum;

          if (data.CConFile[0].CCNum != "") {
            this.COFCheck = CreditCardValidator.COFCheck;
          } else {
            this.COFCheck = CreditCardValidator.CreditCardCheck;
          }

          this.CCExpMonth = data.CConFile[0].CCExpMonth;
          this.CCExpYear = data.CConFile[0].CCExpYear;
          //CreditCardTypeCheck
          this.CCType = data.CConFile[0].CCType;

          if (this.CCType.toUpperCase() == CCType.CCType_VISA.toUpperCase()) {
            this.CreditCardTypeCheck = CCType.rdoVisa;
          }
          if (this.CCType.toUpperCase() == CCType.CCType_MC.toUpperCase()) {
            this.CreditCardTypeCheck = CCType.rdoMC;
          }
          if (this.CCType.toUpperCase() == CCType.CCType_AMEX.toUpperCase()) {
            this.CreditCardTypeCheck = CCType.rdoAmex;
          }
          this.Addr1 = data.CConFile[0].Addr1;
          this.Addr2 = data.CConFile[0].Addr2;
          this.City = data.CConFile[0].City;
          this.State = data.CConFile[0].State;
          this.Zip = data.CConFile[0].Zip;
          if (data.CConFile[0].CCNum != "") {
            //set the data values to UI Fields
            this.myForm.patchValue({
              CreditCardNumber: this.CCNum,
              CreditCardHolderName: this.HolderName,
              selectedMonth: this.Months.find((obj) => {
                return obj.Id === this.CCExpMonth;
              }).Id,
              Year: this.Years.find((e) => e == this.CCExpYear),
              AddressLine1: this.Addr1,
              AddressLine2: this.Addr2,
              City: this.City,
              StateCd:
                this.State === ""
                  ? 0
                  : this.States1.find((obj) => {
                      return obj.State_Cd === this.State;
                    }).State_Cd,
              ZipCode: this.Zip,
            });
          }

          //this.isReadonly = false;
          this.ShowLoader = false;
        }
      });
  }

  //User Selected Container Amount details
  /** Gets the total cost of all transactions. */
  getTotalCost() {
    // this.TotalFeeDue = this.containerAmount
    //   .map((t) => t.FeeAmt)
    //   .reduce((acc, value) => acc + value, 0);
    // return this.containerAmount
    //   .map((t) => t.FeeAmt)
    //   .reduce((acc, value) => acc + value, 0);

      this.TotalFeeDue =  this.containerAmount
      .reduce((acc, t) => Math.round((acc + t.FeeAmt) * 100) / 100, 0);

      return this.TotalFeeDue;
  }
  // //Second Table Total amount details
  // getTotalAmount() {
  //   return this.paymenttninverntory
  //     .map((t) => t.FeeAmt)
  //     .reduce((acc, value) => acc + value, 0);
  // }

  getdata(e: any) {
  }
  //Submiting Payment details based on PaymentTye
  SubmitPaymentDetails() {
    let UserInfo_details: UserInfoType = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
    );

    //Credit Card On File
    if (this.myForm.controls["PayType"].value == CreditCardValidator.COFCheck) {
      const submitPaymentDetails: ImportContainerPaymentFee = {
        Company_Id: UserInfo_details.Company_Id,
        VTXAccount_Id: this.AccountId,
        Ammount: this.TotalFeeDue,
        HolderName: this.myForm.controls["CreditCardHolderName"].value,
        Addr1: this.myForm.controls["AddressLine1"].value,
        Addr2: this.myForm.controls["AddressLine2"].value,
        City: this.myForm.controls["City"].value,
        
        State: this.myForm.controls["StateCd"].value,
        Zip: this.myForm.controls["ZipCode"].value,
        CCType: this.CCType,
        CCNum: this.myForm.controls["CreditCardNumber"].value,
        VerificationCode: "",
        CCExpMonth: this.myForm.controls["selectedMonth"].value,
        CCExpYear: this.myForm.controls["Year"].value,
        SaveCC: "N",
        UseCCOnFile: this.GetCCOnFileFlg,
        PayType_Cd: CreditCardValidator.CreditCardOnFile,
        User_Id: UserInfo_details.User_Id,
        PayType_CdIndex: 0,
        tnConInventory: this.selectedUserFeesDueList,
        Caller: "ImpSearch",
        FeeTypeCTFTMFCode: this.FeeTypeCTFTMFCode
      };

      stringlog("Payment request: " +  JSON.stringify(submitPaymentDetails));
      

      if (this.TotalFeeDue == 0) {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Total FeeDue amount is Zero.";
        this.ShowLoader = false;
        return;
      }
      // else if (Math.abs(this.AccountBalance) < this.TotalFeeDue) {
      //   this.visible = false;
      //   this.Errvisible = true;
      //   this.errorMessage = "Insufficient acct balance: $" + this.AccountBalance;
      //   this.ShowLoader = false;
      //   return;
      // }
      else {
        this.ShowLoader = true;
        stringlog("Ravi: before pay request ");
        this.CallPaymentService(submitPaymentDetails, UserInfo_details);
       
      }
    }
    //Credit Card
    else if (
      this.myForm.controls["PayType"].value ==
      CreditCardValidator.CreditCardCheck
    ) {
      const submitCreditCardPaymentDetails: ImportContainerPaymentFee = {
        Company_Id: UserInfo_details.Company_Id,
        VTXAccount_Id: this.AccountId,
        Ammount: this.TotalFeeDue,
        HolderName: this.myForm.controls["CCreditCardHolderName"].value,
        Addr1: this.myForm.controls["CAddressLine1"].value,
        Addr2: this.myForm.controls["CAddressLine2"].value,
        City: this.myForm.controls["CCity"].value,
        
        State: this.myForm.controls["CState"].value,
        Zip: this.myForm.controls["CZipCode"].value,
        CCType: this.CCheckCreditCardType,
        CCNum: this.myForm.controls["CCreditCardNumber"].value.replace(
          /\s/g,
          ""
        ),
        VerificationCode: this.myForm.controls["CVerificationCode"].value,
        CCExpMonth: this.myForm.controls["CMonth"].value,
        CCExpYear: this.myForm.controls["CYear"].value,
        SaveCC: this.SaveCheck,
        UseCCOnFile: "N",
        PayType_Cd: CreditCardValidator.CreditCard,
        User_Id: UserInfo_details.User_Id,
        PayType_CdIndex: 1,
        tnConInventory: this.selectedUserFeesDueList,
        Caller: "ImpSearch",
        FeeTypeCTFTMFCode: this.FeeTypeCTFTMFCode
      };

      if (this.TotalFeeDue == 0) {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Enter a valid amount.";
        this.ShowLoader = false;
        return;
      }
      // else if (Math.abs(this.AccountBalance) < this.TotalFeeDue) {
      //   this.visible = false;
      //   this.Errvisible = true;
      //   this.errorMessage = "Insufficient acct balance: $" + this.AccountBalance;
      //   this.ShowLoader = false;
      //   return;
      // }
      else {
        this.ShowLoader = true;

        this.CallPaymentService(submitCreditCardPaymentDetails, UserInfo_details);
      
      }
    }
    // eCheck
    else if (
      this.myForm.controls["PayType"].value == CreditCardValidator.eCheck
    ) {
      if (this.myForm.controls["PCType"].value == "Personal") {
        this.personalorcompanycheck = "P";
      } else {
        this.personalorcompanycheck = "C";
      }

      const submiteCheckPaymentDetails: SubmitCheckPaymentDetails = {
        Company_Id: UserInfo_details.Company_Id,
        VTXAccount_Id: this.AccountId,
        Ammount: this.TotalFeeDue,
        BAName: this.myForm.controls["eName"].value,
        Email: this.myForm.controls["eEmail"].value,
        Phone: this.myForm.controls["ePhone"].value,
        Addr1: this.myForm.controls["eAddress"].value,
        City: this.myForm.controls["eCity"].value,
        
        State: this.myForm.controls["eStateCd"].value,
        Zip: this.myForm.controls["eZipCode"].value,
        PersonalOrCompany: this.personalorcompanycheck,
        CompanyName: this.myForm.controls["eCompanyName"].value,
        CompanyTaxId: this.myForm.controls["eCompanyTaxId"].value,
        CheckNum: this.myForm.controls["eCheck"].value,
        BankAcctNum: this.myForm.controls["eBankAcctNumber"].value,
        BankRouteNum: this.myForm.controls["eBankRoutingNumber"].value,
        DLState: this.myForm.controls["PDLSStateCd"].value,
        DLNum: this.myForm.controls["eDLStateId"].value,
        PayType_Cd: CreditCardValidator.eCheck,
        User_Id: UserInfo_details.User_Id,
        Addr2: "",
        SaveBA: "",
        UseBAOnFile: "",
        PayType_CdIndex: 2,
        tnConInventory: this.selectedUserFeesDueList,
        Caller: "ImpSearch",
        FeeTypeCTFTMFCode: this.FeeTypeCTFTMFCode
      };

      if (this.TotalFeeDue == 0) {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Enter a valid amount.";
        this.ShowLoader = false;
        return;
      }
      //  else if (Math.abs(this.AccountBalance) < this.TotalFeeDue) {
      //   this.visible = false;
      //   this.Errvisible = true;
      //   this.errorMessage = "Insufficient acct balance: $" + this.AccountBalance;
      //   this.ShowLoader = false;
      //   return;
      // }
      else if (this.myForm.controls["eName"].value === "") {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Name is required.";
        this.ShowLoader = false;
        return;
      } else if (this.myForm.controls["ePhone"].value === "") {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Phone number is required.";
        this.ShowLoader = false;
        return;
      } else if (this.myForm.controls["eEmail"].value === "") {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Email is required.";
        this.ShowLoader = false;
        return;
      } else if (this.myForm.controls["eAddress"].value === "") {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Address is required.";
        this.ShowLoader = false;
        return;
      } else if (this.myForm.controls["eCity"].value === "") {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "City is required.";
        this.ShowLoader = false;
        return;
      } 
      else if (this.myForm.controls["eCountry"].value === "") {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = this.locale.data.val_country_req;
        this.ShowLoader = false;
        return;
      }
      else if (this.myForm.controls["eStateCd"].value === "") {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "State is required.";
        this.ShowLoader = false;
        return;
      } else if (this.myForm.controls["eZipCode"].value === "") {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Zipcode is required.";
        this.ShowLoader = false;
        return;
      } else if (this.myForm.controls["eCheck"].value === "") {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Check number is required.";
        this.ShowLoader = false;
        return;
      } else if (this.myForm.controls["eBankAcctNumber"].value === "") {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Bank AccountNumber is required.";
        this.ShowLoader = false;
        return;
      } else if (this.myForm.controls["eBankRoutingNumber"].value === "") {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "BankRouting number is required.";
        this.ShowLoader = false;
        return;
      } else if (
        this.personalorcompanycheck == "P" &&
        this.myForm.controls["PDLSStateCd"].value === ""
      ) {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Drivers Lic/State ID is required.";
        this.ShowLoader = false;
        return;
      } else if (
        this.personalorcompanycheck == "P" &&
        this.myForm.controls["eDLStateId"].value === ""
      ) {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Drivers Lic/State ID # is required.";
        this.ShowLoader = false;
        return;
      } else if (
        this.personalorcompanycheck == "C" &&
        this.myForm.controls["eCompanyName"].value === ""
      ) {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Company name is required.";
        this.ShowLoader = false;
        return;
      } else if (
        this.personalorcompanycheck == "C" &&
        this.myForm.controls["eCompanyTaxId"].value === ""
      ) {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Company Tax ID is required.";
        this.ShowLoader = false;
        return;
      } else {
        this.ShowLoader = true;

        this.CallPaymentService(submiteCheckPaymentDetails, UserInfo_details);
       
      }
    }
    //Credit balance Implementation
    // CC Balance
    else if (
      this.myForm.controls["PayType"].value ==
      CreditCardValidator.CreditBalanceSpace
    ) {
      const submitFeesDueCreditBalance: FeesDueCreditBalance = {
        Company_Id: UserInfo_details.Company_Id,
        VTXAccount_Id: this.AccountId,
        Ammount: this.TotalFeeDue,
        PayType_Cd: CreditCardValidator.CreditBalance,
        User_Id: UserInfo_details.User_Id,
        PayType_CdIndex: 3,
        tnConInventory: this.selectedUserFeesDueList,
        Caller: "ImpSearch",
        FeeTypeCTFTMFCode: this.FeeTypeCTFTMFCode
      };
     
      if (this.TotalFeeDue == 0) {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage = "Total FeeDue amount is Zero.";
        this.ShowLoader = false;
        return;
      } else if (Math.abs(this.AccountBalance) < this.TotalFeeDue) {
        this.visible = false;
        this.Errvisible = true;
        this.errorMessage =
          "Insufficient acct balance: $" + this.AccountBalance;
        this.ShowLoader = false;
        return;
      } else {
        this.ShowLoader = true;

        this.CallPaymentService(submitFeesDueCreditBalance, UserInfo_details);
      }
    }
  }

  private CallPaymentService(paymentDetails: any, UserInfo_details: UserInfoType) {
    this.dataService
      .ImportSearchAddPayment(paymentDetails)
      .subscribe((data: any) => {


        //Fetching details after submiting the payment
        //API success Message Code
        if (data.hasOwnProperty("RG_Err")) {

          if (data.RG_Err[0].Err_Nbr == this.VTX_SuccessCode ||
            data.RG_Err[0].Err_Nbr == this.VTX_SuccessUpdateCode) {
            this.visible = true;
            this.Errvisible = false;
            this.Message = data.RG_Err[0].Err_Txt;
            this.ShowLoader = false;
            this.directPayment = false;
            this.paymentReceipt = true;

            this.lPaymentLogId = data.PaymentInfo[0].PaymentLog_Id;

            //PaymentInformation Second Table Implementation//
            this.dataService
              .ExportLoadControldata(
                this.PAYMENT_RECEIPT,
                UserInfo_details.Company_Id,
                UserInfo_details.User_Id,
                UserInfo_details.Login_Nm,
                this.lPaymentLogId,
                this.FeeTypeCTFTMFCode
              )
              .subscribe((data: any) => {
                if (data.length == 0) {
                  this.ShowLoader = false;
                } else {
                  this.ShowLoader = false;
                  this.paymenttninverntory = data.TN_ConInventory;
                  this.dataSourceAfterPayment = new MatTableDataSource(
                    data.TN_ConInventory
                  );
                  this.dataSourceAfterPayment.sort = this.sort;
                  this.AccountNumber = data.RG_BCO[0].VTXAccount_Id;
                  this.PaymentCount = data.TN_ConInventory.length;

                  if (data.TN_ConInventory.length > 0) {
                    //Need to do after getting the data
                    this.OrderTotal = data.TN_ConInventory[0].FeePaid;
                    this.OrderNumber =
                      data.TN_ConInventory[0].VTXTransaction_Id;
                    this.Confirmation = data.TN_ConInventory[0].ConfNum;
                    this.OrderDatetime =
                      data.TN_ConInventory[0].Payment_DtTm;
                  }
                }
              });
          } else {
            this.visible = false;
            this.Errvisible = true;
            this.errorMessage = data.RG_Err[0].Err_Txt;
            this.ShowLoader = false;
            this.directPayment = false;
            this.paymentReceipt = true;

            this.lPaymentLogId = data.PaymentInfo[0].PaymentLog_Id;

            //PaymentInformation Second Table Implementation//
            this.dataService
              .ExportLoadControldata(
                this.PAYMENT_RECEIPT,
                UserInfo_details.Company_Id,
                UserInfo_details.User_Id,
                UserInfo_details.Login_Nm,
                this.lPaymentLogId,
                this.FeeTypeCTFTMFCode
              )
              .subscribe((data: any) => {
                if (data.length == 0) {
                  this.ShowLoader = false;
                } else {
                  this.ShowLoader = false;
                  this.paymenttninverntory = data.TN_ConInventory;
                  this.dataSourceAfterPayment = new MatTableDataSource(
                    data.TN_ConInventory
                  );
                  this.dataSourceAfterPayment.sort = this.sort;
                  this.AccountNumber = data.RG_BCO[0].VTXAccount_Id;
                  this.PaymentCount = data.TN_ConInventory.length;
                }
              });
          }
        } else {
          this.visible = false;
          this.Errvisible = false;
          //this.Message = data.RG_Err[0].Err_Txt;
          this.ShowLoader = false;
          this.directPayment = false;
          this.paymentReceipt = true;

          this.lPaymentLogId = data.PaymentInfo[0].PaymentLog_Id;

          //PaymentInformation Second Table Implementation//
          this.dataService
            .ExportLoadControldata(
              this.PAYMENT_RECEIPT,
              UserInfo_details.Company_Id,
              UserInfo_details.User_Id,
              UserInfo_details.Login_Nm,
              this.lPaymentLogId,
              this.FeeTypeCTFTMFCode
            )
            .subscribe((data: any) => {
              if (data.length == 0) {
                this.ShowLoader = false;
              } else {

                this.ShowLoader = false;
                this.paymenttninverntory = data.TN_ConInventory;
                this.dataSourceAfterPayment = new MatTableDataSource(
                  data.TN_ConInventory
                );

                this.dataSourceAfterPayment.sort = this.sort;
                this.AccountNumber = data.RG_BCO[0].VTXAccount_Id;
                this.PaymentCount = data.TN_ConInventory.length;

                if (data.TN_ConInventory.length > 0) {
                  //Need to do after getting the data
                  this.OrderTotal = data.TN_ConInventory[0].FeePaid;
                  this.OrderNumber =
                    data.TN_ConInventory[0].VTXTransaction_Id;
                  this.Confirmation = data.TN_ConInventory[0].ConfNum;
                  this.OrderDatetime = data.TN_ConInventory[0].Payment_DtTm;
                }
              }
            });
        }
      });
  }

  // Only Integer Numbers
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  modelChangeFn(e) {
    this.echeckAmount = e;
  }

  radioChange(e: any) {
    if (e.value == "rdoVisa") {
      this.CCheckCreditCardType = "Visa";
    }
    if (e.value == "rdoMC") {
      this.CCheckCreditCardType = "MasterCard";
    }
    if (e.value == "rdoAmex") {
      this.CCheckCreditCardType = "AmericanExpress";
    }
  }

  CheckSaveEvent(e: any) {
    if (e.checked) {
      this.SaveCheck = "Y";
    } else {
      this.SaveCheck = "N";
    }
  }

  public LoadYears() {
    for (
      var j = new Date().getFullYear();
      j <= new Date().getFullYear() + 10;
      j++
    ) {
      this.getYears.push(j).toString();
    }
    return this.getYears;
  }

  public LoadMonths() {
    for (var j = 1; j <= 12; j++) {
      this.getMonths.push(j).toString();
    }
    return this.getMonths;
  }

  public getPayMentType() {
    return this.myForm.controls["PayType"].value;
  }

  public getPCType() {
    return this.myForm.controls["PCType"].value;
  }

  getCardNumberControl(): AbstractControl | null {
    return this.myForm && this.myForm.get("CCreditCardNumber");
  }

  cardMaskFunction(rawValue: string): Array<RegExp> {
    const card = getValidationConfigFromCardNo(rawValue);
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }

  backClicked() {
    window.location.reload();
  }

  done() {
    window.location.reload();
  }
  //Export Excel Data
  exportTable() {
    TableExcelUtil.exportTableToExcel("dataSourceAfterPayment");
  }
  //Download PDF Data
  downloadPdf() {
    //TableUtilPDF.exportToPdf('RefundHistoryDetails');
    TableUtilPDF.downloadtopdf("dataSourceAfterPayment", "Payment Receipt");
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getTotalPayment() {
    if (this.PaymentCount > 0) {
      return this.paymenttninverntory
        .map((t) => t.FeePaid)
        .reduce((acc, value) => acc + value, 0);
    } else {
      return 0;
    }
  }

  PaymentChange(e: any) {
    if (
      e.value == "Credit Balance" &&
      Math.abs(this.AccountBalance) < this.TotalFeeDue
    ) {
      this.isSubmitButton = true;
    } else {
      this.isSubmitButton = false;
    }
  }
  onClose() {
    this.dialog.closeAll();
    // window.location.reload();
  }

  onCountryChange(value): void {
    this.myForm.controls["CCountry"].setValue(value);
    this.States=this.States1.filter(i=>i.Country_Cd==value)
    
  }

  oneCountryChange(value): void {
    this.myForm.controls["eCountry"].setValue(value);
    this.States=this.States1.filter(i=>i.Country_Cd==value)
    
  }



}
