<app-loading-backdrop *ngIf="ShowLoader"></app-loading-backdrop>
<mat-toolbar>
  <small style="font-size: 17px;">&nbsp;{{HeaderName}}</small>
  <span class="fill-remaining-space"></span>
  <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1">
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar>
<mat-card>

  <div class="example-container">
    <!-- <div class="mat-elevation-z8"> -->
    <form [formGroup]="myForm" (ngSubmit)="SubmitUserInfo()" class="my-form" *ngIf="!hideEditUser">
      <mat-card-title class="Title">&nbsp;&nbsp;{{ locale.data.user_info }}    
      </mat-card-title>
      <div style=" float: right;" *ngIf="UserId==LoginUserId">
        <button mat-raised-button style="height: 30px;width: 185px;" (click)="hideEditScreen()" type="button">{{ locale.data.chng_psw }}</button>

      </div>
      <br *ngIf="UserId==LoginUserId"><br *ngIf="UserId==LoginUserId">
      <br *ngIf="UserId!=LoginUserId">
      <mat-card-content>
        <div>
          <mat-label style="">{{ locale.data.user_login_id }}:
          </mat-label>
          &nbsp;
          <input  style="font-size: 12px; width: 30%;
                           height: 20px;
                           text-align: left;
                           display: inline-block;" placeholder="Login ID" name="LoginId" maxlength="35" formControlName="LoginId"
            required />


          <mat-error *ngIf="myForm.controls['LoginId'].errors &&
            (myForm.controls['LoginId'].dirty ||
             myForm.controls['LoginId'].touched || myForm.get('LoginId').errors?.pattern)" style="font-size: 12px;"> *
            {{ locale.data.login_id_error }}</mat-error>
        </div>
        <!-- <div style="text-align: center;">
        </div> -->
        <br>
        <div >
          <mat-label style="">{{ locale.data.user_first_name }}:
          </mat-label>
          &nbsp;
          <input  style="font-size: 12px; width: 30%;
                           height: 20px;
                           text-align: left;
                           display: inline-block;" placeholder="First Name" name="FirstName"
            formControlName="FirstName" />

          <mat-error *ngIf="myForm.controls['FirstName'].errors &&
            (myForm.controls['FirstName'].dirty ||
             myForm.controls['FirstName'].touched)" style="font-size: 12px;"> * {{ locale.data.firstname_required }}
          </mat-error>

        </div>

        <!-- <div >
          
        </div> -->
        <br>
        <div>
          <mat-label style="">{{ locale.data.user_last_name }}:
          </mat-label>
          &nbsp;
          <input  style="font-size: 12px; width: 30%;
                           height: 20px;
                           text-align: left;
                           display: inline-block;" placeholder="Last Name" name="LastName"
            formControlName="LastName" />

          <mat-error *ngIf="myForm.controls['LastName'].errors &&
            (myForm.controls['LastName'].dirty ||
             myForm.controls['LastName'].touched)" style="font-size: 12px;"> * {{ locale.data.lastname_required }}
          </mat-error>

        </div>
        <!-- <div style="text-align: center;">
         
        </div> -->
        <br>
        <div>
          <mat-label style="">{{ locale.data.EmailAddress }}
          </mat-label>
          &nbsp;
          <input  type="email" style="font-size: 12px;
                            width: 30%;
                            height: 20px;
                            text-align: left;
                            display: inline-block;
                          " placeholder="Email" name="Email" formControlName="Email" required />

          <label
            *ngIf="(myForm.get('Email').invalid && myForm.get('Email').touched) || myForm.get('Email').dirty || myForm.get('Email').errors?.pattern">
            <mat-error style="font-size: 12px;" class="text-danger" *ngIf="myForm.get('Email').errors?.required">&nbsp;* {{ locale.data.val_email_req }}</mat-error>
            <mat-error style="font-size: 12px;" class="text-danger" *ngIf="myForm.get('Email').errors?.pattern">
              &nbsp;* {{ locale.data.valid_email }}</mat-error>
          </label>
        </div>
        <!-- <div style="text-align: center;">

         
        </div> -->
        <br>
        <div>
          <mat-label style="">{{ locale.data.confirm_email }}
          </mat-label>
          &nbsp;
          <input  type="email" style="font-size: 12px;
                            width: 30%;
                            height: 20px;
                            text-align: left;
                            display: inline-block;
                          " placeholder="Confirm Email" name="ConfEmail" formControlName="ConfEmail" required />

          <label
            *ngIf="(myForm.get('ConfEmail').invalid && myForm.get('ConfEmail').touched) || myForm.get('ConfEmail').dirty || myForm.get('ConfEmail').errors?.pattern || isEmailMismatch">
            <mat-error style="font-size: 12px;" class="text-danger" *ngIf="myForm.get('ConfEmail').errors?.required">
            &nbsp;* {{ locale.data.confirm_email_required }}</mat-error>
            <mat-error style="font-size: 12px;" class="text-danger" *ngIf="myForm.get('ConfEmail').errors?.pattern">
            &nbsp;* {{ locale.data.valid_email }}</mat-error>
            <mat-error style="font-size: 12px;" class="text-danger" *ngIf="isEmailMismatch">&nbsp;* {{ locale.data.confirm_email_match }}</mat-error>
          </label>
        </div>
        <!-- <div >

         
        </div> -->
        <br>
        <div>
          <mat-label style="">{{ locale.data.Phone }}
          </mat-label>
          &nbsp;
          <input  style="font-size: 12px; width: 30%;
                           height: 20px;
                           text-align: left;
                           display: inline-block;" placeholder="Phone" name="Phone" formControlName="Phone" required />
          <label
            *ngIf="(myForm.get('Phone').invalid && myForm.get('Phone').touched) || myForm.get('Phone').dirty || myForm.get('Phone').errors?.pattern">
            <mat-error style="font-size: 12px;" class="text-danger" *ngIf="myForm.get('Phone').errors?.required">&nbsp;* {{ locale.data.contact_phone_required }}</mat-error>
            <mat-error style="font-size: 12px;" class="text-danger" *ngIf="myForm.get('Phone').errors?.pattern">&nbsp;* {{ locale.data.contact_valid_phone }}</mat-error>
          </label>

        </div>
        <!-- <div style="text-align: center;">
         
        </div> -->
        <br>
        <div *ngIf="isAdmin">
          <mat-label>{{ locale.data.lbl_group }}:
          </mat-label>
          &nbsp;
          <mat-select class="ControlSelect" formControlName="CompanyGrp_Cd" [disabled]="disableUserGroup"
            placeholder="Company Group" name="CompanyGrp_Cd" required>
            <mat-option *ngFor="let group of CompanyGrps" [value]="group.CompanyGrp_Cd">
              {{ group.CompanyGrp_Dsc }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="myForm.controls['CompanyGrp_Cd'].errors &&
          (myForm.controls['CompanyGrp_Cd'].dirty ||
           myForm.controls['CompanyGrp_Cd'].touched)"  style="font-size: 12px;">&nbsp;* {{ locale.data.cmpny_grp }}</mat-error>

        </div>
        <!-- <div style="text-align: center;">
         
        </div> -->
        <br>
        <div *ngIf="UserId>0 && isAdmin">
          <mat-label>
          </mat-label>
          <mat-checkbox class="example-margin" formControlName="CheckInactive" name="CheckInactive"
            [(checked)]="isInactive" style="font-size: 12px; margin-left: 1%">
            {{ locale.data.usr_inactive }}
          </mat-checkbox>
          &nbsp;
          <mat-checkbox class="example-margin" *ngIf="LoginUserId!=UserId && isAdmin" formControlName="CheckReset"
            name="CheckReset" [(checked)]="isReset" style="font-size: 12px; margin-left: 1%">
            {{ locale.data.rst_pwd }}
          </mat-checkbox>
        </div>

        <div >
          <mat-checkbox class="example-margin" *ngIf="UserId===0 || ConstantContactEnabled!='Y'"
            formControlName="CheckCTCT" name="CheckCTCT" [(checked)]="checkCTCT"
            style="font-size: 12px; margin-left: 5%;font-weight: bold;">
            <p>{{chkCTCTText}}</p>
          </mat-checkbox>
        </div>
        <div *ngIf="UserId===0" style="font-size: 12px; margin-left: 5%;">
          <p>{{ locale.data.note_tmp_pswd }}</p>
        </div>
        <br>
        <div class="addContentTop" *ngIf="Errvisible" style="font-size: 12px;color:red">* {{ errorMessage }}</div>
        <div style="display: block; float: right; margin-bottom: 10px">
          <button mat-raised-button style="background-color: #FFFFFF;color: black;" type="button"
            (click)="onClose()">{{ locale.data.cancel}}</button>&nbsp;
          <button mat-raised-button type="submit">{{ locale.data.submit}}</button>
        </div>
        <br> <br>
      </mat-card-content>

    </form>
    <form [formGroup]="myPwdForm" (ngSubmit)="submitChangePassword()" class="my-form" *ngIf="hideEditUser">
      <mat-card-title class="Title">&nbsp;&nbsp;{{ locale.data.chng_pwd }}
      </mat-card-title>
      <br>
      <mat-card-content>
        <!-- <div style="text-align: center;">
    <mat-label >Login ID:
    </mat-label>
    &nbsp;
    <mat-label >hideEditUser
    </mat-label>
  </div>

 -->

        <!-- <br> -->
        <div style="text-align: center;">
          <mat-label style="">{{ locale.data.new_pwd }}
          </mat-label>
          &nbsp;

          <input  style="font-size: 12px; width: 40%;
                     height: 20px;
                     text-align: left;
                     display: inline-block;" placeholder="New Password" name="Password" formControlName="Password"
            [type]="pwdhide ? 'password' : 'text'" />
          <mat-icon style="margin-left: -26px;cursor: pointer;" matSuffix (click)="pwdhide = !pwdhide">{{pwdhide ?
            'visibility_off' : 'visibility'}}</mat-icon>



        </div>

        <div style="text-align: center;">
          <mat-error *ngIf="myPwdForm.controls['Password'].errors &&
    (myPwdForm.controls['Password'].dirty ||
    myPwdForm.controls['Password'].touched) || myPwdForm.get('Password').errors?.pattern " style="font-size: 12px;"> *
            {{pwdValidateMSG}}
          </mat-error>

        </div>
        <br>
        <div style="text-align: center;">
          <mat-label style="">{{ locale.data.confirm_pwd }}
          </mat-label>
          &nbsp;
          <input  style="font-size: 12px; width: 40%;
                     height: 20px;
                     text-align: left;
                     display: inline-block;" placeholder="Confirm Password" name="CNFPassword"
            formControlName="CNFPassword" [type]="cnfpwdhide ? 'password' : 'text'" />
          <mat-icon style="margin-left: -26px;cursor: pointer;" matSuffix (click)="cnfpwdhide = !cnfpwdhide">
            {{cnfpwdhide ? 'visibility_off' : 'visibility'}}</mat-icon>


        </div>

        <div style="text-align: center;">
          <mat-error *ngIf="myPwdForm.controls['CNFPassword'].errors &&
                (myPwdForm.controls['CNFPassword'].dirty ||
                myPwdForm.controls['CNFPassword'].touched)" style="font-size: 12px;"> * {{ locale.data.confirm_pwd_req }}
          </mat-error>
          <mat-error style="font-size: 12px;" class="text-danger" *ngIf="isPasswordMismatch">&nbsp;* {{ locale.data.confirm_pwd_match }}</mat-error>

        </div>
        <br>
        <div class="addContentTop" *ngIf="PwdErrvisible" style="font-size: 12px;color:red">* {{ PwdErrorMessage }}</div>
        <div style="display: block; float: right; margin-bottom: 10px">
          <button mat-raised-button style="background-color: #FFFFFF;color: black;" type="button"
            (click)="hideEditUser=!hideEditUser">{{ locale.data.cancel }}</button>&nbsp;
          <button mat-raised-button type="submit">{{ locale.data.submit }}</button>
        </div>
        <br>

        <br>
      </mat-card-content>


    </form>
    <!-- </div> -->
  </div>

</mat-card>
