import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatIconRegistry, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { constants } from 'buffer';
import { WebapidataService } from 'src/app/common/services/webapidata.service';
import { PpGlobalConstants } from 'src/app/ppcommon/pp-global-constants';
import { UserInfoType } from 'src/app/common/typesinfo/typesinfo';
import { TranslateService } from 'src/app/common/services/translate.service';



@Component({
  selector: 'app-account-addedit-user',
  templateUrl: './account-addedit-user.component.html',
  styleUrls: ['./account-addedit-user.component.scss']
})
export class AccountAddeditUserComponent implements OnInit {
  myForm: FormGroup;
  myPwdForm: FormGroup;
  CompanyGrps: any = [];
  isAdmin: boolean = false;
  UserId: number;
  LoginUserId: number;
  isReset: boolean = false;
  isInactive: boolean = false;
  checkCTCT: boolean = false;
  SYSTEMNAME: string = "";
  chkCTCTText: string = "";
  ConstantContactEnabled: string = "";
  Errvisible: boolean = false;
  errorMessage: string = "";
  PwdErrorMessage: string = "";
  HeaderName: string;
  disableUserGroup: boolean = false;
  CompanyGrp_Cd: string = "";
  ShowLoader: boolean = false;
  groupcodeRequired: boolean = false;
  hideEditUser: boolean = false;
  PwdErrvisible: boolean = false
  pwdhide = true;
  cnfpwdhide = true
  minPwdLength: number;
  maxPwdLength: number;
  pwdValidateMSG: string = "";
  UserInfo_details: UserInfoType = JSON.parse(
    localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
  );
  @Output() saveOnReload: EventEmitter<string> = new EventEmitter<string>();
  FeeType_Cd: string;
  constructor(private dialog: MatDialogRef<AccountAddeditUserComponent>, private dataService: WebapidataService, private formBuilder: FormBuilder, private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,public locale: TranslateService, @Inject(MAT_DIALOG_DATA) public data: {
      UserData: any
    }
  ) {

    this.FeeType_Cd = localStorage.getItem(PpGlobalConstants.FeeTypeCTFTMF);

    if (this.data.UserData.MakeInactive == "Y")
      this.isInactive = true;
    this.isAdmin = this.data.UserData.IsAdmin;
    this.LoginUserId = this.data.UserData.loginUserId;
    this.UserId = this.data.UserData.UserId;
   
    if (this.UserId == this.LoginUserId)      
      this.disableUserGroup = true;
    
      
    if (this.data.UserData.IsAdmin) {
      this.CompanyGrps = this.data.UserData.CompanyGrps;
      if (this.UserId > 0) {
        if (this.UserId != this.LoginUserId) {
          this.ShowLoader = true;
          this.dataService.GetUserInfoByUserName(this.data.UserData.LogIn_Id).subscribe((data: any) => {
            this.CompanyGrp_Cd = data.RG_User[0].CompanyGrp_Cd;
            this.myForm.controls["CompanyGrp_Cd"].setValue(this.CompanyGrp_Cd);
            this.ShowLoader = false;
          });;
        } else {
          this.CompanyGrp_Cd = this.data.UserData.Group_Cd;
        }


      }
    }
    
    let appinstance = localStorage.getItem("appInstance_Name");
    if (appinstance) {
      let portconfig = environment.portsconfigs.find(
        (a) => a.clientid == appinstance
      );
      this.ConstantContactEnabled = portconfig.ConstantContactEnabled;
      this.SYSTEMNAME = portconfig.SYSTEMNAME;
      this.minPwdLength = portconfig.MinPasswordLength;
      this.maxPwdLength = portconfig.MaxPasswordLength;
    }
    this.pwdValidateMSG = this.locale.data.pwd_lbl_msg + this.minPwdLength + "-" + this.maxPwdLength + this.locale.data.pwd_lbl_txt;
    this.chkCTCTText = this.locale.data.pwd_notification + this.SYSTEMNAME + this.locale.data.pwd_prgm_mail
    this.myForm = this.formBuilder.group({
      LoginId: ["", [Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,35}$')]],
      FirstName: ["", [Validators.required]],
      LastName: ["", [Validators.required]],
      Email: ["", [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]],
      ConfEmail: ["", [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]],
      Phone: ["", [Validators.required, Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$|^[0-9]{10}$')]],
      CompanyGrp_Cd: ["", [ Validators.required] ],
      CheckCTCT: [""],
      CheckInactive: [""],
      CheckReset: [""],
    }, {
      validator: (form: FormGroup) => { return form.get('ConfEmail').value != "" && form.get('Email').value !== form.get('ConfEmail').value ? { emailMismatch: true } : null },
      validator1: (form: FormGroup) => { return form.get('CompanyGrp_Cd').value == "" && this.isAdmin == true ? { groupcodeRequired: true } : null }
    });

    let pwdReg = '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{' + this.minPwdLength + ',' + this.maxPwdLength + '}$';
    this.myPwdForm = this.formBuilder.group({
      Password: ["", [Validators.required, Validators.pattern(pwdReg)]],
      CNFPassword: ["", [Validators.required]],
    }, {

      validator: (form: FormGroup) => { return form.get('CNFPassword').value != "" && form.get('Password').value !== form.get('CNFPassword').value ? { passwordMismatch: true } : null },
    })
  }

  ngOnInit() {

    this.FeeType_Cd = localStorage.getItem(PpGlobalConstants.FeeTypeCTFTMF);
    
    if (this.data.UserData.UserId > 0) {
      this.HeaderName = this.locale.data.edit_user_header;
      this.myForm.patchValue({
          LoginId: this.data.UserData.LogIn_Id,
        FirstName: this.data.UserData.FirstName,
        LastName: this.data.UserData.LastName,
        CompanyGrp_Cd: this.CompanyGrp_Cd,
        Phone: this.data.UserData.Phone,
        Email: this.data.UserData.EmailAddress,
        ConfEmail: this.data.UserData.EmailAddress,
        CheckInactive: this.isInactive
      });
    } else if (this.data.UserData.UserId === 0) {
      this.HeaderName = this.locale.data.add_user_header;
      this.isReset = true;
      if (this.ConstantContactEnabled == "Y")
        this.checkCTCT = true;
      this.myForm.patchValue({ CheckCTCT: this.checkCTCT });
    }
  }

  SubmitUserInfo() {

    this.markFormTouched(this.myForm);
    if (this.myForm.valid) {
      this.ShowLoader = true;
      let groupCd = "";
      if (this.isAdmin)
        groupCd = this.myForm.controls["CompanyGrp_Cd"].value;
      else
        groupCd = this.data.UserData.Group_Cd;
      if (this.myForm.controls["CheckInactive"].value == true
        || this.myForm.controls["CheckInactive"].value == "Y") {
        this.isInactive = true;
      } else {
        this.isInactive = false;
      }
      if (this.myForm.controls["CheckReset"].value == true
        || this.myForm.controls["CheckReset"].value == "Y") {
        this.isReset = true;
      } else {
        this.isReset = false;
      }

      if (this.myForm.controls["CheckCTCT"].value == true
        || this.myForm.controls["CheckCTCT"].value == "Y") {
        this.checkCTCT = true;
      } else {
        this.checkCTCT = false;
      }


      const AddorEditRequest: IAddUserRequest = {
        loginUserId: this.LoginUserId,
        loginUserNm: this.data.UserData.loginUserNm,
        UserId: this.data.UserData.UserId,
        FeeTypeCd: this.data.UserData.FeeTypeCd,
        CompanyId: this.data.UserData.CompanyId,
        LogIn_Id: this.myForm.controls["LoginId"].value,
        FirstName: this.myForm.controls["FirstName"].value,
        LastName: this.myForm.controls["LastName"].value,
        EmailAddress: this.myForm.controls["Email"].value,
        Phone: this.myForm.controls["Phone"].value,
        Group_Cd: groupCd,
        IsAdmin: this.data.UserData.IsAdmin,
        Resetassword: this.isReset,
        MakeInactive: this.isInactive,
        CTCT: this.checkCTCT,
        ResetPwdOnNextLogin_Flg: this.data.UserData.ResetPwdOnNextLogin_Flg
      }
      this.dataService.AddorEditUser(AddorEditRequest)
        .subscribe((data: any) => {
          this.ShowLoader = false;
          if (data.hasOwnProperty('RG_Err')) {
            this.Errvisible = true;
            this.errorMessage = data.RG_Err[0].Err_Txt;

          } else {
     
            if (this.UserId == 0) {
              this.dialog.close({ event: 'close', data: "Add" });

            }
            else{
              if(this.UserId==this.LoginUserId)
              {
                if (data.hasOwnProperty('RG_User')){
                  const userInfo: UserInfoType = data.RG_User[0];
                  this.UserInfo_details=userInfo;
                  localStorage.setItem(PpGlobalConstants.AdditionalUserInfo, JSON.stringify(userInfo));
                }
              }
              this.dialog.close({ event: 'close', data: "UPDATE" });
             
            }
             
          }
        });
      
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.myForm.controls; }
  get isEmailMismatch() { return this.myForm.getError('emailMismatch') }
  // convenience getter for easy access to form fields
  get g() { return this.myPwdForm.controls; }
  get isPasswordMismatch() { return this.myPwdForm.getError('passwordMismatch') }
  onClose() {
    this.dialog.close();
    // window.location.reload();

  }


  submitChangePassword() {
    this.markFormTouched(this.myPwdForm);
    if (this.myPwdForm.valid) {
      this.ShowLoader = true;
      const changePWDRequest: IChangePasswordRequest = {
        UserId: this.UserInfo_details.User_Id,
        FeeTypeCd: this.FeeType_Cd,
        CompanyId: this.UserInfo_details.Company_Id,
        LogIn_Id: this.UserInfo_details.Login_Nm,
        ProfileType: "Self",
        Password: this.myPwdForm.controls["Password"].value,
        SecurtyQuestionRequired: false,
        SecQuestion_Id: "",
        SecQuestionAnswer: "",
      };
      this.dataService.SubmitChangePassword(changePWDRequest)
        .subscribe((data: any) => {
          if (data.hasOwnProperty('RG_Err')) {

            this.PwdErrvisible = true;
            this.PwdErrorMessage = data.RG_Err[0].Err_Txt;

          } else {
            this.myPwdForm.controls["Password"].setValue("");
            this.myPwdForm.controls["CNFPassword"].setValue("");
            this.hideEditUser = false;
          }
          this.ShowLoader = false;
        });



    }




  }

  //Validation Method
  markFormTouched(group: any) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);

      } else {
        control.markAsTouched();

      }
    });
  }

  hideEditScreen() {
    this.hideEditUser = true;
    this.PwdErrvisible = false;
    this.PwdErrorMessage = "";
  }

  // Only Integer Numbers
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}

export interface IChangePasswordRequest {
  UserId: number,
  FeeTypeCd: string,
  CompanyId: number,
  LogIn_Id: string,
  ProfileType: string,
  Password: string,
  SecurtyQuestionRequired: boolean,
  SecQuestion_Id: string,
  SecQuestionAnswer: string,
}


export interface IAddUserRequest {
  loginUserId: number,
  loginUserNm: string,
  UserId: number,
  FeeTypeCd: string,
  CompanyId: number
  LogIn_Id: string,
  FirstName: string,
  LastName: string,
  EmailAddress: string
  Phone: string
  Group_Cd: string
  IsAdmin: boolean,
  Resetassword: boolean,
  MakeInactive: boolean
  CTCT: boolean,
  ResetPwdOnNextLogin_Flg: string
}
