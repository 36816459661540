<div class="parent-container">
  <mat-sidenav-container fullscreen>
    <mat-sidenav
      #snav
      [mode]="over"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="0"
      position="start"
    >
      <mat-nav-list>
        <mat-tree [dataSource]="binding" [treeControl]="treeControl" #tree>
          <mat-tree-node
            *matTreeNodeDef="let node"
            class="nav-list-item matdivider"
            [ngStyle]="{ display: node.showInMenuFlg ? 'block' : 'none' }"
          >
            <a
              mat-list-item
              *ngIf="!node.is_href"
              routerLink="{{ node.url }}"
              [routerLinkActive]="['link-active']"
              (click)="changeView(snav); adminTitlename = ''"
              class="nav-list-item"
            >
              {{ node.name }}
            </a>
            <a
              mat-list-item
              *ngIf="
                node.is_href &&
                node.url !=
                  'http://www.scspa.com/cargo/operational-tools/vessel-report/ '
              "
              href="{{ node.url }}"
              (click)="changeView(snav); adminTitlename = ''"
              class="nav-list-item"
            >
              {{ node.name }}
            </a>
            <a
              mat-list-item
              *ngIf="
                node.url ==
                'http://www.scspa.com/cargo/operational-tools/vessel-report/ '
              "
              href="{{ node.url }}"
              (click)="changeView(snav); adminTitlename = ''"
              target="_blank"
              class="nav-list-item"
            >
              {{ node.name }}
            </a>
          </mat-tree-node>
          <mat-tree-node
            *matTreeNodeDef="let node; when: hasChild"
            matTreeNodeToggle
            class="menu-l1-title matdivider"
          >
            <span style="min-width: 75%">
              {{ node.name }}
            </span>
            <mat-icon class="pointer">{{
              treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
            }}</mat-icon>
          </mat-tree-node>
        </mat-tree>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <mat-toolbar>
        <!-- <button mat-button class="btn-menu" (click)="LeftSideNavClicked(snav)">
          <mat-icon>menu</mat-icon>
        </button> -->
         <!-- ----- Pierpass and portcheck header logo -->
         <div style="display: flex; flex-direction: row; align-content: space-between; width: 62vw;">
          <!-- <div class="logo"></div> -->
          <img src="../../assets/images/pierpass-logo-v2.png" (click)="openlink(PierPASSHomePage)" style="align-self: center; height: 4vh;cursor:pointer;margin-left: 2.5%;">
          &nbsp;<img *ngIf="DisplayPortCheck" src="../../assets/images/portcheck_header.jpg" (click)="openlink(PortcheckHomePage)" style="align-self: center; height: 4vh;cursor:pointer;">
          <!-- <span class="example-spacer"></span> -->
          </div>
        <!-- <span *ngIf="!mobileQuery.matches" class="app-title">{{ locale.data.title }}</span> -->

        <span class="example-spacer"></span>
        <span
          *ngIf="
            !mobileQuery.matches &&
            GlobalSearchOptions.length > 0 &&
            showglobalsearch
          "
          class="global-search-bar"
        >
          <span style="text-align: left">
            <input
              type="text"
              placeholder="{{ locale.data.globalsearchplaceholder }}"
              [matAutocomplete]="auto"
              aria-label="Number"
              [(ngModel)]="SearchValue"
              matInput
              [formControl]="myControl"
              class="search-bar-input"
              style="
                font-size: 17px;
                color: #333333;
                border: none;
                vertical-align: middle;
              "
              (keyup.enter)="ComponentView(SearchValue)"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option.name + ':'"
              >
                <span>{{ option.name }}</span>
              </mat-option>
            </mat-autocomplete>
          </span>
          <span>
            <span class="search-bar-selected">
              <button
                mat-icon-button
                [ngStyle]="{ visibility: !showclose ? 'hidden' : 'visible' }"
                (click)="ClearHeaderSearch(SearchValue)"
              >
                <mat-icon>close</mat-icon>
              </button>
            </span>
          </span>

          <button
            mat-raised-button
            type="submit"
            class="btn-search global-search-btn"
            (click)="ComponentView(SearchValue)"
          >
            <mat-icon>search</mat-icon>
          </button>
        </span>

        <span
          fxLayout="row"
          style="align-items: center"
          *ngIf="!mobileQuery.matches"
        >
          <!-- <mat-menu #menu1="matMenu" xPosition="before" id="menu_lang">
                <button mat-menu-item [matMenuTriggerFor]="languagues">Languages</button>            
              </mat-menu>
      
              <mat-menu #languagues="matMenu" xPosition="before">
                <ng-container *ngFor="let language of languages">
                  <button mat-menu-item (click)="SaveLocale(language);$event.stopPropagation();setLang(language.key)">
                    <span class="span-menu-item">{{language.name}}</span>
                    <mat-icon class="maticon" *ngIf="language.key == defaultLocale ? true : false">check</mat-icon>
                  </button>
                </ng-container>
              </mat-menu>  -->
          <!-- working here ******** -->
          <mat-divider
            [vertical]="true"
            class="mat-divider-vertical"
          ></mat-divider>
          <!-- <mat-divider
              [vertical]="true"
              class="mat-divider-vertical"
            ></mat-divider> -->
          <button
            mat-icon-button
            matTooltip="{{ locale.data.help }}"
            class="header-right-menu-button"
            style="margin: auto"
            [routerLink]=""
            (click)="openTab()"
          >
            <mat-icon aria-label="help"> help</mat-icon>
          </button>
        </span>

        <span *ngIf="mobileQuery.matches">
          <button
            mat-icon-button
            class="header-right-menu-button"
            (click)="expandMobileSearch()"
          >
            <mat-icon aria-label="person">search</mat-icon>
          </button>
          <button
            mat-icon-button
            class="header-right-menu-button"
            (click)="expandMobileOptions()"
          >
            <mat-icon aria-label="person"> apps </mat-icon>
          </button>
          <span
            fxLayout="row"
            fxLayoutAlign="end center"
            *ngIf="showMobileOptions"
            class="item-icon"
            [@mobileoptionanimation]="showMobileOptions ? 'open' : 'close'"
          >
            <mat-divider
              [vertical]="true"
              class="mat-divider-vertical"
            ></mat-divider>
            <button mat-icon-button class="header-right-menu-button">
              <mat-icon aria-label="notifications">notifications</mat-icon>
            </button>
            <mat-divider
              [vertical]="true"
              class="mat-divider-vertical"
            ></mat-divider>
            <button mat-icon-button class="header-right-menu-button">
              <mat-icon aria-label="settings">settings</mat-icon>
            </button>
            <mat-divider
              [vertical]="true"
              class="mat-divider-vertical"
            ></mat-divider>
            <button mat-icon-button class="header-right-menu-button">
              <mat-icon aria-label="help">help</mat-icon>
            </button>
            <mat-divider
              [vertical]="true"
              class="mat-divider-vertical"
            ></mat-divider>
            <button mat-icon-button class="header-right-menu-button">
              <mat-icon aria-label="person">person</mat-icon>
            </button>
          </span>
        </span>

        <span class="icons-right"> </span>
      </mat-toolbar>
      <mat-toolbar>
        <div class="secondtoolbar" style="padding-left: 20px">
          <!-- <span style="color:lightgray">{{PModTitle}}</span> &nbsp; > &nbsp; <span>{{CModTitle}}</span> -->
          <span style="color: #757575">{{ ParentModuleName }}</span> &nbsp; >
          &nbsp; <span>{{ ChildModuleName }}</span>
        </div>
      </mat-toolbar>
      <main class="main1">
        <!-- <div class="pageheading"
          style="
            display: block;
            margin-top: 10px;
            margin-bottom: 10px;
            color: #1b1e21;
            font-weight: 550;
            align-self: flex-start;
          "
        >
          Acco
        </div> -->
        <app-loading-backdrop *ngIf="ShowLoader"></app-loading-backdrop>
        <div
          class="pageheading"
          style="
            color: red;
            font-size: small;
            display: flex;
            justify-content: flex-start;
            align-self: flex-start;
          "
        ></div>
        <div class="pppagecontainer">
          <div class="as_subcontainer_secondform" style="overflow-y: scroll">
            <div
              class="pageheading"
              style="
                display: block;
                margin-top: 10px;
                margin-bottom: 10px;
                color: #1b1e21;
                font-weight: 550;
                align-self: flex-start;
              "
            >
            {{locale.data.hp_pwd_reset_req_title}}
            </div>
            <br />
            <p *ngIf="IsError" class="error">
              {{ ErrorMessage }}
            </p>
            <p *ngIf="ShowSuccesMessage" style="color: green">
              {{ SuccessMessage }}
            </p>
            <br />
            <mat-horizontal-stepper linear #stepper>
              <ng-template matStepperIcon="edit">
                <mat-icon>done</mat-icon>
              </ng-template>
              <div style="overflow-y: scroll">
                <mat-step [stepControl]="firstFormGroup">
                  <form [formGroup]="firstFormGroup">
                    <ng-template matStepLabel> {{locale.data.user_login_id}} </ng-template>
                    <div
                      id="firststep" class="firststepparentdiv"
                    >
                      <div>
                        <table style="margin-left: 2vw; margin-right: 10vw">
                          <tr>
                            <td align="right" style="padding-right: 5px; padding-bottom: 10px;">
                              {{locale.data.user_login_id}}:
                            </td>
                            <td align="left" >
                              <mat-form-field  appearance="none">
                                <input type="text" matInput  cols="20" class="textbox" formControlName="LoginID" >
                              </mat-form-field> 
                              <!-- <input
                                type="text"
                                matInput
                                placeholder="Login ID"
                                class="textbox"
                                formControlName="LoginID"
                              /> -->
                              <mat-error
                                *ngIf="
                                  firstFormGroup.controls['LoginID'].errors &&
                                  (firstFormGroup.controls['LoginID'].dirty ||
                                    firstFormGroup.controls['LoginID'].touched)
                                "
                                style="font-size: 12px"
                              >
                                *{{locale.data.val_login_req}}</mat-error
                              >
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div class="emptydiv"></div>
                      <div class="bottom-end-buttton">
                        <div style="display: flex; justify-content: flex-end;">
                          <button
                          style="margin-right: 30px"
                          mat-raised-button
                          matStepperNext
                          (click)="Next(stepper)"
                        >
                        {{locale.data.Next}} 
                        </button>
                        </div>
                         
                      </div>
                      <!-- <div class="rightbottomdiv"></div> -->
                    </div>
                  </form>
                </mat-step>
                <!-- *************** WORKING here ********************* -->
                <!-- Second Tab -->
                <mat-step [stepControl]="secondFormGroup">
                  <form [formGroup]="secondFormGroup">
                    <ng-template matStepLabel>{{locale.data.hp_sec_quetions}}</ng-template>
                    <div>
                      <table style="margin-left: 2vw; margin-right: 10vw">
                        <tr>
                          <td align="right" class="account_label1">
                            {{locale.data.hp_secret_q}}
                          </td>
                          <td align="left" class="account_data1">
                            <mat-label>{{ ActualSecQuestion }}</mat-label>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" class="account_label1" style="padding-bottom: 10px;">
                            {{locale.data.hp_secret_ans}}
                          </td>
                          <td align="left" class="account_data1">
                            <input
                              type="text"
                              matInput
                              class="textbox"
                              formControlName="SecretAns"
                            />
                            <mat-error
                              *ngIf="
                                secondFormGroup.controls['SecretAns'].errors &&
                                (secondFormGroup.controls['SecretAns'].dirty ||
                                  secondFormGroup.controls['SecretAns'].touched)
                              "
                              style="font-size: 12px"
                            >
                              *{{locale.data.hp_secret_ans_val}}</mat-error
                            >
                          </td>
                        </tr>
                      </table>
                      <br />
                      <div>
                        <span class="small-heading-dark">
                          {{locale.data.hp_reset_note}}
                        </span>
                      </div>
                      <br>
                    </div>
                    <div class="emptydiv2"></div>
                    <div class="bottom-end">
                      <div
                        class="row"
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: flex-end;
                        "
                      >
                        <span class="fill-remaining-space"></span>
                        <button
                          mat-raised-button
                          matStepperPrevious
                          (click)="Previous()"
                          style="
                            color: #757575;
                            background-color: white;
                            margin-right: 10px;
                          "
                        >
                        {{locale.data.Previous}}
                        </button>
                        <!-- <button
                          mat-raised-button
                          style="
                            color: #757575;
                            background-color: white;
                            margin-right: 10px;
                          "
                          (click)="Cancel()"
                        >
                          CANCEL
                        </button> -->
                        <button
                          style="margin-right: 30px"
                          mat-raised-button
                          matStepperNext
                          (click)="Finish()"
                        >
                        {{locale.data.finish1}}
                        </button>
                      </div>
                    </div>
                    <div style="display: flex; flex-grow: 0; height: 1vh"></div>
                  </form>
                </mat-step>
              </div>
            </mat-horizontal-stepper>
          </div>
        </div>

        <div class="lfooter1">
          <span> &nbsp;&nbsp; {{locale.data.Copyright}} <mat-icon inline="true">copyright</mat-icon>{{locale.data.pp_rights_reserved}}
            &nbsp;&nbsp; | &nbsp;&nbsp; <a title="PierPASS Privacy Policy" target="_blank" href="https://www.wcmtoa.org/wp-content/uploads/2022/05/2022-05-23-PierPASS-Privacy-Policy.pdf">Privacy Policy</a>
            &nbsp;&nbsp; | &nbsp;&nbsp; <a title="PierPASS Terms &amp; Conditions" target="_blank" href="https://www.wcmtoa.org/wp-content/uploads/2022/05/2022-05-20-PierPASS-Terms-Conditions.pdf">Terms &amp; Conditions</a>
            &nbsp;&nbsp; 
          </span>
        </div>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
