import { WebapidataService } from './../common/services/webapidata.service';
import { Component, Input, OnInit } from '@angular/core';
import { WebContentType } from '../common/typesinfo/typesinfo';

@Component({
  selector: 'app-news-info',
  templateUrl: './news-info.component.html',
  styleUrls: ['./news-info.component.scss']
})
export class NewsInfoComponent implements OnInit {
  // newsInfo : WebContentType[];

  @Input() webcontent: WebContentType[];

  constructor() { 
    this.webcontent= [];
  }

  ngOnInit(): void {

  }

}
