import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../common/services/translate.service';

@Component({
  selector: 'app-login-ctf-tc-content',
  templateUrl: './login-ctf-tc-content.component.html',
  styleUrls: ['./login-ctf-tc-content.component.scss']
})
export class LoginCtfTcContentComponent implements OnInit {

  constructor(
    public locale: TranslateService
  ) { }

  ngOnInit() {
  }

}
