import { StringifyOptions } from 'querystring';
import { NumericConfig } from '@rxweb/reactive-form-validators/models/config';
import { environment } from 'src/environments/environment';
import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';
import { AnyAaaaRecord, AnyARecord } from 'dns';

export class CSRCHConstants {
  
  public static S1ContainerSearch: string = 'S1ContainerSearch';
  public static S2ContainersListing: string = 'S2ContainersListing';   
  public static S3ContainerTripDetails: string = 'S3ContainerTripDetails';
}

export function stringlog(message : string){
  
  if(environment.name == "localhost" && message != undefined && message != null)
  {
    console.log(message)
  }

}

export function qauatlog(message : string){
  
  if((environment.name == "localhost" || environment.name == "QA" || environment.name == "UAT" ) && message != undefined && message != null)
  {
    console.log(message)
  }

}

export function objectlog(message : object){
  
  if(environment.name == "localhost" && message != undefined && message != null)
  {
    console.log(JSON.stringify(message));
  }

}

export function alltypelog(message : any){
  
  if(environment.name == "localhost" && message != undefined && message != null)
  {
    console.log(JSON.stringify(message));
  }

}




export interface TNConInventory {
  ConInventory_Id: number;
  InventoryStatusChanged_DtTm: Date;
  ConPrefix_Cd: string;
  Con_Num: string;
  ConChkDigit: string;
  MTO_Cd: string;
  ConSz_Cd: string;
  ConStatus_Dsc: string;
  InventoryStatus_Cd: string;
  InventoryStatus_Dsc: string;
  Carrier: string;
  CargoRef_Num: string;
  ConSvcEvent_Cd: string;
  ConPosType_Cd: string;
  ConPos_Id: number;
  SvcEvent_DtTm?: Date;
  FullConNumber: string;
  FullConNumberPlusPDFExcel: string;
}

export interface TNConFeeDetail {
  ConInventory_Id: number;
  Company_Id?: number;
  FeeType_Cd: string;
  FeeAmt?: number;
  Hold_Flg: string;
  Exempt_Dsc: string;
  Company_Nm: string;
  VTXAccount_Id: string;
  User: string;
  MoveTypeStatus_Cd: string;
  ConPosType_Cd: string;
  ConPos_Id: number;
  FeeStatus_Dsc: string;
  Resend315: string;
}

export interface RootObject {
  TN_ConInventory: TNConInventory[];
  TN_ConFeeDetails: TNConFeeDetail[];
  RG_BCO: any[];
}

export interface CSRContainerSearch {
  FeeTypeCode: string;
  LoginName: string;
  UserId: number;
  ContainerNums: string;
  CompanyId: number;
}

export interface CSRTripDetailsSearch {
  FeeTypeCode: string;
  LoginName: string;
  UserId: number;
  ConPosId: string;
  CompanyId: number;
}

export interface MoveSearchResultDisplay {
  FullConNumberPlus: string;
  Count: number;
  Details: TNConInventory[];
  
}
export interface MoveSearchResultDisplayPDF {
  
  FullConNumberPlusPDFExcel: string; 
  Count: number;
  Details1: TNConInventory[];
}

export interface TNConInventory_Plus {
  ConInventory_Id: number;
  InventoryStatusChanged_DtTm: Date;
  ConPrefix_Cd: string;
  Con_Num: string;
  ConChkDigit: string;
  MTO_Cd: string;
  ConSz_Cd: string;
  ConStatus_Dsc: string;
  InventoryStatus_Cd: string;
  InventoryStatus_Dsc: string;
  Carrier: string;
  CargoRef_Num: string;
  ConSvcEvent_Cd: string;
  ConPosType_Cd: string;
  ConPos_Id: number;
  SvcEvent_DtTm?: Date;
  FullConNumber: string;
  FullConNumberPlus: string;
  FullConNumberPlusPDFExcel: string;
}

export interface TNConInventory_csrch {
  ConInventory_Id: number;
  InventoryStatusChanged_DtTm: Date;
  ConPrefix_Cd: string;
  Con_Num: string;
  ConChkDigit: string;
  MTO_Cd: string;
  ConSz_Cd: string;
  ConStatus_Dsc: string;
  InventoryStatus_Cd: string;
  InventoryStatus_Dsc: string;
  Carrier: string;
  CargoRef_Num: string;
  ConSvcEvent_Cd: string;
  ConPosType_Cd: string;
  ConPos_Id: number;
  SvcEvent_DtTm?: Date;
  FullConNumber: string;
  FullConNumberPlus: string;
  FullConNumberPlusPDFExcel: string;
}



export module MoveDetails {

  export interface TNConInventory {
    ConInventory_Id: number;
    InventoryStatusChanged_DtTm: Date;
    ConPrefix_Cd: string;
    Con_Num: string;
    ConChkDigit: string;
    MTO_Cd?: any;
    MTO_Id?: any;
    ConSz_Cd?: any;
    ConStatus_Dsc: string;
    InventoryStatus_Cd: string;
    InventoryStatus_Dsc: string;
    CargoShip_Dsc?: any;
    CargoVoy_Num?: any;
    CargoRef_Num?: any;
    ConSvcEvent_Cd?: any;
    ConPosType_Cd: string;
    ConPos_Id: number;
    SvcEvent_DtTm?: any;
    ShippingLine_Cd?: any;
    POL_Cd?: any;
    POD_Cd?: any;
    FullEmpty_Cd: string;
    LicensePlate_Nbr?: any;
    LicensePlateState_Cd?: any;
    RFID_Cd?: any;
    TruckDetailsHistory_Id?: any;
    TruckMatchType_Dsc?: any;
    MoveEvent_Id?: any;
    Ver_Nbr: string;
    StatusReason_Cd?: any;
  }

  export interface TNConFeeDetail {
    ConInventory_Id: number;
    Company_Id?: number;
    FeeType_Cd: string;
    FeeAmt?: any;
    FeePaid? : any;
    FeeDue? : any;
    Release_Flg: string;
    PortRelease_Flg: string;
    Exempt_Dsc: string;
    Company_Nm: string;
    VTXAccount_Id?: number;
    VTXCreditStatus_Cd: string;
    VTXAcctStatus_Cd: string;
    First_Nm: string;
    Last_Nm: string;
    Email_Addr: string;
    MoveTypeStatus_Cd?: any;
    FeeStatus_Dsc: string;
    ConPosType_Cd: string;
    ConPos_Id: number;
    CTPBilling_Cd?: any;
    SvcEvent_DtTm: Date;
    FeeBillingDispute_Id?: any;
    Create_DtTm?: any;
    CreatedByUser_Id?: any;
    CreatedByUser?: any;
    Dispute_Txt?: any;
    Resolution_Cd?: any;
    Resolution_Txt?: any;
    Resolved_DtTm?: Date;
    ResolvedByUser_Id?: any;
    ResolvedByUser?: any;
    Resolution_Dsc?: any;
  }

  export interface TNConSvcEventHistory {
    ConInventory_Id: number;
    ConSvcEventHistory_Id: number;
    SvcEvent_DtTm: Date;
    Create_DtTm: Date;
    ConSvcEvent_Cd: string;
    ConSvcEvent_Dsc: string;
    FeeType_Cd: string;
    Hold_Flg: string;
    Company_Nm: string;
    VTXAccount_Id: string;
    User: string;
    MoveTypeStatus_Cd?: any;
  }

  export interface ChangeStatus_ReferenceTables_Request {
    
    CompanyId : number;
    UserId : number;
    LoginName : string;
    FeeTypeCode : string;

    ConPrefix_Cd: string;
    Con_Num: string;
    ConChkDigit: string;
    ConInventory_Id: number;

    ConPosId: number;

    InventoryStatus_Cd: string;
    InventoryStatus_Dsc: string;
    ConStatus : string;
    StatusReason_Cd : string;
    // InventoryStatus_Cd
    // ConStatus_Cd


  }
  
  
  export interface SubmitExemption_Request {
    
    CompanyId : number;
    UserId : number;
    LoginName : string;
    FeeTypeCode : string;

    ConPrefix_Cd: string;
    Con_Num: string;
    ConChkDigit: string;
    ConInventory_Id: number;

    Exempt_Cd: string;
    ConStatus : string;
    FeeType_Cd : string;
    
  }

  export interface SubmitClaim_Request {
    
    CompanyId : number;
    UserId : number;
    LoginName : string;
    FeeTypeCode : string;

    ConPrefix_Cd: string;
    Con_Num: string;
    ConChkDigit: string;
    ConInventory_Id: number;

    VTXAccount_Id: number;
    ConStatus : string;
    sCreditStatus : string;
    Company_Id : number;
    Ref_Num : string;
    FeeType_Cd : string;
    
  }

  export interface SubmitChangeStatus_Request {
    
    CompanyId : number;
    UserId : number;
    LoginName : string;
    FeeTypeCode : string;

    ConPrefix_Cd: string;
    Con_Num: string;
    ConChkDigit: string;
    ConInventory_Id: number;

    ConPosId: number;

    InventoryStatus_Cd: string;
    // InventoryStatus_Dsc: string;
    ConStatus_Cd : string;
    StatusReason_Cd : string;
    // InventoryStatus_Cd
    // ConStatus_Cd


  }

  export interface FeeExemptTypeList {
    FeeType_Cd: string;
    Exempt_Cd: string;
    Exempt_Dsc: string;
}

export interface FilteredFeeTypeList {
    FeeType_Cd: string;
    FeeType_Dsc: string;
}

export interface RGCompany {
  Company_Id: number;
  Company_Nm: string;
  Company_Addr: string;
  City: string;
  State: string;
  Contact_Person: string;
  Contact_Email: string;
  CompanyType_Cd: string;
}

export interface RGBCOAccount {
  Company_Id: number;
  FeeType_Cd: string;
  VTXAccount_Id: number;
  VTXAcctStatus_Cd: string;
  VTXCreditStatus_Cd: string;
  Acct_Status: string;
  Credit_Status: string;
}

  export interface MoveDetails_Result {
    TN_ConInventory: TNConInventory[];
    TN_ConFeeDetails: TNConFeeDetail[];
    TN_ConSvcEventHistory: TNConSvcEventHistory[];
    TN_ConInventoryCSRComments: any[];
    InventoryStatusRevision : TNInventoryStatusRevision[];
  }

  export interface TNInventoryStatusRevision {
    FromInventoryStatus_Cd: string;
        ToInventoryStatus_Cd: string;
        ConStatus_Cd: string;
  }


  export interface TNConInventoryCSRComment {
    CommentText: string;
    Comment_Dt: Date;
    User: string;
  }

  export interface Correction_Result {
    TN_ConInventory: TNConInventory[];
    TN_ConFeeDetails: TNConFeeDetail[];
    TN_ConSvcEventHistory: TNConSvcEventHistory[];
    TN_ConInventoryCSRComments: TNConInventoryCSRComment[];
    RG_DisputeResolution : RGDisputeResolution[];
    Table: ContainerSize[];
    Table1: States[];
    Table2: CTPBilling[];
  }

  export interface RGDisputeResolution {
    Resolution_Cd: string;
    Resolution_Dsc: string;
  
  }

  export interface CorrectionUpdate_Request {
    FeeTypeCode: string;
    LoginName: string;
    UserId: number;
    ConPosId: number;
    CompanyId: number;

    ConInventory_Id: number;
    ConPrefix_Cd: string;
    Con_Num: string;
    ConChkDigit: string;

    SvcEvent_Date: any;
    SvcEvent_Time: any;

    ConSz_Cd?: any;

    RFID_Cd?: any;
    LicensePlate_Nbr?: any;
    LicensePlateState_Cd?: any;

    CTPBilling_Cd?: any;
    MoveEvent_Id?: any;
    MTO_Id?: number;
    CommentText: string;
    Ver_Nbr: string;

    DisputeChecked : boolean;
    Dispute_DtTm: any;
    Dispute_Txt?: any;
    Resolution_Txt?: any;
    Resolved_DtTm?: any;
    Resolution_Cd : string;
  }

  export interface StatusList {
    StatusReason_Cd: string;
    StatusReason_Dsc: string;
    Active_Flg: string;
    AllowCSR_Flg: string;
}

export interface StatusRevision {
    FromInventoryStatus_Cd: string;
    ToInventoryStatus_Cd: string;
    ConStatus_Cd: string;
    InventoryStatus_Dsc: string;
}


  export interface ContainerSize {
    ConSz_Cd: string;
    ConSz_Dsc: string;
    TEUFactor: number;
  }

  export interface States {
    State_Cd: string;
    Country_Cd: string;
    State_Nm: string;
  }

  export interface CTPBilling {
    CTPBilling_Cd: string;
    Location_Cd: string;
    LACPTFunding_Flg: string;
    LBCPTFunding_Flg: string;
    CompliantDiesel_Flg: string;
    AltFuel_Flg: string;
    BillingDefault_Flg: string;
    CTPBilling_Dsc: string;
  }

  export interface RGTruckDetailsHistory  {
    RFID_Cd?: any;
        PlateNbrState: string;
        CTPBillingCodeLA: string;
        CTPBillingCodeLB: string;
        LastUpdate: Date;
        SysUpdate_DtTm: Date;
        Scac_Cd: string;
        Company_Nm: string;
        TruckDetailsHistory_Id: number;
  }


}
