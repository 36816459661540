<div class="parent-container">
    <mat-sidenav-container fullscreen>
      <mat-sidenav #snav [mode]="over" [fixedInViewport]="mobileQuery.matches" fixedTopGap="0" position="start" >
        <mat-nav-list>
          <mat-tree [dataSource]="binding" [treeControl]="treeControl" #tree>
            <mat-tree-node *matTreeNodeDef="let node" class="nav-list-item matdivider"
              [ngStyle]="{display: (node.showInMenuFlg ? 'block' : 'none')}">
              <a mat-list-item *ngIf="!node.is_href" routerLink="{{ node.url }}" [routerLinkActive]="['link-active']"
                (click)="changeView(snav);adminTitlename ='';" class="nav-list-item">
                {{ node.name  }}
              </a>
              <a mat-list-item *ngIf="node.is_href && node.url != 'http://www.scspa.com/cargo/operational-tools/vessel-report/ '" href="{{node.url}}"
                (click)="changeView(snav);adminTitlename ='';" class="nav-list-item">
                {{ node.name  }}
              </a>
              <a mat-list-item *ngIf="node.url == 'http://www.scspa.com/cargo/operational-tools/vessel-report/ '" href="{{node.url}}" (click)="changeView(snav);adminTitlename ='';"
                target="_blank" class="nav-list-item">
                {{ node.name  }}
              </a>
            </mat-tree-node>          
            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodeToggle class='menu-l1-title matdivider'>
              <span style="min-width: 75%">
                {{node.name  }}
              </span>           
              <mat-icon class="pointer">{{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}</mat-icon>
            </mat-tree-node>
          </mat-tree>
    
        </mat-nav-list>
      </mat-sidenav>
    
      <mat-sidenav-content>
        <mat-toolbar>
          <button  style="display: none;"  mat-button class="btn-menu" (click)="LeftSideNavClicked(snav);">
            <mat-icon>menu</mat-icon>
          </button>
          <!-- ----- Pierpass and portcheck header logo -->
        <div style="display: flex; flex-direction: row; align-content: space-between; width: 62vw;">
          <div class="logo"></div>
          <!-- <span class="example-spacer"></span> -->
          <img *ngIf="DisplayPortCheck" src="../../assets/images/portcheck_header.jpg" style="height: 43px; padding-top: 3px;">
        </div>
          <!-- <span *ngIf="!mobileQuery.matches" class="app-title">{{ locale.data.title }}</span> -->
    
          <span class="example-spacer"></span>
            <span *ngIf="!mobileQuery.matches && GlobalSearchOptions.length>0 && showglobalsearch" class="global-search-bar">
              <span style="text-align: left;">
                <input type="text" placeholder="{{ locale.data.globalsearchplaceholder }}" [matAutocomplete]="auto"
                  aria-label="Number" [(ngModel)]="SearchValue" matInput [formControl]="myControl" class="search-bar-input"
                  style="font-size: 17px;color: #333333; border: none;    vertical-align: middle;" (keyup.enter)="ComponentView(SearchValue)" />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of (filteredOptions | async)" [value]="option.name + ':'" >
                    <span>{{ option.name }}</span>
                  </mat-option>
                </mat-autocomplete>
              </span>
              <span>
                <span class="search-bar-selected">
                  <button mat-icon-button [ngStyle]="{ visibility: !showclose ? 'hidden' : 'visible' }"
                    (click)="ClearHeaderSearch(SearchValue)">
                    <mat-icon>close</mat-icon>
                  </button>
                </span>
              </span>
    
              <button mat-raised-button type="submit" class="btn-search global-search-btn"   (click)="ComponentView(SearchValue)">
                <mat-icon>search</mat-icon>
              </button>
            </span>
    
    
    
          <span fxLayout="row" style="align-items: center" *ngIf="!mobileQuery.matches">
            <mat-divider [vertical]="true" class="mat-divider-vertical"></mat-divider>
            <span style="display:block; font-size: 16px; padding:1px; margin:1px">&nbsp;{{locale.data.welcome_msg}}</span> &nbsp;
              <span style=" display: block; color:  #0082BE; font-size: 16px; padding:1px; margin:1px"> {{ LoggedInUserFullName }} &nbsp;</span>
            <mat-divider [vertical]="true" class="mat-divider-vertical"></mat-divider>
            <button (click)="LogOut()"  class="btn btn-primary" style="background-color: transparent; border: none;"> <mat-icon style="background-color: white; color:black">exit_to_app</mat-icon></button>
            <mat-divider [vertical]="true" class="mat-divider-vertical"></mat-divider>
          </span>
    
          <span *ngIf="mobileQuery.matches">
            <button mat-icon-button class="header-right-menu-button" (click)="expandMobileSearch()">
              <mat-icon aria-label="person">search</mat-icon>
            </button>
            <button mat-icon-button class="header-right-menu-button" (click)="expandMobileOptions()">
              <mat-icon aria-label="person">
                apps
              </mat-icon>
            </button>
            <span fxLayout="row" fxLayoutAlign="end center" *ngIf="showMobileOptions" class="item-icon"
              [@mobileoptionanimation]="showMobileOptions ? 'open' : 'close'">
              <mat-divider [vertical]="true" class="mat-divider-vertical"></mat-divider>
              <button mat-icon-button class="header-right-menu-button">
                <mat-icon aria-label="notifications">notifications</mat-icon>
              </button>
              <mat-divider [vertical]="true" class="mat-divider-vertical"></mat-divider>
              <button mat-icon-button class="header-right-menu-button">
                <mat-icon aria-label="settings">settings</mat-icon>
              </button>
              <mat-divider [vertical]="true" class="mat-divider-vertical"></mat-divider>
              <button mat-icon-button class="header-right-menu-button">
                <mat-icon aria-label="help">help</mat-icon>
              </button>
              <mat-divider [vertical]="true" class="mat-divider-vertical"></mat-divider>
              <button mat-icon-button class="header-right-menu-button">
                <mat-icon aria-label="person">person</mat-icon>
              </button>
            </span>
          </span>
    
          <span class="icons-right"> </span>
        </mat-toolbar>
        <mat-toolbar>
          <div class="secondtoolbar" style="padding-left: 20px;">
            <!-- <span style="color:lightgray">{{PModTitle}}</span> &nbsp; > &nbsp; <span>{{CModTitle}}</span> -->
            <span style="color:#757575">{{ParentModuleName}}</span> &nbsp; > &nbsp;  <span>{{ChildModuleName}}</span>
          </div>
        </mat-toolbar>
        <main class="main1" *ngIf="loadOutlet">
          <!-- <mat-card class="page-header" *ngIf="adminTitlename != ''">
            <mat-card-content fxLayout="row wrap">
              <span class="headertitle">{{adminTitlename}}</span>
            </mat-card-content>
          </mat-card> -->
          <app-loading-backdrop *ngIf="ShowLoader"></app-loading-backdrop>
          <div class="content-pages">
            <router-outlet #o="outlet"></router-outlet>
            <div style="display: flex; flex-direction: column; padding-left: 10px;">
              <div style="display: flex; justify-content: flex-start">
                <p *ngIf="ShowError" class="error">
                  {{ ErrorMessage_PP }}
                </p>
              </div>
              <form [formGroup]="changepassFormGroup"> 
                <br>
                <div class="text-color">
                  {{locale.data.cp_note}}
              </div>
              <br>
              <div>
                  <table style="margin-left: 2vw; margin-right: 10vw">
                      <tr>
                        <td align="right" for="" class="account_label1">
                          {{locale.data.new_pwd}}
                        </td>
                        <td align="left" class="account_data1">
                          <!-- <input type="text" matInput  formControlName="CompanyName"> -->
                          <input
                            type="password"
                            matInput
                            formControlName="NewPass" style="border:#757575 solid 1px !Important;"
                          />
                         
                          <!-- <mat-error
                            *ngIf="
                            changepassFormGroup.controls['NewPass'].errors &&
                              (changepassFormGroup.controls['NewPass'].dirty ||
                              changepassFormGroup.controls['NewPass'].touched)"
                              style="font-size: 12px"
                          >
                            *{{locale.data.cp_pwd_req_val}}</mat-error
                          > -->
                        </td>
                        <mat-error *ngIf="changepassFormGroup.controls['NewPass'].errors?.required &&
                        (changepassFormGroup.controls['NewPass'].dirty ||
                        changepassFormGroup.controls['NewPass'].touched)" style="font-size: 12px;">  *{{locale.data.cp_pwd_req_val}}
                        </mat-error>
                        <mat-error *ngIf="changepassFormGroup.controls['NewPass'].errors?.pattern &&
                        (changepassFormGroup.controls['NewPass'].dirty ||
                        changepassFormGroup.controls['NewPass'].touched)" style="font-size: 12px;"> *{{locale.data.cp_pwd_valid_format}}
                        </mat-error>
                      </tr>
                      <tr>
                        <td align="right" class="account_label1">
                          {{locale.data.confirm_pwd}}  
                        </td>
                        <td align="left" class="account_data1">
                          <input
                          type="password"
                            matInput
                            class="textbox"
                            formControlName="ConfirmPass" style="border:#757575 solid 1px !Important;"
                          />
                         
                          <!-- <mat-error *ngIf="changepassFormGroup.controls['ConfirmPass'].errors?.pattern &&
                          (changepassFormGroup.controls['ConfirmPass'].dirty ||
                          changepassFormGroup.controls['ConfirmPass'].touched)" style="font-size: 12px;"> * ConfdoPassword should be in Valid
                          </mat-error> -->
                          <!-- <mat-error
                            *ngIf="
                            changepassFormGroup.controls['ConfirmPass'].errors &&
                              (changepassFormGroup.controls['ConfirmPass'].dirty ||
                              changepassFormGroup.controls['ConfirmPass'].touched)
                            "
                            style="font-size: 12px"
                          >
                            *{{locale.data.confirm_pwd_req}}</mat-error
                          > -->
                        </td>
                        <mat-error *ngIf="changepassFormGroup.controls['ConfirmPass'].errors?.required &&
                        (changepassFormGroup.controls['ConfirmPass'].dirty ||
                        changepassFormGroup.controls['ConfirmPass'].touched)" style="font-size: 12px;"> *{{locale.data.confirm_pwd_req}}
                        </mat-error>
                        <mat-error *ngIf="changepassFormGroup.controls['NewPass'].value != changepassFormGroup.controls['ConfirmPass'].value &&  !changepassFormGroup.controls['NewPass'].errors?.required && !changepassFormGroup.controls['ConfirmPass'].errors?.required" style="font-size: 12px;"> * {{ locale.data.cp_pwd_confirmpwd_match }}
                        </mat-error>
                      </tr>
                      <tr>
                        <td align="right" class="account_label1">
                          {{locale.data.hp_secret_q}}
                        </td>
                        <td align="left" class="account_data1">
                          <mat-select
                            formControlName="SecretQuestion"
                            name="State" style="border:#757575 solid 1px !Important;"
                          >
                            <mat-option
                              *ngFor="let ques of SecretQuestionList"
                              [value]="ques.SecQuestion_Id"
                            >
                              {{ ques.SecQuestion_Txt }}
                            </mat-option>
                          </mat-select>
                        </td>
                        <mat-error *ngIf=" changepassFormGroup.controls['SecretQuestion'].errors && (changepassFormGroup.controls['SecretQuestion'].dirty || changepassFormGroup.controls['SecretQuestion'].touched)" style="font-size: 12px" > *{{locale.data.cp_secret_q}}</mat-error >
                      </tr>
                      <tr>
                        <td align="right" class="account_label1">{{locale.data.hp_secret_ans}}  </td>
                        <td align="left" class="account_data1">
                          <input
                            type="text"
                            matInput
                            class="textbox"
                            formControlName="SecretAnswer" style="border:#757575 solid 1px !Important;"
                          />
                          
                        </td>
                        <mat-error
                            *ngIf="
                            changepassFormGroup.controls['SecretAnswer'].errors &&
                              (changepassFormGroup.controls['SecretAnswer'].dirty ||
                              changepassFormGroup.controls['SecretAnswer'].touched)
                            "
                            style="font-size: 12px"
                          >
                            *{{locale.data.hp_secret_ans_val}}</mat-error
                          >
                      </tr>
                    </table>
                </div>
                <br>
                <div style="display: flex; justify-content: flex-start ;margin-bottom: 5px; margin-left: 10vw; margin-right: 30vw;">
                  <button mat-raised-button type="submit" (click)="Submit()">{{locale.data.Submit}}</button>
                </div>
              </form>
              
            </div>
          </div>
  
        </main>
  
        <div class="lfooter1">
          <span>
           &nbsp;&nbsp; {{locale.data.Copyright}} <mat-icon inline="true">copyright</mat-icon> {{locale.data.pp_rights_reserved}}
          </span>
        </div>
      </mat-sidenav-content>  
    </mat-sidenav-container>
   
  </div>
  
  
  