import {version} from 'package.json'
export const environment = {
  production: true,
  PierPassBackEndServicesBaseURL : "http://192.168.89.15:1618",
  portcheckapibaseurl:"http://192.168.89.15:1618/UIManager",
  logoutURL : 'http://qapierpass.adventintermodal.com/pierpasshome',
  WeeblySiteURL : 'http://192.168.89.15:7979',
  PierPASSHomeURL : 'http://qapierpass.adventintermodal.com/pierpasshome',
  landingURL : 'pierpasshome',
  sitemode : '',

  name : "QA",
  interfaces:{
    mycontainer:'b5b7cb3c-f2dd-4543-8ea3-d058511cf826',
    mybooking:'d347c2ce-a155-46d0-b64f-1c71b1261789',
    groupcode:'e75334b6-0644-4512-aa99-f930c5b59f46',
    vesseschedule:'1f740763-d01c-4e5a-8017-ec9d633d7128',
    myjobs: "efaa2965-c710-407f-99ed-60c293478c0c"
  },
  portsconfigs: [
    {
      instance: "http://qapierpass.adventintermodal.com",
      // instance: "http://192.168.89.15:7575",
      portcheckinstance :  "http://192.168.89.15:7676",

      clientid: "PierPass",
      title:"PierPASS",
      iconpath:"../../assets/images/pierpassfavicon1.png",
      theme: "pierpass",
      analyticsUri: "https://www.googletagmanager.com/gtag/js?id=UA-90096803-27",
      analyticsTrackingId: "UA-90096803-27",
      GA4Property_Id : "G-C8NWE48N26",
      // GA4Property_Id : "G-DV61P422G4",
      showglobalsearch:false,
      hideDomesticTradeType: false,

      ImportClaimList_Interface_Uid: '932E90FE-9501-447B-A0F9-F29E0EA64147',
      ImportClaimList_InterfaceId: 2,

      ExportClaimList_InterfaceId:3,
      ExportClaimList_Interface_Uid:'12B77387-F69F-41B3-B575-8CCE1C524215',

      ExportClaimListContainer_InterfaceId:6,
      ExportClaimListContainer_Interface_Uid:'38E2F59D-F219-4799-AC7E-52BE066A1474',

      ImportFeesDue_InterfaceId:7,
      ImportFeesDue_Interface_Uid:'A8D476A8-2D98-4182-AD27-883936E616A1',

      ExportFeesDue_InterfaceId:8,
      ExportFeesDue_Interface_Uid:'63A6AF4D-A767-4934-9A29-F7A9B2B1B87D',

      ExportPendingList_InterfaceId:5,
      ExportPendingList_Interface_Uid:'B21D2B20-F756-4DD4-909A-2738B3CC07E4',

      ImportPendingList_InterfaceId:4,
      ImportPendingList_Interface_Uid:'FBACE2AE-AD14-4A9E-A690-253E0696D6F4',

      CSRBookingHistory_InterfaceId:9,
      CSRBookingHistory_Interface_Uid:'560A6516-20ED-420E-9A4E-C2BD4A6C43E0',

      domainname: "localhost",
      versionnumber:version,
      SYSTEMNAME:"PierPASS",
      ConstantContactEnabled:"Y",
      MinPasswordLength:6,
      MaxPasswordLength:12,
      PageRefreshDuration:60,
      
      pierpassHeaderConfigs: {     
        languagefile:"emodal",
        UserSelectionlanguage:"en",
      }
    
    },
  ],
  notificationbroadcasrsubject: "Notification Broadcast",
  isDTRautofillrequired: true,
  isDriverNameautofillrequired:true
};
