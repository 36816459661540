import { RGBCO } from './../common/typesinfo/typesinfo';
import { PpGlobalConstants } from 'src/app/ppcommon/pp-global-constants';
import { Component, OnInit } from '@angular/core';
import { stringlog } from '../csr-containerhistory/csr-containerhistory-type';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-tmfctfchild',
  templateUrl: './select-tmfctfchild.component.html',
  styleUrls: ['./select-tmfctfchild.component.scss']
})
export class SelectTmfctfchildComponent implements OnInit {

  ShowLoader = false;
  showselection = false;

  constructor(private router: Router,) { }

  ngOnInit() {
    
    this.ShowLoader = false;
    this.showselection = true;

  }

  setTMF()
  { 
    this.ShowLoader = true;
    this.showselection = false;
    stringlog("Set TMF");
    localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"TMF")
    this.router.navigate(["/accountsummary"]);
  }

  setCTF()
  {
    this.showselection = false;
    this.ShowLoader = true;
    stringlog("Set CTF");
    // localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"CTF")
      // this.router.navigate(["/accountsummary"]);

    const BCOlist : RGBCO[] = JSON.parse(localStorage.getItem(PpGlobalConstants.BCOInfoList));
    let TMFInfo: RGBCO;
    let CTFInfo : RGBCO;

    BCOlist.forEach( element =>
      {
        switch (element.FeeType_Cd)
        {
          case "TMF": { 
            TMFInfo = element;
            break; 
         } 
         case "CTF": { 
          CTFInfo = element;
            break; 
         } 
         default: { 
            //statements; 
            break; 
         } 
        }
      }
    )

    stringlog("CTFInfo info: " +  JSON.stringify(CTFInfo));

    if(CTFInfo.TermsAcceptanceUser_Id != null && CTFInfo.TermsAcceptance_DtTm != null)
    {
      localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"CTF")
      this.router.navigate(["/accountsummary"]);
    }
    else
    {
      // localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"CTF")
      this.router.navigate(['/']).then(() => { this.router.navigate(["/acceptctftermsconditions"]); })
      // this.router.navigate(["/acceptctftermsconditions"]);
    }

  }

}
