import { beautify } from 'xml-beautifier';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { CSRCHConstants, MoveSearchResultDisplayPDF, qauatlog } from './../../csr-containerhistory/csr-containerhistory-type';
import { PpUserRegistrationComponent } from './../../pp-user-registration/pp-user-registration.component';
import { InvoicehistoryMatDialogData } from './../../account-summary/get-account-status-and-history-details';
import { ExportSearchRequestType, SubmitClaimRequest } from "./../../export-search/export-search-types";
import { LoadAfterpaymentdataType } from "src/app/Account/add-funds/direct-payment/load-afterpaymentdata";
import { ImportFeesDueDetailsType } from "./../../Account/add-funds/direct-payment/import-fees-due-details";

import {
    AddRecipients,
    GetCompanyAccountDetailsType,
    DeleteCompanyinvoicedetailsType,
} from "./../../Account/company-profile/get-company-account-details";
import { environment } from "./../../../environments/environment";
import {
    SubmitecheckPayment,
    SubmitPayment,
    SubmitPaymentDetailsType,
} from "./../../Account/add-funds/submit-payment-details";
import { GetAccountStatusAndCreditCardInformationType } from "./../../Account/add-funds/get-account-status-and-credit-card-information";
import {
    WebContentRootObject,
    UserInfoType,
    WebContentType,
    BulletIns,
    LoginResponse,
    LoadedModuleInfo,
    SummaryDetails,
    Terminals,
    CSRAccountInContext,
    FeeTypeCode,
    RGBCO,
} from "./../typesinfo/typesinfo";
import { logging } from "protractor";
import { MatStepperModule } from "@angular/material/stepper";
import { Injectable } from "@angular/core";
import {
    HttpClient,
    HttpClientModule,
    HttpEvent,
    HttpHeaders,
} from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { BehaviorSubject, observable, Observable, of, throwError } from "rxjs";
import { GetUserInfoType } from "src/app/Account/get-user-info-type";
import { GetCompanyIDType } from "src/app/Account/get-company-id";
import { GetRefundReasonsType } from "src/app/Account/get-refund-reasons";
import { GetAccountStatusAndHistoryDetailsType } from "src/app/Account/get-account-status-and-history-details";
import { PostRefundAmoutDetailsType } from "src/app/Account/post-refund-amout-details";

import { debuglog, PpGlobalConstants } from "src/app/ppcommon/pp-global-constants";
import { GetCountryNamesType, GetStateNamesType } from "src/app/Account/add-funds/get-state-names";
import { StringifyOptions } from "querystring";
import { Console } from "console";
import { SubmitProfileDetailsType } from "src/app/Account/company-profile/submit-profile-details";
import { ReceiptHistoryRequest } from "src/app/account-receipt-history/account-receipt-history.component";
import { RetrievingFeesDueListType } from "src/app/Import/import-fees-due/retrieving-fees-due-list";
import { UnClaimRequest } from "src/app/import-claim-list/import-claim-list.component";
import { ImportFeesDueDetails } from "src/app/Account/add-funds/direct-payment/import-fees-due-details";
import {
    GetInvoiceResultType,
    InvoiceDocDetailsRequest
} from "src/app/account-summary/get-account-status-and-history-details";
import { ExportUnClaimRequest } from "src/app/Export/export-claim-list/export-claim-list.component";
import { ExportRetrievingFeesduelistType } from "src/app/Export/export-fees-due/export-retrieving-feesduelist";
import { ExportFeesDueDetailsType } from "src/app/Account/add-funds/export-direct-payment/export-fees-due-details";
import { ExportloadAfterpaymentdataType } from "src/app/Account/add-funds/export-direct-payment/exportload-afterpaymentdata";
import {
    ImportPendingFilterList,
    ImportPendingListRequest
} from "src/app/import-pending-list/import-pending-list.component";
import { ExportPendingFilterList, ExportPendingListRequest } from "src/app/export-pending-list/export-pending-list.component";

import { AccountGetCompanyInfoType } from "src/app/Account/account-credit/account-get-company-info";
import { SubmittingAccountCreditType } from "src/app/Account/account-credit/submitting-account-credit";
import { RetrievingBookingStatusType } from "src/app/Export/export-booking-status/retrieving-booking-status";
import { AccountRegistrationRequestType } from "src/app/account-registration/account-registration-types";
import { SecQuestionResponse } from "src/app/fullscreen-features/reset-password/password-reset-type";
import { NewSubscriberData, NotificationMsgRequest } from "src/app/account-alerts/add-subscription-types";
import { IAddUserRequest, IChangePasswordRequest } from "src/app/Account/account-users/account-addedit-user/account-addedit-user.component";
import { CSRContainerSearch, CSRTripDetailsSearch, MoveSearchResultDisplay, objectlog, stringlog, TNConFeeDetail } from "src/app/csr-containerhistory/csr-containerhistory-type";
import { ConExemptionRequest, ShippingLineRequest } from "src/app/csr/shipping-exemptions/exemption-types";
import { GetAddMoveDataType } from "src/app/csr/addmove/get-add-move-data";
import { PostaddmovedataType } from "src/app/csr/addmove/postaddmovedata";
import { RetrievingedisummaryType } from "src/app/csr/csr-edisummary/retrievingedisummary";
import { MoveDetails } from "src/app/csr-containerhistory/csr-containerhistory-type";
import { BookingHistoryRequest } from 'src/app/csr/csr-bookinghistory/bookinghistory-types';

import { ContainerStatusRequest } from 'src/app/import-container-status/import-container-status.component';
import { BulletinsWebContentData, HomePageManagementRequest } from 'src/app/csr/csr-homepagemanagement/homepagemanagement-types';
import { ImportClaimList_SubmitRequestType, ImportSearchRequestType, NewTariffDetail, PaymentResultType, TN_ConInventoryType } from 'src/app/import-search/import-search-types';
import { PopupRequestType } from 'src/app/csr/csr-managepopups/csr-managepopups.component';
import { NumericValueType } from '@rxweb/reactive-form-validators';



// import { UserInfoType, WebContentType } from '../web-content-type';
// import { AnyAaaaRecord } from 'node:dns';
// import { LoginResponse } from '../login-response';

@Injectable({
    providedIn: "root",
})
export class WebapidataService {

    AUTH: string = PpGlobalConstants.AUTH;
    URL: string = environment.PierPassBackEndServicesBaseURL;

    companyID = new BehaviorSubject<any>("");
    MoveHistoryResult: MoveSearchResultDisplay[]
    MoveHistoryResult1: MoveSearchResultDisplayPDF[]
    ConHistoryMoveFeeDetails: any;
    CSR_CON_HIS_Selected_ConPos_Id: string;
    CSR_CON_HIS_screenname: string;
    CSR_RESETPWD_RGBCOAccountList: any;
    FromRedirect: string;
    SelectedContainerNum: string;
    FeeTypeCTFTMF: string;

    private disabledButtons: string[] = [];

    AcctInfo: CSRAccountInContext = {
        AccountType: "TMF",
        CompanyId: 1,
        CompanyName: "xyz",
        VTXAccountId: -1
    }

    DisabledButtonsList: string[] = ["a", "b"];

    private DisabledButtonsListinContext = new BehaviorSubject<string[]>(this.DisabledButtonsList);
    DisabledButtonsListinContext$ = this.DisabledButtonsListinContext.asObservable();

    private NewChildModuleName = new BehaviorSubject<LoadedModuleInfo>({
        ParentModule: "",
        ChildModule: "",
    });
    LoadedChildModuleName$ = this.NewChildModuleName.asObservable();

    private AccountinContext = new BehaviorSubject<CSRAccountInContext>(this.AcctInfo);
    AccountinContext$ = this.AccountinContext.asObservable();

    constructor(private http: HttpClient,
        private router: Router) {

        this.InitializeCSRAccountInfo();
    }

    setAccountType(CreditStatus: string) {
        localStorage.setItem(PpGlobalConstants.AccountType, CreditStatus);
    }

    getAccountType() {
        return localStorage.getItem(PpGlobalConstants.AccountType);
    }

    setDisableButtonsList(buttons: string[]) {
        this.disabledButtons = buttons;
    }

    isButtonDisabled(button: string): boolean {
        return this.disabledButtons.includes(button);
    }

    // for module name to pop up in Layout component
    PushModuleNameChange(Parent: string, Child: string) {
        let ModInfo: LoadedModuleInfo = {
            ParentModule: Parent,
            ChildModule: Child,
        };

        this.NewChildModuleName.next(ModInfo);
    }

    PushCSRAccountInfo(AcctInfo: CSRAccountInContext) {
        localStorage.setItem(PpGlobalConstants.CSRAccountInContext, JSON.stringify(AcctInfo));
        this.AccountinContext.next(AcctInfo);
    }

    InitializeCSRAccountInfo() {
        // console.log('InitializeCSRAccountInfo Invoked');

        let value = localStorage.getItem(PpGlobalConstants.CSRAccountInContext);

        let CSRAccountInfo: CSRAccountInContext = {
            AccountType: "TMF",
            CompanyId: 1,
            CompanyName: "xyz",
            VTXAccountId: -1
        }

        if (value === null || value === '' || value === 'undefined') {
            // console.log(
            //     'The key does not exist, is associated with an empty string, or is associated with "undefined" in Local Storage!'
            // );
            localStorage.setItem(
                PpGlobalConstants.CSRAccountInContext,
                JSON.stringify(CSRAccountInfo)
            );
        } else {

            CSRAccountInfo = JSON.parse(value);

            // console.log('The key exists, value:', value);

            // console.log('After type conversion', CSRAccountInfo);

            this.AccountinContext.next(CSRAccountInfo);
        }
    }


    PushDisabledButtonsListInfo(DisabledButtonsList: string[]) {
        this.DisabledButtonsListinContext.next(DisabledButtonsList);
        this.setDisableButtonsList(DisabledButtonsList);
    }

    // for User Full name to pop up in Layout component
    private LoggedInUserFullName = new BehaviorSubject<string>("");
    LoggedInUserFullName$ = this.LoggedInUserFullName.asObservable();

    PushUserFullName(UserFullName: string) {
        this.LoggedInUserFullName.next(UserFullName);
    }


    getVTXAccount(FeeType_Cd: FeeTypeCode): number {
        let BCOInfoList: RGBCO[] = null;

        BCOInfoList = JSON.parse(localStorage.getItem(PpGlobalConstants.BCOInfoList));

        let VTXAccount_Id: number = null;

        BCOInfoList.forEach(element => {

            if (FeeType_Cd === (element.FeeType_Cd as FeeTypeCode)) {
                VTXAccount_Id = element.VTXAccount_Id;
                return VTXAccount_Id;
            }

        })

        return VTXAccount_Id;
    }
    private CSR_CON_HIS_ScreenChangeEvent = new BehaviorSubject<string>('');

    PUSH_CSR_CON_HIS_ScreenName(msg: string) {
        this.CSR_CON_HIS_ScreenChangeEvent.next(msg)
    }

    LISTEN_CSR_CON_HIS_ScreenName() {
        return this.CSR_CON_HIS_ScreenChangeEvent.asObservable();
    }

    Common_GetAccessPriveleges(LoggedUser_Id: number) {
        //   const body = req;
        const fullurl = this.URL + "/api/CommonInfo/GetUserAccessPrivileges/" + LoggedUser_Id;
        return this.http.get<any>(fullurl, undefined).pipe();
    }


    //// EDI Calls

    EDI_IB_Monitor_EDIIBMonitorRefDataGet(req: any) {
        const body = req;
        const fullurl = this.URL + "/api/EDI/EDIIBMonitorRefDataGet";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    EDI_IB_Monitor_SearchEDIIBMonitor(req: any) {
        const body = req;
        const fullurl = this.URL + "/api/EDI/SearchEDIIBMonitor";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    EDI_IB_Monitor_EDIInboundXMLGet(req: any) {
        const body = req;
        const fullurl = this.URL + "/api/EDI/EDIInboundXMLGet";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    EDI_OB_Monitor_EDIOBMonitorRefDataGet(req: any) {
        const body = req;
        const fullurl = this.URL + "/api/EDI/EDIOBMonitorRefDataGet";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    EDI_OB_Monitor_SearchEDIOBMonitor(req: any) {
        const body = req;
        const fullurl = this.URL + "/api/EDI/SearchEDIOBMonitor";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    EDI_OB_Monitor_EDIOutboundXMLGet(req: any) {
        const body = req;
        const fullurl = this.URL + "/api/EDI/EDIOutboundXMLGet";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    //////////

    /// Home - Password Reset ; GetUserAccountInfo_PwdReset
    GetUserAccountInfo_PwdReset(
        Login_Nm: string
    ) {

        const body = {};

        const fullurl = this.URL + "/api/Home/ResetPwd/GetUserAccountInfo/" + Login_Nm;

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        stringlog("Login Name:" + Login_Nm);

        return this.http.get<any>(fullurl).pipe(
            map((data: SecQuestionResponse) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );

    }

    // Home - Password Reset ; Submit Password Reset
    SubmitPasswordReset(
        req: SecQuestionResponse
    ) {
        const body = req;

        const fullurl = this.URL + "/api/Home/ResetPwd/SubmitResetPasswordInfo"

        return this.http.post<any>(fullurl, body).pipe(
            map((data: SecQuestionResponse) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }


    //EDI  BRE Summary
    GetEDIBRESummary(req: any) {
        const body = req;

        const fullurl = this.URL + "/api/EDI/BRESummary/GetBreSummary"

        return this.http.post<any>(fullurl, body).pipe(
            map((data: SecQuestionResponse) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }

    AccountReister_GetBCOInfo(Login_Nm: string) {
        const fullurl = this.URL + "/api/Account/Register/GetBCOInfo/" + Login_Nm;
        return this.http.get<any>(fullurl, undefined).pipe();
    }


    // Account Registration -- GET states, country, business types, container rane info
    GetAccountRegister() {
        const fullurl = this.URL + "/api/Account/Register/RegisterGet";

        stringlog("In GetAccountRegister call");

        var response: any;

        return this.http.get<any>(fullurl).pipe(
            map((data: GetInvoiceResultType) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }

    // Account Registration -- Check if company name with the same name exists
    CheckCompanyForExistence(
        Company_Nm: string
    ): Observable<HttpEvent<any>> {


        const fullurl = this.URL + "/api/Account/Register/CheckCompanyExistence";

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        stringlog("Company Name:" + Company_Nm);

        const body = { "Company_Nm": Company_Nm };

        return this.http.post<any>(fullurl, body).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }

    // Account Registration : Submit account registration
    SubmitAccountRegistration(
        req: AccountRegistrationRequestType
    ): Observable<HttpEvent<any>> {
        const body = req;

        const fullurl = this.URL + "/api/Account/Register/SubmitNewAccountRegistration"

        return this.http.post<any>(fullurl, body).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }

    // Import Search Payment
    ImportSearchAddPayment(submitDetails: any) {
        const fullurl = this.URL + "/api/ImportSearch/Payment";
        return this.http
            .post<PaymentResultType[]>(fullurl, submitDetails)
            .pipe(
                map((data: PaymentResultType[]) => {
                    stringlog("ImportSearchAddPayment");
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }


    SubmitPostImportClaims(
        req: ImportClaimList_SubmitRequestType
    ): Observable<HttpEvent<any>> {
        const body = req;

        const fullurl = this.URL + "/api/ImportSearch/SubmitClaims";

        return this.http.post<any>(fullurl, body).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }

    ImportGetClaims(req: ImportSearchRequestType): Observable<HttpEvent<any>> {
        const body = req;
        const fullurl = this.URL + "/api/ImportSearch/ImportGetClaims";

        var response: any;

        return this.http.post<any>(fullurl, body).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }


    checkFutureTariffDetails(req:TN_ConInventoryType): Observable<HttpEvent<any>> {
        const body = req;
        const fullurl = this.URL + "/api/ImportSearch/checkFutureTariffDetails";

        var response: any;

        return this.http.post<any>(fullurl, body).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }

    SubmitPostExportClaims(
        claimSubReq: SubmitClaimRequest
    ): Observable<HttpEvent<any>> {
        const body = claimSubReq;

        const fullurl = this.URL + "/api/Export/SubmitExportClaimPostbyBookingNums";

        var response: any;

        return this.http.post<any>(fullurl, body).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }

    Login_GetBCOInfo(
        req: any
    ) {
        const body = req;
        const fullurl = this.URL + "/api/Login/GetBCOInfo";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    CSR_CompanyAdmin_ResetAdminEmail(req: any) {
        const body = req;
        const fullurl = this.URL + "/api/CSR/PassswordReset/ResetAdminEmail";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    AccountRegister_UpdateCTFTerms(req: any) {
        const body = req;
        const fullurl = this.URL + "/api/Account/Register/UpdateCTFTerms";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }


    CSR_CompanyAdmin_GetCompanyAccountDetails(req: any) {
        const body = req;
        const fullurl = this.URL + "/api/CSR/PassswordReset/GetCompanyAccountDetails";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    CSR_PassordReset_GetCompaniesMatchingList(req: any) {
        const body = req;
        const fullurl = this.URL + "/api/CSR/PassswordReset/GetCompaniesMatchingList";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    CSR_PassordReset_GetUserList(req: any) {
        const body = req;
        const fullurl = this.URL + "/api/CSR/PassswordReset/GetUserList";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    CSR_PassordReset_AdminResetPassword(req: any) {
        const body = req;
        const fullurl = this.URL + "/api/CSR/PassswordReset/AdminResetPassword";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }


    CSRCH_Resend315Import(
        req: any
    ) {
        const body = req;

        // ContainerReviewDetails
        const fullurl = this.URL + "/api/CSR/Resend315Import";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    CSRCH_Resend315Export(
        req: any
    ) {
        const body = req;

        // ContainerReviewDetails
        const fullurl = this.URL + "/api/CSR/Resend315Export";

        // stringlog(fullurl);

        return this.http.post<any>(fullurl, body, undefined).pipe();
    }


    CSRCH_GetAccountDetailsbyVTXId(
        req: any
    ) {
        const body = req;

        // ContainerReviewDetails
        const fullurl = this.URL + "/api/CSR/GetAccountDetailsbyVTXId";

        // stringlog(fullurl);

        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    CSRCH_SubmitClaim(
        req: any
    ) {
        const body = req;

        // ContainerReviewDetails
        const fullurl = this.URL + "/api/CSR/SubmitClaim";

        // stringlog(fullurl);

        return this.http.post<any>(fullurl, body, undefined).pipe();
    }


    CSRCH_SubmitChangeStatus(
        req: any
    ) {
        const body = req;

        // ContainerReviewDetails
        const fullurl = this.URL + "/api/CSR/SubmitChangeStatus";

        // stringlog(fullurl);

        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    CSRCH_SubmitExemption(
        req: any
    ) {
        const body = req;

        // ContainerReviewDetails
        const fullurl = this.URL + "/api/CSR/SubmitExemption";

        // stringlog(fullurl);

        return this.http.post<any>(fullurl, body, undefined).pipe();
    }


    CSRCH_GetChangeExemptStatus(
        req: any
    ) {
        const body = req;

        // ContainerReviewDetails
        const fullurl = this.URL + "/api/CSR/GetChangeExemptStatus";

        // stringlog(fullurl);

        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    CSRCH_GetTruckHistory(req: any) {
        const body = req;
        const fullurl = this.URL + "/api/CSR/GetTruckHistory";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }


    CSRCH_ChangeStatusReferenceTables(
        req: MoveDetails.ChangeStatus_ReferenceTables_Request
    ) {
        const body = req;

        // ContainerReviewDetails
        const fullurl = this.URL + "/api/CSR/ChangeStatusReferenceTables";

        // stringlog(fullurl);

        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    getCSRContainerReviewDetails(
        req: CSRTripDetailsSearch
    ) {
        const body = req;

        // ContainerReviewDetails
        const fullurl = this.URL + "/api/CSR/ContainerReviewDetails";

        // stringlog(fullurl);

        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    CSRCH_SubmitMoveCorrection(
        req: MoveDetails.CorrectionUpdate_Request
    ) {
        const body = req;

        // ContainerReviewDetails
        const fullurl = this.URL + "/api/CSR/SubmitMoveCorrection";

        // stringlog(fullurl);

        return this.http.post<any>(fullurl, body, undefined).pipe();
    }


    getCSRContainerTripDetails(
        req: CSRTripDetailsSearch
    ) {
        const body = req;

        // ContainerReviewDetails
        const fullurl = this.URL + "/api/CSR/ContainerTripDetails";

        // stringlog(fullurl);

        return this.http.post<any>(fullurl, body, undefined).pipe();
    }


    getCSRBookingContainerHistory(
        req: CSRContainerSearch
    ) {
        const body = req;

        const fullurl = this.URL + "/api/CSR/ContainerHistory";

        // stringlog(fullurl);

        return this.http.post<any>(fullurl, body, undefined).pipe();
    }


    postGetExportClaimbyBookingNums(
        req: ExportSearchRequestType
    ) {
        const body = req;

        const fullurl = this.URL + "/api/Export/GetExportClaimbyBookingNums";

        // stringlog(fullurl);

        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    //GetRefundReasons Service Call
    GetRefundReasons() {
        const fullurl = this.URL + "/api/Account/Refund/GetRefundReasons";
        return this.http.get<GetRefundReasonsType[]>(fullurl).pipe(
            map((data: GetRefundReasonsType[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }

    //GetAccountID,Balance and GridHistory  Service Call
    //parameters:CompanyID,UserID
    GetAccountStatusAndHistoryDetails(Company_Id: any, User_Id: any, FeeType_Cd: string) {
        const fullurl =
            this.URL +
            "/api/Account/Refund/GetAccountStatusAndHistoryDetails/" +
            Company_Id +
            "/" +
            User_Id + "/" + FeeType_Cd;

        return this.http.get<GetAccountStatusAndHistoryDetailsType[]>(fullurl).pipe(
            map((data: GetAccountStatusAndHistoryDetailsType[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }
    GetImportClaimList(Company_Id: any, User_Id: any, Login_Nm: any, FeeType_Cd: string) {
        const fullurl = this.URL + "/api/Import/ImportClaimList/GetImportClaimList/" + Company_Id + "/" + User_Id + "/" + Login_Nm + "/" + FeeType_Cd;

        return this.http.get<GetAccountStatusAndHistoryDetailsType[]>(fullurl).pipe(
            map((data: GetAccountStatusAndHistoryDetailsType[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }

    GetAccountUsersList(Company_Id: any, User_Id: any, Login_Nm: any, FeeType_Cd: string, CompanyGrp_Cd: string) {
        const fullurl =
            this.URL +
            "/api/Account/Users/GetUsers/" + Company_Id + "/" + User_Id + "/" + Login_Nm + "/" + FeeType_Cd + "/" + CompanyGrp_Cd;

        return this.http.get<GetAccountStatusAndHistoryDetailsType[]>(fullurl).pipe(
            map((data: GetAccountStatusAndHistoryDetailsType[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }

    GetSecurityQuestions(Company_Id: any, User_Id: any, Login_Nm: any, FeeType_Cd: string, CompanyGrp_Cd: string) {
        const fullurl =
            this.URL +
            "/api/Login/GetSecurityQuestions/" + Company_Id + "/" + User_Id + "/" + Login_Nm + "/" + FeeType_Cd + "/" + CompanyGrp_Cd;


        stringlog("Security Questions in WebAPI data service");

        return this.http.get<any[]>(fullurl).pipe(
            map((data: any[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }


    GetUserInfoByUserName(UserNmae: string) {
        const fullurl =
            this.URL +
            "/api/CommonInfo/GetUserAccountInformation/" + UserNmae;

        return this.http.get<GetAccountStatusAndHistoryDetailsType[]>(fullurl).pipe(
            map((data: GetAccountStatusAndHistoryDetailsType[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }
    SubmitChangePassword(UserData: IChangePasswordRequest) {
        const fullurl = this.URL + '/api/Account/Users/ChangePassword';

        return this.http
            .post<IChangePasswordRequest[]>(fullurl, UserData)
            .pipe(
                map((data: IChangePasswordRequest[]) => {
                    return data;
                }),
                catchError((error) => {

                    return throwError('Something went wrong!');

                })
            );

        //return this.http.post<any>(fullurl, unClaimRequest, undefined).pipe();
    }

    FirstLoginChangePassword(UserData: IChangePasswordRequest) {
        const fullurl = this.URL + '/api/Login/ChangePassword';

        return this.http
            .post<IChangePasswordRequest[]>(fullurl, UserData)
            .pipe(
                map((data: IChangePasswordRequest[]) => {
                    return data;
                }),
                catchError((error) => {

                    return throwError('Something went wrong!');

                })
            );

        //return this.http.post<any>(fullurl, unClaimRequest, undefined).pipe();
    }

    AddorEditUser(UserData: any) {
        const fullurl = this.URL + '/api/Account/Users/AddorEditUser';

        return this.http
            .post<IAddUserRequest[]>(fullurl, UserData)
            .pipe(
                map((data: IAddUserRequest[]) => {
                    return data;
                }),
                catchError((error) => {

                    return throwError('Something went wrong!');

                })
            );

        //return this.http.post<any>(fullurl, unClaimRequest, undefined).pipe();
    }


    GetExportClaimList(Company_Id: any, User_Id: any, Login_Nm: any, FeeType_Cd: string) {

        stringlog('GetExportClaimList getting called:')
        const fullurl = `${this.URL}/api/Export/ExportClaimList/GetExportClaimList/${Company_Id}/${User_Id}/${Login_Nm}/${FeeType_Cd}`;

        return this.http.get<GetAccountStatusAndHistoryDetailsType[]>(fullurl).pipe(
            map((data: GetAccountStatusAndHistoryDetailsType[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }
    GetImportHistoryList(conHistoryRequest: any) {
        const fullurl = this.URL + '/api/Import/ImportHistory/RetrievingImportHistoryBasedOnContainers';

        return this.http
            .post<UnClaimRequest[]>(fullurl, conHistoryRequest)
            .pipe(
                map((data: UnClaimRequest[]) => {
                    return data;
                }),
                catchError((error) => {

                    return throwError('Something went wrong!');

                })
            );

        //return this.http.post<any>(fullurl, unClaimRequest, undefined).pipe();
    }

    GetExportHistoryList(conHistoryRequest: any) {
        const fullurl = this.URL + '/api/Export/ExportHistory/GetExportHistory';

        return this.http
            .post<UnClaimRequest[]>(fullurl, conHistoryRequest)
            .pipe(
                map((data: UnClaimRequest[]) => {
                    return data;
                }),
                catchError((error) => {

                    return throwError('Something went wrong!');

                })
            );

        //return this.http.post<any>(fullurl, unClaimRequest, undefined).pipe();
    }
    UnClaimContainers(unClaimRequest: any) {
        const fullurl = this.URL + "/api/Import/ImportClaimList/UnClaimContainers";

        return this.http.post<UnClaimRequest[]>(fullurl, unClaimRequest).pipe(
            map((data: UnClaimRequest[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );

        //return this.http.post<any>(fullurl, unClaimRequest, undefined).pipe();
    }
    GetImportContainerHistory(conHistoryRequest: any) {
        const fullurl = this.URL + '/api/Import/ContainerHistory/GetContainerHistory';

        return this.http
            .post<UnClaimRequest[]>(fullurl, conHistoryRequest)
            .pipe(
                map((data: UnClaimRequest[]) => {
                    return data;
                }),
                catchError((error) => {

                    return throwError('Something went wrong!');

                })
            );

        //return this.http.post<any>(fullurl, unClaimRequest, undefined).pipe();
    }
    ExportUnClaimBookings(unClaimRequest: any) {
        const fullurl = this.URL + "/api/Export/ExportClaimList/UnclaimBooking";

        return this.http.post<ExportUnClaimRequest[]>(fullurl, unClaimRequest).pipe(
            map((data: ExportUnClaimRequest[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );

        //return this.http.post<any>(fullurl, unClaimRequest, undefined).pipe();
    }
    GetReceiptHistory(receiptHistoryRequest: any) {
        const fullurl = this.URL + "/api/Account/ReceiptHistory/GetReceiptHistory";

        return this.http
            .post<ReceiptHistoryRequest>(fullurl, receiptHistoryRequest)
            .pipe(
                map((data: ReceiptHistoryRequest) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }
    //Submitting RefundRequestAmount Service Call
    PostRefundAmoutDetails(submitDetails: any) {
        const fullurl =
            this.URL + "/api/Account/Refund/SubmittingRequestRefundDetails";

        return this.http
            .post<PostRefundAmoutDetailsType[]>(fullurl, submitDetails)
            .pipe(
                map((data: PostRefundAmoutDetailsType[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    logout() {
        localStorage.setItem(PpGlobalConstants.AUTH, "");
        localStorage.setItem(PpGlobalConstants.AdditionalUserInfo, "");
        localStorage.setItem(PpGlobalConstants.AccountId, "");
        localStorage.setItem(PpGlobalConstants.ModuleNavigation, "");
        localStorage.setItem(PpGlobalConstants.LoginAttemptsCount, "");
        // localStorage.setItem(PpGlobalConstants.appInstance_Name, "");
        // localStorage.setItem(PpGlobalConstants.app_instance, "");
        localStorage.setItem(PpGlobalConstants.BCOInfo, "");
        localStorage.setItem(PpGlobalConstants.TokenExpired, "false");
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage, "");
        localStorage.setItem(PpGlobalConstants.BCOInfoList, "");
        localStorage.setItem(PpGlobalConstants.UserAccounTypeInfo, "");

        this.FeeTypeCTFTMF = localStorage.getItem(PpGlobalConstants.FeeTypeCTFTMF);

        localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF, "");

        this.PushUserFullName("");
        this.PUSH_CSR_CON_HIS_ScreenName(CSRCHConstants.S1ContainerSearch);

        localStorage.setItem(PpGlobalConstants.CSRInContext, "false");
        localStorage.setItem(PpGlobalConstants.AccessPrivileges, "");
        localStorage.setItem(PpGlobalConstants.CSRImpersonation_VTXAccountInfo, "");
        localStorage.setItem(PpGlobalConstants.DisabledButtonsList, "");

        if (this.FeeTypeCTFTMF == 'TMF') {
            environment.logoutURL = environment.PierPASSHomeURL;
            this.router.navigate(["/pierpasshome"]);
        }
        else if (this.FeeTypeCTFTMF == 'CTF') {
            environment.logoutURL = environment.WeeblySiteURL;
            window.location.href = environment.logoutURL;

        }
    }

    ClearAllLocalData() {
        localStorage.setItem(PpGlobalConstants.AUTH, "");
        localStorage.setItem(PpGlobalConstants.AdditionalUserInfo, "");
        localStorage.setItem(PpGlobalConstants.AccountId, "");
        localStorage.setItem(PpGlobalConstants.ModuleNavigation, "");
        localStorage.setItem(PpGlobalConstants.BCOInfo, "");
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage, "");
        localStorage.setItem(PpGlobalConstants.BCOInfoList, "");
        localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF, "");
        localStorage.setItem(PpGlobalConstants.UserAccounTypeInfo, "");

        this.PushUserFullName("");
        this.PUSH_CSR_CON_HIS_ScreenName(CSRCHConstants.S1ContainerSearch);
        // this.conpo
    }


    ForceOutSession() {
        localStorage.setItem(PpGlobalConstants.AUTH, "");
        localStorage.setItem(PpGlobalConstants.AdditionalUserInfo, "");
        localStorage.setItem(PpGlobalConstants.AccountId, "");
        localStorage.setItem(PpGlobalConstants.ModuleNavigation, "");
        localStorage.setItem(PpGlobalConstants.LoginAttemptsCount, "");
        // localStorage.setItem(PpGlobalConstants.appInstance_Name, "");
        // localStorage.setItem(PpGlobalConstants.app_instance, "");
        localStorage.setItem(PpGlobalConstants.BCOInfo, "");
        localStorage.setItem(PpGlobalConstants.TokenExpired, "true");

        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage, "");
        localStorage.setItem(PpGlobalConstants.BCOInfoList, "");

        this.FeeTypeCTFTMF = localStorage.getItem(PpGlobalConstants.FeeTypeCTFTMF);
        if (this.FeeTypeCTFTMF == 'TMF') {
            environment.logoutURL = environment.PierPASSHomeURL;
        }
        else if (this.FeeTypeCTFTMF == 'CTF') {
            environment.logoutURL = environment.WeeblySiteURL;
        }


        localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF, "");

        this.PushUserFullName("");
        this.PUSH_CSR_CON_HIS_ScreenName(CSRCHConstants.S1ContainerSearch);

        localStorage.setItem(PpGlobalConstants.AccessPrivileges, "");
        // this.conpo
    }



    //Account Summary
    getAccountSummaryDetails(Company_Id: any, User_Id: any, FeeType_Cd: string) {
        const fullurl = this.URL + "/api/Account/AccountSummary/GetAccountSummaryDetails/" + Company_Id + "/" + User_Id + "/" + FeeType_Cd;
        return this.http.get<any>(fullurl).pipe(map((data: any) => { return data; }),
            catchError((error) => { return throwError("Unable to connect to Dataservice: " + error); }));
    }

    AccountSummary_GetAccountDetails(Company_Id: any) {

        const fullurl = this.URL + "/api/Account/AccountSummary/GetAccountDetails/" + Company_Id;

        return this.http.get<any>(fullurl).pipe(map((data: any) => { return data; }),
            catchError((error) => { return throwError("Unable to connect to Dataservice: " + error); }));
    }

    // Account Statement Details
    PostAccountStatementDetails(AccoutStatementInput: any) {

        const fullurl = this.URL + "/api/Account/AccountSummary/GetAccountStatementDetails";

        const body = AccoutStatementInput;

        return this.http
            .post<any>(fullurl, body)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Unable to connect to Dataservice: " + error);
                })
            );
    }

    GetAddAnnouncementReferencedata(Company_Id: any, User_Id: any, Login_Nm: any, FeeType_Cd: string) {
        const fullurl =
            this.URL +
            "/api/CSR/AddAnnouncement/GetAnnouncementReferenceData/" +
            Company_Id +
            "/" +
            User_Id +
            "/" +
            Login_Nm +
            "/" +
            FeeType_Cd;

        return this.http.get<any>(fullurl).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }


    GetFeeExemptData(Company_Id: any, User_Id: any, Login_Nm: any, FeeType_Cd: string) {
        const fullurl =
            this.URL +
            "/api/CSR/AddAnnouncement/GetFeeExemptData/" +
            Company_Id +
            "/" +
            User_Id +
            "/" +
            Login_Nm +
            "/" +
            FeeType_Cd;

        return this.http.get<GetAccountStatusAndHistoryDetailsType[]>(fullurl).pipe(
            map((data: GetAccountStatusAndHistoryDetailsType[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }

    //Post Announcement Details
    PostAddAnnounceDetails(Announcement: any) {
        const fullurl =
            this.URL + "/api/CSR/AddAnnouncement/PostAnnouncement";
        // stringlog("In PostAccountStatementDetails");

        const body = Announcement;
        var response: any;

        return this.http.post<GetInvoiceResultType>(fullurl, body).pipe(
            map((data: GetInvoiceResultType) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }


    // Account Invoice History Details
    PostAccountInvoiceHistDetails(AccoutInvoiceInput: any) {
        const fullurl =
            this.URL + "/api/Account/AccountSummary/GetAccountInvoiceHistoryDetails";
        // stringlog("In PostAccountStatementDetails");

        const body = AccoutInvoiceInput;
        var response: any;

        return this.http.post<GetInvoiceResultType>(fullurl, body).pipe(
            map((data: GetInvoiceResultType) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }
    GetInvoiceDetailsforInvoiceDoc(InvoiceDocReq: InvoiceDocDetailsRequest) {
        const fullurl = this.URL + "/api/Account/AccountSummary/GetInvoicePDFFile";

        // stringlog("In PostAccountStatementDetails");

        const body = InvoiceDocReq;
        var response: any;

        return this.http.post<GetInvoiceResultType>(fullurl, body).pipe(
            map((data: GetInvoiceResultType) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }
    getAuthorizationToken() {
        let AuthInfo: LoginResponse = JSON.parse(
            localStorage.getItem(PpGlobalConstants.AUTH) || "{}"
        );

        return AuthInfo.Token;
    }

    CheckRefreshToken() {

        let AuthInfo: LoginResponse = JSON.parse(
            localStorage.getItem(PpGlobalConstants.AUTH) || "{}"
        );


        if (AuthInfo == undefined || AuthInfo.Token == null || AuthInfo.Token == "") {
            return;
        }


        const NowDatetime = new Date();
        // MinutsDiff number : 0;
        let minutes = (+NowDatetime - +AuthInfo.LastRefreshedTime) / 60000;

        // stringlog("Sec Object minutes:" + minutes);

        // if(minutes >= 60) return false
        if (minutes >= 2) {
            AuthInfo.Token = null;
            stringlog("nullify token:" + minutes);
            return false;
        }
        else {
            stringlog("Refresh/GetNew token:" + minutes);
            this.postJWTRefreshToken(AuthInfo).subscribe(
                (data: any) => {
                    if (data.Error == "error") {
                        stringlog("error:" + data.Result);
                        return;
                    }
                    else if (data.Error == "good") {
                        AuthInfo = data;
                        localStorage.setItem(PpGlobalConstants.AUTH, JSON.stringify(AuthInfo));
                        // stringlog("Sec Object:" + JSON.stringify(AuthInfo));
                        return true;
                    }

                },
                (error) => {
                    stringlog("connectivity error: " + error);
                    stringlog("connectivity error: " + error.message);
                    return false;
                }
            );

        }

        return false;

    }

    getLoginInfo(): LoginResponse {
        let AuthInfo: LoginResponse = JSON.parse(
            localStorage.getItem(PpGlobalConstants.AUTH) || "{}"
        );

        return AuthInfo;
    }

    getCSRDashBoardMenus(VTXAccount_Id: number) {
        // debuglog('Webapidataservice: getCSRDashBoardMenus');
        let AuthInfo: LoginResponse = JSON.parse(
            localStorage.getItem(PpGlobalConstants.AUTH) || "{}"
        );

        stringlog("vtx AccountId: " + VTXAccount_Id);

        const body = AuthInfo;
        const fullurl = this.URL + "/api/MenusFromDB/GetScreenMenus/" + AuthInfo.UserName + "/" + "CSRImpersonation" + "/" + VTXAccount_Id;
        return this.http.get<any>(fullurl).pipe();
    }

    getButtonsAccessList(Mod_Nm: string, VTXAccount_Id: number) {
        //   debuglog('Webapidataservice: getButtonsAccessList');
        let AuthInfo: LoginResponse = JSON.parse(
            localStorage.getItem(PpGlobalConstants.AUTH) || "{}"
        );

        stringlog("vtx AccountId: " + VTXAccount_Id);

        const body = AuthInfo;
        const fullurl = this.URL + "/api/MenusFromDB/GetButtonsAccesslist/" + AuthInfo.UserName + "/" + Mod_Nm + "/" + VTXAccount_Id;
        return this.http.get<any>(fullurl).pipe();
    }

    getMenusFromDB(VTXAccount_Id: number, Mod_Nm: string) {
        debuglog('Webapidataservice: getMenusFromDB');
        let AuthInfo: LoginResponse = JSON.parse(
            localStorage.getItem(PpGlobalConstants.AUTH) || "{}"
        );

        stringlog("vtx AccountId: " + VTXAccount_Id);

        const body = AuthInfo;
        const fullurl = this.URL + "/api/MenusFromDB/GetScreenMenus/" + AuthInfo.UserName + "/" + Mod_Nm + "/" + VTXAccount_Id;
        return this.http.get<any>(fullurl).pipe();
    }

    getPreAuthMenusFromDB() {
        debuglog('Webapidataservice: getPreAuthMenusFromDB1');

        const fullurl = this.URL + "/api/MenusFromDB/GetPreAuthScreenMenus";
        return this.http.get<any>(fullurl).pipe();
    }


    // getALLMenus(layout : string) 
    // {

    //    let AuthInfo: LoginResponse = JSON.parse(
    //        localStorage.getItem(PpGlobalConstants.AUTH) || "{}"
    //      );


    //      if(layout == "PreAuth")
    //      {
    //        AuthInfo.IsAuthenticated =  false;
    //        stringlog("In PreAuth" + JSON.stringify(AuthInfo));
    //        const body = AuthInfo;
    //        const fullurl = this.URL + "/api/Login/GetMenus";
    //        return this.http.post<any>(fullurl, body, undefined).pipe();
    //      }
    //      else if(layout == "CSR")
    //      {
    //        stringlog("In CSR" + JSON.stringify(AuthInfo));
    //        const body = AuthInfo;
    //        const fullurl = this.URL + "/api/Login/GetMenus";
    //        return this.http.post<any>(fullurl, body, undefined).pipe();
    //      }
    //      else if(layout == "PostAuth")
    //      {
    //     //    stringlog("In PostAuth" + JSON.stringify(AuthInfo));
    //        const body = AuthInfo;
    //        const fullurl = this.URL + "/api/Login/GetMenus";
    //        return this.http.post<any>(fullurl, body, undefined).pipe();
    //      }
    //      else if(layout == "BCO")
    //      {
    //         let FeeTypeCTFTMF = localStorage.getItem(PpGlobalConstants.FeeTypeCTFTMF);

    //         if(FeeTypeCTFTMF == "CTF")
    //         {
    //             const body = AuthInfo;
    //             const fullurl = this.URL + "/api/Login/CTFGetMenus";
    //             return this.http.post<any>(fullurl, body, undefined).pipe();

    //         }
    //         else if(FeeTypeCTFTMF == "TMF")
    //         {
    //             const body = AuthInfo;
    //             const fullurl = this.URL + "/api/Login/GetMenus";
    //             return this.http.post<any>(fullurl, body, undefined).pipe();

    //         }
    //      }

    //     //  const body = AuthInfo;
    //     //  const fullurl = this.URL + "/api/Login/GetMenus";

    //  }

    GetPopAlert() {
        const fullurl = this.URL + "/api/Login/GetPopAlert";

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        return this.http.get<any>(fullurl, httpOptions).pipe();
    }

    Get_CSRPopup(PopContentType_Cd: string, pFeeType_Cd: string) {
        const fullurl = this.URL + "/api/CSRManagePopups/GetPopups/" + PopContentType_Cd + "/" + pFeeType_Cd;
        return this.http.get<any>(fullurl).pipe();
    }

    Get_ScreensPopupforDisplay(PopContentType_Cd: string, pFeeType_Cd: string) {
        const fullurl = this.URL + "/api/CSRManagePopups/GetPopups_FilterwithTime/" + PopContentType_Cd + "/" + pFeeType_Cd;
        return this.http.get<any>(fullurl).pipe();
    }

    Update_Popups(req: PopupRequestType) {
        const body = req;
        const fullurl = this.URL + "/api/CSRManagePopups/UpdatePopup";
        return this.http.post<any>(fullurl, body, undefined).pipe();

    }


    GetTMFCTFForkIn() {
        const fullurl = this.URL + "/api/Login/GetTMFCTFForkInSwitch";
        return this.http.get<any>(fullurl).pipe();
    }

    GetPierPASSTerms() {
        const fullurl = this.URL + "/api/Login/GetPierPASSTerms";
        return this.http.get<any>(fullurl).pipe();
    }

    GetAboutPierPASS() {
        const fullurl = this.URL + "/api/Login/GetAboutPierPASS";
        return this.http.get<any>(fullurl).pipe();
    }

    // gets all user information like userid, companyid, etc
    getUserInfo(): Observable<any> {
        // get loggged in usernamr
        let AuthInfo: LoginResponse = JSON.parse(
            localStorage.getItem(PpGlobalConstants.AUTH) || "{}"
        );

        // let FeeType_Cd = localStorage.getItem(PpGlobalConstants.FeeTypeCTFTMF);

        stringlog("getUserInfo");
        // stringlog(AuthInfo);

        if (AuthInfo != undefined) {
            const body = {};

            // const fullurl = this.URL + "/api/Common/GetUserInfo/" + AuthInfo.UserName;
            const fullurl = this.URL + "/api/CommonInfo/GetUserAccountInformation/" + AuthInfo.UserName;

            const httpOptions = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                }),
            };

            // stringlog(fullurl);
            // stringlog('Before calling post method');

            return this.http.get<any>(fullurl, httpOptions).pipe();
        }
    }

    getLoginAttemdptedUserInfo(Login_Name: string): Observable<any> {

        const body = {};
        // const fullurl = this.URL + "/api/Common/GetUserInfo/" + AuthInfo.UserName;
        const fullurl = this.URL + "/api/CommonInfo/GetUserAccountInformation/" + Login_Name;

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        return this.http.get<any>(fullurl, httpOptions).pipe();
    }

    // CheckCompanyAccountStatus(Login_Name : string): Observable<any> 
    // {
    //     const body = {};
    //     // const fullurl = this.URL +  "/api/Login/CheckUserStatus/"  + Login_Name;
    //     const fullurl = this.URL +  "/api/Login/LoginCheck2/"  + Login_Name;
    //     qauatlog("CheckCompanyAccountStatus URL: " +  fullurl);
    //     return this.http.get<any>(fullurl).pipe();
    // }

    CheckCompanyAccountTypeStatus(Login_Name: string): Observable<any> {
        const body = {};
        const fullurl = this.URL + "/api/Login/CheckCompanyAccountStatus/" + Login_Name;
        qauatlog("CheckCompanyAccountStatus URL: " + fullurl);
        return this.http.get<any>(fullurl).pipe();
    }



    DeActivateUser(LoginName: string, UserId: number, Company_Id: number): Observable<any> {

        // const fullurl = this.URL + "/api/Common/GetUserInfo/" + AuthInfo.UserName;
        const fullurl = this.URL + "/api/Login/DeActivateUser/" + LoginName + "/" + UserId + "/" + Company_Id;

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        return this.http.get<any>(fullurl, httpOptions).pipe();
    }

    isAuthenticated(): boolean {
        let AuthInfo: LoginResponse = JSON.parse(
            localStorage.getItem(PpGlobalConstants.AUTH) || "{}"
        );

        return AuthInfo.IsAuthenticated;
    }

    isAuthorized(): boolean {
        let AuthInfo: LoginResponse = JSON.parse(
            localStorage.getItem(PpGlobalConstants.AUTH) || "{}"
        );

        if (AuthInfo.Token != null) {

            const NowDatetime = new Date();
            const LastRefreshedTime = new Date(AuthInfo.LastRefreshedTime);
            // MinutsDiff number : 0;
            let minutes = (+NowDatetime - +LastRefreshedTime) / 60000;

            // stringlog("Sec Object Now Time:" + NowDatetime);
            // stringlog("Sec Object Last Refresh:" + LastRefreshedTime);
            // stringlog("Sec Object minutes:" + minutes);

            // more than 60 mins, logout 
            if (minutes > 60) {
                this.ForceOutSession();
                this.router.navigate(['pphome']);
                return false;
            }
            else {
                AuthInfo.LastRefreshedTime = new Date().toISOString();
                localStorage.setItem(PpGlobalConstants.AUTH, JSON.stringify(AuthInfo));
                // stringlog("Sec Object:" + JSON.stringify(AuthInfo));
                return true;
            }

        }

    }

    /// POST
    PostTestRequest(): Observable<any> {
        const body = {};
        const fullurl = this.URL + "/api/test";

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        stringlog(fullurl);
        stringlog("Before calling post method");

        return this.http.post<any>(fullurl, body, httpOptions).pipe();
    }

    /// GET
    GetTestRequest(): Observable<any> {
        const body = {};
        const fullurl = this.URL + "/api/test";

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        stringlog(fullurl);
        stringlog("Before calling post method");

        return this.http.get<any>(fullurl, httpOptions).pipe();

        // var insertedtoken =  httpOptions.headers.get("Authorization");
        // stringlog("token value:" +  httpOptions.headers.get("Authorization"));
        // stringlog("token value:" +  JSON.stringify(httpOptions));
    }

    postJWTAuthenticate(
        username: string,
        password: string
    ) {
        const body = { UserName: username, Password: password };

        const fullurl = this.URL + "/api/Authenticate/JWTAuth";

        return this.http.post<LoginResponse>(fullurl, body).pipe(
            map((data: LoginResponse) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }


    postJWTRefreshToken(req: any) {
        const body = req;
        const fullurl = this.URL + "/api/Authenticate/JWTRefreshToken";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }



    getBulletIns() {

        const fullurl = this.URL + "/api/WebContent/GetBulletInInfo";

        return this.http.get<BulletIns[]>(fullurl).pipe(
            map((data: BulletIns[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }

    getWebContentAll(): Observable<WebContentType[][]> {
        const fullurl = this.URL + "/api/WebContent/All";
        return this.http.get<WebContentRootObject[]>(fullurl).pipe(
            map((plans) => plans.map((plan) => plan.Table)),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }

    //Add Funds Service calls

    //GetStateNames
    GetStateNames() {
        const fullurl = this.URL + "/api/Account/AddFunds/GetStateNames";
        return this.http.get<GetStateNamesType[]>(fullurl).pipe(
            map((data: GetStateNamesType[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }

            //GetcountryNames
            GetCountrys() {
                const fullurl = this.URL + "/api/Account/AddFunds/GetCountrys";
                return this.http.get<GetCountryNamesType[]>(fullurl).pipe(
                    map((data: GetCountryNamesType[]) => {
                        return data;
                    }),
                    catchError((error) => {
                        return throwError("Something went wrong!");
                    })
                );
            }



    //Getting Account ID ,Balance and Existing Credit card Details
    GetAccountStatusAndCreditCardInformation(Company_Id: any, User_Id: any, FeeTypeCTFTMFCode: any) {
        const fullurl =
            this.URL +
            "/api/Account/AddFunds/GetAccountStatusAndCreditCardInformation/" +
            Company_Id +
            "/" +
            User_Id +
            "/" +
            FeeTypeCTFTMFCode;
        return this.http
            .get<GetAccountStatusAndCreditCardInformationType[]>(fullurl)
            .pipe(
                map((data: GetAccountStatusAndCreditCardInformationType[]) => {
                    objectlog(data);
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Submitting Payment details Service Call
    SubmittingPaymentDetails(submitDetails: any) {
        const fullurl = this.URL + "/api/Account/AddFunds/SubmitPayment";
        return this.http.post<SubmitPayment[]>(fullurl, submitDetails).pipe(
            map((data: SubmitPayment[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }

    //Submitting echeck Payment details Service Call
    echeckSubmittingPaymentDetails(submitDetails: any) {
        const fullurl = this.URL + "/api/Account/AddFunds/SubmitPayment";
        return this.http.post<SubmitPayment[]>(fullurl, submitDetails).pipe(
            map((data: SubmitPayment[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }

    //Account Profile Service calls

    //Getting Account ID ,Balance and Existing Credit card Details
    GetCompanyAccountDetails(Company_Id: any, User_Id: any, FeeType_Cd: string) {
        const fullurl =
            this.URL +
            "/api/Account/Profile/GetCompanyAccountDetails/" +
            Company_Id +
            "/" +
            User_Id + "/" + FeeType_Cd;
        return this.http.get<GetCompanyAccountDetailsType[]>(fullurl).pipe(
            map((data: GetCompanyAccountDetailsType[]) => {
                //stringlog(data);
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }

    //Removing the invoice recipients
    RemoveInvoiceRecipient(
        BCOAuthInvRecpt_Id: any,
        Company_Id: any,
        User_Id: any
    ) {
        const fullurl =
            this.URL +
            "/api/Account/Profile/RemoveInvoiceRecipient/" +
            BCOAuthInvRecpt_Id +
            "/" +
            Company_Id +
            "/" +
            User_Id;
        return this.http.get<DeleteCompanyinvoicedetailsType[]>(fullurl).pipe(
            map((data: DeleteCompanyinvoicedetailsType[]) => {
                stringlog("Mycheck");
                objectlog(data);
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }
    //Add invoicerecipients details
    AddInvoiceRecipients(addRecipients: any) {
        const fullurl = this.URL + "/api/Account/Profile/AddInvoiceRecipients";
        return this.http
            .post<GetCompanyAccountDetailsType[]>(fullurl, addRecipients)
            .pipe(
                map((data: GetCompanyAccountDetailsType[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    GetTruckerSearchList(truckerSearchRequest: any) {
        const fullurl = this.URL + "/api/DTR/GetTruckerSearchList";
        return this.http
            .post<GetCompanyAccountDetailsType[]>(fullurl, truckerSearchRequest)
            .pipe(
                map((data: GetCompanyAccountDetailsType[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }


    UploadFile(filedata: FormData) {
        const fullurl = this.URL + "/api/CSR/HomePageManagement/UploadFile";

        return this.http
            .post<any>(fullurl, filedata)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );

    }

    //Getting file details
    GetFileDetails() {
        const fullurl = this.URL + "/api/CSR/HomePageManagement/GetFileDetails";

        return this.http
            .get<GetAccountStatusAndCreditCardInformationType[]>(fullurl)
            .pipe(
                map((data: GetAccountStatusAndCreditCardInformationType[]) => {
                    objectlog(data);
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }


    //Submiting CompanyProfile details
    SubmitProfileDetails(submitProfileDetails: any) {
        const fullurl = this.URL + "/api/Account/Profile/SubmitProfileDetails";
        return this.http
            .post<SubmitProfileDetailsType[]>(fullurl, submitProfileDetails)
            .pipe(
                map((data: SubmitProfileDetailsType[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    ////////// test calls
    BasicTest(): Observable<LoginResponse> {
        const fullurl = this.URL + "/api/test/basic";
        return this.http.get<LoginResponse>(fullurl).pipe(
            map((data: LoginResponse) => {
                //stringlog(data);
                return data;
            }),
            catchError((error) => {
                stringlog(error);
                return throwError("Something went wrong!");
            })
        );
    }

    BasicTest2(): Observable<any> {
        const fullurl = this.URL + "/api/test/basic2";

        return this.http.get<any>(fullurl).pipe(
            map((data: any) => {
                //stringlog(data);
                return data;
            }),
            catchError((error) => {
                stringlog(error);
                return throwError("Something went wrong!");
            })
        );
    }

    DirectDBTest(): Observable<LoginResponse> {
        const fullurl = this.URL + "/api/test/directdb";

        return this.http.get<LoginResponse>(fullurl).pipe(
            map((data: LoginResponse) => {
                //stringlog(data);
                return data;
            }),
            catchError((error) => {
                stringlog(error);
                return throwError("Something went wrong!");
            })
        );
    }

    BackEndCallTest(): Observable<LoginResponse> {
        const fullurl = this.URL + "/api/test/backendcall";
        return this.http.get<LoginResponse>(fullurl).pipe(
            map((data: LoginResponse) => {
                //stringlog(data);
                return data;
            }),
            catchError((error) => {
                stringlog(error);
                return throwError("Something went wrong!");
            })
        );
    }

    //Fees Due API Service Calls

    RetrievingFeesDueList(
        Company_Id: any,
        User_Id: any,
        Login_Nm: any,
        FeeType_Cd: any
    ) {
        const fullurl =
            this.URL +
            "/api/Import/FeesDue/RetrievingFeesDueList/" +
            Company_Id +
            "/" +
            User_Id +
            "/" +
            Login_Nm +
            "/" +
            FeeType_Cd;
        return this.http.get<RetrievingFeesDueListType[]>(fullurl).pipe(
            map((data: RetrievingFeesDueListType[]) => {
                //stringlog(data);
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }

    //ImportFeeDue Payment details Service Call
    ImportFeeDuePaymentDetails(submitDetails: any) {
        const fullurl = this.URL + "/api/Import/FeesDue/ImportFeeDue";
        return this.http
            .post<ImportFeesDueDetailsType[]>(fullurl, submitDetails)
            .pipe(
                map((data: ImportFeesDueDetailsType[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //ImportFeeDue echeck Payment details Service Call
    echeckImportFeeDuePaymentDetails(submitDetails: any) {
        const fullurl = this.URL + "/api/Import/FeesDue/ImportFeeDue";
        return this.http
            .post<ImportFeesDueDetailsType[]>(fullurl, submitDetails)
            .pipe(
                map((data: ImportFeesDueDetailsType[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }
    //ImportFeeDue CreditBalance Payment details Service Call
    ImportFeeDueCreditBalancePaymentDetails(submitDetails: any) {
        const fullurl = this.URL + "/api/Import/FeesDue/ImportFeeDue";
        return this.http
            .post<ImportFeesDueDetailsType[]>(fullurl, submitDetails)
            .pipe(
                map((data: ImportFeesDueDetailsType[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    LoadControldata(
        PAYMENT_RECEIPT: any,
        Company_Id: any,
        User_Id: any,
        Login_Nm: any,
        lPaymentLogId: any,
        FeeTypeCTFTMFCode: any
    ) {
        const fullurl =
            this.URL +
            "/api/Import/FeesDue/LoadControldata/" +
            PAYMENT_RECEIPT +
            "/" +
            Company_Id +
            "/" +
            User_Id +
            "/" +
            Login_Nm +
            "/" +
            lPaymentLogId +
            "/" +
            FeeTypeCTFTMFCode;
        return this.http.get<LoadAfterpaymentdataType[]>(fullurl).pipe(
            map((data: LoadAfterpaymentdataType[]) => {
                //stringlog(data);
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }
    //Export FeesDue  Implementation
    RetrievingExportFeesDueList(
        Company_Id: any,
        User_Id: any,
        Login_Nm: any,
        FeeType_Cd: any
    ) {
        const fullurl =
            this.URL +
            "/api/Export/FeesDue/RetrievingExportFeesDueList/" +
            Company_Id +
            "/" +
            User_Id +
            "/" +
            Login_Nm +
            "/" +
            FeeType_Cd;
        return this.http.get<ExportRetrievingFeesduelistType[]>(fullurl).pipe(
            map((data: ExportRetrievingFeesduelistType[]) => {
                //stringlog(data);
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }
    //Export Creditcardonfile and credit card service call
    ExportFeeDuePaymentDetails(submitDetails: any) {
        const fullurl = this.URL + "/api/Export/FeesDue/ExportFeeDue";
        return this.http
            .post<ExportFeesDueDetailsType[]>(fullurl, submitDetails)
            .pipe(
                map((data: ExportFeesDueDetailsType[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //ExportFeeDue echeck Payment details Service Call
    echeckExportFeeDuePaymentDetails(submitDetails: any) {
        const fullurl = this.URL + "/api/Export/FeesDue/ExportFeeDue";
        return this.http
            .post<ExportFeesDueDetailsType[]>(fullurl, submitDetails)
            .pipe(
                map((data: ExportFeesDueDetailsType[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }
    //ExportFeeDue CreditBalance Payment details Service Call
    ExportFeeDueCreditBalancePaymentDetails(submitDetails: any) {
        const fullurl = this.URL + "/api/Export/FeesDue/ExportFeeDue";
        return this.http
            .post<ExportFeesDueDetailsType[]>(fullurl, submitDetails)
            .pipe(
                map((data: ExportFeesDueDetailsType[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    ExportLoadControldata(
        PAYMENT_RECEIPT: any,
        Company_Id: any,
        User_Id: any,
        Login_Nm: any,
        lPaymentLogId: any,
        FeeTypeCTFTMFCode: any
    ) {
        const fullurl =
            this.URL +
            "/api/Export/FeesDue/ExportLoadControldata/" +
            PAYMENT_RECEIPT +
            "/" +
            Company_Id +
            "/" +
            User_Id +
            "/" +
            Login_Nm +
            "/" +
            lPaymentLogId +
            "/" +
            FeeTypeCTFTMFCode;
        return this.http.get<ExportloadAfterpaymentdataType[]>(fullurl).pipe(
            map((data: ExportloadAfterpaymentdataType[]) => {
                //stringlog(data);
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }

    GetContainerStatus(containerStatusRequest: any) {
        const fullurl = this.URL + '/api/Import/ContainerStatus/GetContainerStatus';
        return this.http
            .post<ContainerStatusRequest[]>(fullurl, containerStatusRequest)
            .pipe(
                map((data: ContainerStatusRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError('Something went wrong!');
                })
            );
    }

    GetImportPendingList(importPendingListRequest: any) {
        const fullurl =
            this.URL + "/api/Import/ImportPendingList/GetImportPendingList";
        return this.http
            .post<ImportPendingListRequest[]>(fullurl, importPendingListRequest)
            .pipe(
                map((data: ImportPendingListRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    RemoveImportPendings(importPendingList: any) {
        const fullurl = this.URL + "/api/Import/ImportPendingList/PostImportPendingList";

        return this.http
            .post<ImportPendingFilterList[]>(fullurl, importPendingList)
            .pipe(
                map((data: ImportPendingFilterList[]) => {
                    return data;
                }),
                catchError((error) => {

                    return throwError("Something went wrong!");

                })
            );
    }

    GetExportPendingList(exportPendingListRequest: any) {
        const fullurl = this.URL + "/api/Export/ExportPendingList/GetExportPendingList";
        return this.http
            .post<ExportPendingListRequest[]>(fullurl, exportPendingListRequest)
            .pipe(
                map((data: ExportPendingListRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    RemoveExportPendings(exportPendingList: any) {
        const fullurl = this.URL + "/api/Export/ExportPendingList/PostExportPendingList";

        return this.http
            .post<ExportPendingFilterList[]>(fullurl, exportPendingList)
            .pipe(
                map((data: ExportPendingFilterList[]) => {
                    return data;
                }),
                catchError((error) => {

                    return throwError("Something went wrong!");

                })
            );
    }

    //Account Credit Service Calls
    AccountGetCompanyInfo(Company_Id: any, User_Id: any) {
        const fullurl = this.URL + '/api/Account/Credit/AccountGetCompanyInfo/' + Company_Id + '/' + User_Id;
        return this.http
            .get<AccountGetCompanyInfoType[]>(fullurl)
            .pipe(
                map((data: AccountGetCompanyInfoType[]) => {
                    //stringlog(data);
                    return data;
                }),
                catchError((error) => {
                    return throwError('Something went wrong!');
                })
            );
    }
    //Account Credit Details
    SubmittingAccountCreditDetails(submitDetails: any) {
        const fullurl = this.URL + '/api/Account/Credit/SubmittingAccountCreditDetails';
        return this.http
            .post<SubmittingAccountCreditType[]>(fullurl, submitDetails)
            .pipe(
                map((data: SubmittingAccountCreditType[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError('Something went wrong!');
                })
            );
    }


    //Export Booking Status

    RetrievingBookingStatus(exportBookingStatusRequest: any) {
        const fullurl = this.URL + "/api/Export/BookingStatus/RetrievingBookingStatus";
        return this.http
            .post<RetrievingBookingStatusType[]>(fullurl, exportBookingStatusRequest)
            .pipe(
                map((data: RetrievingBookingStatusType[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Get Notification Alerts
    GetAlertList(notificationMsgRequest: any) {
        const fullurl = this.URL + "/api/Account/AccountAlerts/GetAlertList";
        return this.http
            .post<NotificationMsgRequest[]>(fullurl, notificationMsgRequest)
            .pipe(
                map((data: NotificationMsgRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Get User Notifications
    GetUserNotification(notificationMsgRequest: any) {
        const fullurl = this.URL + "/api/Account/AccountAlerts/GetUserNotification";
        return this.http
            .post<NotificationMsgRequest[]>(fullurl, notificationMsgRequest)
            .pipe(
                map((data: NotificationMsgRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Insert User Notifications
    PostUserNotification(newSubscriberData: any) {
        const fullurl = this.URL + "/api/Account/AccountAlerts/PostUserNotification";
        return this.http
            .post<NewSubscriberData[]>(fullurl, newSubscriberData)
            .pipe(
                map((data: NewSubscriberData[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Remove User Notifications
    RemoveUserNotification(notificationMsgRequest: any) {
        const fullurl = this.URL + "/api/Account/AccountAlerts/RemoveUserNotification";
        return this.http
            .post<NotificationMsgRequest[]>(fullurl, notificationMsgRequest)
            .pipe(
                map((data: NotificationMsgRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Get Fee Type Details
    GetFeeType(shippingLineRequest: any) {
        const fullurl = this.URL + "/api/CSR/ShippingLineExemption/GetReferenceDataTable";
        return this.http
            .post<ShippingLineRequest[]>(fullurl, shippingLineRequest)
            .pipe(
                map((data: ShippingLineRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Get Fee Type Exempt Details
    GetFeeTypeExempt(shippingLineRequest: any) {
        const fullurl = this.URL + "/api/CSR/ShippingLineExemption/GetFeeTypeExempt";
        return this.http
            .post<ShippingLineRequest[]>(fullurl, shippingLineRequest)
            .pipe(
                map((data: ShippingLineRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Insert Import Exempt
    PostImportExempt(conExemptionRequest: any) {
        const fullurl = this.URL + "/api/CSR/ShippingLineExemption/PostConImportExemption";
        return this.http
            .post<ConExemptionRequest[]>(fullurl, conExemptionRequest)
            .pipe(
                map((data: ConExemptionRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Insert Export Exempt
    PostExportExempt(conExemptionRequest: any) {
        const fullurl = this.URL + "/api/CSR/ShippingLineExemption/PostConExportExemption";
        return this.http
            .post<ConExemptionRequest[]>(fullurl, conExemptionRequest)
            .pipe(
                map((data: ConExemptionRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Insert Import Exempt
    PostBookingExempt(conExemptionRequest: any) {
        const fullurl = this.URL + "/api/CSR/ShippingLineExemption/PostBookingExemption";
        return this.http
            .post<ConExemptionRequest[]>(fullurl, conExemptionRequest)
            .pipe(
                map((data: ConExemptionRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //CSR AddMove Service Call

    GetAddMoveData(Company_Id: any, User_Id: any) {
        const fullurl = this.URL + "/api/CSR/AddMove/GetAddMoveData/" + Company_Id + "/" + User_Id;

        return this.http.get<GetAddMoveDataType[]>(fullurl).pipe(
            map((data: GetAddMoveDataType[]) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Something went wrong!");
            })
        );
    }

    //Post Announcement Details
    PostAddMoveData(request: any) {
        const body = request;
        const fullurl = this.URL + "/api/CSR/AddMove/PostAddMoveData";
        return this.http.post<any>(fullurl, body, undefined).pipe();
    }

    //Post Announcement Finaldata Details
    PostAddMoveFinaldata(Announcement: any) {
        const fullurl = this.URL + "/api/CSR/AddMove/PostAddMoveFinaldata";
        const body = Announcement;
        var response: any;

        return this.http.post<PostaddmovedataType>(fullurl, body).pipe(
            map((data: PostaddmovedataType) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }

    //CSR EDI Summary Service call

    RetrievingEDISummarydetails(EdiSummary: any) {
        const fullurl = this.URL + "/api/CSR/EDISummary/RetrievingEDISummarydetails";
        const body = EdiSummary;
        var response: any;

        return this.http.post<RetrievingedisummaryType>(fullurl, body).pipe(
            map((data: RetrievingedisummaryType) => {
                return data;
            }),
            catchError((error) => {
                return throwError("Unable to connect to Dataservice: " + error);
            })
        );
    }

    //Get CSR Booking History
    PostCSRBookingHistorySearch(bookingHistoryRequest: any) {
        const fullurl = this.URL + "/api/CSR/BookingHistory/PostBookingHistorySearch";
        return this.http
            .post<BookingHistoryRequest[]>(fullurl, bookingHistoryRequest)
            .pipe(
                map((data: BookingHistoryRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Get CSR Booking Status 
    PostCSRBookingStatusUpdate(bookingHistoryRequest: any) {
        const fullurl = this.URL + "/api/CSR/BookingHistory/PostBookingStatusUpdate";
        return this.http
            .post<BookingHistoryRequest[]>(fullurl, bookingHistoryRequest)
            .pipe(
                map((data: BookingHistoryRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Get CSR Booking Resend Data 
    GetCSRBookingResendData(bookingHistoryRequest: any) {
        const fullurl = this.URL + "/api/CSR/BookingHistory/GetBookingResendData";
        return this.http
            .post<BookingHistoryRequest[]>(fullurl, bookingHistoryRequest)
            .pipe(
                map((data: BookingHistoryRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Get CSR Booking Status
    GetCSRBookingStatus(bookingHistoryRequest: any) {
        const fullurl = this.URL + "/api/CSR/BookingHistory/GetBookingStatus";
        return this.http
            .post<BookingHistoryRequest[]>(fullurl, bookingHistoryRequest)
            .pipe(
                map((data: BookingHistoryRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Get CSR Bulletins and WebContent
    GetCSRBulletinsWebContent(homePageManagementRequest: any) {
        const fullurl = this.URL + "/api/CSR/HomePageManagement/GetBulletinsAndWebContent";
        return this.http
            .post<HomePageManagementRequest[]>(fullurl, homePageManagementRequest)
            .pipe(
                map((data: HomePageManagementRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Post CSR WebContent
    PostWebContent(homePageManagementRequest: any) {
        const fullurl = this.URL + "/api/CSR/HomePageManagement/PostWebContent";
        return this.http
            .post<HomePageManagementRequest[]>(fullurl, homePageManagementRequest)
            .pipe(
                map((data: HomePageManagementRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }

    //Post CSR Bulletins 
    PostBulletins(homePageManagementRequest: any) {
        const fullurl = this.URL + "/api/CSR/HomePageManagement/PostBulletins";
        return this.http
            .post<HomePageManagementRequest[]>(fullurl, homePageManagementRequest)
            .pipe(
                map((data: HomePageManagementRequest[]) => {
                    return data;
                }),
                catchError((error) => {
                    return throwError("Something went wrong!");
                })
            );
    }
}
