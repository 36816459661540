<!-- <p>pp-home works!</p> -->
<div class="pp-term-info">
  <mat-tab-group>
    <mat-tab label="Appointment Systems">
      <app-pp-terminalinfo-appointment-systems></app-pp-terminalinfo-appointment-systems>
    </mat-tab>
    <mat-tab label="Terminal Contacts">
      <app-pp-terminalinfo-terminal-contacts></app-pp-terminalinfo-terminal-contacts>
    </mat-tab>
    <mat-tab label="Live Images">
      <div class="sub-container-liveimages">
        <table width="75%" class="HomePageText">
          <tbody>
            <tr>
              <td align="left" colspan="2">
                "Live Gate Images" has moved to WCMTOA.org. This link will remain
                on PierPass-tmf.org for a limited time. Please bookmark the new
                location!
              </td>
            </tr>
            <tr>
              <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
              <td colspan="2">
                &nbsp; Go to
                <a href="http://wcmtoa.org/live/" target="HelpPopout"
                  >Live Gate Images</a
                >
                on WCMTOA.org
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-tab>
  </mat-tab-group>
 
</div>