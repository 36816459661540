import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'src/app/common/services/translate.service';

@Component({
  selector: 'app-pp-terminalinfo-appointment-systems',
  templateUrl: './pp-terminalinfo-appointment-systems.component.html',
  styleUrls: ['./pp-terminalinfo-appointment-systems.component.scss']
})
export class PpTerminalinfoAppointmentSystemsComponent implements OnInit {

  constructor(public locale: TranslateService) { }

  ngOnInit() {
  }

}
