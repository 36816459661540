<app-loading-backdrop *ngIf="ShowLoader"></app-loading-backdrop>
<mat-toolbar>
  <small>{{locale.data.is_direct_pay}} </small>
  <span class="fill-remaining-space"></span>
  <button
    class="btn-dialog-close"
    mat-stroked-button
    (click)="onClose()"
    tabIndex="-1"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar>

<div>
  <mat-card
    class="add-funds"
    *ngIf="visible"
    style="font-size: 12px; color: green"
    >{{ Message }}</mat-card
  >
  <mat-card
    class="add-funds"
    *ngIf="Errvisible"
    style="font-size: 12px; color: red"
    >*{{ errorMessage }}</mat-card
  >

  <div class="wrapper" *ngIf="directPayment">
    <div class="payoptions">
      <mat-card class="add-funds">
        <!-- <app-user-message *ngIf="visible" [serMessage]="this.serversideErrorMessage"></app-user-message>  -->

        <form [formGroup]="myForm" class="my-form">
          <mat-card-content>
            <mat-card-title style="color: black; margin-left: 20%">
              {{locale.data.TotalDue}} <label>{{ TotalFeeDue | currency }}</label>
            </mat-card-title>
          </mat-card-content>

          <mat-card class="middleContent">
            <mat-card-header style="margin-left: 20%; font-size: 12px">
              <mat-card-title style="font-size: 12px; color: black"
                >{{ locale.data.AddFunds_PaymentType }}</mat-card-title
              >
              <mat-radio-group
                aria-label="Select an option"
                formControlName="PayType"
                [(ngModel)]="COFCheck"
              >
                <mat-radio-button
                  class="example-radio-button"
                  *ngFor="let pType of paymentTypes"
                  [value]="pType"
                  [checked]="pType === 'COFCheck'"
                  (change)="PaymentChange($event)"
                >
                  {{ pType }}
                </mat-radio-button>
              </mat-radio-group>
            </mat-card-header>
          </mat-card>
          <!-- Based on payment type need to handle the Logic -->
          <div>
            <mat-card class="Content">
              <mat-card-content>
                <ng-container [ngSwitch]="getPayMentType()">
                  <div *ngSwitchCase="'Credit Card On File'">
                    <mat-card-title style="font-size: 12px; color: black"
                      >{{ locale.data.ImprtFeesdue_CreditCardInformation }}</mat-card-title
                    >

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_CreditCardType }} </mat-label
                      >&nbsp;

                      <mat-radio-group
                        aria-label="Select an option"
                        style="
                          font-size: 12px;
                          text-align: left;
                          display: inline-block;
                        "
                        formControlName="CreditCardType"
                        [(ngModel)]="CreditCardTypeCheck"
                      >
                        <mat-radio-button
                          value="rdoVisa"
                          [checked]="rdoVisa === 'CreditCardTypeCheck'"
                          ><img
                            src="../assets/images/Visa.gif"
                            style="vertical-align: middle"
                          />
                        </mat-radio-button>
                        <mat-radio-button
                          value="rdoMC"
                          [checked]="rdoMC === 'CreditCardTypeCheck'"
                          ><img
                            src="../assets/images/MasterCard.gif"
                            style="vertical-align: middle"
                        /></mat-radio-button>
                        <mat-radio-button
                          value="rdoAmex"
                          [checked]="rdoAmex === 'CreditCardTypeCheck'"
                          ><img
                            src="../assets/images/AmEx.gif"
                            style="vertical-align: middle"
                        /></mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <br />

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_CreditCardNumber }}
                      </mat-label>
                      &nbsp;
                      <input
                        matInput
                        style="
                          font-size: 12px;
                          width: 30%;
                          height: 20px;
                          text-align: left;
                          display: inline-block;
                        "
                        placeholder="Credit Card Number"
                        name="CreditCardNumber"
                        formControlName="CreditCardNumber"
                        required
                      />
                    </div>
                    <br />

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_CreditCardHolderName }}
                      </mat-label>
                      &nbsp;
                      <input
                        matInput
                        style="
                          font-size: 12px;
                          width: 30%;
                          height: 20px;
                          text-align: left;
                          display: inline-block;
                        "
                        placeholder="Credit Card Holder Name"
                        name="CreditCardHolderName"
                        formControlName="CreditCardHolderName"
                        required
                      />
                    </div>

                    <div>
                      <br />
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_Expiration }}</mat-label
                      >

                      <mat-select
                        class="selectMonth"
                        placeholder="Month"
                        formControlName="selectedMonth"
                        name="selectedMonth"
                        style="margin-left: 1%; padding-top: 5px"
                      >
                        <mat-option
                          *ngFor="let month of Months"
                          [value]="month.Id"
                        >
                          {{ month.Id }} - {{ month.Name }}
                        </mat-option>
                      </mat-select>
                      &nbsp;
                      <mat-select
                        class="selectYear"
                        placeholder="Year"
                        formControlName="Year"
                        style="padding-top: 5px"
                      >
                        <mat-option *ngFor="let year of Years" [value]="year">
                          {{ year }}
                        </mat-option>
                      </mat-select>
                    </div>
                    <br />
                    <mat-card-title style="font-size: 12px; color: black"
                      >{{ locale.data.ImprtFeesdue_BillingAddress }}</mat-card-title
                    >

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_AddressLine1 }}
                      </mat-label>
                      &nbsp;
                      <input
                        matInput
                        style="
                          font-size: 12px;
                          width: 30%;
                          height: 20px;
                          text-align: left;
                          display: inline-block;
                        "
                        placeholder="Address Line 1"
                        name="AddressLine1"
                        formControlName="AddressLine1"
                        required
                      />
                    </div>
                    <br />

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_AddressLine2 }}
                      </mat-label>
                      &nbsp;
                      <input
                        matInput
                        style="
                          font-size: 12px;
                          width: 30%;
                          height: 20px;
                          text-align: left;
                          display: inline-block;
                        "
                        placeholder="Address Line 2"
                        name="AddressLine2"
                        formControlName="AddressLine2"
                        required
                      />
                    </div>
                    <br />

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_City }}
                      </mat-label>
                      &nbsp;
                      <input
                        matInput
                        style="
                          font-size: 12px;
                          width: 30%;
                          height: 20px;
                          text-align: left;
                          display: inline-block;
                        "
                        placeholder="City"
                        name="City"
                        formControlName="City"
                        required
                      />
                    </div>
                    <br />

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_State }}
                      </mat-label>
                      &nbsp;
                      <mat-select
                        class="ControlSelect"
                        formControlName="StateCd"
                        placeholder="State"
                        style="padding-top: 5px"
                        name="StateCd"
                      >
                        <mat-option
                          *ngFor="let state of States1"
                          [value]="state.State_Cd"
                          style="padding-top: 5px"
                        >
                          {{ state.State_Cd }}
                        </mat-option>
                      </mat-select>
                    </div>
                    <br />

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_ZipCode }}
                      </mat-label>
                      &nbsp;
                      <input
                        matInput
                        style="
                          font-size: 12px;
                          width: 30%;
                          height: 20px;
                          text-align: left;
                          display: inline-block;
                        "
                        placeholder="Zip Code"
                        name="ZipCode"
                        formControlName="ZipCode"
                        required
                      />
                    </div>
                    <br />
                  </div>

                  <div *ngSwitchCase="'Credit Card'">
                    <mat-card-title style="font-size: 12px; color: black"
                      >{{ locale.data.ImprtFeesdue_CreditCardInformation }} </mat-card-title
                    >

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_CreditCardType }} </mat-label
                      >&nbsp;
                      <mat-radio-group
                        aria-labelledby="example-radio-group-label"
                        formControlName="CCreditCardType"
                        style="
                          font-size: 12px;
                          text-align: left;
                          display: inline-block;
                        "
                        [(ngModel)]="CCreditCardType"
                      >
                        <mat-radio-button
                          value="rdoVisa"
                          (change)="radioChange($event)"
                          ><img
                            src="../assets/images/Visa.gif"
                            style="vertical-align: middle"
                          />
                        </mat-radio-button>
                        <mat-radio-button
                          value="rdoMC"
                          (change)="radioChange($event)"
                          ><img
                            src="../assets/images/MasterCard.gif"
                            style="vertical-align: middle"
                        /></mat-radio-button>
                        <mat-radio-button
                          value="rdoAmex"
                          (change)="radioChange($event)"
                          ><img
                            src="../assets/images/AmEx.gif"
                            style="vertical-align: middle"
                        /></mat-radio-button>
                      </mat-radio-group>

                      <mat-error
                        *ngIf="
                          myForm.controls['CCreditCardType'].errors &&
                          (myForm.controls['CCreditCardType'].dirty ||
                            myForm.controls['CCreditCardType'].touched)
                        "
                        style="font-size: 12px"
                        >&nbsp;&nbsp;&nbsp;&nbsp; *{{ locale.data.ImprtFeesdue_CreditCardTypeisRequired }}</mat-error
                      >
                    </div>
                    <br />

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_CreditCardNumber }}
                      </mat-label>
                      &nbsp;
                      <input
                        matInput
                        formControlName="CCreditCardNumber"
                        class="CCreditCardNumber"
                        style="
                          font-size: 12px;
                          width: 30%;
                          height: 20px;
                          text-align: left;
                          display: inline-block;
                        "
                        placeholder="Credit Card Number"
                        name="CCreditCardNumber"
                        [formControl]="getCardNumberControl()"
                        [textMask]="{
                          mask: cardMaskFunction,
                          guide: false,
                          showMask: true
                        }"
                        required
                      />

                      <div *ngIf="getCardNumberControl().dirty">
                        <p [class.invalid]="getCardNumberControl().invalid"></p>
                      </div>
                    </div>
                    <br />

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_CreditCardHolderName }}
                      </mat-label>
                      &nbsp;
                      <input
                        matInput
                        style="
                          font-size: 12px;
                          width: 30%;
                          height: 20px;
                          text-align: left;
                          display: inline-block;
                        "
                        placeholder="Credit Card Holder Name"
                        name="CCreditCardHolderName"
                        formControlName="CCreditCardHolderName"
                        required
                      />

                      <mat-error
                        *ngIf="
                          myForm.controls['CCreditCardHolderName'].errors &&
                          (myForm.controls['CCreditCardHolderName'].dirty ||
                            myForm.controls['CCreditCardHolderName'].touched)
                        "
                        style="font-size: 12px"
                      >
                        *{{ locale.data.ImprtFeesdue_CreditCardHolderNameisRequired }}</mat-error
                      >
                    </div>

                    <div>
                      <br />
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_Expiration }}</mat-label
                      >

                      <mat-select
                        class="selectMonth"
                        style="margin-left: 1%; padding-top: 5px"
                        formControlName="CMonth"
                        placeholder="Month"
                      >
                        <mat-option
                          *ngFor="let month of Months"
                          [value]="month.Id"
                        >
                          {{ month.Id }} - {{ month.Name }}
                        </mat-option>
                      </mat-select>
                      &nbsp;
                      <mat-select
                        class="selectYear"
                        formControlName="CYear"
                        placeholder="Year"
                        style="padding-top: 5px"
                      >
                        <mat-option *ngFor="let year of Years" [value]="year">
                          {{ year }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          myForm.controls['CMonth'].errors &&
                          (myForm.controls['CMonth'].dirty ||
                            myForm.controls['CMonth'].touched)
                        "
                        style="font-size: 12px"
                      >
                        *{{ locale.data.ImprtFeesdue_MonthisRequired }}</mat-error
                      >

                      <mat-error
                        *ngIf="
                          myForm.controls['CYear'].errors &&
                          (myForm.controls['CYear'].dirty ||
                            myForm.controls['CYear'].touched)
                        "
                        style="font-size: 12px"
                      >
                        *{{ locale.data.ImprtFeesdue_YearisRequired }}</mat-error
                      >
                    </div>
                    <br />
                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_VerificationCode }}:
                      </mat-label>
                      &nbsp;
                      <input
                        matInput
                        maxlength="4"
                        formControlName="CVerificationCode"
                        style="
                          font-size: 12px;
                          width: 10%;
                          height: 20px;
                          text-align: left;
                          display: inline-block;
                        "
                        placeholder="VerificationCode"
                        name="CVerificationCode"
                        (keypress)="keyPressNumbers($event)"
                        required
                      />

                      <mat-error
                        *ngIf="
                          myForm.controls['CVerificationCode'].errors &&
                          (myForm.controls['CVerificationCode'].dirty ||
                            myForm.controls['CVerificationCode'].touched)
                        "
                        style="font-size: 12px"
                      >
                        *{{ locale.data.ImprtFeesdue_VerificationCodeisRequired }}</mat-error
                      >
                    </div>

                    <br />
                    <mat-card-title style="font-size: 12px; color: black"
                      >{{ locale.data.ImprtFeesdue_BillingAddress }}</mat-card-title
                    >

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_AddressLine1 }}
                      </mat-label>
                      &nbsp;
                      <input
                        matInput
                        formControlName="CAddressLine1"
                        style="
                          font-size: 12px;
                          width: 30%;
                          height: 20px;
                          text-align: left;
                          display: inline-block;
                        "
                        placeholder="Address Line 1"
                        name="CAddressLine1"
                        required
                      />

                      <mat-error
                        *ngIf="
                          myForm.controls['CAddressLine1'].errors &&
                          (myForm.controls['CAddressLine1'].dirty ||
                            myForm.controls['CAddressLine1'].touched)
                        "
                        style="font-size: 12px"
                      >
                        *{{ locale.data.ImprtFeesdue_Adress1isRequired }}</mat-error
                      >
                    </div>
                    <br />

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_AddressLine2 }}
                      </mat-label>
                      &nbsp;
                      <input
                        matInput
                        formControlName="CAddressLine2"
                        style="
                          font-size: 12px;
                          width: 30%;
                          height: 20px;
                          text-align: left;
                          display: inline-block;
                        "
                        placeholder="Address Line 2"
                        name="CAddressLine2"
                        required
                      />
                    </div>
                    <br />

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_City }}
                      </mat-label>
                      &nbsp;
                      <input
                        matInput
                        formControlName="CCity"
                        style="
                          font-size: 12px;
                          width: 30%;
                          height: 20px;
                          text-align: left;
                          display: inline-block;
                        "
                        placeholder="City"
                        name="CCity"
                        required
                      />

                      <mat-error
                        *ngIf="
                          myForm.controls['CCity'].errors &&
                          (myForm.controls['CCity'].dirty ||
                            myForm.controls['CCity'].touched)
                        "
                        style="font-size: 12px"
                      >
                        *{{ locale.data.ImprtFeesdue_CityisRequired }}</mat-error
                      >
                    </div>
                    <br />

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%">{{ locale.data.Country }}:
                      </mat-label>
                      &nbsp;
                      <mat-select formControlName="CCountry" placeholder="Country" class="ControlSelect" style="padding-top: 5px; " 
                          name="CCountry" (selectionChange)="onCountryChange($event.value)">
                            <mat-option *ngFor="let c of Countrys" [value]="c.Country_Cd"> {{ c.Country_Nm }} </mat-option>
                          </mat-select>
                     
              
                      <mat-error *ngIf="myForm.controls['CCountry'].errors &&
                      (myForm.controls['CCountry'].dirty || myForm.controls['CCountry'].touched)" style="font-size: 12px;"> *Country is Required</mat-error>
    
                    </div>
                    <br />


                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_State }}
                      </mat-label>
                      &nbsp;

                      <mat-select
                        formControlName="CState"
                        name="CState"
                        class="ControlSelect"
                        placeholder="State"
                        style="padding-top: 5px"
                      >
                        <mat-option
                          *ngFor="let state of States"
                          [value]="state.State_Cd"
                        >
                          {{ state.State_Cd }}
                        </mat-option>
                      </mat-select>

                      <mat-error
                        *ngIf="
                          myForm.controls['CState'].errors &&
                          (myForm.controls['CState'].dirty ||
                            myForm.controls['CState'].touched)
                        "
                        style="font-size: 12px"
                      >
                        *{{ locale.data.ImprtFeesdue_StateCodeisRequired }}</mat-error
                      >
                    </div>
                    <br />

                    <div>
                      <mat-label style="font-size: 12px; margin-left: 20%"
                        >{{ locale.data.ImprtFeesdue_ZipCode }}
                      </mat-label>
                      &nbsp;
                      <input
                        matInput
                        maxlength="10"
                        formControlName="CZipCode"
                        style="
                          font-size: 12px;
                          width: 30%;
                          height: 20px;
                          text-align: left;
                          display: inline-block;
                        "
                        placeholder="Zip Code"
                        name="CZipCode"
                        (keypress)="keyPressNumbers($event)"
                        required
                      />

                      <mat-error
                        *ngIf="
                          myForm.controls['CZipCode'].errors &&
                          (myForm.controls['CZipCode'].dirty ||
                            myForm.controls['CZipCode'].touched)
                        "
                        style="font-size: 12px"
                      >
                        *{{ locale.data.ImprtFeesdue_ZipCodeisRequired }}</mat-error
                      >
                    </div>
                    <br />

                    <mat-checkbox
                      class="example-margin"
                      formControlName="CCheckFuture"
                      style="font-size: 12px; margin-left: 35%"
                      (change)="CheckSaveEvent($event)"
                    >
                    {{ locale.data.ImprtFeesdue_Savethiscreditcardforfutureuse }}
                    </mat-checkbox>
                  </div>

                  <!-- E-check Code  -->

                  <div *ngSwitchCase="'eCheck'">
                    <br />
                    <mat-card-title style="height: 35px;"
                      ><img
                        src="../assets/images/echeck_official_logo.gif"
                        alt="TeleCheck Logo"
                    /></mat-card-title>
                    <div
                      style="
                        background-image: url('../assets/images/ECheck_Bg.bmp');
                        width: 120%;
                        height: 120%;
                        background-repeat: no-repeat;
                        background-position: center;
                        display: flex;
                        flex-direction: column;
                      "
                    >
                      <div style="padding-top: 10px">
                        <div class="tables-parent">
                          <div class="tableone">
                            <!-- First Table -->
                            <table class="pay-table">
                              <tr>
                                <td align="right" class="account_label1">
                                  <mat-label>{{ locale.data.ImprtFeesdue_Name }} </mat-label>
                                </td>
                                <td align="left" class="account_data1">
                                  <input
                                    matInput
                                    style="
                                      text-align: left;
                                      display: inline-block;
                                    "
                                    placeholder="Name"
                                    formControlName="eName"
                                    name="eName"
                                    required
                                  />
                                </td>
                                <td>
                                  <mat-error
                                    *ngIf="
                                      myForm.controls['eName'].errors &&
                                      (myForm.controls['eName'].dirty ||
                                        myForm.controls['eName'].touched)
                                    "
                                    style="font-size: 12px; display: inline"
                                  >
                                    *</mat-error
                                  >
                                </td>
                              </tr>
                              <tr style="vertical-align: top">
                                <td align="right" class="account_label1">
                                  <mat-label>{{ locale.data.ImprtFeesdue_Phone }} </mat-label>
                                </td>
                                <td align="left" class="account_data1">
                                  <input
                                    matInput
                                    minlength="10"
                                    maxlength="10"
                                    (keypress)="keyPressNumbers($event)"
                                    style="text-align: left; display: inline"
                                    placeholder="Phone"
                                    formControlName="ePhone"
                                    name="ePhone"
                                    required
                                  />
                                </td>
                                <td>
                                  <mat-error
                                    *ngIf="
                                      myForm.controls['ePhone'].errors &&
                                      (myForm.controls['ePhone'].dirty ||
                                        myForm.controls['ePhone'].touched)
                                    "
                                    style="
                                      font-size: 12px;
                                      display: inline-block;
                                    "
                                  >
                                    *</mat-error
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td align="right" class="account_label1">
                                  <mat-label>{{ locale.data.ImprtFeesdue_Email }} </mat-label>
                                </td>
                                <td align="left" class="account_data1">
                                  <input
                                    type="email"
                                    matInput
                                    style="
                                      text-align: left;
                                      display: inline-block;
                                    "
                                    placeholder="Email"
                                    formControlName="eEmail"
                                    name="eEmail"
                                    required
                                  />
                                </td>
                                <td>
                                  <mat-error
                                    *ngIf="
                                      myForm.controls['eEmail'].errors &&
                                      (myForm.controls['eEmail'].dirty ||
                                        myForm.controls['eEmail'].touched)
                                    "
                                    style="font-size: 12px"
                                  >
                                    *</mat-error
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td align="right" class="account_label1">
                                  <mat-label>{{ locale.data.ImprtFeesdue_Address }} </mat-label>
                                </td>
                                <td align="left" class="account_data1">
                                  <input
                                    matInput
                                    style="
                                      text-align: left;
                                      display: inline-block;
                                    "
                                    placeholder="Address"
                                    formControlName="eAddress"
                                    name="eAddress"
                                    required
                                  />
                                </td>
                                <td>
                                  <mat-error
                                    *ngIf="
                                      myForm.controls['eAddress'].errors &&
                                      (myForm.controls['eAddress'].dirty ||
                                        myForm.controls['eAddress'].touched)
                                    "
                                    style="font-size: 12px"
                                  >
                                    *</mat-error
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td align="right" class="account_label1">
                                  <mat-label>{{ locale.data.ImprtFeesdue_City }} </mat-label>
                                </td>
                                <td align="left" class="account_data1">
                                  <input
                                    matInput
                                    style="
                                      text-align: left;
                                      display: inline-block;
                                    "
                                    placeholder="City"
                                    formControlName="eCity"
                                    name="eCity"
                                    required
                                  />
                                </td>
                                <td>
                                  <mat-error
                                    *ngIf="
                                      myForm.controls['eCity'].errors &&
                                      (myForm.controls['eCity'].dirty ||
                                        myForm.controls['eCity'].touched)
                                    "
                                    style="font-size: 12px"
                                  >
                                    *</mat-error
                                  >
                                </td>
                              </tr>

                              <tr>
                                <td align="right" class="account_label1">
                                  <mat-label>{{ locale.data.Country }} </mat-label>
                                </td>
                                <td align="left" class="account_data1">
                                  <mat-select
                                    class="echeckstate"
                                    style="width: 95%"
                                    placeholder="--Country--"
                                    formControlName="eCountry"
                                    name="eCountry" (selectionChange)="oneCountryChange($event.value)"
                                  >
                                    <mat-option
                                      *ngFor="let c of Countrys"
                                      [value]="c.Country_Cd"
                                    >
                                    {{ c.Country_Nm }}
                                    </mat-option>
                                  </mat-select>
                                </td>
                                <td>
                                  <mat-error
                                    *ngIf="
                                      myForm.controls['eCountry'].errors &&
                                      (myForm.controls['eCountry'].dirty ||
                                        myForm.controls['eCountry'].touched)
                                    "
                                    style="font-size: 12px"
                                  >
                                    *</mat-error
                                  >
                                </td>
                              </tr>


                              <tr>
                                <td align="right" class="account_label1">
                                  <mat-label>{{ locale.data.ImprtFeesdue_State }} </mat-label>
                                </td>
                                <td align="left" class="account_data1">
                                  <mat-select
                                    class="echeckstate"
                                    style="width: 95%"
                                    placeholder="--State--"
                                    formControlName="eStateCd"
                                    name="eStateCd"
                                  >
                                    <mat-option
                                      *ngFor="let state of States"
                                      [value]="state.State_Cd"
                                    >
                                      {{ state.State_Cd }}
                                    </mat-option>
                                  </mat-select>
                                </td>
                                <td>
                                  <mat-error
                                    *ngIf="
                                      myForm.controls['eStateCd'].errors &&
                                      (myForm.controls['eStateCd'].dirty ||
                                        myForm.controls['eStateCd'].touched)
                                    "
                                    style="font-size: 12px"
                                  >
                                    *</mat-error
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td align="right" class="account_label1">
                                  <mat-label>{{ locale.data.ImprtFeesdue_ZipCode }} </mat-label>
                                </td>
                                <td align="left" class="account_data1">
                                  <input
                                    matInput
                                    (keypress)="keyPressNumbers($event)"
                                    maxlength="10"
                                    style="
                                      text-align: left;
                                      display: inline-block;
                                    "
                                    placeholder="Zip Code"
                                    formControlName="eZipCode"
                                    name="eZipCode"
                                    required
                                  />
                                </td>
                                <td>
                                  <mat-error
                                    *ngIf="
                                      myForm.controls['eZipCode'].errors &&
                                      (myForm.controls['eZipCode'].dirty ||
                                        myForm.controls['eZipCode'].touched)
                                    "
                                    style="font-size: 12px"
                                  >
                                    *</mat-error
                                  >
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div class="tabletwo">
                            <!-- Second Table -->
                            <table class="pay-table2">
                              <tr>
                                <td align="right" class="account_label1">
                                  <mat-label>{{ locale.data.ImprtFeesdue_Check }} </mat-label>
                                </td>
                                <td align="left" class="account_data1">
                                  <input
                                    matInput
                                    style="
                                      text-align: left;
                                      display: inline-block;
                                    "
                                    placeholder="Check"
                                    formControlName="eCheck"
                                    name="eCheck"
                                    required
                                  />
                                </td>
                                <td>
                                  <mat-error
                                    *ngIf="
                                      myForm.controls['eCheck'].errors &&
                                      (myForm.controls['eCheck'].dirty ||
                                        myForm.controls['eCheck'].touched)
                                    "
                                    style="font-size: 12px"
                                  >
                                    *</mat-error
                                  >
                                </td>
                              </tr>
                              <tr style="vertical-align: top">
                                <td align="right" class="account_label1"></td>
                                <td align="left" class="account_data1">
                                  <mat-label>
                                    {{ today | date: "MMM d, y" }}</mat-label
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td align="right" class="account_label1"></td>
                                <td align="left" class="account_data1"></td>
                              </tr>
                              <tr>
                                <td align="right" class="account_label1"></td>
                                <td align="left" class="account_data1"></td>
                              </tr>
                              <tr></tr>
                              <tr></tr>
                              <tr></tr>
                              <tr>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          style="
                            width: 31vw;
                            display: flex;
                            flex-direction: row;
                            margin-left: 17vw;
                          "
                        >
                          <label style="font-size: 8px"
                            >{{ locale.data.PAYTOTHEORDEROF }}</label
                          >
                          <mat-label style="font-size: 9px"
                            ><b>{{ locale.data.Pier }}<i>{{ locale.data.PASS }}</i></b></mat-label
                          >
                          <mat-label style="font-size: 9px"
                            >{{ locale.data.Amt }} {{ TotalFeeDue | currency }}</mat-label
                          >
                        </div>
                      </div>
                      <div class="tables-parent">
                        <div class="tableone">
                          <!-- First Table -->
                          <table class="pay-table">
                            <tr>
                              <td align="right" class="account_label1">
                                <mat-label>{{ locale.data.BankRoutingNumber }}</mat-label>
                              </td>
                              <td align="left" class="account_data1">
                                <input
                                  matInput
                                  style="
                                    text-align: left;
                                    display: inline-block;
                                  "
                                  placeholder="Bank Routing Number"
                                  formControlName="eBankRoutingNumber"
                                  name="eBankRoutingNumber"
                                  required
                                />
                              </td>
                              <td>
                                <mat-error
                                  *ngIf="
                                    myForm.controls['eBankRoutingNumber']
                                      .errors &&
                                    (myForm.controls['eBankRoutingNumber']
                                      .dirty ||
                                      myForm.controls['eBankRoutingNumber']
                                        .touched)
                                  "
                                  style="font-size: 12px"
                                >
                                  *</mat-error
                                >
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div class="tabletwo">
                          <!-- Second Table -->
                          <table class="pay-table2">
                            <tr>
                              <td align="right" class="account_label1">
                                <mat-label
                                  >{{ locale.data.BankAccountNumber }}
                                </mat-label>
                              </td>
                              <td align="left" class="account_data1">
                                <input matInput style="
                        text-align: left;
                        display: inline-block;
                      " placeholder="Bank Account Number" formControlName="eBankAcctNumber" name="eBankAcctNumber" required />
                              </td>
                              <td>
<mat-error *ngIf="myForm.controls['eBankAcctNumber'].errors &&
                  (myForm.controls['eBankAcctNumber'].dirty ||
                  myForm.controls['eBankAcctNumber'].touched)" style="font-size: 12px;" >*</mat-error>
</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div>
                        <div>
                          <mat-radio-group
                            aria-label="Select an option"
                            formControlName="PCType"
                            style="
                              text-align: left;
                              display: inline-block;
                              margin-left: 35%;
                              padding-bottom: 1%;
                            "
                            [(ngModel)]="PCheck"
                          >
                            <mat-radio-button
                              class="example-radio-button"
                              *ngFor="let pcType of PersonalCompanyType"
                              [value]="pcType"
                              [checked]="pcType === 'PCheck'"
                            >
                              {{ pcType }}
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                        <ng-container [ngSwitch]="getPCType()">
                          <div *ngSwitchCase="'Personal'">
                            <div class="tables-parent">
                              <div class="tableone">
                                <!-- First Table -->
                                <table class="pay-table">
                                  <tr>
                                    <td align="right" class="account_label1">
                                      <mat-label>
                                        {{ locale.data.DriverLicSateID }}</mat-label
                                      >
                                    </td>
                                    <td align="left" class="account_data1">
                                      <mat-select
                                        class="echeckstateid"
                                        placeholder="-----State-----"
                                        formControlName="PDLSStateCd"
                                        name="PDLSStateCd"
                                        style="width: 120px"
                                      >
                                        <mat-option
                                          *ngFor="let state of States1"
                                          [value]="state.State_Cd"
                                        >
                                          {{ state.State_Cd }}
                                        </mat-option>
                                      </mat-select>
                                    </td>
                                    <td></td>
                                  </tr>
                                </table>
                              </div>
                              <div class="tabletwo">
                                <!-- Second Table -->
                                <table class="pay-table2">
                                  <tr>
                                    <td align="right" class="account_label1">
                                      <mat-label
                                        >{{ locale.data.DriverLicSateIDhash }}
                                      </mat-label>
                                    </td>
                                    <td align="left" class="account_data1">
                                      <input
                                        matInput
                                        style="
                                          text-align: left;
                                          display: inline-block;
                                          width: 135px;
                                        "
                                        placeholder="Driver Lic/Sate ID"
                                        formControlName="eDLStateId"
                                        name="eDLStateId"
                                        required
                                      />
                                    </td>
                                    <td></td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div *ngSwitchCase="'Company'">
                            <div class="tables-parent">
                              <div class="tableone">
                                <table class="pay-table">
                                  <tr>
                                    <td align="right" class="account_label1">
                                      <mat-label> {{ locale.data.AddFunds_CompanyName }}</mat-label>
                                    </td>
                                    <td align="left" class="account_data1">
                                      <input
                                        matInput
                                        style="
                                          text-align: left;
                                          display: inline-block;
                                        "
                                        placeholder="Company Name"
                                        formControlName="eCompanyName"
                                        name="eCompanyName"
                                        required
                                      />
                                    </td>
                                    <td></td>
                                  </tr>
                                </table>
                              </div>
                              <div class="tabletwo">
                                <!-- Second Table -->
                                <table class="pay-table2">
                                  <tr>
                                    <td align="right" class="account_label1">
                                      <mat-label> {{ locale.data.CompanyTaxID }}</mat-label>
                                    </td>
                                    <td align="left" class="account_data1">
                                      <input
                                        matInput
                                        style="
                                          text-align: left;
                                          display: inline-block;
                                        "
                                        placeholder="Company Tax ID"
                                        formControlName="eCompanyTaxId"
                                        name="eCompanyTaxId"
                                        required
                                      />
                                    </td>
                                    <td></td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <br />
                    </div>
                    <p
                      style="
                        text-align: justify;
                        font-size: smaller;
                        font-family: Verdana;
                      "
                    >
                      * {{ locale.data.AddFunds_p }}<br />{{ locale.data.AddFunds_p1 }} 
                      <a
                        target="_blank"
                        href="http://www.telecheck.com/CheckFormFAQ/CheckFormFAQ.html"
                        >{{ locale.data.AddFunds_p2 }}</a
                      ><br />
                      <br />{{ locale.data.AddFunds_p3 }} <b>{{ locale.data.AddFunds_p4 }}</b> {{ locale.data.AddFunds_p5 }}
                      <br />
                      <br />
                      <b>{{ locale.data.AddFunds_p6 }}&nbsp;</b> {{ locale.data.AddFunds_p7 }}<a target="_blank" href="http://www.telecheck.com/ica/internetcheckfaq.html">{{ locale.data.AddFunds_p8 }}</a> 
                        {{ locale.data.AddFunds_p9 }} <a target="_blank" href="http://www.telecheck.com/ica/privacy_policy.html">{{ locale.data.AddFunds_p10 }}</a>.                                
                      </p>
                  </div>
                  <div *ngSwitchCase="'Credit Balance'" class="Content">
                    <mat-card-title style="font-size: 12px; color: black"
                      >{{ locale.data.CreditBalanceInformation }}</mat-card-title
                    >
                    <label style="font-size: 12px; margin-left: 10%"
                      >{{ locale.data.CreditBalance }} {{ AccountBalance | currency }}</label
                    >
                  </div>
                </ng-container>
              </mat-card-content>
            </mat-card>
          </div>

          <p style="font-size: 10px; text-align: right; padding: 10px">
            {{ locale.data.NoteMoney }}
          </p>
          <div style="display: flex; flex-direction: row">
            <img
              style="margin-right: 59%; width: 13%; height: 100%"
              src="../assets/images/evrecgreen.png"
              alt="TeleCheck Logo"
            />
            <span class="fill-remaining-space"></span>
            <button
              mat-raised-button
              style="
                color: #757575;
                background-color: white;
                margin-right: 10px;
              "
              (click)="backClicked()"
            >
            {{ locale.data.cancel }}
            </button>
            <button
              mat-raised-button
              style="margin-right: 10px; width: 200px; padding-right: 10px"
              [disabled]="isSubmitButton"
              (click)="SubmitPaymentDetails()"
            >
            {{ locale.data.submit }}
            </button>
          </div>
        </form>
      </mat-card>
    </div>
    <div class="containerlist">
      <mat-card class="add-funds">
        <div class="example-container">
          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>
              <!-- Position Column -->
              <ng-container matColumnDef="ContainerNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ locale.data.ImprtFeesdue_Container }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                    element.ConPrefix_Cd + element.Con_Num + element.ConChkDigit
                  }}
                </td>
                <td mat-footer-cell *matFooterCellDef style="font-weight: bold">
                  {{ locale.data.Export_Total }}
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="FeeAmt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ locale.data.ImprtFeesdue_Fee }}</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.FeeAmt | currency }}
                </td>
                <td mat-footer-cell *matFooterCellDef style="font-weight: bold">
                  {{ getTotalCost() | currency }}
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr
                mat-footer-row
                *matFooterRowDef="displayedColumns; sticky: true"
              ></tr>
            </table>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <!-- payment receipt implementation -->
  <div *ngIf="paymentReceipt">
    <mat-card class="add-funds">
      <mat-card-header>
        <mat-card-title style="font-size: 14px; color: black"
          >{{ locale.data.ImprtFeesdue_PaymentReceipt }}</mat-card-title
        >

        <div style="margin-left: 78%">
          <mat-icon mat-icon-button (click)="downloadPdf()" matTooltip="PDF">
            <img
              src="../assets/images/pdfmeduim.png"
              style="
                vertical-align: middle;
                height: 28px;
                width: 30px;
                cursor: pointer;
              "
          /></mat-icon>
          &nbsp;
          <mat-icon (click)="exportTable()" matTooltip="EXCEL">
            <img
              src="../assets/images/excel.png"
              style="vertical-align: middle; height: 23px; cursor: pointer"
            />
          </mat-icon>
        </div>
      </mat-card-header>

      <mat-card class="add-funds">
        <mat-card-content>
          <div>
            <mat-label style="font-size: 12px; margin-left: 20%"
              >{{ locale.data.ImprtFeesdue_AccountNumber }}
            </mat-label>
            <label style="font-size: 12px; text-align: left">
              {{ AccountNumber }}</label
            >

            <mat-label style="font-size: 12px; margin-left: 30%"
              >{{ locale.data.ImprtFeesdue_OrderTotal }} </mat-label
            ><label style="font-size: 12px; text-align: left">
              {{ getTotalPayment() | currency }}</label
            >
          </div>
          <br />
          <div>
            <mat-label style="font-size: 12px; margin-left: 20%"
              >{{ locale.data.ImprtFeesdue_OrderNumber }} </mat-label
            ><label style="font-size: 12px; text-align: left">
              {{ OrderNumber }}</label
            >
            <mat-label style="font-size: 12px; margin-left: 27%"
              >{{ locale.data.ImprtFeesdue_Confirmation }} </mat-label
            >&nbsp;<label style="font-size: 12px; text-align: left"
              >{{ Confirmation }}
            </label>
          </div>
          <br />
          <div>
            <mat-label style="font-size: 12px; margin-left: 20%"
              >{{ locale.data.OrderDateTime }} </mat-label
            >&nbsp;
            <label style="font-size: 12px; text-align: left">{{
              OrderDatetime | date: "M/d/yy hh:mm"
            }}</label>
          </div>
        </mat-card-content>

        <mat-card class="add-funds">
          <div>
            <div class="example-container">
              <div class="mat-elevation-z8">
                <table
                  mat-table
                  id="dataSourceAfterPayment"
                  [dataSource]="dataSourceAfterPayment"
                  matSort
                >
                  <!-- ContainerNumber Column -->
                  <ng-container matColumnDef="ContainerNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ locale.data.ImprtFeesdue_Container }} 
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        element.ConPrefix_Cd +
                          element.Con_Num +
                          element.ConChkDigit
                      }}
                    </td>
                  </ng-container>

                  <!-- Carrier Column -->
                  <ng-container matColumnDef="BLBooking">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ locale.data.ImprtFeesdue_BLBooking }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.CargoRef_Num }}
                    </td>
                  </ng-container>

                  <!-- Carrier Column -->
                  <ng-container matColumnDef="Carrier">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ locale.data.ImprtFeesdue_VesselVoyage }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.Carrier }}
                    </td>
                  </ng-container>

                  <!-- Ref# Column -->
                  <ng-container matColumnDef="Ref_Num">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ locale.data.ImprtFeesdue_Ref }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.Ref_Num }}
                    </td>
                  </ng-container>

                  <!-- Amount Column -->
                  <ng-container matColumnDef="FeePaid">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ locale.data.ImprtFeesdue_Amount }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.FeePaid | currency }}
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="
                      displayedColumnsafterpayment;
                      sticky: true
                    "
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumnsafterpayment"
                  ></tr>
                </table>
              </div>
            </div>
            <label style="font-size: 13px; font-weight: bold"
              >{{ locale.data.ImprtFeesdue_Count }} {{ PaymentCount }}</label
            >
            <label
              style="margin-left: 72.5%; font-size: 13px; font-weight: bold"
              >{{ locale.data.ImprtFeesdue_Total }}  {{ getTotalPayment() | currency }}</label
            >
          </div>
          <br />
          <div style="display: flex; margin-left: 40%">
            <button mat-raised-button (click)="done()"> {{ locale.data.ImprtFeesdue_DONE }}</button>
          </div>
        </mat-card>

        <mat-card class="add-funds">
          <small style="margin-left: 40%; font-weight: bold; color: red"
            >{{ locale.data.IMPORTANTNOTICETOOURUSERS }}</small
          >
          <p style="text-align: justify; font-size: 11px">
            {{ locale.data.ImprtFeesdue_marineterminal }}
          </p>
        </mat-card>
      </mat-card>
    </mat-card>
  </div>
</div>
