import { trackByHourSegment } from 'angular-calendar/modules/common/util';
import { qauatlog, stringlog } from 'src/app/csr-containerhistory/csr-containerhistory-type';
import { PpGlobalConstants } from './../ppcommon/pp-global-constants';
import { UserInfoType, RGBCO, UserAccountTypeInfo } from './../common/typesinfo/typesinfo';
import { WebapidataService } from "./../common/services/webapidata.service";
import { Component, Inject, OnInit, HostListener } from "@angular/core";
import { AuthService } from "../common/services/auth.service";
import { DOCUMENT, JsonPipe } from "@angular/common";
import { environment } from "src/environments/environment";
import { SharedService } from "../common/services/globalvariable.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginResponse } from "../common/typesinfo/typesinfo";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { userInfo } from 'os';
import { ValidationMethods } from '../common/models/validationmethods';
import { TranslateService } from '../common/services/translate.service';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { MessageAlertComponent } from './message-alert/message-alert.component';
import { RGACCESSLIST } from '../common/models/userinfomodel';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  LoginFailed = false;
  LoginReturned = false;
  ShowLoader: boolean = false;

  loadingmessage = "";

  loginResponse = <LoginResponse>{};

  // AUTH: string = PpGlobalConstants.AUTH;
  SiteUrl: string;
  ErrorMessage: string;

  PreviousInvalidAttemptsCount = 0;
  MaxLoginAttempts = 3;
  MaxLoginAttemptMessage = "";
  //. Please call the PierPASS OffPeak Customer Service Center at 877-863-3310 for assistance.";
  TokenExpired =  false;
  dialogRef: MatDialogRef<MessageAlertComponent>;
  CTFEnabled: boolean = false;
  ProgramType: string;
  Portcheck: boolean;
  PierPASS: boolean;
  useraccounttype: UserAccountTypeInfo;
  // MaxLoginAttemptMessage = "Maximum invalid attempts reached. Locked User ID. Use FORGOT PASSWORD link to generate new password. Please call the PierPASS OffPeak Customer Service Center at 877-863-3310 for assistance.";
  
  // returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: WebapidataService,
    private sharedService: SharedService,
    private validate: ValidationMethods,
    public locale: TranslateService,
    public dialog: MatDialog,
  ) {

    this.ProgramType  =  localStorage.getItem(PpGlobalConstants.ProgramType);
    if(this.ProgramType == PpGlobalConstants.Portcheck)
      this.Portcheck =  true;
    else 
      this.PierPASS = true;

    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
    this.loadingmessage = this.locale.data.hp_login_loading;
    this.MaxLoginAttemptMessage = this.locale.data.hp_max_login_limits;

    this.CheckCTFEnabled();

  }

  get f() {
    return this.loginForm.controls;
  }

  ngOnInit(): void {
    let _instance = environment.portsconfigs.find(x => x.clientid == localStorage.getItem("appInstance_Name").trim());
    this.SiteUrl = _instance.instance;

    this.loadingmessage = this.locale.data.hp_login_loading;
    this.MaxLoginAttemptMessage = this.locale.data.hp_max_login_limits;

    this.TokenExpired = JSON.parse(
      localStorage.getItem(PpGlobalConstants.TokenExpired) || "{}"
    );
    
    if( this.TokenExpired == true)
    {
      this.LoginFailed = true;
      this.ErrorMessage = this.locale.data.hp_session_expired;
      this.ErrorMessage = "Session expired: Please login again";
    }

    // this.CheckCTFEnabled();

  }

  onSubmit() {

    this.dataService.ClearAllLocalData();

    this.ShowForkInPage();

    this.submitted = true;

    const p_username = this.loginForm.value["username"];
    const p_password = this.loginForm.value["password"];

    if (this.loginForm.invalid) {
      return;
    }

    if(this.CheckMaxLoginAttempts(p_username))
     return ;
    else
    {
      this.CheckAccountStatus(p_username, p_password);
  
    }

    this.loading = true;
    this.ShowLoader = true; 

  }
  private CheckAccountStatus(p_username: any, p_password: any) {
    this.dataService.CheckCompanyAccountTypeStatus(p_username).subscribe(
      (data: any) => {
        if (data.result == "good") {


          qauatlog("CheckCompanyAccountTypeStatus: " + JSON.stringify(data));

          this.useraccounttype = data;
          localStorage.setItem(PpGlobalConstants.UserAccounTypeInfo, JSON.stringify(this.useraccounttype));
          if (this.useraccounttype.TMF == true || this.useraccounttype.Others == true)
            this.DoAuthentication(p_username, p_password);

          else {
            this.LoginFailed = true;
            this.ErrorMessage = this.useraccounttype.HelpCenter;
            this.ShowLoader = false;
            return;
          }

        }
        else if (data.result == "error") {
          // user status failed
          this.LoginFailed = true;
          this.ErrorMessage = data.errormessage;
          this.ShowLoader = false;
          return;
        }
      },

      (error) => {
        stringlog(error);
        this.ShowLoader = false;
      }
    );
  }

  DoAuthentication(p_username: string,p_password: string) {
    this.dataService.postJWTAuthenticate(p_username, p_password).subscribe(
      (data: LoginResponse) =>
      {
       this.LoginReturned = true;

      if(data.Error == "error")
      {
          stringlog("authentication failed");

          // increment PreviousInvalidAttemptsCount 
          this.PreviousInvalidAttemptsCount =  this.PreviousInvalidAttemptsCount + 1;
          this.loading = false;
          this.ShowLoader = false;
          this.LoginFailed = true;
          // Login Failed: Login ID or Password Incorrect
          if(this.ErrorMessage == "User Not Found")
          {
              this.ErrorMessage = this.locale.data.hp_login_failed_msg;
          }
          else
          {
              this.ErrorMessage = this.locale.data.hp_login_failed_msg;
          }
          return;
      }
      else if(data.Error == "good" && data.IsAuthenticated == true)
      {   
          this.loginResponse = data;
          this.loginResponse.LastRefreshedTime = new Date().toISOString();

          // Check Users Account Status.
          stringlog("login GOOD");

          localStorage.setItem(PpGlobalConstants.TokenExpired, "false");
      
          this.getsetCommonInfo();                       

      }
    },
    (error) => {
      stringlog(error);
      this.loading = false;
      this.ShowLoader = false;
      this.LoginFailed = true;
      this.ErrorMessage = this.locale.data.hp_data_services_down;
      return;
    }
  );
  }

  CheckMaxLoginAttempts(p_username : string) {

    stringlog("----START----");
    const PreviousUserName = localStorage.getItem(PpGlobalConstants.PreviousUserName);
    stringlog("Previous UserName: " + PreviousUserName);
    stringlog("current username: " + p_username);
    stringlog("previous invalid logins count: " +  this.PreviousInvalidAttemptsCount);

    if(PreviousUserName != undefined || PreviousUserName != null || PreviousUserName != "")
    {
      // previous username present username different
      if(PreviousUserName != p_username)
      {
        // set the counter
        this.PreviousInvalidAttemptsCount = 0;
        localStorage.setItem(PpGlobalConstants.PreviousUserName,p_username);
        return false; 
      }
      else
      {
        // same username, check for previous attempts
        if (this.PreviousInvalidAttemptsCount >= this.MaxLoginAttempts) 
        {
          // const LockingUserName = localStorage.getItem(PpGlobalConstants.LockingUserName);
          this.ShowLoader =  true;
          // DeActivate the account
          this.dataService.getLoginAttemdptedUserInfo(p_username).subscribe(
            (data: any) => {
            // this.loginResponse = data;
            const userInfo: UserInfoType = data.RG_User[0];
            this.dataService.DeActivateUser(userInfo.Login_Nm, userInfo.User_Id, userInfo.Company_Id).subscribe(
              (data: any) => {
                if(data.result == "good"){
    
                  this.PreviousInvalidAttemptsCount = 0;
                  this.LoginFailed = true;
                  this.ErrorMessage = data.errormessage;
                  stringlog("Deactivate User called and succeeded");
                }
                else if(data.result == "error")
                {
                  stringlog("Deactivate User called and FAILED");
                  this.LoginFailed = true;
                  this.ErrorMessage = data.errormessage;
                }
                this.ShowLoader =  false;
              },
            
            (error) => {
              stringlog(error);
              this.ShowLoader =  false;
            }
          );
    
    
            },
          (error) => {
            stringlog(error);
          }
        );
          return true;
        }
        else
        {
          return false; 
        }
      }

    }
  }

  getsetCommonInfo() {

    this.ProcessPopUp();

    // Store Auth info, username, token
    localStorage.setItem(PpGlobalConstants.AUTH, JSON.stringify(this.loginResponse));
    let AuthInfo: LoginResponse = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AUTH) || "{}"
    );

    this.dataService.getUserInfo().subscribe(
        (data: any) => {
                  
        // this.loginResponse = data;
        const userInfo: UserInfoType = data.RG_User[0];
        let  BCOInfoList: RGBCO[] = null;

        if(localStorage.getItem(PpGlobalConstants.ProgramType) == PpGlobalConstants.PierPASS)
          localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"TMF");
        else  localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"CTF")

        if(data.hasOwnProperty('RG_BCO'))
        {
          BCOInfoList = data.RG_BCO;
          stringlog("getsetCommonInfo - BCO Info: " + JSON.stringify(BCOInfoList));
          localStorage.setItem(PpGlobalConstants.BCOInfoList, JSON.stringify(BCOInfoList));
        }

        localStorage.setItem(PpGlobalConstants.AdditionalUserInfo, JSON.stringify(userInfo));

        let UserInfo_Get: UserInfoType = JSON.parse(
          localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
        );

        // stringlog(UserInfo_Get);

        if(userInfo.ResetPwdOnNextLogin_Flg == "Y")
        {
          localStorage.setItem(PpGlobalConstants.AvoidTMFCTFForkInPage, "true");
          this.router.navigate(["/firstlogin"]);
          return;
        }

        this.dataService.PushUserFullName(userInfo.First_Nm + " " + userInfo.Last_Nm);

        // stringlog(UserInfo_Get);

        //loading default customview data
        this.sharedService.loadCustomViewData(this.validate.EnvironmentConfig().ImportClaimList_InterfaceId,          
        this.validate.EnvironmentConfig().ImportClaimList_Interface_Uid,
           "importclaimlist").then(data=>{                     
         });

         //loading default customview data for Export claim List
        this.sharedService.loadCustomViewData(this.validate.EnvironmentConfig().ExportClaimList_InterfaceId,          
        this.validate.EnvironmentConfig().ExportClaimList_Interface_Uid,
           "exportclaimlist").then(data=>{                     
         });

         //loading default customview data for Export claim list for container
        this.sharedService.loadCustomViewData(this.validate.EnvironmentConfig().ExportClaimListContainer_InterfaceId,          
        this.validate.EnvironmentConfig().ExportClaimListContainer_Interface_Uid,
           "exportclaimlistcontainer").then(data=>{                     
         });

        //loading default customview data for Import FeesDue
        this.sharedService.loadCustomViewData(this.validate.EnvironmentConfig().ImportFeesDue_InterfaceId,          
        this.validate.EnvironmentConfig().ImportFeesDue_Interface_Uid,
           "importfeesdue").then(data=>{                     
         });

         //loading default customview data for Export FeesDue
        this.sharedService.loadCustomViewData(this.validate.EnvironmentConfig().ExportFeesDue_InterfaceId,          
        this.validate.EnvironmentConfig().ExportFeesDue_Interface_Uid,
           "exportfeesdue").then(data=>{                     
         });


        //loading default customview data for Export PendingList
        this.sharedService.loadCustomViewData(this.validate.EnvironmentConfig().ExportPendingList_InterfaceId,          
        this.validate.EnvironmentConfig().ExportPendingList_Interface_Uid,
           "exportpendinglist").then(data=>{                     
         });

        //loading default customview data for Import PendingList
        this.sharedService.loadCustomViewData(this.validate.EnvironmentConfig().ImportPendingList_InterfaceId,          
        this.validate.EnvironmentConfig().ImportPendingList_Interface_Uid,
           "importpendinglist").then(data=>{                     
         });

         //loading default customview data for CSR BookingHistory
        this.sharedService.loadCustomViewData(this.validate.EnvironmentConfig().CSRBookingHistory_InterfaceId,          
        this.validate.EnvironmentConfig().CSRBookingHistory_Interface_Uid,
           "csrbookinghistory").then(data=>{                     
         });


         if(userInfo.CompanyType_Cd == "H" || userInfo.CompanyType_Cd == 'E')
         {
          stringlog("login H or E");
          this.ProceedToNavigation(UserInfo_Get);
          
         }
         else
         {
          let bcorequest = { 
            Company_Id : userInfo.Company_Id,
            FeeTypeCode : 'TMF'
           }

           this.dataService.Login_GetBCOInfo(bcorequest).subscribe((data: any) => {
            if (data.result == "error") {
              stringlog("login check BCO Error");
            } else {
              stringlog("login check BCO GOOD");
              stringlog(JSON.stringify(data[0]));
      
              localStorage.setItem(PpGlobalConstants.BCOInfo,JSON.stringify(data[0]));
      
              let BCOInfo_Get: any = JSON.parse(
                localStorage.getItem(PpGlobalConstants.BCOInfo) || "{}"
              );
      
              this.ProceedToNavigation(UserInfo_Get);
      
              let VTXCreditStatus_Cd = BCOInfo_Get.BCOInfo_Get;
      
              stringlog("Deserialize:" +  JSON.stringify(BCOInfo_Get));
      
            }
          });
           
         }

        },
      (error) => {
        stringlog(error);
      }
    );


  }
  getPriveleges(userInfo: UserInfoType) {

    let LoggedUser_Id = userInfo.User_Id;

    return;

    this.dataService.Common_GetAccessPriveleges(LoggedUser_Id).subscribe(
      (data: any) => {
        
        stringlog("getAccessPriveleges");

        if (data.result == "error") {

          stringlog("getAccessPriveleges: ERROR ");
          // this.DisplayError = true;
          // this.ErrMessageInfo = data.errormessage;
          // this.ShowLoader = false;
          // this.ShowCompaniesSearchResult = false;
          return;
        } else {
          stringlog("getAccessPriveleges: success ");
       
          // stringlog("access list:" +  JSON.stringify(data));

          let RGACCESSLISTList : RGACCESSLIST[] = data.RG_ACCESSLIST;

          stringlog("access list:" +  JSON.stringify(RGACCESSLISTList));

          localStorage.setItem(PpGlobalConstants.AccessPrivileges,JSON.stringify(RGACCESSLISTList));


        
        }
        this.ShowLoader = false;
        return;
      },
      (error) => {
        console.log("connectivity error: " + error);
        console.log("connectivity error: " + error.message);
        this.ShowLoader = false;
      }
    );

  }

  //ProcessPopUp() {
   // this.dataService.GetPopAlert().subscribe(
    //  (data: any) => {
      //  stringlog("result" + JSON.stringify(data));
      //  if(data.result == "error")
      //  {
       //   
       //   stringlog("Error");
       //   return;
       // }
       // else if(data.result == "good" && data.data == "nodata")
       // {
       //   stringlog("good & nodata");
       //   return;
       // }
       // else
       // {
         // stringlog("displaying popup");

       //   const dialogConfig = new MatDialogConfig();
       //   dialogConfig.disableClose = true;
       //   dialogConfig.autoFocus = true;
       //   dialogConfig.height = "50vh"
        //  dialogConfig.width = "75vw";
         
        //  dialogConfig.data = data;
        //  this.dialog.open( MessageAlertComponent, dialogConfig);

        //  this.DisplayPopUp(data);
        //}
      //}
    //);
 // }

  ProcessPopUp() {
    this.dataService.GetPopAlert().subscribe(
      (data: any) => {
        // stringlog("result" + JSON.stringify(data));
        if(data.result == "error")
        {
          // 
          stringlog("Error");
          return;
        }
        else if(data.result == "good" && data.data == "nodata")
        {
          stringlog("good & nodata");
          return;
        }
        else
        {
          stringlog("displaying popup");

          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          // dialogConfig.height = "50vh"
          // dialogConfig.width = "75vw";
         
          dialogConfig.data = data;
          this.dialog.open( MessageAlertComponent, dialogConfig);

          // this.DisplayPopUp(data);
        }
      }
    );
  }

  private ProceedToNavigation(UserInfo_Get: UserInfoType) {

    localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"TMF")

    if (UserInfo_Get.CompanyType_Cd == "H") {
       stringlog("Host User");
      this.router.navigate(["/csr/csrcontainerhistory"]);
      // this.router.navigate(["/csr/csrcompanysearch"]);
    }
    else if(UserInfo_Get.CompanyType_Cd == "E")
    {
      this.router.navigate(["/csr/csrcontainerhistory"]);
    }
    else if (UserInfo_Get.CompanyType_Cd == "B" || UserInfo_Get.CompanyType_Cd == "S") {
      // stringlog("BCO User");
      // this.router.navigate(["/accountsummary"]);
      //  this.router.navigate(["/tmfctfselect"]);

      // let ShowTMFCTFForkInPage : boolean = JSON.parse(localStorage.getItem(PpGlobalConstants.ShowTMFCTFForkInPage));
      // stringlog("ShowTMFCTFForkInPage :"  +  ShowTMFCTFForkInPage);

      // if (ShowTMFCTFForkInPage == true) {
      //   this.router.navigate(["/tmfctfselect"]);
      // } else if (ShowTMFCTFForkInPage == false) {
      //   this.router.navigate(["/accountsummary"]);
      // }

      if(localStorage.getItem(PpGlobalConstants.ProgramType) == PpGlobalConstants.PierPASS)
      {
        this.ShowLoader = true;
        // this.showselection = false;
        stringlog("Set TMF");
        localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"TMF")
        this.router.navigate(["/accountsummary"]);
      }
      else   if(localStorage.getItem(PpGlobalConstants.ProgramType) == PpGlobalConstants.Portcheck)
      {
        this.setCTF();
      }

    }
  }

  setCTF()
  {
    // this.showselection = false;
    this.ShowLoader = true;
    stringlog("Set CTF");
    // localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"CTF")
      // this.router.navigate(["/accountsummary"]);

    const BCOlist : RGBCO[] = JSON.parse(localStorage.getItem(PpGlobalConstants.BCOInfoList));
    let TMFInfo: RGBCO;
    let CTFInfo : RGBCO;

    BCOlist.forEach( element =>
      {
        switch (element.FeeType_Cd)
        {
          case "TMF": { 
            TMFInfo = element;
            break; 
         } 
         case "CTF": { 
          CTFInfo = element;
            break; 
         } 
         default: { 
            //statements; 
            break; 
         } 
        }
      }
    )

    stringlog("CTFInfo info: " +  JSON.stringify(CTFInfo));

    if(CTFInfo.TermsAcceptanceUser_Id != null && CTFInfo.TermsAcceptance_DtTm != null)
    {
      localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"CTF")
      this.router.navigate(["/accountsummary"]);
    }
    else
    {
      // localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"CTF")
      this.router.navigate(['/']).then(() => { this.router.navigate(["/acceptctftermsconditions"]); })
      // this.router.navigate(["/acceptctftermsconditions"]);
    }

  }
  
   CheckCTFEnabled()  {
    this.dataService.GetTMFCTFForkIn().subscribe((data: any) => {
      stringlog("CheckCTFEnabled");
      stringlog(JSON.stringify(data));
      if (data.result == "error") {
        stringlog("CheckCTFEnabled: Error");
        this.CTFEnabled =  false;
      }
      else if(data.result == "good" && data.data == "true")
      {
        stringlog("CheckCTFEnabled: true");
        this.CTFEnabled =  true;
      }
      else if(data.result == "good" && data.data == "false")
      {
        stringlog("CheckCTFEnabled: false");
        this.CTFEnabled =  false;
      }
    });

  }


  private ShowForkInPage()  {
    this.dataService.GetTMFCTFForkIn().subscribe((data: any) => {
      stringlog("ShowForkInPage");
      stringlog(JSON.stringify(data));
      if (data.result == "error") {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage,"false");
        stringlog("ShowForkInPage: Error");
      }
      else if(data.result == "good" && data.data == "true")
      {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage,"true");
        stringlog("ShowForkInPage: true");
      }
      else if(data.result == "good" && data.data == "false")
      {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage,"false");
        stringlog("ShowForkInPage: false");
      }
    });

  }


  openTab() 
  {
    window.open(this.SiteUrl +  "/ppuserregistration" , '_blank');
  }

  openTabResetPassword(){
      //window.open(this.SiteUrl +  "/resetpwd" , '_blank');
      window.location.replace(this.SiteUrl + "/resetpwd");
  }

  openNewCompanyRegistration(){    
      //window.open(this.SiteUrl +  "/registernewaccount" , '_blank');
      window.location.replace(this.SiteUrl + "/registernewaccount");
  }

}
