import { IInterfaceActions } from "./../../common/models/userinfomodel";
import {
  Facility,
  ShippingLine,
  UserLandingPageModel,
  UserLocaleModel,
} from "./../../common/models/customviewmodel";

// import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  HostListener,
  NgZone,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { forkJoin, Observable } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { MediaMatcher } from "@angular/cdk/layout";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
  MatTreeFlattener,
  MatTreeFlatDataSource,
  MatSnackBar,
  MAT_DATE_FORMATS,
  DateAdapter,
  MAT_DATE_LOCALE,
  MatDialog,
  MatStepper,
} from "@angular/material";
import {
  Router,
  ActivatedRoute,
  ChildrenOutletContexts,
} from "@angular/router";
import { stringToKeyValue } from "@angular/flex-layout/extended/typings/style/style-transforms";

import { DomSanitizer } from "@angular/platform-browser";
import {
  StepperSelectionEvent,
  STEPPER_GLOBAL_OPTIONS,
} from "@angular/cdk/stepper";
import { identifierModuleUrl } from "@angular/compiler";
import { setegid } from "process";
import { slideInAnimation } from "src/app/animation";
import { APIService } from "src/app/common/services/httpservicefactory.service";
import {
  IInterfaces,
  IInterfacesAccess,
  IMenu,
  IModules,
  IUserInfo,
} from "src/app/common/models/userinfomodel";
import { Terminals } from "src/app/preauth-landing/preauth-landing.component";
import { TranslateService } from "src/app/common/services/translate.service";
import { AuthService } from "src/app/common/services/auth.service";
import { SharedService } from "src/app/common/services/globalvariable.service";
import { WebapidataService } from "src/app/common/services/webapidata.service";
import {
  CompanyData,
  UserData,
  AccountRegistrationRequestType,
} from "src/app/account-registration/account-registration-types";
import { IsizeType } from "src/app/common/models/commonmodel";
import { UserInfoType } from "src/app/common/typesinfo/typesinfo";
import { PpGlobalConstants } from "src/app/ppcommon/pp-global-constants";
import { environment } from "src/environments/environment";
import { SecQuestionResponse } from "./password-reset-type";
import { stringlog } from "src/app/csr-containerhistory/csr-containerhistory-type";
//import { IsizeType, IShippingLine } from '../preadvice/preadvice.interface';

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

interface Languages {
  key: string;
  name: string;
}

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
  animations: [slideInAnimation],
  providers: [
    APIService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }]
})
export class ResetPasswordComponent implements OnInit {
  // UserName : string = "";
  // loggedinUserInfo = <UserInfoType>{};

  showglobalsearch: any;
  // for menu
  @ViewChild("tree", { static: false }) tree: any;
  noofreq = 0;
  reqcount = 0;
  overlay = true;
  WindowsHeight: any = "0px";
  selected = "en";
  errorMessage: any;
  over: any;
  showMobileOptions: boolean = false;
  showMobileSearch: boolean = false;
  SelectedGlobalSearch: string = "";
  SearchValue: string;
  SerachContainerView: any = [];
  containerno: string = "";
  bookingno: string = "";
  isAction: boolean = false;

  userInfo: IUserInfo;
  iMenu: IMenu;
  lstIMenu: IMenu[];
  iModules: IModules[];
  iInterfaces: IInterfaces[];
  // menu
  MENU_TREE: Terminals[];

  iInterfacesAccess: IInterfacesAccess[] = [];
  interfaceActions: IInterfaceActions[] = [];

  username: string;
  email: string;
  userLandingPageModel: UserLandingPageModel = null;
  userLocaleModel: UserLocaleModel = null;
  defaultInterfaceUId: string;
  defaultLocale: string;

  DrayUnitStatues: any[] = [];
  PortFacilities: Facility[] = [];
  PreGateStatuses: any[] = [];
  ShippingLines: ShippingLine[] = [];
  adminTitlename: string = "";
  userLandingPage: string = "";
  loadOutlet: boolean = true;
  // Menus
  private transformer = (node: Terminals, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      moduleName: node.moduleName,
      name: node.name,
      url: node.url,
      interfaceId: node.interfaceId,
      showInMenuFlg: node.showInMenuFlg,
      interfaceCd: node.interfaceCd,
      interfaceUid: node.interfaceUid,
      is_href: node.is_href,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  // menus RS
  binding = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  showclose: boolean = false;
  loading: boolean = false;
  typename: string;

  ParentModuleName: string = "Home";
  ChildModuleName: string = "Request Password Reset";

  // ChildModuleName: string;
  // ParentModuleName: string;
  LoggedInUserFullName: string;
  SiteUrl: string;
  demo1TabIndex = 0;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isLinear = false;
  thirdFormGroup: FormGroup;
  States: any;
  state_cd: any;
  Countrys: any;
  ContainerMovesRange: any;
  BusinessTypes: any;
  ContainerTypes: Array<any> = [
    { name: "Importer", value: "Importer" },
    { name: "Exporter", value: "Exporter" },
  ];
  // showlist: boolean=false;

  IsTermsChecked = false;
  isFirstStepCompleted = false;

  IsError = false;
  ErrorMessage = "";
  ShowLoader: boolean;
  ActualSecQuestion: string;

  AcctRequest: SecQuestionResponse;
  firstresponse: SecQuestionResponse;
  SuccessMessage: string;
  ShowSuccesMessage = false;
  AttemptsCounter = 1;

  pierpassHeaderConfigs: any;
  jsonfile:string;
  Userlanguage:string;
  DisplayPortCheck: boolean;
  PierPASSHomePage: string;
  PortcheckHomePage: string;


  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    // private oidcSecurityService: OidcSecurityService,
    private aPIService: APIService,
    private snackBar: MatSnackBar,
    public locale: TranslateService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private route: ActivatedRoute,
    // private oidcSecurityStorage: OidcSecurityStorage,
    //private cookieService: CookieService,
    private zone: NgZone,
    //public validate: ValidationMethods,
    public apiService: APIService,
    private authService: AuthService,
    public sharedService: SharedService,
    private dataService: WebapidataService,
    private _formBuilder: FormBuilder
  ) {
    // this.binding.data = this.MENU_TREE;

    let instanceConfig = environment.portsconfigs.find(a => a.clientid == localStorage.getItem("appInstance_Name").trim());
    this.pierpassHeaderConfigs = instanceConfig.pierpassHeaderConfigs;
    this.jsonfile=instanceConfig.pierpassHeaderConfigs.languagefile;
    this.Userlanguage=instanceConfig.pierpassHeaderConfigs.UserSelectionlanguage;
    this.locale.use(this.Userlanguage,this.jsonfile);

    let UserInfo_Get: UserInfoType = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
    );

    this.CTFEnabled();

    this.PierPASSHomePage = environment.PierPASSHomeURL;
    this.PortcheckHomePage = environment.WeeblySiteURL;

    // this.UserName = UserInfo_Get.First_Nm + " " + UserInfo_Get.Last_Nm;
   
    //this.loadScript("./assets/files/text.js");
    this.mobileQuery = media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.WindowsHeight = window.innerHeight;
    this.gotosearchdetails();
    // if (this.oidcSecurityService.getToken() != "" || localStorage.getItem("auth_token")) {
    //   this.getUserIdentityData(environment.oidcmoduleconfig.userinfoendpoint);
    // }
    if (this.SearchValue) {
      this.showclose = true;
    } else {
      this.showclose = false;
    }
  }
  CTFEnabled() {
    this.dataService.GetTMFCTFForkIn().subscribe((data: any) => {
      // stringlog("CTFEnabled call executed");
      stringlog(JSON.stringify(data));
      if (data.result == "error") {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage,"false");
        this.DisplayPortCheck =  false;
        // stringlog("CTFEnabled call: Error");
      }
      else if(data.result == "good" && data.data == "true")
      {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage,"true");
        this.DisplayPortCheck =  true;
        // stringlog("CTFEnabled call: true");
      }
      else if(data.result == "good" && data.data == "false")
      {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage,"false");
        this.DisplayPortCheck =  false;
        // stringlog("CTFEnabled call: false");
      }
    });
  }

  openlink(URL: string)
  {
    window.open(URL, '_self');
  }

  openTab() {
     
      window.open(this.SiteUrl +  "/pphelp" , '_blank');
    
  }

  Previous() {

    // this.demo1TabIndex = this.demo1TabIndex - 1;
    this.IsError =  false;

  }

  Finish() {


    this.ShowSuccesMessage = false;

    if (this.AttemptsCounter > this.firstresponse.MaxLoginAttempts) {
      this.ErrorMessage = this.firstresponse.MaxLoginAttemptMessage;
      this.IsError = true;
      return;
    }

    const SecAnswerResp = this.secondFormGroup.get("SecretAns").value;

    if (SecAnswerResp == null || SecAnswerResp == "") {
      this.IsError = true;
      this.ErrorMessage = this.locale.data.hp_enter_sec_ans_val;
      return;
    }

    this.ShowLoader = true;
    this.IsError = false;

    this.firstresponse.SecAnswer = SecAnswerResp;

    if (
      this.secondFormGroup.valid &&
      SecAnswerResp != null &&
      SecAnswerResp != ""
    ) {
      this.dataService
        .SubmitPasswordReset(this.firstresponse)
        .subscribe((data: SecQuestionResponse) => {
          this.ShowLoader = false;
          if (data.result == "good") {
            this.IsError = false;
            this.SuccessMessage = this.locale.data.hp_succes_reset_pwd_msg;
            this.ShowSuccesMessage = true;

            // auto redirect 
            setTimeout(() => {
              this.router.navigate(["/"]);
          }, 5000);  //5s
            
            
          } else if (data.result == "error") {
            let logincount = this.AttemptsCounter;

            if (isNaN(logincount) || logincount === undefined) {
              logincount = 0;
            } else {
              logincount += 1;
            }
            this.AttemptsCounter = logincount;

            this.IsError = true;
            this.ErrorMessage = data.errormessage;
            this.ShowLoader = false;
            return;
          } else {
          }
        });
    }
  }

  Next(stepper: MatStepper) {
    this.ShowSuccesMessage = false;
    stepper.previous();

    const LoginName = this.firstFormGroup.get("LoginID").value;

    if (LoginName == null || LoginName == "") {
      this.IsError = true;
      this.ErrorMessage = "Enter Login ID";
      return;
    }

    // if(this.firstFormGroup.)

    this.ShowLoader = true;
    this.IsError = false;
    if (this.firstFormGroup.valid && LoginName != null && LoginName != "") {
      this.dataService.GetUserAccountInfo_PwdReset(LoginName).subscribe(
        (data: SecQuestionResponse) => {
          this.ShowLoader = false;
          if (data.result == "good") {
            this.IsError = false;
            this.ActualSecQuestion = data.strSecQuestion;
            this.firstresponse = data;
            stepper.next();
          } else if (data.result == "error") {
            this.IsError = true;
            this.ErrorMessage = data.errormessage;

            this.ShowLoader = false;
            return;
          } else {
          }
        },
        (error) => {
          this.IsError = true;
          this.ErrorMessage = "Data services down";
          this.ShowLoader = false;
        }
      );
    }
  }


  Cancel() {}

  markFormTouched(group: any) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement("script");
    script.innerHTML = "";
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  myControl = new FormControl();

  GlobalSearchOptions: any[] = [];

  noptions: any[] = [
    {
      name: "Container#",
      value: "mycontainer",
      validate: true,
      Interface_Uid: environment.interfaces.mycontainer,
    },
    {
      name: "Booking#",
      value: "mybooking",
      validate: false,
      Interface_Uid: environment.interfaces.mybooking,
    },
    {
      name: "GroupCode#",
      value: "mygroupcodes",
      validate: false,
      Interface_Uid: environment.interfaces.groupcode,
    },
    {
      name: "Vessel",
      value: "vesselschedule",
      validate: false,
      Interface_Uid: environment.interfaces.vesseschedule,
    },
  ];

  languages: Languages[] = [
    { key: "en", name: "English" },
    //{ key: "spanish", name: "Spanish" }
  ];

  filteredOptions: Observable<any[]>;
  SelectedOption: string = "";
  SearchText: string = "";
  path: string = "";
  readonlyvalue: boolean = false;

  ChangeComponent(item: string) {
    this.SelectedGlobalSearch = item;
    this.showclose = false;
    this.SearchValue = "";
  }

  ClearHeaderSearch(item: string) {
    let i: number = item.indexOf(":");
    const newitem = item.substr(0, i);
    const cno = item.substr(i + 1, item.length);
    if (cno.trim()) {
      for (let i = 0; i < this.noptions.length; i++) {
        if (newitem === this.noptions[i].name) {
          this.router.navigate(["/" + this.noptions[i].value]);
        }
      }
    }
    // this.SelectedOption='';
    this.adminTitlename = "";
    this.SearchValue = "";
    this.showclose = false;
  }

  ComponentView(item: string) {
    this.adminTitlename = "";
    let i: number = item.indexOf(":");
    if (i == -1) {
      this.snackBar.open("Please enter value for the search", "X", {
        duration: 5000,
        panelClass: "errorMessage",
        horizontalPosition: "center",
        verticalPosition: "top",
      });
      return;
    }
    const newitem = item.substr(0, i);
    const cno = item.substr(i + 1, item.length);
    if (cno.trim()) {
      for (let name of this.noptions) {
        let cont = cno.trim();
        let dname = newitem.replace("#", "");
        dname = dname.trim();
        if (newitem.trim() === name.name && name.validate) {
          if (this.validateinput(cno.trim())) {
            this.router.navigate(["/search" + dname.toLowerCase()], {
              queryParams: { type: dname, value: cont.toUpperCase() },
            });
          } else {
            this.snackBar.open("Invalid " + name.name + " No", "X", {
              duration: 5000,
              panelClass: "errorMessage",
              horizontalPosition: "center",
              verticalPosition: "top",
            });
            return;
          }
        } else if (!name.validate) {
          if (dname == "Vessel") {
            this.router.navigate(["/vesselschedule"], {
              queryParams: { vesselno: cont },
            });
          } else {
            this.router.navigate(["/search" + dname.toLowerCase()], {
              queryParams: { type: dname, value: cont.toUpperCase() },
            });
          }
        }
      }
    }
    if (this.SearchValue) {
      this.showclose = true;
    } else {
      this.showclose = false;
    }
  }
  validateinput(searchinput) {
    if (searchinput.trim() != "") {
      if (searchinput.trim().length == 10 || searchinput.trim().length == 11) {
        let alphabets = searchinput.trim().slice(0, 4);
        let numbers = searchinput.trim().slice(4, searchinput.trim().length);
        if (/^[a-zA-Z]+$/.test(alphabets) && /^[0-9]+$/.test(numbers)) {
          return true;
        }
      }
    }
    return false;
  }

  gotosearchdetails() {
    this.typename = this.route.snapshot.queryParamMap.get("type");
    this.containerno = this.route.snapshot.queryParamMap.get("value");
    this.showclose = this.containerno ? true : false;
    this.SearchValue = this.containerno
      ? this.typename + "#:" + this.containerno
      : "";
  }

  expandMobileOptions() {
    this.showMobileOptions = !this.showMobileOptions;
    this.showMobileSearch = this.showMobileSearch
      ? !this.showMobileSearch
      : false;
  }
  expandMobileSearch() {
    this.showMobileSearch = !this.showMobileSearch;
    this.showMobileOptions = this.showMobileOptions
      ? !this.showMobileOptions
      : false;
  }

  ngOnInit() {
    // this.binding.data = this.MENU_TREE;

    this.dataService.getPreAuthMenusFromDB().subscribe((data:any) =>
    {
      let terminals = data;
      this.binding.data = terminals;
   
    });


    let _instance = environment.portsconfigs.find(
      (x) => x.clientid == localStorage.getItem("appInstance_Name").trim()
    );
    this.SiteUrl = _instance.instance;

    let UserInfo_Get: UserInfoType = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
    );

    this.firstFormGroup = this._formBuilder.group({
      LoginID: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      SecretAns: ["", Validators.required],
    });

    this.showglobalsearch = environment.portsconfigs.find(
      (x) => x.showglobalsearch == true
    );
    window.addEventListener(
      "storage",
      this.storageEventListener.bind(this),
      false
    );
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }
  private storageEventListener(event: StorageEvent) {
    if (!event.storageArea.userInfo || event.storageArea.userInfo == "") {
      // this.authService.clearsession();
      window.location.href = "/nomultipetabs";
    }
  }
  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  progressbar(loadval: boolean) {
    this.loading = loadval;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  changeView(nav: any) {
    nav.toggle();
    this.SearchValue = "";
    this.showclose = false;
  }

  setLang(language: string) {
    this.locale.use(language, "emodal");
  }

  appendedtext: string[] = [];

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.GlobalSearchOptions.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) == 0
    );
  }

  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  LeftSideNavClicked(item) {
    item.toggle();
  }

  navigateUser(interfaceurl: string) {
    this.router.navigate(["/adminsettings"], {
      queryParams: { name: interfaceurl },
    });
  }
  getUserIdentityData(url: string) {
    this.aPIService.getuserManagement(url).subscribe(
      async (Response) => {
        if (Response) {
          localStorage.setItem("userInfo", JSON.stringify(Response));
          this.apiService.UserInfo();
          await this.getMenus(Response).then((result) => {
            this.loadOutlet = true;
            this.userLandingPage = Response.claims.homepage;
            this.SetLocale();
            this.SetLandingPage();

            this.loadInitialData();
          });
        } else {
          // this.authService.clearsession();
          // this.oidcSecurityService.logoff();
        }
      },
      (error) => {
        console.log(error);
        // this.authService.clearsession();
        // this.oidcSecurityService.logoff();
      }
    );
  }

  hasGlobalSearchAccess() {
    if (localStorage.getItem("userIntefaces")) {
      var aInterfaces = JSON.parse(localStorage.getItem("userIntefaces"));
      for (let i = 0; i < this.noptions.length; i++) {
        var intfc = aInterfaces.filter(
          (x) => x.interfaceUId == this.noptions[i].Interface_Uid.toLowerCase()
        );
        if (intfc.length > 0) {
          this.GlobalSearchOptions.push(this.noptions[i]);
        }
      }
    }
  }

  getuserint() {
    let abc = this.iInterfacesAccess.map((x) => x.interfaceUId);
    let xyz = this.noptions.map((y) => y.Interface_Uid);
    let intersection = abc.filter((x) => xyz.includes(x));
  }
  async getMenus(userInfoforMenu: IUserInfo): Promise<void> {
    if (userInfoforMenu) {
      this.username = userInfoforMenu.claims.fullname;
      this.email = userInfoforMenu.email;
      this.sharedService.email = userInfoforMenu.email;
      this.lstIMenu = userInfoforMenu.claims.menu;
      if (this.lstIMenu) {
        await Promise.all(
          this.lstIMenu.map(async (module) => {
            this.addModulestoMenu(module.modules);
            this.sharedService.interfaceActions = this.getInterfaceActions(
              module.modules
            );
            let lstInterfaceAccess = this.getInterfaces(module.modules);
            // if (lstInterfaceAccess.length > 0)
            //  await this.LoadCustomViews(lstInterfaceAccess);
          })
        );

        // menus
        if (this.MENU_TREE.length > 0) {
          this.binding.data = this.MENU_TREE;
          setTimeout((asd) => {
            try {
              let url = this.router.url;
              if (url.includes("adminsettings")) {
                let stringurl = url.split("name=")[1];
                var re = /%2F/gi;
                url = stringurl.replace(re, "/");
              }
              let node = this.tree.treeControl.dataNodes.filter(
                (a) => a.url == url
              );
              let parentnode = this.tree.treeControl.dataNodes.filter(
                (a) => a.moduleName == node[0].moduleName && a.expandable
              );
              this.tree.treeControl.expand(parentnode[0]);
              if (this.router.url.includes("adminsettings")) {
                this.adminTitlename = "";
                this.adminTitlename = node[0].name;
              } else this.adminTitlename = "";
            } catch {}
          }, 1000);
        }
      }
    }
  }

  getInterfaceActions(imodules: IModules[]): IInterfaceActions[] {
    imodules.forEach((imodule) => {
      if (imodule.interfaces.length > 0) {
        imodule.interfaces.forEach((element) => {
          if (!element.showInMenu_Flg && element.interface_Cd != null) {
            this.interfaceActions.push({
              module_Id: imodule.module_Id,
              module_nm: imodule.moduleName,
              interfaces_Id: element.interfaces_Id,
              interface_UId: element.interface_UId,
              interfaceDsc: element.interfaceDsc,
              interface_Cd: element.interface_Cd,
            });
          }
        });
      }
    });

    return this.interfaceActions;
  }

  addModulestoMenu(imodules: IModules[]) {
    this.zone.run(() => {
      imodules.forEach((imodule) => {
        let modulename: string = imodule.moduleName;
        let children: {
          moduleName: string;
          name: string;
          url: string;
          interfaceId: number;
          interfaceUid: string;
          showInMenuFlg: boolean;
          interfaceCd: string;
          is_href: boolean;
        }[] = [];
        if (
          imodule.interfaces != null &&
          imodule.interfaces != undefined &&
          imodule.interfaces.filter((x) => x.showInMenu_Flg == true).length >= 1
        ) {
          if (imodule.showInMenu_Flg) {
            imodule.interfaces.forEach((element) => {
              if (element.showInMenu_Flg) {
                let appinstance = localStorage.getItem("appInstance_Name");
                console.log("AppInnstance Name:" + appinstance);
                let portconfig = environment.portsconfigs.find(
                  (a) => a.clientid == appinstance
                );
                console.log(
                  "instanceConfig:" + portconfig + " appinstance:" + appinstance
                );
                if (element.interface_Url.includes(portconfig.instance)) {
                  element.interface_Url = element.interface_Url.replace(
                    portconfig.instance,
                    ""
                  );
                }

                children.push({
                  moduleName: imodule.moduleName,
                  name: element.interfaceDsc,
                  url: element.interface_Url,
                  interfaceId: element.interfaces_Id,
                  interfaceUid: element.interface_UId,
                  showInMenuFlg: element.showInMenu_Flg,
                  interfaceCd: element.interface_Cd,
                  is_href: this.isValid(element.interface_Url) ? true : false,
                });
              }
            });

            // this.MENU_TREE.push({
            //   moduleName: modulename,
            //   name: modulename ,
            //   url: "",
            //   interfaceId: 0,
            //   interfaceUid: "",
            //   showInMenuFlg: true,
            //   interfaceCd: "",
            //   is_href: false,
            //   children: children
            // });
          }
        }
      });
    });
  }

  getInterfaces(imodules: IModules[]): IInterfacesAccess[] {
    imodules.forEach((imodule) => {
      imodule.interfaces.forEach((element) => {
        this.iInterfacesAccess.push({
          modShowInMenu_Flg: imodule.showInMenu_Flg,
          interfacesId: element.interfaces_Id,
          interfaceUId: element.interface_UId,
          interfaceDsc: element.interfaceDsc,
          url: element.interface_Url,
          showInMenu_Flg: element.showInMenu_Flg,
          interfaceCd: element.interface_Cd,
        });
      });
    });
    localStorage.setItem(
      "userIntefaces",
      JSON.stringify(this.iInterfacesAccess)
    );
    this.hasGlobalSearchAccess();
    return this.iInterfacesAccess;
  }

  async LoadCustomViews(iInterfacesAccess: IInterfacesAccess[]): Promise<void> {
    if (
      !localStorage.getItem("customView_mycontainer") ||
      localStorage.getItem("customView_mycontainer") == "" ||
      localStorage.getItem("customView_mycontainer") == null
    ) {
      let item = iInterfacesAccess.find(
        (x) => x.interfaceUId == environment.interfaces.mycontainer
      );
      if (item) {
        await this.apiService.loadCustomViewData(
          this.sharedService.EnvironmentConfig().mycontainer_InterfaceId,
          item.interfaceUId,
          "mycontainer"
        );
      }
    }
  }

  loadInitialData() {
    let UnitStatusPromise: Observable<any[]>;
    let PregateStatusPromise: Observable<any[]>;
    let ShippingLinePromise: Observable<any[]>;
  
    if (
      this.DrayUnitStatues.length == 0 &&
      this.PreGateStatuses.length == 0 &&
      this.ShippingLines.length == 0
    ) {
      forkJoin([
        UnitStatusPromise,
        PregateStatusPromise,
        ShippingLinePromise,
      ]).subscribe((results) => {
        this.DrayUnitStatues = results[0].sort((a, b) =>
          a.drayUnitStatus_Dsc.localeCompare(b.drayUnitStatus_Dsc)
        );
        localStorage.setItem(
          "DrayUnitStatuses",
          JSON.stringify(this.DrayUnitStatues)
        );
        this.PreGateStatuses = results[1].sort((a, b) =>
          a.preGateStatus_Dsc.localeCompare(b.preGateStatus_Dsc)
        );
        localStorage.setItem(
          "PreGateStatuses",
          JSON.stringify(this.PreGateStatuses)
        );
        results[2].forEach((element) => {
          if (
            element.scaC_Cd &&
            element.scaC_Cd != null &&
            element.scaC_Cd != ""
          ) {
            element.scaC_Cd = element.scaC_Cd.trim();
            this.ShippingLines.push(element);
          }
        });
        this.ShippingLines = results[2]
          .filter((item: ShippingLine) => item.scaC_Cd)
          .sort((a, b) => a.scaC_Cd.localeCompare(b.scaC_Cd));
        localStorage.setItem(
          "ShippingLines",
          JSON.stringify(this.ShippingLines)
        );
      });
    }
    //put the static data loading promises here
  }
  isValid(text) {
    return /\b(http|https)/.test(text);
  }
  SaveLandingPage(item: any) {
    this.userLandingPageModel = {} as UserLandingPageModel;
    this.userLandingPageModel.DefaultInterface_Id = 0;
    this.userLandingPageModel.Interface_Uid = item.interfaceUId;
    this.userLandingPageModel.Interface_Uri = item.url;
    this.userLandingPageModel.User_Uid = this.apiService.User_Uid;
    this.apiService.SaveUserLandingPage(this.userLandingPageModel).subscribe(
      (data) => {
        this.defaultInterfaceUId = data.Interface_Uid;
        localStorage.setItem("dv", data.Interface_Uid);
      },
      (error) => {
        this.errorMessage = <any>error;
      }
    );
  }

  SaveLocale(item: any) {
    this.userLocaleModel = {} as UserLocaleModel;
    this.userLocaleModel.DefaultLocale_Id = 0;
    this.userLocaleModel.Locale = item.key;
    this.userLocaleModel.User_Uid = this.apiService.User_Uid;
    this.apiService.SaveUserLocale(this.userLocaleModel).subscribe(
      (data) => {
        this.defaultLocale = data.Locale;
        this.setLang(data.Locale);
      },
      (error) => {
        this.errorMessage = <any>error;
      }
    );
  }

  SetLandingPage() {
    if (!localStorage.getItem("dr")) {
      localStorage.setItem("dr", this.userLandingPage);
    }
  }
  SetLocale() {
    this.apiService.GetUserLocale().subscribe(
      (data) => {
        if (!!data) {
          this.defaultLocale = data.Locale;
          this.setLang(data.Locale);
        } else {
          this.defaultLocale = this.selected;
          this.setLang(this.selected);
        }
      },
      (error) => {
        this.errorMessage = <any>error;
      }
    );
  }

  showDefaultViewMenu(iInterfacesAccess: IInterfacesAccess[]): Boolean {
    if (
      iInterfacesAccess.length > 0 &&
      iInterfacesAccess.filter(
        (x) =>
          x.interfaceCd == null && x.showInMenu_Flg == true && x.url != null
      ).length > 0
    )
      return true;
    else return false;
  }

  disableActions(code: string): boolean {
    let lstAction = this.sharedService.interfaceActions.filter(
      (x) => x.interface_Cd == code
    );
    if (lstAction.length > 0) this.isAction = true;
    else this.isAction = false;
    return this.isAction;
  }
}
