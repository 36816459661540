import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '../common/services/translate.service';
import { WebapidataService } from '../common/services/webapidata.service';
import { WebContentType } from '../common/typesinfo/typesinfo';
import { stringlog } from '../csr-containerhistory/csr-containerhistory-type';

@Component({
  selector: 'app-pierpasshome',
  templateUrl: './pierpasshome.component.html',
  styleUrls: ['./pierpasshome.component.scss']
})
export class PierpasshomeComponent implements OnInit {

  landingpagetabscontent : WebContentType[];
  landingpagetabscontent1 : WebContentType[];
  SiteUrl: string;
  ppTabIndex: number;
  selectedIndex: number;
  
  constructor(private dataService:  WebapidataService,
    public locale: TranslateService) {
    this.dataService.getWebContentAll().subscribe((data:any) => {
      this.landingpagetabscontent1 = data[0];
    });

   }

  ngOnInit() {

    this.dataService.getWebContentAll().subscribe((data:any) => {
      this.landingpagetabscontent = data[0];

      let regstring = 'href="/Documents';
      let finalstring = 'target="_blank" rel="noopener" href="../../assets/Documents';
       
      this.landingpagetabscontent.forEach(e => 
        {
          e.Content_Txt = this.replaceAll(e.Content_Txt, regstring,finalstring);
        })

        let _instance = environment.portsconfigs.find(
          (x) => x.clientid == localStorage.getItem("appInstance_Name").trim()
        );
        this.SiteUrl = _instance.instance;
        
    });
    
  }

  openhelplink()
  {
    stringlog("openhelplink clicked");
    window.open(this.SiteUrl +  "/pphelp" , '_blank');
    this.selectedIndex = 1;
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

}
