<div style="margin-left: 20px; margin-top: 20px; padding-top: 50px;">
    <div *ngIf="document" style="color:red;  font-size: medium;">
        <span>The document </span> 
        <span style="font-weight: bold;">{{docname}}</span>
        <span> you are looking is NOT found </span> 
    </div> 
    <span *ngIf="!document" style="color:red;  font-size: medium;">
        {{message}}
    </span>
</div>
