<div class="sub-container">
  <table width="80%">
    <tbody>
      <tr>
        <td align="left">
          <h2>Los Angeles Terminals</h2>
        </td>
      </tr>
      <tr>
        <td align="left">
          <!--<a href="http://www.apmterminals.com/en/operations/north-america/los-angeles" target="HelpPopout">APM Terminals (APMT)</a>-->
          <a
            href="https://www.apmterminals.com/en/operations/north-america/los-angeles/contact-us/contact-us"
            target="HelpPopout"
            >APM Terminals (APMT)</a
          >
        </td>
        <td align="left">(310) 221-4000</td>
      </tr>
      <tr>
        <td align="left">
          <a
            href="https://portal.fenixmarineservices.com/apptmt-app/home"
            target="HelpPopout"
            >Fenix Marine Services (FMS)</a
          >
        </td>
        <td align="left">(888) 815-3758</td>
      </tr>
      <tr>
        <td align="left">
          <a href="http://www.wbctla.com/" target="HelpPopout"
            >West Basin Container Terminal (WBCT)</a
          >
        </td>
        <td align="left">(877)-391-2725</td>
      </tr>
      <tr>
        <td align="left">
          <a href="https://www.etslink.com/" target="HelpPopout"
            >Everport Terminal Services (ETS)</a
          >
        </td>
        <td align="left">(310) 221-0220</td>
      </tr>
      <tr>
        <td align="left">
          <a
            href="http://trapac.com/terminals/los-angeles-ca"
            target="HelpPopout"
            >TraPac, LLC</a
          >
        </td>
        <td align="left">(877) 387-2722 / (310) 830-2000</td>
      </tr>
      <tr>
        <td align="left">
          <a href="https://yti.com/contact/" target="HelpPopout"
            >Yusen Terminals, LLC</a
          >
        </td>
        <td align="left">(310) 548-8000</td>
      </tr>
      <tr>
        <td align="left">&nbsp;</td>
      </tr>
      <tr>
        <td align="left">
          <h2>Long Beach Terminals</h2>
        </td>
        <td align="left"></td>
      </tr>
      <tr>
        <td align="left">
          <a
            href="https://www.lbct.com/ContactUs/CustomerService"
            target="HelpPopout"
            >Long Beach Container Terminal (LBCT)</a
          >
        </td>
        <td align="left">(562) 435-8585</td>
      </tr>
      <tr>
        <td align="left">
          <a
            href="http://www.totalterminals.com/?act=articles&amp;catid=1&amp;subcatid=6"
            target="HelpPopout"
            >Total Terminal Inc (TTI)</a
          >
        </td>
        <td align="left">(562) 256-2649</td>
      </tr>
      <tr>
        <td align="left">
          <a href="https://www.itslb.com/" target="HelpPopout"
            >International Transportation Service (ITS)</a
          >
        </td>
        <td align="left">(562) 435-7781</td>
      </tr>
      <tr>
        <td align="left">
          <a
            href="http://www.ssamarine.com/locations/pacific-container-terminal/"
            target="HelpPopout"
            >Pacific Container Terminal (PCT)</a
          >
        </td>
        <td align="left">(562) 590-8728 / (866) 545-3142</td>
      </tr>
      <tr>
        <td align="left">
          <a
            href="http://www.ssamarine.com/locations/terminal-a/"
            target="HelpPopout"
            >SSA Marine Pier A</a
          >
        </td>
        <td align="left">(562) 436-4489 / (866) 545-3142</td>
      </tr>
      <tr>
        <td align="left">
          <a
            href="http://www.ssamarine.com/locations/terminal-c60/"
            target="HelpPopout"
            >C 60 Matson</a
          >
        </td>
        <td align="left">(562) 495-8612</td>
      </tr>
    </tbody>
  </table>
  <!-- <app-testcontent></app-testcontent> -->
</div>  
  
  <!--<table width="80%" class="HomePageText">-->
