import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { WebapidataService } from '../common/services/webapidata.service';
import { stringlog } from '../csr-containerhistory/csr-containerhistory-type';

@Directive({
  selector: '[PierpassButtonControl]'
})
export class PierpassButtonControlDirective implements OnInit, AfterViewInit {
  @Input('PierpassButtonControl') buttonName!: string;
  @Input() DisableFlag?: boolean =  false;

  constructor(private dataService: WebapidataService, private el: ElementRef, private renderer: Renderer2) { }
  ngAfterViewInit(): void {

    // stringlog(" ButtonControlDirective PierpassButtonControl " + this.buttonName);
    // stringlog(" ButtonControlDirective DisableFlag " + this.DisableFlag);

    this.dataService.DisabledButtonsListinContext$.subscribe(res => {

      // stringlog("after:" + JSON.stringify(res));

      if (this.dataService.isButtonDisabled(this.buttonName)) {

          // this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');

          this.el.nativeElement.disabled = true;
          this.renderer.setAttribute(this.el.nativeElement, 'title', 'Disabled for CSR access');

          if (this.el.nativeElement.tagName.toLowerCase() === 'mat-icon') {
            this.renderer.setStyle(this.el.nativeElement, 'cursor', 'not-allowed');
            this.renderer.setStyle(this.el.nativeElement, 'color', 'gray');
          }

          this.renderer.listen(this.el.nativeElement, 'click', (event: Event) => {
            event.preventDefault();
            event.stopPropagation();
          });
      }
      else
      {
        this.renderer.removeAttribute(this.el.nativeElement, 'title');;
      }

    })

  }

  ngOnInit(): void {
   
  }
}