import { Component, Inject, OnInit } from '@angular/core';
import { AfterViewInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AccountReceiptHistoryService } from '../account-receipt-history.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TableUtil } from 'src/app/Account/table-util';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { animate, state, style, transition, trigger } from '@angular/animations';
import moment from 'moment';
import { TranslateService } from 'src/app/common/services/translate.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

declare var jsPDF: any;
@Component({
  selector: 'app-account-receipt-history-details',
  templateUrl: './account-receipt-history-details.component.html',
  styleUrls: ['./account-receipt-history-details.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AccountReceiptHistoryDetailsComponent implements OnInit {



  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: any;
  panelOpenState = true;
  upDownArrow: boolean = false;
  dataSource: any;

  showTable: boolean = false;
  expandedElement: any | null;
  Hist: ReceiptHistory[];
  NoDataFound: any;
  TotalRecords = 0;
  receiptHistoryData: any
  receipt_History: any
  results = [];
  selectedResult = [];
  length: number;
  pageSize = 50;
  pageSizeOptions: number[] = [10, 20, 50];
  pageEvent: PageEvent;
  pdfcolumnNames: { title: string; dataKey: string }[];

  displayedColumns: string[] = [
    'collapsepanelarrow',
    'VTXTransaction_ID',
    'Payment_DtTm',
    'Amount',
    'OverdueAmount',
    'PayType_Dsc'
  ];
  rowClicked = -1;
  getAccordionDataDisplay: any;
  renderedData: any;
  constructor(private dialog: MatDialog, private matIconRegistry: MatIconRegistry,
    public locale: TranslateService,
    private domSanitizer: DomSanitizer, private myService: AccountReceiptHistoryService, 
    @Inject(MAT_DIALOG_DATA) public data: {
      receipt_Hist: any
    }
  ) { }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  getData(event?: PageEvent) {
    this.selectedResult = this.results.slice(event.pageIndex * event.pageSize, event.pageIndex * event.pageSize + event.pageSize);
    return event;
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {



    if (this.data.receipt_Hist) {


      this.receipt_History = Object.assign([], this.data.receipt_Hist.sort((a, b) => 0 - (a.VTXTransaction_ID > b.VTXTransaction_ID ? 1 : -1)));

      this.dataSource = new MatTableDataSource(this.data.receipt_Hist.sort((a, b) => 0 - (a.VTXTransaction_ID > b.VTXTransaction_ID ? 1 : -1)));
      this.dataSource.sort = this.sort;
      this.TotalRecords = this.results.length;

    }
  }
  allRowsExpanded: boolean = false;

  public toggle() {
    this.allRowsExpanded = !this.allRowsExpanded;
    if( this.receipt_History.length>0){
      this.dataSource.filteredData.forEach((row: any) => {
        row.isExpanded = this.allRowsExpanded;
      })
    }
   
  }
  downloadtoxlsx(): void {
    let xlsxcolumnNames = [];
    this.pdfcolumnNames = [];
    this.pdfcolumnNames.push({
      title: "Order#",
      dataKey: "VTXTransaction_ID"
    });
    this.pdfcolumnNames.push({
      title: "Order Date/Time",
      dataKey: "Payment_DtTm"
    });
    this.pdfcolumnNames.push({
      title: "Order Total",
      dataKey: "Amount"
    });
    this.pdfcolumnNames.push({
      title: "Previous Balance",
      dataKey: "OverdueAmount"
    });
    this.pdfcolumnNames.push({
      title: "Payment Type",
      dataKey: "PayType_Dsc"
    });
        this.pdfcolumnNames.push({
      title: "",
      dataKey: "Ref_Num"
    });
    this.pdfcolumnNames.push({
      title: "",
      dataKey: "FeePaid"
    });

    if (this.receipt_History.length > 0) {
      this.dataSource.connect().subscribe(d => this.renderedData = d);
      this.renderedData.forEach(element => {
        let row = [];
        let date = moment(element.Payment_DtTm).format("MM/DD/YYYY hh:mm A");
        for (let j = 0; j < this.pdfcolumnNames.length; j++) {

          switch (this.pdfcolumnNames[j].dataKey) {
            case "VTXTransaction_ID":
              row.push(element.VTXTransaction_ID);
              break;
            case "Payment_DtTm":
              row.push(date);
              break;
            case "Amount":
              row.push(element.Amount);
              break;
            case "OverdueAmount":
              row.push(element.OverdueAmount);
              break;
            case "PayType_Dsc":
              row.push(element.PayType_Dsc);
              break;
                 case "Ref_Num":
              row.push("");
              break;
            case "FeePaid":
              row.push("");
              break;
          }

        }

        xlsxcolumnNames.push(row);
        if (element.ConInventories.length > 0) {
          // let headerRow  =["","","","","",""]
          // xlsxcolumnNames.push(headerRow);
          let headerRow = ["", "Container#", "Import/Export", "Vessel/Voyage", "BL/Booking","Ref_Num", "Fee Amount"];
          xlsxcolumnNames.push(headerRow);

          element.ConInventories.forEach(child => {
            let childrow = [];
            for (let j = 0; j < this.pdfcolumnNames.length; j++) {

              switch (this.pdfcolumnNames[j].dataKey) {
                case "VTXTransaction_ID":
                  childrow.push("");
                  break;
                case "Payment_DtTm":
                  childrow.push(child.FullConNumber);
                  break;
                case "Amount":
                  childrow.push(child.ConStatus_Dsc);
                  break;
                case "OverdueAmount":
                  childrow.push(child.Carrier);
                  break;
                case "PayType_Dsc":
                  childrow.push(child.CargoRef_Num);
                  break;
                  case "Ref_Num":
                  childrow.push(child.Ref_Num);
                  break;
                case "FeePaid":
                  childrow.push(child.FeePaid);
                  break;

              }

            }
            xlsxcolumnNames.push(childrow);
          });
          // headerRow  =["","","","","",""]
          // xlsxcolumnNames.push(headerRow);
        }
      });

      this.exportToExcelFile(
        xlsxcolumnNames,
        "ReceiptHistory",
        this.pdfcolumnNames
      );

    }
  }
  downloadtopdf(): void {

    if (this.receipt_History.length > 0) {
      this.dataSource.connect().subscribe(d => this.renderedData = d);
      var doc = new jsPDF();
      var col = ["","Order#", "Order Date/Time", "Order Total", "Previous Balance", "Payment Type", ""];
      var rows = [];
      this.renderedData.forEach(item => {
        let date = moment(item.Payment_DtTm).format("MM/DD/YYYY hh:mm A");
        var temp = ["",item.VTXTransaction_ID, date, item.Amount, item.OverdueAmount, item.PayType_Dsc,""];
        rows.push(temp);
        if (item.ConInventories.length > 0 ) {
          let hRow = ["","Container#", "Import/Export", "Vessel/Voyage", "BL/Booking", "Ref_Num","Fee Amount"];
          rows.push(hRow);
          item.ConInventories.forEach(element => {
            var temprow = ["", element.FullConNumber, element.ConStatus_Dsc, element.Carrier, element.CargoRef_Num,element.Ref_Num, element.FeePaid];
            rows.push(temprow);
          });
        }

      });
      doc.autoTable(col, rows);
      doc.save("ReceiptHistory.pdf");
    }

  }

  //Export Excel Data
  exportTable() {
    this.downloadtoxlsx()

  }
  //Download PDF Data
  downloadPdf() {

    this.downloadtopdf();
  }

  onClose() {
    this.dialog.closeAll();
    // window.location.reload();

  }


  public exportToExcelFile(json: any[], excelFileName: string, columnNames: any): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    var range = XLSX.utils.decode_range(worksheet['!ref']);
    for (var C = range.s.r; C < columnNames.length; ++C) {
      var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
      if (!worksheet[address]) continue;
      worksheet[address].v = columnNames[worksheet[address].v].title;
    }
    const workbook: XLSX.WorkBook = { Sheets: { 'DisplayList': worksheet }, SheetNames: ['DisplayList'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_list' + EXCEL_EXTENSION);
  }
}

export interface ReceiptHistory {
  VTXAccount_Id: number,
  PaymentLog_ID: number,
  VTXTransaction_ID: number,
  Payment_DtTm: Date,
  Amount: number,
  OverdueAmount: number,
  PayType_Dsc: string,
  Print: string,
  isExpanded?: number,
  ConInventories: ConInventory[]
}


export interface ConInventory {
  PaymentLog_ID: number
  FullConNumber: string;
  ConStatus_Dsc: string;
  Carrier: string;
  CargoRef_Num: string;
  Ref_Num:string;
  FeePaid: number;

}
