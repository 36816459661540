<div id="main">
  <div style="width: 50vw">
    <app-loading-backdrop *ngIf="ShowLoader"></app-loading-backdrop>
  </div>
  <div id="claimwatchlist" *ngIf="claimwatchlistdisplay">
    <div class="sticky">
      <mat-toolbar>
        <small>{{locale.data.import_search}}</small>
        <span class="fill-remaining-space"></span>
        <button
          class="btn-dialog-close"
          mat-stroked-button
          (click)="onClose()"
          tabIndex="-1"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-toolbar>
      <mat-card>
        <div *ngIf="!csrreadonly" style="display: flex; justify-content: flex-end; ">
          <div>
            <span class="fill-remaining-space"></span>
            <button mat-raised-button style=" color: #757575; background-color: white; margin-right: 10px; margin-left: auto; " (click)="Cancel()" >
              {{locale.data.cancel}}
            </button>
            <button mat-raised-button style="margin-right: 0px; width: 200px" id="btnSubmit" (click)="SubmitButton()" [disabled] = "btnDisable" >
              {{locale.data.claim_invoice}}
            </button>
          </div>
        </div>
        <div *ngIf="csrreadonly" style="display: flex; justify-content: flex-end; ">
          <div>
            <span class="fill-remaining-space"></span>
            <button mat-raised-button style=" color: #757575; background-color: white; margin-right: 10px; margin-left: auto; " (click)="Cancel()" >
              {{locale.data.cancel}}
            </button>
            <button PierpassButtonControl="IMPSEARCHCREDITCLAIMSUBMIT" mat-raised-button style="margin-right: 0px; width: 200px" id="btnSubmit" (click)="SubmitButton()" [disabled] = "btnDisable" >
              {{locale.data.claim_invoice}}
            </button>
          </div>
        </div>
      </mat-card>
     
    </div>
    <br>
    <div>
      <div>
        <mat-card style="display: flex;">  
          <div>
            <span class="heading_black" style="padding-left: 0px">
              {{locale.data.eligible_cons}}
            </span>
            <div style="background-color: white; color: black; font-size: small">
              <span class="text-color" style="display: block">
                {{locale.data.eligible_cons_credit_subhead}}
              </span>
            </div>
          </div>
          <span class="fill-remaining-space"></span>
          <div style="justify-content: flex-end; padding-top: 30px; padding-right: 20px;">
            <div class="export-result-details">
              &nbsp;
              <mat-icon mat-icon-button (click)="DownloadPDF_Claimlist()" matTooltip="PDF" > <img src="../assets/images/pdfmeduim.png" style=" vertical-align: middle; height: 28px; width: 30px; cursor: pointer; " /></mat-icon>
              &nbsp;
              <mat-icon (click)="DownloadExcel_ClaimList()" matTooltip="EXCEL"> <img src="../assets/images/excel.png" style="vertical-align: middle; height: 23px; cursor: pointer" /> </mat-icon>
            </div>
          </div>
        </mat-card>
        <!-- <mat-card>
         
        </mat-card> -->
        <mat-card>
          <div class="example-container">
            <div class="mat-elevation-z8">
              <table id="ExportSearchResultDetails" mat-table [dataSource]="dsClaimsList" *ngIf="showClaimListTable" matSort>
                <ng-container matColumnDef="Container">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.containerhash}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Container }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.container_status}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Status }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Message">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.message}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Message }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Ref_Num">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.reference_hash}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Ref_Num }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Hold">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.Hold}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Hold }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Size">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.attachments_size}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.ConSz_Cd }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Exempt">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.Exempt}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Exempt }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="MTO">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{locale.data.MTO}}</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.MTO }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Carrier">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.VesselVoyage}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Carrier }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Remove">
                  <th mat-header-cell *matHeaderCellDef>{{locale.data.remove}}</th>
                  <td mat-cell *matCellDef="let element" style="font-size: 10px" > <button mat-icon-button color="primary" (click)="RemoveContainerFromClaimlist(element.Con_Num)" > &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <mat-icon style="cursor: pointer; color: #0082be" >delete</mat-icon > </button> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="example-first-header-row" ></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
              </table>
              <br />
              <span style="font-size: small; color: black; margin-top: 10px" >{{locale.data.Count}} : {{ vm_ClaimList.length }}</span >
            </div>
          </div>
          <div style="display: flex; justify-content: flex-start">
            <p *ngIf="ImportSearchErr" class="error">
              {{ ImportSearchMessageError }}
            </p>
          </div>
          <br />
        </mat-card>

        <br />
      </div>

      <br />
      <br />
      <div>
        <mat-card style="display: flex;">  
          <div>
            <span class="heading_black" style="padding-left: 0px"> {{locale.data.not_eligible_cons}} </span>
            <div style="background-color: white; color: black; font-size: small" *ngIf="this.BCO_Info.VTXCreditStatus_Cd == 'C'" > <span class="text-color" style="display: block"> {{locale.data.cons_not_eligible_heading}} </span> </div>
          </div>
          <span class="fill-remaining-space"></span>
          <div style="justify-content: flex-end; padding-top: 30px; padding-right: 20px;">
            <div class="export-result-details"> &nbsp; <mat-icon mat-icon-button (click)="DownloadPDF_Watchlist()" matTooltip="PDF">
              <img src="../assets/images/pdfmeduim.png" style=" vertical-align: middle; height: 28px; width: 30px; cursor: pointer; " />
            </mat-icon>
              &nbsp;
              <mat-icon (click)="DownloadExcel_WatchList()" matTooltip="EXCEL"> <img src="../assets/images/excel.png" style="vertical-align: middle; height: 23px; cursor: pointer" /> </mat-icon>
            </div>
          </div>
        </mat-card>
        <!-- <mat-card>
         
        </mat-card> -->
        <mat-card>
          <div class="example-container">
            <div class="mat-elevation-z8">
              <table #table2 #watchlistsort="matSort" id="ExportSearchResultWatchListDetails" mat-table [dataSource]="dsWatchList" *ngIf="showWatchListTable" matSort >
                <!-- Checkbox Column -->
                <ng-container matColumnDef="Container">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.containerhash}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Container }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.Status}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Status }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Message">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.message}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Message }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Ref_Num">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.reference_hash}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Ref_Num }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Hold">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.Hold}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Hold }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Size">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.attachments_size}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.ConSz_Cd }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Exempt">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.Exempt}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Exempt }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="MTO">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{locale.data.MTO}}</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.MTO }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Carrier">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.VesselVoyage}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Carrier }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Remove">
                  <th mat-header-cell *matHeaderCellDef>{{locale.data.remove}}</th>
                  <td mat-cell *matCellDef="let element" style="font-size: 10px" > <button mat-icon-button color="primary" (click)="RemoveContainerFromWatchlist(element.Con_Num)" > &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <mat-icon style="cursor: pointer; color: #0082be" >delete</mat-icon > </button> </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns_wl; sticky: true" class="example-first-header-row" ></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns_wl" ></tr>
              </table>
              <br />
              <span style="font-size: small; color: black; margin-top: 10px" >{{locale.data.Count}} : {{ vm_WatchList.length }}</span >
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
