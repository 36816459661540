import { claimList } from './../import-claim-list/claim-list.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatIconRegistry, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { WebapidataService } from '../common/services/webapidata.service';
import { TN_Booking, UserInfoType } from '../common/typesinfo/typesinfo';
import { PpGlobalConstants } from '../ppcommon/pp-global-constants';
import { ClaimContainerList, ClaimResultDialogData, ExportSearchRequestType, ExportSearch_ClaimList_VM, ExportSearch_WatchList_VM, SubmitClaimRequest, WatchContainerlist } from './export-search-types';

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { TranslateService } from '../common/services/translate.service';
import { stringlog } from '../csr-containerhistory/csr-containerhistory-type';
import { ContextinfoService } from '../common/services/contextinfo.service';

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";


@Component({
  selector: "app-exports-search-result-details",
  templateUrl: "./exports-search-result-details.component.html",
  styleUrls: ["./exports-search-result-details.component.scss"],
})
export class ExportsSearchResultDetailsComponent implements OnInit {
  @Input() childMessage: string;
  @ViewChild(MatSort, { static: false }) sort: MatSort | any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | any;

  @ViewChild(MatPaginator, { static: false }) wlpaginator: MatPaginator | any;
  @ViewChild('watchlistsort', { static: false }) public watchlistsort: MatSort;


  gridHistorydata: any;
  showClaimListTable: boolean = false;
  serversideErrorMessage: any;
  // Company_Id: any;
  Company_Nm: any;
  dsClaimsList: any;
  TotalRecords: any;
  disableRemove: boolean = true;

  PDFColNamesClaimtList: { title: string; dataKey: string }[];

  ClaimList_Info: TN_Booking[];

  displayedColumns: string[] = [
    // "cl_select",
    "Booking",
    "Status",
    "Message",
    "Ref",
    "Hold",
    "Exempt",
    "MTO",
    "Carrier",
    "Remove",
  ];

  // displayedColumns3: string[] = ['Booking_Num', 'BookingStatus_Dsc', 'BookingStatus_Cd', 'Ref_Num',
  // 'Hold_Flg',  'Exempt_Flg','MTO_Cd','Carrier'];

  displayedColumns_wl: string[] = [
    // "wl_select",
    "Booking",
    "Status",
    "Message",
    "Ref",
    "Hold",
    "Exempt",
    "MTO",
    "Carrier",
    "Remove",
  ];

  mainvbvCheckBox: boolean = false;

  ShowLoader: boolean;
  // CompanyId: any;

  dsWatchList: any;
  WatchList_Info: TN_Booking[];
  showWatchListTable: boolean;
  FeeTypeCode: string;
  LoginName: string;
  UserId: number;

  ExpSearchError: boolean;
  ExpSearchErrMessage: string;
  claimselection: SelectionModel<TN_Booking>;
  watchlistselection: SelectionModel<TN_Booking>;
  SearchRequestObj: ExportSearchRequestType;
  vm_ClaimList = [<ExportSearch_ClaimList_VM>{}];
  vm_WatchList = [<ExportSearch_WatchList_VM>{}];
  csrreadonly: boolean;

  constructor(
    private router: Router,
    private dataService: WebapidataService,
    fb: FormBuilder,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public locale: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ClaimResultDialogData,
    private context: ContextinfoService
  ) {}

  RemoveALLCheckedWatchList() {

    this.watchlistselection.selected.forEach((s) => {
      this.vm_WatchList = this.vm_WatchList.filter(
        (obj) => obj.Booking_Num !== s.Booking_Num
      );
    });
 
    this.dsWatchList = new MatTableDataSource(this.vm_WatchList);

    this.dsWatchList.sortingDataAccessor = (item, property) => {
        switch (property) {
            case "Booking":
                return item.Booking_Num;
            case "MTO":
                return item.MTO_Cd;
            case "VesselVoyage":
                return item.Carrier;
            // case 'FullContainer': return item.FullContainer;
            // case 'FullContainer': return item.FullContainer;
            // case 'FullContainer': return item.FullContainer;
            default:
                return item[property];
        }
    };

    this.dsWatchList.sort = this.watchlistsort;
    // this.dsWatchList.sort = this.sort;
    // this.dsWatchList.paginator = this.paginator;
    this.TotalRecords = this.vm_WatchList.length;
  }
  RemoveALLCheckedClaimList() {

    this.claimselection.selected.forEach((s) => {
      this.vm_ClaimList = this.vm_ClaimList.filter(
        (obj) => obj.Booking_Num !== s.Booking_Num
      );
    });

    this.dsClaimsList = new MatTableDataSource(this.vm_ClaimList);

    this.dsClaimsList.sortingDataAccessor = (item, property) => {
        switch (property) {
            case "Booking":
                return item.Booking_Num;
            case "MTO":
                return item.MTO_Cd;
            case "VesselVoyage":
                return item.Carrier;
            // case 'FullContainer': return item.FullContainer;
            // case 'FullContainer': return item.FullContainer;
            // case 'FullContainer': return item.FullContainer;
            default:
                return item[property];
        }
    };

    this.dsClaimsList.sort = this.sort;
    this.dsClaimsList.paginator = this.paginator;
    this.TotalRecords = this.ClaimList_Info.length;
  }

  isAllClaimsSelected() {
    const numSelected = this.claimselection.selected.length;
    const numRows = this.dsClaimsList.data.length;
    return numSelected === numRows;
  }

  isWatchListAllSelected() {
    const numSelected = this.watchlistselection.selected.length;
    const numRows = this.dsWatchList.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterClaimsToggle() {
    this.isAllClaimsSelected()
      ? this.claimselection.clear()
      : this.dsClaimsList.data.forEach((row) =>
          this.claimselection.select(row)
        );
  }

  masterWatchListToggle() {
    this.isWatchListAllSelected()
      ? this.watchlistselection.clear()
      : this.dsWatchList.data.forEach((row) =>
          this.watchlistselection.select(row)
        );
  }

  ngAfterViewInit() {
    this.dsClaimsList.sort = this.sort;
    this.dsClaimsList.paginator = this.paginator;

    this.dsWatchList.sort = this.watchlistsort;
    // this.dsWatchList.paginator = this.watchlistpaginator;
  }

  ngOnInit() {
    let UserInfo_Get: UserInfoType = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
    );

    // this.CompanyId = 14361;
    this.UserId = UserInfo_Get.User_Id;
    this.LoginName = UserInfo_Get.Login_Nm;
    this.FeeTypeCode = localStorage.getItem(PpGlobalConstants.FeeTypeCTFTMF);

    this.ClaimList_Info = this.data.ClaimList;
    this.SearchRequestObj = this.data.SearchRequestObj;

    if(this.context.IsCSRMode())
    {
      stringlog("Export Search Dialog : CSR Mode");
      this.csrreadonly = true;

    }
    else
    {
      stringlog("Export Search Dialog : Pure Mode");
      this.csrreadonly = false;
    }
   
    // create VM and add business rules    

    this.ClaimList_Info.forEach((element) => {
      
      let message: string;
      let hold: string;
      let exempt: string;
      let refnum: string;

      refnum = element.Ref_Num;

      if (element.BookingStatus_Cd == "A") {
        message = this.locale.data.available_claim;
      } else if (element.BookingStatus_Cd == "P") {
        message = this.locale.data.not_announced;
      }

      if (element.Company_Id == this.SearchRequestObj.CompanyId) {
        message = this.locale.data.claimed_by_your_com;
      } else {
        refnum = this.SearchRequestObj.RefNum;
      }

      if (element.Exempt_Flg == "Y") {
        exempt =this.locale.data.Yes;
      } else if (element.Exempt_Flg == "N") {
        exempt = this.locale.data.No;
      }

      if (element.Hold_Flg == "Y") {
        hold = this.locale.data.Yes;
      } else if (element.Hold_Flg == "N") {
        hold = this.locale.data.No;
      }

      this.vm_ClaimList.push({
        Booking_Id: element.Booking_Id,
        Booking_Num: element.Booking_Num,
        BookingStatus_Cd: element.BookingStatus_Cd,
        BookingStatus_Dsc: element.BookingStatus_Dsc,
        Hold_Flg: element.Hold_Flg,
        MTO_Cd: element.MTO_Cd,
        Carrier: element.Carrier,
        Company_Id: element.Company_Id,
        Ref_Num: element.Ref_Num,
        Exempt_Flg: element.Exempt_Flg,
        Ver_Nbr: element.Ver_Nbr,
        Status: element.BookingStatus_Dsc,
        Message: message,
        Hold: hold,
        Exempt: exempt,
        Ref: refnum,
      });
    });

    this.vm_ClaimList = this.vm_ClaimList.slice(1);

    this.dsClaimsList = new MatTableDataSource(this.vm_ClaimList);

    // this.dsClaimsList = new MatTableDataSource(this.ClaimList_Info);
    this.dsClaimsList.sortingDataAccessor = (item, property) => {
        switch (property) {
            case "Booking":
                return item.Booking_Num;
            case "Status":
                return item.BookingStatus_Dsc;
            case "MTO":
                return item.MTO_Cd;
            case "VesselVoyage":
                return item.Carrier;
            // case 'FullContainer': return item.FullContainer;
            // case 'FullContainer': return item.FullContainer;
            // case 'FullContainer': return item.FullContainer;
            default:
                return item[property];
        }
    };

    this.dsClaimsList.sort = this.sort;
    this.dsClaimsList.paginator = this.paginator;
    this.TotalRecords = this.vm_ClaimList.length;
    this.claimselection = new SelectionModel<TN_Booking>(true, []);

    this.showClaimListTable = true;

    // change it to Watch Result
    this.WatchList_Info = this.data.WatchList;
    //////

    this.WatchList_Info.forEach((element) => {
      // let booking: string;
      // let status: string;
      let message: string;
      let hold: string;
      let exempt: string;
      let refnum: string;

      if(element.Company_Id != null && element.Company_Id != this.SearchRequestObj.CompanyId)
      {
        message = this.locale.data.claimed_by_another_com;
        refnum = "";

      }

      if(element.Exempt_Flg == "Y" )
      {
        message = this.locale.data.Exempt;
      }

      if (element.Exempt_Flg == "Y") {
        exempt = this.locale.data.Yes;
      } else if (element.Exempt_Flg == "N") {
        exempt = this.locale.data.No;
      }

      if (element.Hold_Flg == "Y") {
        hold = this.locale.data.Yes;
      } else if (element.Hold_Flg == "N") {
        hold = this.locale.data.No;
      }

      this.vm_WatchList.push({
        Booking_Id: element.Booking_Id,
        Booking_Num: element.Booking_Num,
        BookingStatus_Cd: element.BookingStatus_Cd,
        BookingStatus_Dsc: element.BookingStatus_Dsc,
        Hold_Flg: element.Hold_Flg,
        MTO_Cd: element.MTO_Cd,
        Carrier: element.Carrier,
        Company_Id: element.Company_Id,
        Ref_Num: element.Ref_Num,
        Exempt_Flg: element.Exempt_Flg,
        Ver_Nbr: element.Ver_Nbr,
        Status: element.BookingStatus_Dsc,
        Message: message,
        Hold: hold,
        Exempt: exempt,
        Ref: refnum,
      });
    });

    this.vm_WatchList = this.vm_WatchList.slice(1);

    // this.dsClaimsList = new MatTableDataSource(this.vm_WatchList);


    //////////
    this.dsWatchList = new MatTableDataSource(this.vm_WatchList);

    this.dsWatchList.sortingDataAccessor = (item, property) => {
        switch (property) {
            case "Booking":
                return item.Booking_Num;
            case "Status":
                return item.BookingStatus_Dsc;
            case "MTO":
                return item.MTO_Cd;
            // case 'FullContainer': return item.FullContainer;
            // case 'FullContainer': return item.FullContainer;
            // case 'FullContainer': return item.FullContainer;
            default:
                return item[property];
        }
    };

    this.dsWatchList.sort = this.watchlistsort;
    // this.dsWatchList.sort = this.sort;
    // this.dsWatchList.paginator = this.paginator;
    this.TotalRecords = this.vm_WatchList.length;
    this.showWatchListTable = true;
    this.watchlistselection = new SelectionModel<TN_Booking>(true, []);
  }

  Cancel() {

    let url =  this.router.url;
    stringlog("CANCEL current url: " +  url);
    this.router.navigate(['/']).then(() => { this.router.navigate([url]); })

    // this.router.navigate(['/']).then(() => { this.router.navigate(["/exportsearch"]); })
    stringlog("Close");
    this.dialog.closeAll();
  }
  SubmitClaims() {

    // const  claimSubReq : SubmitClaimRequest  = new SubmitClaimRequest();
    const claimSubReq = <SubmitClaimRequest>{};

    claimSubReq.CompanyId = this.SearchRequestObj.CompanyId;
    claimSubReq.FeeTypeCode = this.SearchRequestObj.FeeTypeCode;
    claimSubReq.LoginName = this.SearchRequestObj.LoginName;
    claimSubReq.UserId = this.SearchRequestObj.UserId;

    let claimlist1 = [<ClaimContainerList>{}];  
  
    this.vm_ClaimList.forEach((element) => {
      claimlist1.push({
        Booking_Id: element.Booking_Id,
        Booking_Num: element.Booking_Num,
        Ref_Num: element.Ref,
        CompanyId: element.Company_Id,
        Ver_Nbr: element.Ver_Nbr,
      });
    });

    claimlist1 = claimlist1.slice(1);
    //  this.vm_ClaimList = this.vm_ClaimList.slice(1);   

    claimSubReq.claimslist = claimlist1;

    let watchlist1 = [<WatchContainerlist>{}];

    this.vm_WatchList.forEach((element) => {
      watchlist1.push({
        Booking_Id: element.Booking_Id,
        Booking_Num: element.Booking_Num,
        Ref_Num: element.Ref,
        CompanyId: element.Company_Id,
        Ver_Nbr: element.Ver_Nbr,
      });
    });

    watchlist1 = watchlist1.slice(1);

    claimSubReq.watchlist = watchlist1;

    this.ShowLoader = true;

    this.dataService.SubmitPostExportClaims(claimSubReq).subscribe(
      (data: any) => {
        this.ShowLoader = false;
        if (data.result == "error") {
          this.ExpSearchError = true;
          this.ExpSearchErrMessage = data.errormessage;
        } else if (data == true) {
          this.dialog.closeAll();
          this.router.navigate(["/exportclaimlist"]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  RemoveContainerFromClaimlist(row: any) {

    this.vm_ClaimList = this.vm_ClaimList.filter(
      (obj) => obj.Booking_Num !== row
    );


    this.dsClaimsList = new MatTableDataSource(this.vm_ClaimList);

      // sort code

      this.dsClaimsList.sortingDataAccessor = (item, property) => {
          switch (property) {
              case "Booking":
                  return item.Booking_Num;
              case "MTO":
                  return item.MTO_Cd;
              case "VesselVoyage":
                  return item.Carrier;
              // case 'FullContainer': return item.FullContainer;
              // case 'FullContainer': return item.FullContainer;
              // case 'FullContainer': return item.FullContainer;
              default:
                  return item[property];
          }
      };
    this.dsClaimsList.sort = this.sort;
    this.dsClaimsList.paginator = this.paginator;
    this.TotalRecords = this.ClaimList_Info.length;

    let UserInfo_Get: UserInfoType = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
    );

    let AccountId: string = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AccountId) || "{}"
    );

  }

  RemoveContainerFromWatchlist(row: any) {

    this.vm_WatchList = this.vm_WatchList.filter(
      (obj) => obj.Booking_Num !== row
    );

    this.dsWatchList = new MatTableDataSource(this.vm_WatchList);

    this.dsWatchList.sortingDataAccessor = (item, property) => {
        switch (property) {
            case "Booking":
                return item.Booking_Num;
            case "MTO":
                return item.MTO_Cd;
            case "VesselVoyage":
                return item.Carrier;
            // case 'FullContainer': return item.FullContainer;
            // case 'FullContainer': return item.FullContainer;
            // case 'FullContainer': return item.FullContainer;
            default:
                return item[property];
        }
    };

    this.dsWatchList.sort = this.watchlistsort;

    this.dsWatchList.paginator = this.paginator;
    this.TotalRecords = this.vm_WatchList.length;
  }

  DownloadClaimListToXlsx(): void {
    let xlsxcolumnNames = [];
    this.PDFColNamesClaimtList = [];
    
    this.PDFColNamesClaimtList.push({
      title: "Booking#",
      dataKey: "Booking_Num",
    });
    this.PDFColNamesClaimtList.push({
      title: "Status",
      dataKey: "BookingStatus_Dsc",
    });
    this.PDFColNamesClaimtList.push({
      title: "Message",
      dataKey: "Message",
    });
    this.PDFColNamesClaimtList.push({
      title: "Ref#",
      dataKey: "Ref",
    });
    this.PDFColNamesClaimtList.push({
      title: "Hold",
      dataKey: "Hold",
    });
    this.PDFColNamesClaimtList.push({
      title: "Exempt",
      dataKey: "Exempt",
    });
    this.PDFColNamesClaimtList.push({
      title: "MTO",
      dataKey: "MTO_Cd",
    });
    this.PDFColNamesClaimtList.push({
      title: "VesselVoyage",
      dataKey: "Carrier",
    });

    if (this.vm_ClaimList.length > 0) {
      this.vm_ClaimList.forEach((element) => {
        let row = [];

        for (let j = 0; j < this.PDFColNamesClaimtList.length; j++) {
          switch (this.PDFColNamesClaimtList[j].dataKey) {
            case "Booking_Num":
              row.push(element.Booking_Num);
              break;
            case "BookingStatus_Dsc":
                row.push(element.BookingStatus_Dsc);
                break;
            case "Message":
              row.push(element.Message);
              break;
            case "Ref":
              row.push(element.Ref);
              break;
            case "Hold":
              row.push(element.Hold);
              break;
            case "Exempt":
              row.push(element.Exempt);
              break;
            case "MTO_Cd":
              row.push(element.MTO_Cd);
              break;
            case "Carrier":
              row.push(element.Carrier);
              break;
          }
        }
        xlsxcolumnNames.push(row);
      });

      this.exportToExcelFile(
        xlsxcolumnNames,
        "Claim List",
        this.PDFColNamesClaimtList
      );
    }
  }

  DownloadWatchListToXlsx(): void {
    let xlsxcolumnNames = [];
    this.PDFColNamesClaimtList = [];
   
    this.PDFColNamesClaimtList.push({
      title: "Booking#",
      dataKey: "Booking_Num",
    });
    this.PDFColNamesClaimtList.push({
      title: "Status",
      dataKey: "BookingStatus_Dsc",
    });
    this.PDFColNamesClaimtList.push({
      title: "Message",
      dataKey: "Message",
    });
    this.PDFColNamesClaimtList.push({
      title: "Ref#",
      dataKey: "Ref",
    });
    this.PDFColNamesClaimtList.push({
      title: "Hold",
      dataKey: "Hold",
    });
    this.PDFColNamesClaimtList.push({
      title: "Exempt",
      dataKey: "Exempt",
    });
    this.PDFColNamesClaimtList.push({
      title: "MTO",
      dataKey: "MTO_Cd",
    });
    this.PDFColNamesClaimtList.push({
      title: "VesselVoyage",
      dataKey: "Carrier",
    });

    if (this.vm_WatchList.length > 0) {
      this.vm_WatchList.forEach((element) => {
        let row = [];

        for (let j = 0; j < this.PDFColNamesClaimtList.length; j++) {
          switch (this.PDFColNamesClaimtList[j].dataKey) {
            case "Booking_Num":
              row.push(element.Booking_Num);
              break;
            case "BookingStatus_Dsc":
                row.push(element.BookingStatus_Dsc);
                break;
            case "Message":
              row.push(element.Message);
              break;
            case "Ref":
              row.push(element.Ref);
              break;
            case "Hold":
              row.push(element.Hold);
              break;
            case "Exempt":
              row.push(element.Exempt);
              break;
            case "MTO_Cd":
              row.push(element.MTO_Cd);
              break;
            case "Carrier":
              row.push(element.Carrier);
              break;
          }
        }
        xlsxcolumnNames.push(row);
      });

      this.exportToExcelFile(
        xlsxcolumnNames,
        "Watch List",
        this.PDFColNamesClaimtList
      );
    }
  }

  public exportToExcelFile(
    json: any[],
    excelFileName: string,
    columnNames: any
  ): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    var range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (var C = range.s.r; C < columnNames.length; ++C) {
      var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
      if (!worksheet[address]) continue;
      worksheet[address].v = columnNames[worksheet[address].v].title;
    }
    const workbook: XLSX.WorkBook = {
      Sheets: { DisplayList: worksheet },
      SheetNames: ["DisplayList"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  //Download PDF Data
  DownloadPdf_Claims() {

    if (this.vm_ClaimList.length > 0) {
      var doc = new jsPDF("l", "in", [1200, 1401]);
      var col = [
        "Booking#",
        "Status",
        "Message",
        "Ref#",
        "Hold",
        "Exempt",
        "MTO",
        "VesselVoyage",
      ];
      var rows = [];
      this.vm_ClaimList.forEach((item) => {
      
        var temp = [
          item.Booking_Num,
          item.BookingStatus_Dsc,
          item.Message,
          item.Ref,
          item.Hold,
          item.Exempt,
          item.MTO_Cd,
          item.Carrier,
          // item.DueDate,
        ];
        rows.push(temp);
      });
      doc.autoTable(col, rows);
      doc.save("Claim List.pdf");
    }
  }


  DownloadPdf_watchlist() {
    if (this.vm_WatchList.length > 0) {
      var doc = new jsPDF("l", "in", [1200, 1401]);
      var col = [
        "Booking#",
        "Status",
        "Message",
        "Ref#",
        "Hold",
        "Exempt",
        "MTO",
        "VesselVoyage",
      ];
      var rows = [];
      this.vm_WatchList.forEach((item) => {
      
        var temp = [
          item.Booking_Num,
          item.BookingStatus_Dsc,
          item.Message,
          item.Ref,
          item.Hold,
          item.Exempt,
          item.MTO_Cd,
          item.Carrier,
          // item.DueDate,
        ];
        rows.push(temp);
      });
      doc.autoTable(col, rows);
      doc.save("Watch List.pdf");
    }
  }

  onClose() {

    let url =  this.router.url;
    stringlog("CLOSE current url: " +  url);
    this.router.navigate(['/']).then(() => { this.router.navigate([url]); })

    stringlog("Close");
    this.dialog.closeAll();
  }
}
