import { stringlog } from 'src/app/csr-containerhistory/csr-containerhistory-type';
import { WebapidataService } from './webapidata.service';
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { apiurls } from '../models/globalconstants';
import { Router } from '@angular/router';
import { PpGlobalConstants } from 'src/app/ppcommon/pp-global-constants';

@Injectable({
  providedIn: "root"
})
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private auth: WebapidataService,
    private router: Router) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    // const token = "";

    const authToken = this.auth.getAuthorizationToken();

    if(authToken == undefined)
    {
      request = request.clone({
        headers: request.headers.set('Authorization', "NOTAUTHORIZED")
      });

      // request = request.clone({
      //   headers: request.headers.set('Access-Control-Allow-Origin', "http://localhost:55040/")
      // });

      request = request.clone({
        headers: request.headers.set('Access-Control-Allow-Headers', "Authorization")
      });

      // request = request.clone({
      //   headers: request.headers.set('Access-Control-Allow-Methods', "GET, POST, PUT, PATCH, POST, DELETE, OPTIONS")
      // });
      // request = request.clone({
      //   headers: request.headers.set('Access-Control-Allow-Headers', "Content-Type")
      // });

      // request = request.clone({
      //   headers: request.headers.set('Access-Control-Max-Age',"86400")
      // });

    }
    else
    {
      request = request.clone({
        headers: request.headers.set('Authorization', authToken)
      });

      // request = request.clone({
      //   headers: request.headers.set('Access-Control-Allow-Origin', "http://localhost:55040/")
      // });
      request = request.clone({
        headers: request.headers.set('Access-Control-Allow-Headers', "Authorization")
      });
      // request = request.clone({
      //   headers: request.headers.set('Access-Control-Allow-Methods', "GET, POST, PUT, PATCH, POST, DELETE, OPTIONS")
      // });
      // request = request.clone({
      //   headers: request.headers.set('Access-Control-Allow-Headers', "Content-Type")
      // });

      // request = request.clone({
      //   headers: request.headers.set('Access-Control-Max-Age',"86400")
      // });
    }
    
    // //if (request.url != apiurls.addCartItem)
    // {
    //   request = request.clone({
    //     headers: request.headers.set("Authorization", token)
    //   });
    // }
    // request = request.clone({
    //   headers: request.headers.set("Content-Type", "application/json")
    // });
    request = request.clone({
      headers: request.headers.set("Accept", "application/json")
    });
    // request = request.clone({
    //   headers: request.headers.set("Accept", "text/html; q=1.0, text/*; q=0.8, image/gif; q=0.6, image/jpeg; q=0.6, image/*; q=0.5, */*; q=0.1,gzip, deflate")
    // });
    // request = request.clone({
    //   headers: request.headers.set("Cache-Control", "no-cache, no-store, must-revalidate, post- check=0, pre-check=0")
    // });
    // request = request.clone({
    //   headers: request.headers.set("Pragma", "no-cache")
    // });
    // request = request.clone({
    //   headers: request.headers.set("Expires", "0")
    // });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let data = {};

        if(error.status === 403 && error.statusText == "TokenExpired")
        {
          stringlog("error:" +  "Token Expired");
          
          this.auth.logout();
          localStorage.setItem(PpGlobalConstants.TokenExpired, "true");
          // this.router.navigate(['pphome']);
          
        }

        data = {
          reason: error && error.error.reason ? error.error.reason : "",
          status: error.status
        };


        if (
          data["status"] === 401 &&
          error["error"]["reason"] === "invalid_token"
        ) {
          /* Call the refresh token service here and do paste below line inside success method*/
          // return next.handle(request);
        }
        return throwError(error);
      })
    );
  }
}
