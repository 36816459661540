import { StringifyOptions } from 'querystring';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-backdrop',
  templateUrl: './loading-backdrop.component.html',
  styleUrls: ['./loading-backdrop.component.scss']
})
export class LoadingBackdropComponent implements OnInit {

  @Input() message = "";

  constructor() { }

  ngOnInit() {
  }

}
