<table>
	<tbody>
		<tr>
			<td class="HomePageText" colspan="2">
				<p>PortCheck provides rate collection services for the ports of&nbsp;Los Angeles and Long Beach's Clean Truck Program. PortCheck collects the Clean Truck Fund Rate.</p>
				<p>&nbsp;</p>
				<p>To contact the Customer Service Center for both PierPASS and PortCheck, please call: (877) 863-3310.</p>
				<p><a title="PortCheck Terms &amp; Conditions" target="_blank" href="https://www.wcmtoa.org/wp-content/uploads/2022/05/2022-05-20-PortCheck-Terms-and-Conditions.pdf">PortCheck Terms &amp; Conditions</a>
					<br /><a title="PortCheck Privacy Policy" target="_blank" href="https://www.wcmtoa.org/wp-content/uploads/2022/05/2022-05-23-PortCheck-Privacy-Policy.pdf">PortCheck Privacy Policy</a></p>
			</td>
		</tr>
	</tbody>
</table>