import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { APIService } from './httpservicefactory.service';
@Injectable()
export class DefaultGuardService implements CanActivate {
  constructor(private router: Router,private aPIService: APIService,) {}
  public canActivate(): Observable<boolean> | boolean {
    return true;
  }
}
