import { environment } from "./../../../environments/environment.qa";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import {
  LoginResponse,
  RGBCO,
  UserAccountTypeInfo,
  UserInfoType,
} from "src/app/common/typesinfo/typesinfo";
import { stringlog } from "src/app/csr-containerhistory/csr-containerhistory-type";
import {
  PpGlobalConstants,
  debuglog,
} from "src/app/ppcommon/pp-global-constants";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { ValidationMethods } from "src/app/common/models/validationmethods";
import { SharedService } from "src/app/common/services/globalvariable.service";
import { TranslateService } from "src/app/common/services/translate.service";
import { WebapidataService } from "src/app/common/services/webapidata.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-from-external-landing",
  templateUrl: "./from-external-landing.component.html",
  styleUrls: ["./from-external-landing.component.scss"],
})
export class FromExternalLandingComponent implements OnInit {
  q1: any;
  decoded: string;
  loginResponse = <LoginResponse>{};
  externaldatalogin = <ExternalLoginData>{};
  ShowLoader: boolean;
  DisplayError = false;
  ErrorMessage = "";
  useraccounttype: UserAccountTypeInfo;

  constructor(
    private activatedroute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: WebapidataService,
    private sharedService: SharedService,
    private validate: ValidationMethods,
    public locale: TranslateService
  ) {
    this.ShowLoader = true;
  }

  ngOnInit() {
    debuglog("sitemode: before If: " + environment.sitemode);

    localStorage.setItem(PpGlobalConstants.UserAccounTypeInfo,"");

      debuglog("landing page INIT");

      this.DisplayError = false;
  
      this.dataService.ClearAllLocalData();
      this.ShowForkInPage();
  
      this.activatedroute.queryParams.subscribe((params) => {
        try {
          if (params["q"]) {
            debuglog("if query params:");
            this.q1 = params.q;
            // console.log(this.q1);
  
            this.decoded = window.atob(this.q1);
  
            this.externaldatalogin = JSON.parse(this.decoded);
  
            this.loginResponse.UserName = this.externaldatalogin.UserName;
            this.loginResponse.IsAuthenticated =
              this.externaldatalogin.IsAuthenticated;
            this.loginResponse.Token = this.externaldatalogin.Token;
            this.loginResponse.FirstLoginTime =
              this.externaldatalogin.FirstLoginTime;
            this.loginResponse.LastRefreshedTime =
              this.externaldatalogin.LastRefreshedTime;
            this.loginResponse.Password = "";
  
            // localStorage.setItem(PpGlobalConstants.AUTH, JSON.stringify(this.loginResponse));
  
            localStorage.setItem(
              PpGlobalConstants.FeeTypeCTFTMF,
              this.externaldatalogin.ProgramType
            );
  
            this.CheckUserAccountTypeStatus(this.loginResponse.UserName);
  
            if (this.externaldatalogin.IsAuthenticated == true) {
              this.getsetCommonInfo();
              this.CheckUserAccountTypeStatus(this.loginResponse.UserName);
            }
          } else {
            // console.log("error: parsing query string in landing page:" + error);
            this.DisplayError = true;
            this.ErrorMessage =
              "UnAuthorised Access OR Request tampered. Redirecting to Home Site wiithin 10 seconds...";
            this.ShowLoader = false;
            // this.router.navigate(['/pagenotfound]']);
  
            setTimeout(() => {
              this.dataService.logout();
            }, 100);
          }
        } catch (error) {
          console.log("error: parsing query string in landing page:" + error);
          this.DisplayError = true;
          this.ErrorMessage = "UnAuthorised Access OR Request tampered";
          this.ShowLoader = false;
          // this.router.navigate(['/pagenotfound]']);
        }
      });
    }

  getsetCommonInfo() {
    // this.ProcessPopUp();

    // Store Auth info, username, token
    localStorage.setItem(
      PpGlobalConstants.AUTH,
      JSON.stringify(this.loginResponse)
    );
    let AuthInfo: LoginResponse = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AUTH) || "{}"
    );

    this.dataService.getUserInfo().subscribe(
      (data: any) => {
        // this.loginResponse = data;
        const userInfo: UserInfoType = data.RG_User[0];
        let BCOInfoList: RGBCO[] = null;

        if (data.hasOwnProperty("RG_BCO")) {
          BCOInfoList = data.RG_BCO;
          stringlog(
            "getsetCommonInfo - BCO Info: " + JSON.stringify(BCOInfoList)
          );
          localStorage.setItem(
            PpGlobalConstants.BCOInfoList,
            JSON.stringify(BCOInfoList)
          );
        }

        localStorage.setItem(
          PpGlobalConstants.AdditionalUserInfo,
          JSON.stringify(userInfo)
        );

        let UserInfo_Get: UserInfoType = JSON.parse(
          localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
        );

        // stringlog(UserInfo_Get);

        if (userInfo.ResetPwdOnNextLogin_Flg == "Y") {
          localStorage.setItem(PpGlobalConstants.AvoidTMFCTFForkInPage, "true");
          // console.log("Avoid: " + JSON.parse(localStorage.getItem(PpGlobalConstants.AvoidTMFCTFForkInPage)))
          let AvoidTMFCTFForkInPage: boolean = false;
          AvoidTMFCTFForkInPage = JSON.parse(
            localStorage.getItem(PpGlobalConstants.AvoidTMFCTFForkInPage)
          );
          // stringlog("Avoid TMFCTFForkInPage :"  +  AvoidTMFCTFForkInPage);

          this.router.navigate(["/firstlogin"]);
          return;
        }

        this.dataService.PushUserFullName(
          userInfo.First_Nm + " " + userInfo.Last_Nm
        );

        // stringlog(UserInfo_Get);

        //loading default customview data
        this.sharedService
          .loadCustomViewData(
            this.validate.EnvironmentConfig().ImportClaimList_InterfaceId,
            this.validate.EnvironmentConfig().ImportClaimList_Interface_Uid,
            "importclaimlist"
          )
          .then((data) => {});

        //loading default customview data for Export claim List
        this.sharedService
          .loadCustomViewData(
            this.validate.EnvironmentConfig().ExportClaimList_InterfaceId,
            this.validate.EnvironmentConfig().ExportClaimList_Interface_Uid,
            "exportclaimlist"
          )
          .then((data) => {});

        //loading default customview data for Export claim list for container
        this.sharedService
          .loadCustomViewData(
            this.validate.EnvironmentConfig()
              .ExportClaimListContainer_InterfaceId,
            this.validate.EnvironmentConfig()
              .ExportClaimListContainer_Interface_Uid,
            "exportclaimlistcontainer"
          )
          .then((data) => {});

        //loading default customview data for Import FeesDue
        this.sharedService
          .loadCustomViewData(
            this.validate.EnvironmentConfig().ImportFeesDue_InterfaceId,
            this.validate.EnvironmentConfig().ImportFeesDue_Interface_Uid,
            "importfeesdue"
          )
          .then((data) => {});

        //loading default customview data for Export FeesDue
        this.sharedService
          .loadCustomViewData(
            this.validate.EnvironmentConfig().ExportFeesDue_InterfaceId,
            this.validate.EnvironmentConfig().ExportFeesDue_Interface_Uid,
            "exportfeesdue"
          )
          .then((data) => {});

        //loading default customview data for Export PendingList
        this.sharedService
          .loadCustomViewData(
            this.validate.EnvironmentConfig().ExportPendingList_InterfaceId,
            this.validate.EnvironmentConfig().ExportPendingList_Interface_Uid,
            "exportpendinglist"
          )
          .then((data) => {});

        //loading default customview data for Import PendingList
        this.sharedService
          .loadCustomViewData(
            this.validate.EnvironmentConfig().ImportPendingList_InterfaceId,
            this.validate.EnvironmentConfig().ImportPendingList_Interface_Uid,
            "importpendinglist"
          )
          .then((data) => {});

        //loading default customview data for CSR BookingHistory
        this.sharedService
          .loadCustomViewData(
            this.validate.EnvironmentConfig().CSRBookingHistory_InterfaceId,
            this.validate.EnvironmentConfig().CSRBookingHistory_Interface_Uid,
            "csrbookinghistory"
          )
          .then((data) => {});

        if (userInfo.CompanyType_Cd == "H" || userInfo.CompanyType_Cd == "E") {
          stringlog("login H or E");
          this.ProceedToNavigation(UserInfo_Get);
        } else {
          let bcorequest = {
            Company_Id: userInfo.Company_Id,
            FeeTypeCode: "TMF",
          };

          this.dataService
            .Login_GetBCOInfo(bcorequest)
            .subscribe((data: any) => {
              if (data.result == "error") {
                stringlog("login check BCO Error");
              } else {
                stringlog("login check BCO GOOD");
                stringlog(JSON.stringify(data[0]));

                localStorage.setItem(
                  PpGlobalConstants.BCOInfo,
                  JSON.stringify(data[0])
                );

                let BCOInfo_Get: any = JSON.parse(
                  localStorage.getItem(PpGlobalConstants.BCOInfo) || "{}"
                );

                debuglog("Before Proceeding to Nav");

                this.ProceedToNavigation(UserInfo_Get);

                let VTXCreditStatus_Cd = BCOInfo_Get.BCOInfo_Get;

                // stringlog("Deserialize:" +  JSON.stringify(BCOInfo_Get));
              }
            });
        }
      },
      (error) => {
        stringlog(error);
      }
    );
  }

  private CheckUserAccountTypeStatus(p_username : string)
  {
    this.dataService.CheckCompanyAccountTypeStatus(p_username).subscribe(
      (data: any) => {
        if(data.result == "good"){

          this.useraccounttype = data;
          localStorage.setItem(PpGlobalConstants.UserAccounTypeInfo,JSON.stringify(this.useraccounttype));

          if(this.useraccounttype.CTF == false && this.useraccounttype.Others == false)
          {
            this.DisplayError = true;
            this.ErrorMessage = this.useraccounttype.HelpCenter;
            this.ErrorMessage += "<br>Redirecting to Home Site wiithin 10 seconds...";
            //  "UnAuthorised Access OR Request tampered. Redirecting to Home Site wiithin 10 seconds...";
            this.ShowLoader = false;
            // this.router.navigate(['/pagenotfound]']);
  
            setTimeout(() => {
              this.dataService.logout();
            }, 100);
          }

        }
        else if(data.result == "error")
        {
          // user status failed
          this.ErrorMessage = data.errormessage;
          this.ShowLoader =  false;
          return;
        }
      },
    
    (error) => {
      stringlog(error);
      this.ShowLoader =  false;
    }
  );

  }

  private ProceedToNavigation(UserInfo_Get: UserInfoType) {
    debuglog("ProceedToNavigation -  STARTED");
    // localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"TMF")

    if (UserInfo_Get.CompanyType_Cd == "H") {
      stringlog("Host User");
      this.router.navigate(["/csr/csrcontainerhistory"]);
    } else if (UserInfo_Get.CompanyType_Cd == "E") {
      this.router.navigate(["/csr/csrcontainerhistory"]);
    } else if (
      UserInfo_Get.CompanyType_Cd == "B" ||
      UserInfo_Get.CompanyType_Cd == "S"
    ) {
      debuglog("ProceedToNavigation -  b/s");

      if (this.externaldatalogin.ProgramType == "TMF") {
        // this.titleService.setTitle('PierPASS');
        this.ShowLoader = true;
        // this.showselection = false;
        stringlog("In ProceedToNavigation: Set TMF");
        localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF, "TMF");
        environment.logoutURL = environment.PierPASSHomeURL;
        debuglog('  environment.logoutURL: ' +  environment.logoutURL);
        this.router.navigate(["/accountsummary"]);
      } else if (this.externaldatalogin.ProgramType == "CTF") {
        // this.titleService.setTitle('Portcheck');
        this.setCTF();
      }
    }
  }

  setCTF() {
    // this.showselection = false;
    this.ShowLoader = true;
    stringlog("Set CTF");
    // localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"CTF")
    // this.router.navigate(["/accountsummary"]);

    const BCOlist: RGBCO[] = JSON.parse(
      localStorage.getItem(PpGlobalConstants.BCOInfoList)
    );
    let TMFInfo: RGBCO;
    let CTFInfo: RGBCO;

    BCOlist.forEach((element) => {
      switch (element.FeeType_Cd) {
        case "TMF": {
          TMFInfo = element;
          break;
        }
        case "CTF": {
          CTFInfo = element;
          break;
        }
        default: {
          //statements;
          break;
        }
      }
    });

    stringlog("CTFInfo info: " + JSON.stringify(CTFInfo));

    if (
      CTFInfo.TermsAcceptanceUser_Id != null &&
      CTFInfo.TermsAcceptance_DtTm != null
    ) {
      debuglog("CTF TC -- accepted");
      localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF, "CTF");
      environment.logoutURL = environment.WeeblySiteURL;
      debuglog('  environment.logoutURL: ' +  environment.logoutURL);
      this.router.navigate(["/accountsummary"]);
    } else {
      debuglog("CTF TC -- NOT accepted");

      this.router.navigate(["/acceptctftermsconditions"]);

      // localStorage.setItem(PpGlobalConstants.FeeTypeCTFTMF,"CTF")
      // this.router.navigate(['/']).then(() => { this.router.navigate(["/acceptctftermsconditions"]); })
      // this.router.navigate(["/acceptctftermsconditions"]);
    }
  }

  private ShowForkInPage() {
    this.dataService.GetTMFCTFForkIn().subscribe((data: any) => {
      stringlog("ShowForkInPage");
      stringlog(JSON.stringify(data));
      if (data.result == "error") {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage, "false");
        stringlog("ShowForkInPage: Error");
      } else if (data.result == "good" && data.data == "true") {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage, "true");
        stringlog("ShowForkInPage: true");
      } else if (data.result == "good" && data.data == "false") {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage, "false");
        stringlog("ShowForkInPage: false");
      }
    });
  }
}

export interface ExternalLoginData {
  UserName: string;
  IsAuthenticated: boolean;
  Token: string;
  Error: string;
  FirstLoginTime: Date;
  LastRefreshedTime: string;
  ProgramType: string;
}
