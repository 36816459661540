<!-- *** PierPASS Login Control ***** -->
<!-- <app-loading-backdrop *ngIf="ShowLoader" [message]= "locale.data.hp_login_loading" ></app-loading-backdrop>  -->
<div class="loading-screen-wrapper" *ngIf="ShowLoader">
  <div class="loading-screen-icon"
    style="font-size: 30px; font-weight: 600; opacity: 0.9">
    {{locale.data.hp_login_loading}}
  </div>
</div>
<!-- <mat-spinner *ngIf="ShowLoader"></mat-spinner> -->
<div class="matcard">

  <div style="text-align: center" *ngIf="PierPASS">
    <img
      src="../../assets/images/PPLogoV2.png"
      style="width: 156px; height: 156px"
    />
  </div>
  <div style="text-align: center" *ngIf="Portcheck">
    <img
      src="../../assets/images/pcimg_3.jpg"
      style="width: 150px; margin-top: 50px;"
    />
  </div>
  <div class="row" style="display:none">
    <div class="column" *ngIf="PierPASS">
      <img
      src="../../assets/images/PPLogoV2.png"
      style="width: 156px; height: 156px"
    />
    </div>
    <div class="column" *ngIf="Portcheck"> <img
      src="../../assets/images/pcimg_3.jpg" style="margin-top: 50px;width: 150px;"   
    />
    </div>
   
 </div>
  
  <mat-card-content>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <p>
        <mat-form-field>
          <input
            type="text"
            matInput
            [placeholder]="locale.data.user_login_id"
            formControlName="username"
          />
        </mat-form-field>
      </p>
      <p>
        <mat-form-field>
          <input
            type="password"
            matInput
            [placeholder]="locale.data.hp_password"
            formControlName="password"
          />
        </mat-form-field>
      </p>
      <p *ngIf="LoginFailed" class="error">{{ ErrorMessage }}</p>

      <div
        style="
          color: #0088e8;
          font-size: 12px;
          font-weight: normal;
          font-family: sans-serif;
          padding-bottom: 30px;
        "
      >
        <a
          style="text-decoration: none; float: left"
          [routerLink]=""
          (click)="openNewCompanyRegistration()"
          ><b>{{ locale.data.hp_new_com_reg }} </b></a
        >
        <span class="fill-remaining-space;" display="width:1%"></span>
        <a
          style="text-decoration: none; float: right"
          [routerLink]=""
          (click)="openTabResetPassword()"
          ><b>{{ locale.data.hp_fwt_pwd }}</b></a
        >
      </div>

      <!-- <div class="ppnewuserlink" style="padding-bottom: 20px;">
        <div style=" color: #0088e8; font-size: 12px; font-weight: normal; font-family: sans-serif; margin-left: auto; " >
           <a style="text-decoration:none;" [routerLink]="" (click)="openTabResetPassword()"><b>{{locale.data.hp_fwt_pwd}}</b></a> 
        </div>
     </div> -->
      <div class="loginbutton">
        <button type="submit" mat-button style="width: 100%; display: block">
          {{ locale.data.hp_login }}
        </button>
      </div>
      <div style="padding-bottom: 10px; padding-left: 10px; padding-right: 10px; text-align: center;">
        <span style="color: #757575; font-size: 14px !important; font-weight: normal; font-family: sans-serif;">Registered users for PierPASS and PortCheck use the same Login ID and Password</span>
      </div>
      <div
        style="
          font-size: 12px;
          font-weight: normal;
          font-family: sans-serif;
          padding-bottom: 30px;
        "
      >
      <a   style="text-decoration: underline; float: left" title="PierPASS Privacy Policy" target="_blank" href="https://www.wcmtoa.org/wp-content/uploads/2022/05/2022-05-23-PierPASS-Privacy-Policy.pdf"><b>Privacy Policy</b></a>
        <span class="fill-remaining-space;" display="width:1%"></span>
        <a  style="text-decoration: underline; float: right" title="PierPASS Terms &amp; Conditions" target="_blank" href="https://www.wcmtoa.org/wp-content/uploads/2022/05/2022-05-20-PierPASS-Terms-Conditions.pdf"><b>Terms &amp; Conditions</b></a>
      </div>
      <!-- <div class="ppnewuserlink" *ngIf="false"> <span style=" color: #757575; font-size: 16px; font-weight: normal; font-family: sans-serif; " >{{locale.data.hp_new_user}}</span > <span style=" color: #0088e8; font-size: 16px; font-weight: normal; font-family: sans-serif; " > <a style="text-decoration:none;" [routerLink]="" (click)="openTab()"><b>{{locale.data.hp_register_now}}</b></a> </span> </div> -->
    </form>
  </mat-card-content>
</div>
