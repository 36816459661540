<div class="example-container">
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>


      <!-- Container# Column -->
      <ng-container matColumnDef="FullConNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-weight: bold;">{{locale.data.container_hash}}</th>
        <td mat-cell *matCellDef="let element" style="font-weight:bold;"> {{element.FullConNumber}} </td>
      </ng-container>

      <!-- Import/Export Column -->
      <ng-container matColumnDef="ConStatus_Dsc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-weight: bold;">{{locale.data.import_export}}</th>
        <td mat-cell *matCellDef="let element"> {{element.ConStatus_Dsc }} </td>
      </ng-container>
      <!-- Vessel/Voyage Column -->
      <ng-container matColumnDef="Carrier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-weight: bold;">{{locale.data.eir_vessel_voyage}}</th>
        <td mat-cell *matCellDef="let element"> {{element.Carrier}} </td>
      </ng-container>
      <!-- BL/Booking Column -->
      <ng-container matColumnDef="CargoRef_Num">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-weight: bold;">{{locale.data.bl_booking}}</th>
        <td mat-cell *matCellDef="let element"> {{element.CargoRef_Num}} </td>
      </ng-container>
      <!-- Ref_Num Column -->
      <ng-container matColumnDef="Ref_Num">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-weight: bold;">{{locale.data.Ref_Num}}</th>
        <td mat-cell *matCellDef="let element"> {{element.Ref_Num}} </td>
      </ng-container>
      <!--  Fee Amount Column -->
      <ng-container matColumnDef="FeePaid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-weight: bold;">{{locale.data.fee_amount_1}}</th>
        <td mat-cell *matCellDef="let element"> {{element.FeePaid | currency}} </td>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    </table>
  </div>
</div>
<!-- <mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator> -->