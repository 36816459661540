import { stringlog } from 'src/app/csr-containerhistory/csr-containerhistory-type';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA} from '@angular/material';
import { TranslateService } from 'src/app/common/services/translate.service';

@Component({
  selector: 'app-message-alert',
  templateUrl: './message-alert.component.html',
  styleUrls: ['./message-alert.component.scss']
})
export class MessageAlertComponent implements OnInit {
  message: any;

  constructor(public locale: TranslateService, 
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit() {
    this.message =  this.data.PopUp[0].message;

  }

  onClose() {
    this.dialog.closeAll();
    
  }

}
