import { AccountUsersComponent } from './../../Account/account-users/account-users.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { LoadingBackdropComponent } from "../components/loading-backdrop/loading-backdrop.component";
import { MaterialModule } from "./material.module";
//import { ConfirmDialogComponent } from "../components/confirmdialog/confirmdialog.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandlerService } from "../services/errorhandler.service";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
//import { DefaultComponent } from "../components/defaultcomponent/defaultcomponent.component";
//import { DragDirective } from "../directives/DragDirective";
import { MatCardModule, MAT_DATE_LOCALE } from "@angular/material";
import { ConfirmDialogComponent } from "../components/confirmdialog/confirmdialog.component";
import { NoSanitizePipe } from "../pipe/no-sanitize.pipe";
import { CommonModule } from "@angular/common";
import { CustomViewComponent } from 'src/app/shared/components/custom-view/custom-view.component';
import { CustomviewfilterComponent } from 'src/app/shared/components/custom-view/customviewfilter.component';
import { CustomviewsaveComponent } from 'src/app/shared/components/custom-view/customviewsave.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AccountAddeditUserComponent } from 'src/app/Account/account-users/account-addedit-user/account-addedit-user.component';
import { GenericpopupComponent } from 'src/app/ppcommon/genericpopup/genericpopup.component';
import { ImportSearchComponent } from 'src/app/import-search/import-search.component';
import { ImportsearchCreditDialogComponent } from 'src/app/import-search/importsearch-credit-dialog.component';
import { ImportSearchresultDialogComponent } from 'src/app/import-search/import-searchresult-dialog.component';
import { ExportClaimListComponent } from 'src/app/Export/export-claim-list/export-claim-list.component';
import { ExportPendingListComponent } from 'src/app/export-pending-list/export-pending-list.component';
import { AccountSummaryComponent } from 'src/app/account-summary/account-summary.component';
import { AccountSummaryInvoiceListDialogComponent } from 'src/app/account-summary/account-summary-invoice-list-dialog.component';
import { RouterModule } from '@angular/router';
import { ExportFeesDueComponent } from 'src/app/Export/export-fees-due/export-fees-due.component';
import { ImportClaimListComponent } from 'src/app/import-claim-list/import-claim-list.component';
import { ImportFeesDueComponent } from 'src/app/Import/import-fees-due/import-fees-due.component';
import { ImportPendingListComponent } from 'src/app/import-pending-list/import-pending-list.component';
import { AccountReceiptHistoryComponent } from 'src/app/account-receipt-history/account-receipt-history.component';
import { ExportsSearchClaimComponent } from 'src/app/export-search/exports-search-claim.component';
import { ExportsSearchResultDetailsComponent } from 'src/app/export-search/exports-search-result-details.component';
import { AccountReceiptHistoryDetailsComponent } from 'src/app/account-receipt-history/account-receipt-history-details/account-receipt-history-details.component';
import { ExpandcollapsepanelComponent } from 'src/app/account-receipt-history/expandcollapsepanel/expandcollapsepanel.component';
import { ImportsearchContainerPaymentComponent } from 'src/app/import-search/payment/importsearch-container-payment.component';
import { TextMaskModule } from 'angular2-text-mask';
import { DirectPaymentComponent } from 'src/app/Account/add-funds/direct-payment/direct-payment.component';
import { ExportDirectPaymentComponent } from 'src/app/Account/add-funds/export-direct-payment/export-direct-payment.component';
import { ExportClaimConinfoComponent } from 'src/app/Export/export-claim-list/export-claim-coninfo/export-claim-coninfo.component';
import { ExportBookingConinfoComponent } from 'src/app/Export/export-claim-list/export-booking-coninfo/export-booking-coninfo.component';
import { PierpassButtonControlDirective } from 'src/app/ppcommon/pierpass-button-control.directive';


@NgModule({
  declarations: [ ConfirmDialogComponent,
    NoSanitizePipe,
    LoadingBackdropComponent,
    AccountUsersComponent,
    CustomViewComponent,
CustomviewfilterComponent,
CustomviewsaveComponent,
AccountAddeditUserComponent,
GenericpopupComponent,

ImportSearchComponent,
ImportSearchresultDialogComponent,
ImportsearchCreditDialogComponent,
ExportClaimListComponent,
ExportPendingListComponent,
AccountSummaryComponent,
AccountSummaryInvoiceListDialogComponent,
ExportFeesDueComponent,
ImportClaimListComponent,
ImportFeesDueComponent,
ImportPendingListComponent,
AccountReceiptHistoryComponent,
AccountReceiptHistoryDetailsComponent,
ExpandcollapsepanelComponent,
ExportsSearchClaimComponent,
ExportsSearchResultDetailsComponent,
ImportsearchContainerPaymentComponent,
DirectPaymentComponent,
ExportDirectPaymentComponent,
ExportClaimConinfoComponent,
ExportBookingConinfoComponent,
PierpassButtonControlDirective
  ],
  imports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatCardModule,
    NgxMaterialTimepickerModule,
    RouterModule,
    TextMaskModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports: [ConfirmDialogComponent,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NoSanitizePipe,
    LoadingBackdropComponent,
    AccountUsersComponent,
    CustomViewComponent,
    CustomviewfilterComponent,
    CustomviewsaveComponent,
    NgxMaterialTimepickerModule,
    AccountAddeditUserComponent,
    GenericpopupComponent,
    
    AccountAddeditUserComponent,
    ImportSearchComponent,
    ImportSearchresultDialogComponent,
ImportsearchCreditDialogComponent,
ExportClaimListComponent,
ExportPendingListComponent,
AccountSummaryComponent,
AccountSummaryInvoiceListDialogComponent,
ExportFeesDueComponent,
ImportClaimListComponent,
ImportFeesDueComponent,
ImportPendingListComponent,
AccountReceiptHistoryComponent,
AccountReceiptHistoryDetailsComponent,
ExpandcollapsepanelComponent,
ExportsSearchClaimComponent,
ExportsSearchResultDetailsComponent,
ImportsearchContainerPaymentComponent,
DirectPaymentComponent,
ExportDirectPaymentComponent,
ExportClaimConinfoComponent,
ExportBookingConinfoComponent,
PierpassButtonControlDirective
  ],
  entryComponents: [
    ConfirmDialogComponent, 
    LoadingBackdropComponent,
    AccountAddeditUserComponent,
    CustomviewfilterComponent,
    CustomviewsaveComponent,
    GenericpopupComponent,
    CustomviewsaveComponent,
    ImportSearchresultDialogComponent,
ImportsearchCreditDialogComponent,
AccountSummaryInvoiceListDialogComponent,
ExportsSearchResultDetailsComponent,
AccountReceiptHistoryDetailsComponent,
ImportsearchContainerPaymentComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerService, multi: true },
    NoSanitizePipe
  ]
})
export class SharedModule {}
