<div mat-dialog-header class="vesseluploadheader">
  <mat-icon class="confirmicon">warning</mat-icon>
  <span class="confirmtext">{{locale.data.confirm_delete}}</span>
</div>
<div mat-dialog-content>
  <span class="confirmmessage warn-message">{{data}}</span>
</div>
<div mat-dialog-actions fxLayout="row wrap">
  <button mat-button (click)="cancelClick()">{{locale.data.no}}</button>
  <button mat-button class="btn-submit" (click)="okClick()">{{locale.data.yes}}</button>
</div>