<div>
    <mat-toolbar>
      <small></small>
      <span class="fill-remaining-space"></span>
      <button class="btn-dialog-close" mat-stroked-button tabIndex="-1"   (click)="onClose()"> <mat-icon>clear</mat-icon> </button>
    </mat-toolbar>
  </div>
  <br>
    <div class="divtitle">
      <span [innerHTML]="Title | noSanitize"></span>
    </div>
    <div class="as_subcontainer">
      <span [innerHTML]="Content | noSanitize"></span>
    </div>
  