<div id="main">
  <div style="width: 50vw">
    <app-loading-backdrop *ngIf="ShowLoader"></app-loading-backdrop>
  </div>
  <div id="directpayment">
    <app-importsearch-container-payment *ngIf="directPayment" [selectedUserFeesDueList]="this.selectedUserFeesDueList"></app-importsearch-container-payment>
  </div>
  <div id="claimwatchlist" *ngIf="claimwatchlistdisplay">
    <div>
      <div class="sticky">
        <mat-toolbar>
          <small>{{locale.data.import_search}}</small>
          <span class="fill-remaining-space"></span>
          <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1" > <mat-icon>clear</mat-icon> </button>
        </mat-toolbar>
        <mat-card>
          <div *ngIf="!csrreadonly" style="display: flex; justify-content: flex-end">
            <div *ngIf="!StartEndDateRangeExists">
              <span class="fill-remaining-space"></span>
              <button mat-raised-button style=" color: #757575; background-color: white; margin-right: 10px; margin-left: auto; " (click)="Cancel()" > {{locale.data.cancel}} </button>
  
              <button  *ngIf="vm_WatchList.length > 0" mat-raised-button  [disabled]="!SUBMITOnly" style="margin-right: 10px; width: 170px" id="btnPendingSubmit" (click)="SubmitButton('submit')"> {{locale.data.submit}} </button>
              <button  *ngIf="vm_ClaimList.length > 0" mat-raised-button  [disabled]="!PAYplusSUBMIT" style="margin-right: 0px" id="btnSubmit" (click)="SubmitButton('pay')"> {{locale.data.pay_plus_submit}}</button>
            
            </div>
            <div *ngIf="StartEndDateRangeExists">
              <span class="fill-remaining-space"></span>
              <button mat-raised-button style=" color: #757575; background-color: white; margin-right: 10px; margin-left: auto; " (click)="Cancel()" > {{locale.data.cancel}} </button>
              
              <button *ngIf="vm_WatchList.length > 0" mat-raised-button [disabled]="!SUBMITOnly"  style="margin-right: 10px; width: 170px" id="btnPendingSubmit1" (click)="SubmitButton('submit')" > {{locale.data.submit}} </button>
              <button *ngIf="vm_ClaimList.length > 0" mat-raised-button [disabled]="!PAYplusSUBMIT" style="margin-right: 10px; width:230px" id="btnSubmit" (click)="SubmitButton('pay')" >{{CurrentTariffLabel}}</button>
              <div style="display:inline"  [matTooltip]="ToolTip">
                <button *ngIf="vm_ClaimList.length > 0  " mat-raised-button [disabled]="!PAYplusSUBMIT" style="margin-right: 0px; width:210px" id="btnSubmit" (click)="SubmitButton('futurepay')" >{{FutureButtonLabel}}</button>
              </div>
            </div>
          </div>
          <div *ngIf="csrreadonly" style="display: flex; justify-content: flex-end">
            <div *ngIf="!StartEndDateRangeExists">
              <span class="fill-remaining-space"></span>
              <button mat-raised-button style=" color: #757575; background-color: white; margin-right: 10px; margin-left: auto; " (click)="Cancel()" > {{locale.data.cancel}} </button>
  
              <button  *ngIf="vm_WatchList.length > 0" mat-raised-button PierpassButtonControl="IMPSEARCHCDIRECTPAYSUBMIT1"  style="margin-right: 10px; width: 170px" id="btnPendingSubmit" (click)="SubmitButton('submit')"> {{locale.data.submit}} </button>
              <button  *ngIf="vm_ClaimList.length > 0" mat-raised-button PierpassButtonControl="IMPSEARCHCDIRECTPAYSUBMIT1" style="margin-right: 0px" id="btnSubmit" (click)="SubmitButton('pay')"> {{locale.data.pay_plus_submit}}</button>
            
            </div>
            <div *ngIf="StartEndDateRangeExists">
              <span class="fill-remaining-space"></span>
              <button mat-raised-button style=" color: #757575; background-color: white; margin-right: 10px; margin-left: auto; " (click)="Cancel()" > {{locale.data.cancel}} </button>
              
              <button *ngIf="vm_WatchList.length > 0" mat-raised-button PierpassButtonControl="IMPSEARCHCDIRECTPAYSUBMIT1"  style="margin-right: 10px; width: 170px" id="btnPendingSubmit1" (click)="SubmitButton('submit')" > {{locale.data.submit}} </button>
              <button *ngIf="vm_ClaimList.length > 0" mat-raised-button PierpassButtonControl="IMPSEARCHCDIRECTPAYSUBMIT1" style="margin-right: 10px; width:230px" id="btnSubmit" (click)="SubmitButton('pay')" >{{CurrentTariffLabel}}</button>
              <div style="display:inline"  [matTooltip]="ToolTip">
                <button *ngIf="vm_ClaimList.length > 0" mat-raised-button PierpassButtonControl="IMPSEARCHCDIRECTPAYSUBMIT1"  style="margin-right: 0px; width:210px" id="btnSubmit" (click)="SubmitButton('futurepay')" >{{FutureButtonLabel}}</button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    
      <br>

      <div>
        <mat-card style="display: flex;">  
          <div>
            <span class="heading_black" style="padding-left: 0px">
              {{locale.data.available_pay_caps}}
            </span>
            <div style="background-color: white; color: black; font-size: small" >
              <span class="text-color" style="display: block">
                {{locale.data.cons_pay_release}}
              </span>
              <span class="greyoutsublabel">
                {{locale.data.is_delete_info_msg1}}
                <br>
                {{locale.data.is_delete_info_msg2}}
                <mat-icon style="cursor: pointer; color: #0082be">delete</mat-icon>
                {{locale.data.is_delete_info_msg3}}

                <!-- Check the box(es) next to containers you wish to pay for
                during this transaction. Click 'Check All' to select all
                containers for payment. <br>Click Delete (<mat-icon style="cursor: pointer; color: #0082be">delete</mat-icon>) Icon next to containers you
                do not wish to pay for at all. -->
              </span>
               <!-- <span class="greyoutsublabel" [innerHTML]="locale.data.is_delete_info_msg">
                {{locale.data.is_delete_info_msg}}
              </span> -->
            </div>
          </div>
          <span class="fill-remaining-space"></span>
          <div style="justify-content: flex-end; padding-top: 50px; padding-right: 20px;">
            <div class="export-result-details">
              &nbsp;
              <mat-icon
                mat-icon-button
                (click)="DownloadPDF_Claimlist()"
                matTooltip="PDF"
              >
                <img
                  src="../assets/images/pdfmeduim.png"
                  style="
                    vertical-align: middle;
                    height: 28px;
                    width: 30px;
                    cursor: pointer;
                  "
              /></mat-icon>
              &nbsp;
              <mat-icon
                (click)="DownloadExcel_ClaimList()"
                matTooltip="EXCEL"
              >
                <img
                  src="../assets/images/excel.png"
                  style="
                    vertical-align: middle;
                    height: 23px;
                    cursor: pointer;
                  "
                />
              </mat-icon>
            </div>
          </div>
        </mat-card>
        <!-- <mat-card>
         
        </mat-card> -->
        <mat-card>
          <div class="example-container">
            <div class="mat-elevation-z8">
              <table
                id="ExportSearchResultDetails"
                mat-table
                [dataSource]="dsClaimsList"
                *ngIf="showClaimListTable"
                matSort
              >
                <!-- Checkbox Column -->
                <ng-container matColumnDef="cl_select">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox PierpassButtonControl="IMPSEARCHCDIRECTPAYSUBMIT1" (change)=" $event ? masterClaimsToggle() : null; CheckBoxChange() " [checked]=" claimselection.hasValue() && isAllClaimsSelected() " [indeterminate]=" claimselection.hasValue() && !isAllClaimsSelected() " [disabled]=" vm_ClaimList.length == 0 ? true : false " > </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row"> <mat-checkbox PierpassButtonControl="IMPSEARCHCDIRECTPAYSUBMIT1" (click)="$event.stopPropagation()" (change)=" $event ? claimselection.toggle(row) : null; CheckBoxChange() " [checked]="claimselection.isSelected(row)" [disabled]="row.Company_Id == this.Claim_Request.CompanyId" > </mat-checkbox> </td>
                </ng-container>
                <ng-container matColumnDef="Container">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.containerhash}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Container }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.container_status}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Status }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Message">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.message}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Message }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Ref_Num">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.reference_hash}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Ref_Num }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Hold">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.Hold}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Hold }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Size">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.Size}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.ConSz_Cd }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Exempt">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.Exempt}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Exempt }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="MTO">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.MTO}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.MTO }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Carrier">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.VesselVoyage}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Carrier }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Remove">
                  <th mat-header-cell *matHeaderCellDef>Remove</th>
                  <td mat-cell *matCellDef="let element" style="font-size: 10px" > <button mat-icon-button color="primary" (click) = "RemoveContainerFromClaimlist(element.Con_Num) " > &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <mat-icon style="cursor: pointer; color: #0082be" >delete</mat-icon > </button> </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                  class="example-first-header-row"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
              <br>
          <span style="font-size: small; color:black; margin-top: 10px;">{{locale.data.Count}} : {{vm_ClaimList.length}}</span>
            </div>
          </div>
          <div style="display: flex; justify-content: flex-start">
            <p *ngIf="ImportSearchErr" class="error">
              {{ ImportSearchMessageError }}
            </p>
          </div>
          <br />
        
        </mat-card>
       
        <br />
    
      </div>
    
      <br />
      <br />
      <div>
        <mat-card style="display: flex;">  
          <div>
            <span class="heading_black" style="padding-left: 0px">
              {{locale.data.pending_cons}}
            </span>
            <div style="background-color: white; color: black; font-size: small">
              <span class="text-color" style="display: block">
                {{locale.data.is_pending_awaiting_announs}}<br>{{locale.data.is_delete_info_msg2}}<mat-icon style="cursor: pointer; color: #0082be">delete</mat-icon>{{locale.data.is_del_msg}}
              </span>
            </div>
          </div>
          <span class="fill-remaining-space"></span>
          <div style="justify-content: flex-end; padding-top: 50px; padding-right: 20px;">
            <div class="export-result-details">
              &nbsp;
              <mat-icon mat-icon-button (click)="DownloadPDF_Watchlist()" matTooltip="PDF" > <img src="../assets/images/pdfmeduim.png" style=" vertical-align: middle; height: 28px; width: 30px; cursor: pointer; " /></mat-icon>
              &nbsp;
              <mat-icon (click)="DownloadExcel_WatchList()" matTooltip="EXCEL" > <img src="../assets/images/excel.png" style=" vertical-align: middle; height: 23px; cursor: pointer; " /> </mat-icon>
            </div>
          </div>
        </mat-card>
        <!-- <mat-card>
         
        </mat-card> -->
        <mat-card>
          <div class="example-container">
            <div class="mat-elevation-z8">
              <table #table2 #watchlistsort="matSort" id="ExportSearchResultWatchListDetails" mat-table [dataSource]="dsWatchList" *ngIf="showWatchListTable" matSort >
              <!-- Pending Containers -->
                <ng-container matColumnDef="Container">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.containerhash}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Container }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.container_status}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Status }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Message">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.message}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Message }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Ref_Num">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.reference_hash}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Ref_Num }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Hold">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.Hold}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Hold }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Size">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.ExportClaimList_Size}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.ConSz_Cd }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Exempt">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.Exempt}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Exempt }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="MTO">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.MTO}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.MTO }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Carrier">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.VesselVoyage}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Carrier }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Remove"> <th mat-header-cell *matHeaderCellDef>Remove</th> <td mat-cell *matCellDef="let element" style="font-size: 10px" > <button mat-icon-button color="primary" (click)=" RemoveContainerFromWatchlist(element.Con_Num) " > &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <mat-icon style="cursor: pointer; color: #0082be" >delete</mat-icon > </button> </td> </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns_wl; sticky: true" class="example-first-header-row" ></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns_wl" ></tr>
              </table>
              <br>
              <span style="font-size: small; color:black; margin-top: 10px;">{{locale.data.Count}} : {{vm_WatchList.length}}</span>
            </div>
          </div>
        </mat-card>
      </div>
      <br />
      <br />

      <div>
        <mat-card style="display: flex;">  
          <div>
            <span class="heading_black" style="padding-left: 0px">
              {{locale.data.not_available}}
            </span>
            <div style="background-color: white; color: black; font-size: small">
              <span class="text-color" style="display: block">
                {{locale.data.cons_not_eligible}}
              </span>
            </div>
          </div>
          <span class="fill-remaining-space"></span>
          <div style="justify-content: flex-end; padding-top: 35px; padding-right: 20px;">
            <div class="export-result-details">
              &nbsp;
              <mat-icon
                mat-icon-button
                (click)="DownloadPDF_NElist()"
                matTooltip="PDF"
              >
                <img
                  src="../assets/images/pdfmeduim.png"
                  style="
                    vertical-align: middle;
                    height: 28px;
                    width: 30px;
                    cursor: pointer;
                  "
              /></mat-icon>
              &nbsp;
              <mat-icon
                (click)="DownloadExcel_NEList()"
                matTooltip="EXCEL"
              >
                <img
                  src="../assets/images/excel.png"
                  style="
                    vertical-align: middle;
                    height: 23px;
                    cursor: pointer;
                  "
                />
              </mat-icon>
            </div>
          </div>
        </mat-card>
        <!-- <mat-card>
         
        </mat-card> -->
        <mat-card>
          <div class="example-container">
            <div class="mat-elevation-z8">
              <table
                #table3
                #nelistsort="matSort"
                id="ExportSearchResultWatchListDetails"
                mat-table
                [dataSource]="dsNotEliWatchList"
                *ngIf="showWatchListTable"
                matSort
              >
              <!-- Not Available -->
                <ng-container matColumnDef="Container">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.containerhash}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Container }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.container_status}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Status }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Message">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.message}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Message }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Ref_Num">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.reference_hash}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Ref_Num }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Hold">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.Hold}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Hold }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Size">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.Size}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.ConSz_Cd }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Exempt">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.Exempt}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Exempt }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="MTO">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.MTO}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.MTO }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Carrier">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{locale.data.VesselVoyage}}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.Carrier }}
                  </td>
                </ng-container>

                <!-- <ng-container matColumnDef="Remove">
                  <th mat-header-cell *matHeaderCellDef>Remove</th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    style="font-size: 10px"
                  >
                    <button
                      mat-icon-button
                      color="primary"
                      (click)="
                        RemoveContainerFromWatchlist(element.Con_Num)
                      "
                    >
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      <mat-icon style="cursor: pointer; color: #0082be"
                        >delete</mat-icon
                      >
                    </button>
                  </td>
                </ng-container> -->
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns_ne; sticky: true"
                  class="example-first-header-row"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns_ne"
                ></tr>
              </table>
              <br>
              <span style="font-size: small; color:black; margin-top: 10px;">{{locale.data.Count}} : {{vm_NOTEligibleWatchList.length}}</span>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
