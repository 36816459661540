import { LoginAcceptCtfTermsconditionsComponent } from './login/login-accept-ctf-termsconditions.component';
import { stringlog } from 'src/app/csr-containerhistory/csr-containerhistory-type';

import { CompanyProfileComponent } from './Account/company-profile/company-profile.component';
/*Components*/
import { AppComponent } from "./app.component";

/*Modules*/
import { MaterialModule } from "./common/modules/material.module";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER, Inject } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InfiniteScrollModule, InfiniteScrollDirective} from "ngx-infinite-scroll";
/*Services*/
import { TranslateService } from "./common/services/translate.service";

import { AuthGuardService } from "./common/services/authguard.service";
import { AuthService } from "./common/services/auth.service";
import { DefaultGuardService } from "./common/services/defaultguard.service";

import { Http, HttpModule, Headers, Response } from "@angular/http";
import { environment } from "../environments/environment";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
import { SharedModule } from "./common/modules/shared.module";

import { APIService } from "./common/services/httpservicefactory.service";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { HttpConfigInterceptor } from "./common/services/httpinterceptor.service";

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import {Title} from "@angular/platform-browser";
import { LoginComponent } from './login/login.component';
import { DefaultComponent } from './default/default.component';
import { DOCUMENT, CommonModule } from '@angular/common';
import { PreauthLandingComponent } from './preauth-landing/preauth-landing.component';
import { AppRoutingModule } from "./app-routing.module";
import { NewsInfoComponent } from './news-info/news-info.component';
import { PierpassLinksComponent } from './pierpass-links/pierpass-links.component';
import { AboutPierpassComponent } from './about-pierpass/about-pierpass.component';
import { BulletinComponent } from './bulletin/bulletin.component';

import { PpHomeComponent } from "./preauth-landing/pp-home/pp-home.component";
import { PpRegisterComponent } from './preauth-landing/pp-register/pp-register.component';
import { PpRfidTagsComponent } from './preauth-landing/pp-rfid-tags/pp-rfid-tags.component';
import { PpTerminalInfoComponent } from './preauth-landing/pp-terminal-info/pp-terminal-info.component';
import { PpHelpComponent } from './preauth-landing/pp-help/pp-help.component';
import { PpTerminalinfoAppointmentSystemsComponent } from './preauth-landing/pp-terminalinfo-appointment-systems/pp-terminalinfo-appointment-systems.component';
import { PpTerminalinfoTerminalContactsComponent } from './preauth-landing/pp-terminalinfo-terminal-contacts/pp-terminalinfo-terminal-contacts.component';

import { TextMaskModule } from "angular2-text-mask";
import { NgOnlyDigitMaskModule } from "ng-only-digit-mask";

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { PpUserRegistrationComponent } from './pp-user-registration/pp-user-registration.component';

import { ResetPasswordComponent } from './fullscreen-features/reset-password/reset-password.component';
import { TestComponent } from './shared/components/test/test.component';
import { PpGlobalConstants } from './ppcommon/pp-global-constants';

import { LoginChangepasswordComponent } from './login/login-changepassword.component';
import { PpImpersonateComponent } from './csr/pp-impersonate/pp-impersonate.component';

import { PpHelpApptSystemsComponent } from './preauth-landing/pp-help/pp-help-appt-systems.component';

import { ExpandedisummaryComponent } from './csr/csr-edisummary/expandedisummary/expandedisummary.component';

import { AngularEditorModule } from '@kolkov/angular-editor';

import { MessageAlertComponent } from './login/message-alert/message-alert.component';
import { LoginCtfTcContentComponent } from './login/login-ctf-tc-content.component';
import { SelectTmfctfchildComponent } from './login/select-tmfctfchild.component';
import { SelectTmfctfComponent } from './login/select-tmfctf.component';
import { PortchecklayoutComponent } from './portcheck/portchecklayout/portchecklayout.component';
import { PortcheckhomeComponent } from './portcheck/portcheckhome/portcheckhome.component';
import { PierpasslayoutComponent } from './pierpasshome/pierpasslayout.component';
import { PierpasshomeComponent } from './pierpasshome/pierpasshome.component';
import { debuglog } from 'util';
import { AboutportcheckComponent } from './portcheck/aboutportcheck/aboutportcheck.component';
import { FromExternalLandingComponent } from './login/ExternalAccess/from-external-landing.component';
import { LoginResponse } from './common/typesinfo/typesinfo';
import { NomultipletabsComponent } from './page-not-found/nomultipletabs.component';
import { GenericpopupComponent } from './ppcommon/genericpopup/genericpopup.component';


@NgModule({
  declarations: [
    AppComponent,
    ForbiddenComponent,
   LoginComponent,
   DefaultComponent,
   PreauthLandingComponent,
   NewsInfoComponent,
   PierpassLinksComponent,
   AboutPierpassComponent,
   BulletinComponent,  
   PpHomeComponent,
   PpRegisterComponent,
   PpRfidTagsComponent,
   PpTerminalInfoComponent,
   PpHelpComponent,
   PpTerminalinfoAppointmentSystemsComponent,
   PpTerminalinfoTerminalContactsComponent,
   PageNotFoundComponent,
   PpUserRegistrationComponent,
      ResetPasswordComponent,
      TestComponent,
      LoginChangepasswordComponent,
      PpImpersonateComponent,
      PpHelpApptSystemsComponent,
      ExpandedisummaryComponent,    
      MessageAlertComponent,
      LoginCtfTcContentComponent,
      SelectTmfctfComponent,
      SelectTmfctfchildComponent,
      LoginAcceptCtfTermsconditionsComponent,
      PortchecklayoutComponent,
      PortcheckhomeComponent,
      PierpasslayoutComponent,
      PierpasshomeComponent,
      AboutportcheckComponent,
      FromExternalLandingComponent,
      NomultipletabsComponent,
      
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    SharedModule,
    TextMaskModule,
    AppRoutingModule,
    NgOnlyDigitMaskModule,
    HttpModule,
      AngularEditorModule
  ],

  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },

    AuthService,
    AuthGuardService,
    DefaultGuardService,
    TranslateService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: setupTranslateFactory,
    //   deps: [TranslateService],
    //   multi: true
    // },
    // OidcSecurityService,
    // OidcConfigService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: loadConfig,
    //   deps: [OidcConfigService],
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    InfiniteScrollDirective,
    InfiniteScrollModule,
    // NoSanitizePipe
  ],
  bootstrap: [AppComponent],
  entryComponents:
    [  
      MessageAlertComponent
    ]    
})
export class AppModule {
  hostname: string;

  constructor(    
   
    private apiService: APIService,
    private router: Router,
    private titleService:Title,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {

    this.hostname =  window.location.origin;

    stringlog("origin:" + window.location.origin);

    if(this.hostname.includes("www"))
    {
      stringlog("befor change hostname:" + this.hostname);
      this.hostname = this.hostname.replace("www.",'');
      stringlog("after change hostname:" + this.hostname);
    }

    console.log("App Module: " + window.location.origin);
    console.log("Hostname: " + this.hostname);
    console.log("ENV:" + environment.name);
    console.log("API:" + environment.PierPassBackEndServicesBaseURL);

    // console.log("Full URL:" + window.location.href);

    console.log("Node:" + _document.getElementById("nodename").innerHTML);

    let AuthInfo: LoginResponse = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AUTH) || "{}"
    );

    // stringlog("App module: Login Info:" + JSON.stringify(AuthInfo));

    if(AuthInfo == null || AuthInfo == undefined || JSON.stringify(AuthInfo) === '{}' || AuthInfo.IsAuthenticated == false)
    {

      // stringlog("App module: Login Info: AuthInfo.IsAuthenticated == false");

      if(window.location.href.includes("landing"))
      {
        localStorage.setItem( PpGlobalConstants.ProgramType, PpGlobalConstants.Portcheck );
        environment.logoutURL = environment.WeeblySiteURL;
  
      }
      else
      {
        localStorage.setItem( PpGlobalConstants.ProgramType, PpGlobalConstants.PierPASS );
        environment.logoutURL = environment.PierPASSHomeURL;
      }
     
    }


    // if(environment.name ==  'UAT')
    // {
    //   if (this.hostname.includes("ctf.uatpierpass-tmf.org"))
    //   {
    //     environment.logoutURL = 'https://portcheck.uatpierpass-tmf.org';
    //     environment.landingURL = '';
    //     environment.sitemode = 'portcheck'
    //     localStorage.setItem(
    //             PpGlobalConstants.ProgramType,
    //             PpGlobalConstants.Portcheck
    //           );
    //   }
    //   else
    //   {
    //     environment.logoutURL = 'https://uatpierpass-tmf.org';
    //     environment.landingURL = 'pierpasshome';
    //     environment.sitemode = 'pierpass';
    //     localStorage.setItem(
    //       PpGlobalConstants.ProgramType,
    //       PpGlobalConstants.PierPASS
    //     );
    //   }
    // }

    // if(environment.name ==  'PROD')
    // {
    //   if (this.hostname.includes("ctf.pierpass-tmf.org"))
    //   {
    //     environment.logoutURL = 'https://portcheck.pierpass-tmf.org';
    //     environment.landingURL = '';
    //     environment.sitemode = 'portcheck'
    //     localStorage.setItem(
    //             PpGlobalConstants.ProgramType,
    //             PpGlobalConstants.Portcheck
    //           );
    //   }
    //   else
    //   {
    //     environment.logoutURL = 'https://pierpass-tmf.org';
    //     environment.landingURL = 'pierpasshome';
    //     environment.sitemode = 'pierpass';
    //     localStorage.setItem(
    //       PpGlobalConstants.ProgramType,
    //       PpGlobalConstants.PierPASS
    //     );
    //   }
    // }

    // if(environment.name ==  'localhost')
    // {
    //   /////////////// portcheckmode
    //   // environment.logoutURL = 'https://portcheck.uatpierpass-tmf.org';
    //   // environment.landingURL = 'landing';
    //   // environment.sitemode = 'portcheck';
    //   // localStorage.setItem(
    //   //   PpGlobalConstants.ProgramType,
    //   //   PpGlobalConstants.Portcheck
    //   // );

    //   //////////// pierpass mode
    //   environment.logoutURL = 'http://localhost:55040';
    //   environment.landingURL = 'pierpasshome';
    //   environment.sitemode = 'pierpass'
    //   localStorage.setItem(
    //     PpGlobalConstants.ProgramType,
    //     PpGlobalConstants.PierPASS
    //   );

    //   stringlog('localhost:' +  environment.sitemode);
     
    // }

    // if (environment.name == "QA") {
    //   if (this.hostname.includes("7676")) {
    //     localStorage.setItem(
    //       PpGlobalConstants.ProgramType,
    //       PpGlobalConstants.Portcheck
    //     );
    //   } else if (this.hostname.includes("7575")) {
    //     localStorage.setItem(
    //       PpGlobalConstants.ProgramType,
    //       PpGlobalConstants.PierPASS
    //     );
    //   }
    //   else if (this.hostname.includes("7777")) {
    //     localStorage.setItem(
    //       PpGlobalConstants.ProgramType,
    //       PpGlobalConstants.PierPASS
    //     );
    //   }
    // }

    // if (environment.name == "localhost") {
    //   console.log("in LocalHost");
    //   // debuglog("in LocalHost");
    //   // localStorage.setItem(PpGlobalConstants.ProgramType,PpGlobalConstants.Portcheck);
    //   localStorage.setItem(PpGlobalConstants.ProgramType,PpGlobalConstants.PierPASS);
    // }

    console.log("App Module: ProgramType : " +  localStorage.getItem(PpGlobalConstants.ProgramType) );
  
    this.getAppInstanceDetails(environment.portsconfigs.find(a => a.instance == this.hostname ||  a.portcheckinstance == this.hostname).clientid.trim());
    this.titleService.setTitle(environment.portsconfigs.find(a => a.instance == this.hostname ||  a.portcheckinstance == this.hostname).title);
    let ipath:string= environment.portsconfigs.find(a => a.instance == this.hostname ||  a.portcheckinstance == this.hostname).iconpath;
    this._document.getElementById('appFavicon').setAttribute('href',ipath);
    if(!localStorage.getItem("version_number")){
      let ver=environment.portsconfigs.find(a => a.instance == this.hostname ||  a.portcheckinstance == this.hostname).versionnumber;
      localStorage.setItem("version_number",ver);
    }
    if(!localStorage.getItem("googleanalytics_id")){
      let googleanalytics=environment.portsconfigs.find(a=>a.instance==this.hostname ||  a.portcheckinstance == this.hostname).analyticsTrackingId;
     localStorage.setItem("googleanalytics_id",googleanalytics);
    }
    if(!localStorage.getItem("GA4Property_Id")){
      let GA4Property_Id=environment.portsconfigs.find(a=>a.instance==this.hostname ||  a.portcheckinstance == this.hostname).GA4Property_Id;
     localStorage.setItem("GA4Property_Id",GA4Property_Id);
    }
   }
   getAppInstanceDetails(clientid: string): any {
    localStorage.setItem("appInstance_Name", clientid);    
    this.apiService.getAppInstance(clientid).subscribe(data => {
      localStorage.setItem(PpGlobalConstants.app_instance, JSON.stringify(data));
    });

  }
 }
