import { ContextinfoService } from 'src/app/common/services/contextinfo.service';
import { alltypelog, stringlog } from 'src/app/csr-containerhistory/csr-containerhistory-type';
import { filter } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatIconRegistry, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { WebapidataService } from '../common/services/webapidata.service';
import { UserInfoType } from '../common/typesinfo/typesinfo';
import { PpGlobalConstants } from '../ppcommon/pp-global-constants';
import { ImportClaimList_SubmitRequestType, ImportSearchRequestType, ImpSearch_VM, RG_BCO, TN_ConInventory, TN_ImpWatchList } from './import-search-types';

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { BlockScrollStrategy } from "@angular/cdk/overlay";
import { truncate } from "fs";
import { TranslateService } from '../common/services/translate.service';
import { CheckNullReturnEmpty } from '../common/typesinfo/commonfunctions';

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Component({
  selector: 'app-importsearch-credit-dialog',
  templateUrl: './importsearch-credit-dialog.component.html',
  styleUrls: ['./importsearch-credit-dialog.component.scss']
})
export class ImportsearchCreditDialogComponent  implements OnInit {
  @Input() childMessage: string;
  @ViewChild(MatSort, { static: false }) sort: MatSort | any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | any;

  @ViewChild(MatPaginator, { static: false }) wlpaginator: MatPaginator | any;
  @ViewChild('watchlistsort', { static: false }) public watchlistsort: MatSort;

  gridHistorydata: any;
  showClaimListTable: boolean = false;
  serversideErrorMessage: any;
 
  dsClaimsList: any;
  TotalRecords: any;
  disableRemove: boolean = true;

  PDFColNamesClaimtList: { title: string; dataKey: string }[];

  ClaimList_Info: TN_ConInventory[];

  displayedColumns: string[] = [
    "Container",
    "Status",
    "Message",
    // "FeeAmount",
    "Ref_Num",
    "Hold",
    "Size",
    "Exempt",
    "MTO",
    "Carrier",
    "Remove",
  ];


  displayedColumns_wl: string[] = [
    "Container",
    "Status",
    "Message",
    "Ref_Num",
    "Hold",
    "Size",
    "Exempt",
    "MTO",
    "Carrier",
    "Remove",
  ];

  ShowLoader: boolean;

  dsWatchList: any;
  WatchList_Info: TN_ImpWatchList[];
  showWatchListTable: boolean;
  LoginName: string;
  UserId: number;

  ImportSearchErr: boolean;
  ImportSearchMessageError: string;

  claimselection: SelectionModel<TN_ConInventory>;
  watchlistselection: SelectionModel<TN_ImpWatchList>;

  BCO_Info: RG_BCO;
  
  vm_ClaimList = [<ImpSearch_VM>{}];
  vm_WatchList = [<ImpSearch_VM>{}];
  Claim_Request: ImportSearchRequestType;
  claimwatchlistdisplay: boolean;
  btnDisable: boolean;
  FeeType_Cd: string;
  csrreadonly: boolean;

  constructor(
    private router: Router,
    private dataService: WebapidataService,
    fb: FormBuilder,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public locale: TranslateService,
    private context: ContextinfoService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngAfterViewInit() {
    this.dsClaimsList.sort = this.sort;
    this.dsClaimsList.paginator = this.paginator;

    this.dsWatchList.sort = this.watchlistsort;

  }


  ngOnInit() {

    stringlog("this is CREDIT Dialog");

    this.claimwatchlistdisplay = true;

    let UserInfo_Get: UserInfoType = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
    );

    if(this.context.IsCSRMode())
    {
      stringlog("Import Search Credit : CSR Mode");
      this.csrreadonly = true;
    }
    else
    {
      stringlog("Import Search Credit : Pure Mode");
      this.csrreadonly = false;
    }


    this.BCO_Info = this.data.BCOInfo;
    this.Claim_Request = this.data.Claim_Req;

    this.UserId = UserInfo_Get.User_Id;
    this.LoginName = UserInfo_Get.Login_Nm;
    this.FeeType_Cd = localStorage.getItem(PpGlobalConstants.FeeTypeCTFTMF);

   
    this.ClaimList_Info = this.data.ClaimList;

    this.WatchList_Info = this.data.WatchList;

    this.ClaimList_Info.forEach((element) => {
      let container: string;
      let status: string;
      let message: string;
      let hold: string;
      let exempt: string;
      let refnum: string;
      let feeamount : number;

      container = element.ConPrefix_Cd + element.Con_Num + CheckNullReturnEmpty(element.ConChkDigit);

      feeamount = element.FeeAmt;

      if (element.InventoryStatus_Cd == "A") {
        if (this.BCO_Info.VTXCreditStatus_Cd == "D")
        {
          message = this.locale.data.available_pay;
          if (element.FeeAmt == null || element.FeeAmt == 0.0) {
            feeamount = 0.0;
          }
        }
        else if (this.BCO_Info.VTXCreditStatus_Cd == "C")
        {
          message = this.locale.data.available_claim;
        }
      } else if (element.InventoryStatus_Cd == "P") {
        message = this.locale.data.not_announced;
      }

      refnum = element.Ref_Num;
      // check if claimed by same account
      if (element.Company_Id == this.Claim_Request.CompanyId) {
        if (this.BCO_Info.VTXCreditStatus_Cd == "D") {
          message = this.locale.data.paid_by_your_com;
        } else message = this.locale.data.claimed_by_your_com;
      } else {
        refnum = this.Claim_Request.RefNum;
      }

      if (element.Hold_Flg == "Y") 
        hold = this.locale.data.Yes;
      else hold = this.locale.data.No;

      if (element.Exempt_Flg == "Y") 
        exempt = this.locale.data.Yes;
      else exempt = this.locale.data.No;

      this.vm_ClaimList.push({
        Container: container,
        Status: element.InventoryStatus_Dsc,
        Message: message,
        Ref_Num: refnum,
        Hold: hold,
        Exempt: exempt,
        MTO: element.MTO_Cd,
        Carrier: element.Carrier,
        ConInventory_Id: element.ConInventory_Id,
        ConPrefix_Cd: element.ConPrefix_Cd,
        Con_Num: element.Con_Num,
        ConChkDigit: CheckNullReturnEmpty(element.ConChkDigit),
        ConSz_Cd: element.ConSz_Cd,
        InventoryStatus_Cd: element.InventoryStatus_Cd,
        InventoryStatus_Dsc: element.InventoryStatus_Dsc,
        Hold_Flg: element.Hold_Flg,
        MTO_Cd: element.MTO_Cd,
        FeeStatus_Cd: element.FeeStatus_Cd,
        Exempt_Flg: element.Exempt_Flg,
        Ver_Nbr: element.Ver_Nbr,
        Company_Id: element.Company_Id,
        FeeAmt: feeamount
      });
    });

   
    this.vm_ClaimList = this.vm_ClaimList.slice(1);

    this.dsClaimsList = new MatTableDataSource(this.vm_ClaimList);

    this.dsClaimsList.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Container': return item.Container;
        case 'Size': return item.ConSz_Cd;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        default: return item[property];
      }
    };

    this.dsClaimsList.sort = this.sort;
    this.dsClaimsList.paginator = this.paginator;
    this.TotalRecords = this.vm_ClaimList.length;
    this.claimselection = new SelectionModel<TN_ConInventory>(true, []);

    this.showClaimListTable = true;

    this.WatchList_Info.forEach((element) => {
      let container: string;
      let status: string;
      let message: string;
      let hold: string;
      let exempt: string;
      let refnum: string;
      

      container = element.ConPrefix_Cd + element.Con_Num + CheckNullReturnEmpty(element.ConChkDigit);

      if (element.InventoryStatus_Cd == "P") {
        message = this.locale.data.not_announced;
        refnum = this.Claim_Request.RefNum;
      }

      // check if already claimed by another account

      if (
        element.Company_Id != null &&
        element.Company_Id != this.Claim_Request.CompanyId
      ) {
        message = this.locale.data.claimed_by_another_com;
        refnum = "";
      }

      if (element.Exempt_Flg == "Y") {
        message = this.locale.data.Exempt;
      }

      if (element.FeeAmt == null || element.FeeAmt == 0.0) {
        message = this.locale.data.No_fee;
      }

      if (element.Hold_Flg == "Y") hold = "Yes";
      else hold = this.locale.data.No;

      if (element.Exempt_Flg == "Y") exempt = "Yes";
      else exempt = this.locale.data.No;

      this.vm_WatchList.push({
        Container: container,
        Status: element.InventoryStatus_Dsc,
        Message: message,
        Ref_Num: refnum,
        Hold: hold,
        Exempt: exempt,
        MTO: element.MTO_Cd,
        Carrier: element.Carrier,
        ConInventory_Id: element.ConInventory_Id,
        ConPrefix_Cd: element.ConPrefix_Cd,
        Con_Num: element.Con_Num,
        ConChkDigit: CheckNullReturnEmpty(element.ConChkDigit),
        ConSz_Cd: element.ConSz_Cd,
        InventoryStatus_Cd: element.InventoryStatus_Cd,
        InventoryStatus_Dsc: element.InventoryStatus_Dsc,
        Hold_Flg: element.Hold_Flg,
        MTO_Cd: element.MTO_Cd,
        FeeStatus_Cd: element.FeeStatus_Cd,
        Exempt_Flg: element.Exempt_Flg,
        Ver_Nbr: element.Ver_Nbr,
        Company_Id: element.Company_Id,
        FeeAmt: element.FeeAmt,
      });
    });

    this.vm_WatchList = this.vm_WatchList.slice(1);

   
    // change it to Watch Result

    this.dsWatchList = new MatTableDataSource(this.vm_WatchList);

    this.dsWatchList.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Container': return item.Container;
        case 'Size': return item.ConSz_Cd;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        default: return item[property];
      }
    };

    this.dsWatchList.sort = this.watchlistsort;
    this.dsWatchList.paginator = this.paginator;
    this.TotalRecords = this.vm_WatchList.length;
    this.watchlistselection = new SelectionModel<TN_ImpWatchList>(true, []);
    this.showWatchListTable = true;


    if(this.vm_WatchList.length == 0 && this.vm_ClaimList.length == 0)
    {
      this.btnDisable = true
      alltypelog(this.vm_WatchList.length + ":" + this.vm_ClaimList.length + ": " + this.btnDisable)
    }
    else
    {
      this.btnDisable = false;
      alltypelog(this.vm_WatchList.length + ":" + this.vm_ClaimList.length + ": " + this.btnDisable)
    } 
   

  }

  Cancel() {
    // this.router.navigate(['/']).then(() => { this.router.navigate(["/importsearch"]); })

    let url =  this.router.url;
    stringlog("CANCEL current url: " +  url);
    // this.router.navigate(['/']).then(() => { this.router.navigate(["/importsearch"]); })
    this.router.navigate(['/']).then(() => { this.router.navigate([url]); })

    stringlog("Cancel");
    this.dialog.closeAll();
  }

  ClaimforInvoicing() {

    this.claimwatchlistdisplay = true;

    const claimSubReq = <ImportClaimList_SubmitRequestType>{};

    // this.Claim_Request

    claimSubReq.CompanyId = this.BCO_Info.Company_Id;
    claimSubReq.FeeTypeCode = this.Claim_Request.FeeTypeCode;
    claimSubReq.LoginName = this.Claim_Request.LoginName;
    claimSubReq.UserId = this.Claim_Request.UserId;
    claimSubReq.RefNum = this.Claim_Request.RefNum;

    let claimlist1 = [<TN_ConInventory>{}];

    this.vm_ClaimList.forEach((element) => {
      claimlist1.push({
        ConInventory_Id: element.ConInventory_Id,
        ConPrefix_Cd: element.ConPrefix_Cd,
        Con_Num: element.Con_Num,
        ConChkDigit: CheckNullReturnEmpty(element.ConChkDigit),
        ConSz_Cd: element.ConSz_Cd,
        InventoryStatus_Cd: element.InventoryStatus_Cd,
        InventoryStatus_Dsc: element.InventoryStatus_Dsc,
        Hold_Flg: element.Hold_Flg,
        MTO_Cd: element.MTO_Cd,
        Carrier: element.Carrier,
        Company_Id: this.BCO_Info.VTXCreditStatus_Cd == "D"? "": claimSubReq.CompanyId,
        FeeStatus_Cd: element.FeeStatus_Cd,
        FeeAmt: element.FeeAmt,
        Ref_Num: element.Ref_Num,
        Exempt_Flg: element.Exempt_Flg,
        Ver_Nbr: element.Ver_Nbr,
      });
    });


    claimlist1 = claimlist1.slice(1);

    claimSubReq.claimslist = claimlist1;

    let watchlist1 = [<TN_ImpWatchList>{}];

    this.WatchList_Info.forEach((element) => {
      watchlist1.push({
        ConInventory_Id: element.ConInventory_Id,
        ConPrefix_Cd: element.ConPrefix_Cd,
        Con_Num: element.Con_Num,
        ConChkDigit: CheckNullReturnEmpty(element.ConChkDigit),
        ConSz_Cd: element.ConSz_Cd,
        InventoryStatus_Cd: element.InventoryStatus_Cd,
        InventoryStatus_Dsc: element.InventoryStatus_Dsc,
        Hold_Flg: element.Hold_Flg,
        MTO_Cd: element.MTO_Cd,
        Carrier: element.Carrier,
        Company_Id: element.Company_Id,
        FeeStatus_Cd: element.FeeStatus_Cd,
        FeeAmt: element.FeeAmt,
        Ref_Num: element.Ref_Num,
        Exempt_Flg: element.Exempt_Flg,
        Ver_Nbr: element.Ver_Nbr,
      });
    });

    watchlist1 = watchlist1.slice(1);

    claimSubReq.watchlist = watchlist1;

    this.ShowLoader = true;

    this.dataService.SubmitPostImportClaims(claimSubReq).subscribe(
      (data: any) => {
        this.ShowLoader = false;
       
        if (data.result == "error") {
          this.ImportSearchErr = true;
          this.ImportSearchMessageError = data.errormessage;
        } else if (data == true) {
          this.dialog.closeAll();

          this.router.navigate(["/importclaimlist"]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  SubmitButton() {
    this.ClaimforInvoicing();
  }

  RemoveContainerFromClaimlist(row: any) {

    this.claimselection.deselect(this.vm_ClaimList.find( e => e.Con_Num ==  row));

    this.vm_ClaimList = this.vm_ClaimList.filter((obj) => obj.Con_Num !== row);

    this.dsClaimsList = new MatTableDataSource(this.vm_ClaimList);
    this.dsClaimsList.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Container': return item.Container;
        case 'Size': return item.ConSz_Cd;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        default: return item[property];
      }
    };

    this.dsClaimsList.sort = this.sort;
    this.dsClaimsList.paginator = this.paginator;
    this.TotalRecords = this.vm_ClaimList.length;

    let checkedcount = 0;
    this.claimselection.selected.forEach((s) => {
      if(s.InventoryStatus_Cd == "A")
        checkedcount = checkedcount + 1;
    });


    if(this.vm_WatchList.length == 0 && this.vm_ClaimList.length == 0)
    {
      this.btnDisable = true
      alltypelog(this.vm_WatchList.length + ":" + this.vm_ClaimList.length + ": " + this.btnDisable)
    }
    else
    {
      this.btnDisable = false;
      alltypelog(this.vm_WatchList.length + ":" + this.vm_ClaimList.length + ": " + this.btnDisable)
    } 
   

  }

  RemoveContainerFromWatchlist(row: any) {

    this.vm_WatchList = this.vm_WatchList.filter((obj) => obj.Con_Num !== row);

    this.dsWatchList = new MatTableDataSource(this.vm_WatchList);

    this.dsWatchList.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Container': return item.Container;
        case 'Size': return item.ConSz_Cd;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        default: return item[property];
      }
    };


  this.dsWatchList.sort = this.watchlistsort;
  
    this.dsWatchList.paginator = this.paginator;
    this.TotalRecords = this.vm_WatchList.length;

    if(this.vm_WatchList.length == 0 && this.vm_ClaimList.length == 0)
    {
      this.btnDisable = true
      alltypelog(this.vm_WatchList.length + ":" + this.vm_ClaimList.length + ": " + this.btnDisable)
    }
    else
    {
      this.btnDisable = false;
      alltypelog(this.vm_WatchList.length + ":" + this.vm_ClaimList.length + ": " + this.btnDisable)
    } 
   
    
  }
  DownloadExcel_ClaimList(): void {
    let xlsxcolumnNames = [];
    this.PDFColNamesClaimtList = [];
  
    this.PDFColNamesClaimtList.push({
      title: "Container",
      dataKey: "Container",
    });
    this.PDFColNamesClaimtList.push({
      title: "Status",
      dataKey: "Status",
    });
    this.PDFColNamesClaimtList.push({
      title: "Message",
      dataKey: "Message",
    });
    this.PDFColNamesClaimtList.push({
      title: "Ref Num",
      dataKey: "Ref_Num",
    });
    this.PDFColNamesClaimtList.push({
      title: "Hold",
      dataKey: "Hold",
    });
    this.PDFColNamesClaimtList.push({
      title: "Size",
      dataKey: "ConSz_Cd",
    });
    this.PDFColNamesClaimtList.push({
      title: "Exempt",
      dataKey: "Exempt",
    });
    this.PDFColNamesClaimtList.push({
      title: "MTO",
      dataKey: "MTO",
    });
    this.PDFColNamesClaimtList.push({
      title: "Vessel Voyage",
      dataKey: "Carrier",
    });

    if (this.vm_ClaimList.length > 0) {
      this.vm_ClaimList.forEach((element) => {
        let row = [];

        // let duedate = moment(element.DueDate).format("MM/DD/YYYY");
        // let invdate = moment(element.InvoiceDate).format("MM/DD/YYYY");

        // ['Booking_Num','BookingStatus_Dsc', 'BookingStatus_Cd', 'Ref_Num',
        //'Hold_Flg',  'Exempt_Flg','MTO_Cd','Carrier','Remove'];

        for (let j = 0; j < this.PDFColNamesClaimtList.length; j++) {
          switch (this.PDFColNamesClaimtList[j].dataKey) {
            case "Container":
              row.push(element.Container);
              break;
            case "Status":
              row.push(element.Status);
              break;
            case "Message":
              row.push(element.Message);
              break;
            case "Ref_Num":
              row.push(element.Ref_Num);
              break;
            case "Hold":
              row.push(element.Hold);
              break;
              case "ConSz_Cd":
                row.push(element.ConSz_Cd);
                break;
            case "Exempt":
              row.push(element.Exempt);
              break;
            case "MTO":
              row.push(element.MTO);
              break;
            case "Carrier":
              row.push(element.Carrier);
              break;
          }
        }
        xlsxcolumnNames.push(row);
      });

      this.exportToExcelFile(
        xlsxcolumnNames,
        "Import Container Claim List",
        this.PDFColNamesClaimtList
      );
    }
  }

  public exportToExcelFile(
    json: any[],
    excelFileName: string,
    columnNames: any
  ) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    var range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (var C = range.s.r; C < columnNames.length; ++C) {
      var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
      if (!worksheet[address]) continue;
      worksheet[address].v = columnNames[worksheet[address].v].title;
    }
    const workbook: XLSX.WorkBook = {
      Sheets: { DisplayList: worksheet },
      SheetNames: ["DisplayList"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  exportTable() {
    // TableUtil.exportTableToExcel('ExportSearchResultDetails','Export Claim Eligible List');
  }
  DownloadPDF_Claimlist() {
    if (this.vm_ClaimList.length > 0) {
      var doc = new jsPDF("l", "in", [1200, 1401]);
      var col = [
        "Container",
        "Status",
        "Message",
        "Ref Num",
        "Hold",
        "Size",
        "Exempt",
        "MTO",
        "Vessel Voyage",
      ];
      var rows = [];
      this.vm_ClaimList.forEach((item) => {
        // let duedate = moment(item.DueDate).format("MM/DD/YYYY");
        // let invdate = moment(item.InvoiceDate).format("MM/DD/YYYY");

        var temp = [
          item.Container,
          item.Status,
          item.Message,
          item.Ref_Num == null ? "" : item.Ref_Num,
          item.Hold,
          item.ConSz_Cd,
          item.Exempt,
          item.MTO,
          item.Carrier,
          // item.DueDate,
        ];
        rows.push(temp);
      });
      doc.autoTable(col, rows);
      doc.save("Import Container ClaimList.pdf");
    }
  }

  exportTable_watchlist() {
  }

  DownloadPDF_Watchlist() {
    if (this.vm_WatchList.length > 0) {
      var doc = new jsPDF("l", "in", [1200, 1401]);
      var col = [
        "Container",
        "Status",
        "Message",
        "Ref Num",
        "Hold",
        "Size",
        "Exempt",
        "MTO",
        "Vessel Voyage",
      ];
      var rows = [];
      this.vm_WatchList.forEach((item) => {
        // let duedate = moment(item.DueDate).format("MM/DD/YYYY");
        // let invdate = moment(item.InvoiceDate).format("MM/DD/YYYY");

        var temp = [
          item.Container,
          item.Status,
          item.Message,
          item.Ref_Num == null ? "" : item.Ref_Num,
          item.Hold,
          item.ConSz_Cd == null ? "": item.ConSz_Cd,
          item.Exempt,
          item.MTO,
          item.Carrier,
          // item.DueDate,
        ];
        rows.push(temp);
      });
      doc.autoTable(col, rows);
      doc.save("Import Container Watch ist.pdf");
    }
  }

  DownloadExcel_WatchList() {
    let xlsxcolumnNames = [];
    this.PDFColNamesClaimtList = [];

    this.PDFColNamesClaimtList.push({
      title: "Container",
      dataKey: "Container",
    });
    this.PDFColNamesClaimtList.push({
      title: "Status",
      dataKey: "Status",
    });
    this.PDFColNamesClaimtList.push({
      title: "Message",
      dataKey: "Message",
    });
    this.PDFColNamesClaimtList.push({
      title: "Ref Num",
      dataKey: "Ref_Num",
    });
    this.PDFColNamesClaimtList.push({
      title: "Hold",
      dataKey: "Hold",
    });
    this.PDFColNamesClaimtList.push({
      title: "Size",
      dataKey: "ConSz_Cd",
    });
    this.PDFColNamesClaimtList.push({
      title: "Exempt",
      dataKey: "Exempt",
    });
    this.PDFColNamesClaimtList.push({
      title: "MTO",
      dataKey: "MTO",
    });
    this.PDFColNamesClaimtList.push({
      title: "Vessel Voyage",
      dataKey: "Carrier",
    });

    if (this.vm_WatchList.length > 0) {
      this.vm_WatchList.forEach((element) => {
        let row = [];

        // let duedate = moment(element.DueDate).format("MM/DD/YYYY");
        // let invdate = moment(element.InvoiceDate).format("MM/DD/YYYY");

        // ['Booking_Num','BookingStatus_Dsc', 'BookingStatus_Cd', 'Ref_Num',
        //'Hold_Flg',  'Exempt_Flg','MTO_Cd','Carrier','Remove'];

        for (let j = 0; j < this.PDFColNamesClaimtList.length; j++) {
          switch (this.PDFColNamesClaimtList[j].dataKey) {
            case "Container":
              row.push(element.Container);
              break;
            case "Status":
              row.push(element.Status);
              break;
            case "Message":
              row.push(element.Message);
              break;
            case "Ref_Num":
              row.push(element.Ref_Num);
              break;
            case "Hold":
              row.push(element.Hold);
              break;
              case "ConSz_Cd":
                row.push(element.ConSz_Cd);
                break;
            case "Exempt":
              row.push(element.Exempt);
              break;
            case "MTO":
              row.push(element.MTO);
              break;
            case "Carrier":
              row.push(element.Carrier);
              break;
          }
        }
        xlsxcolumnNames.push(row);
      });

      this.exportToExcelFile(
        xlsxcolumnNames,
        "Import Container Watch List",
        this.PDFColNamesClaimtList
      );
    }
  }

  onClose() {

    let url =  this.router.url;
    stringlog("CLOSE current url: " +  url);
    this.router.navigate(['/']).then(() => { this.router.navigate([url]); })

    stringlog("Cancel");
    this.dialog.closeAll();
  }
}
