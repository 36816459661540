<app-loading-backdrop *ngIf="ShowLoader"></app-loading-backdrop>
<div class="tmfctfcenter" *ngIf="showselection">
    <span style="text-align: center; display: inherit; font-size: x-large;font-weight: bold;">
        Select a Program to Continue
    </span>
    <br>
    <br>
    <div class="parent-imgdiv">
        <div>
            <img src="../../assets/images/pierpass_logo.gif"  (click)="setTMF()" 
            style="margin-left: 5px;cursor: pointer;box-shadow: 5px 5px 5px 5px rgb(0 0 0 / 20%);
                width: 190px; height: 86px;" />
            <br><br>
            <p class="sitecontent">
                Select this to pay the<br>
            &nbsp;Traffic Mitigation Fee (TMF)<br>
                    for PierPASS
            </p>
        </div>
        
        <div>
            <img src="../../assets/images/pcimg_3.jpg" (click)="setCTF()" 
            style="cursor: pointer;box-shadow: 5px 5px 5px 5px rgb(0 0 0 / 20%);width: 192px;height: 85px;"/>
            <br><br>
            <p class="sitecontent">
                Select this to pay the<br>
                Clean Truck Fund rate (CTF)<br>
                 for PortCheck
            </p>
            
        </div>
    </div>
</div>