

<!-- <button (click)="downloadPdf()">downloadPdf</button> -->

<div class="sub-container">
    <div *ngFor="let wcontent of webcontent"> 
        <div *ngIf="wcontent.WebContentType_Cd == 'L'" [innerHTML]="wcontent.Content_Txt"></div>
    </div>
    <!-- <app-testcontent></app-testcontent> -->
</div>
