<div>
  <div style="width: 50vw">
    <app-loading-backdrop *ngIf="ShowLoader"></app-loading-backdrop>
  </div>
  <div class="sticky">
  <mat-toolbar>
    <small>{{locale.data.exp_search}}</small>
    <span class="fill-remaining-space"></span>
    <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1" > <mat-icon>clear</mat-icon> </button>
  </mat-toolbar>
  <mat-card>
    <div style="display: flex; justify-content: flex-end">
      <div>
        <span class="fill-remaining-space"></span>
        <button mat-raised-button style=" color: #757575; background-color: white; margin-right: 10px; margin-left: auto; " (click)="Cancel()" > {{locale.data.cancel}} </button>
        <button PierpassButtonControl="EXPORTSEARCHSUBMITCLAIMS" mat-raised-button [disabled] = "vm_ClaimList.length == 0" style="margin-right: 0px" (click)="SubmitClaims()" > {{locale.data.claim}} </button>
        <!-- <div *ngIf="!csrreadonly" style="display: inline;">
          <button mat-raised-button [disabled] = "vm_ClaimList.length == 0" style="margin-right: 0px" (click)="SubmitClaims()" > {{locale.data.claim}} </button>
        </div>
        <div *ngIf="csrreadonly" matTooltip="Disabled for CSR access" style="display: inline;">
          <button mat-raised-button disabled  style="margin-right: 0px" (click)="SubmitClaims()" > {{locale.data.claim}} </button>
        </div> -->
      </div>
    </div>
  </mat-card>
</div>
 
  <div>
    <mat-card style="display: flex;">  
      <div>
        <span class="heading_black" style="padding-left: 0px">
          {{locale.data.eligible_bookings}}
        </span>
        <span class="text-color" style="display: block">
          {{locale.data.es_claim_info}}
        </span>
      </div>
      <span class="fill-remaining-space"></span>
      <div style="justify-content: flex-end; padding-top: 30px;">
        <mat-icon mat-icon-button (click)="DownloadPdf_Claims()" matTooltip="PDF"> <img src="../assets/images/pdfmeduim.png" style=" vertical-align: middle; height: 28px; width: 30px; cursor: pointer; " /></mat-icon>
        &nbsp;
        <mat-icon (click)="DownloadClaimListToXlsx()" matTooltip="EXCEL"> <img src="../assets/images/excel.png" style="vertical-align: middle; height: 23px; cursor: pointer" /> </mat-icon>
      </div>
    </mat-card>
    <mat-card>
      <div class="example-container">
        <div class="mat-elevation-z8">
          <table id="ExportSearchResultDetails" mat-table [dataSource]="dsClaimsList" *ngIf="showClaimListTable" matSort >
            <!-- Checkbox Column -->
            <!-- <ng-container matColumnDef="cl_select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterClaimsToggle() : null"
                  [checked]="claimselection.hasValue() && isAllClaimsSelected()"
                  [indeterminate]="claimselection.hasValue() && !isAllClaimsSelected()"
                  [disabled]="ClaimList_Info.length==0?true:false"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? claimselection.toggle(row) : null"
                  [checked]="claimselection.isSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container> -->
            <ng-container matColumnDef="Booking">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.Export_Booking}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Booking_Num }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.container_status}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Status }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Message">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.message}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Message }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Ref">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.Ref_hash}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Ref }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Hold">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.Hold}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Hold }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Exempt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.Exempt}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Exempt }}
              </td>
            </ng-container>

            <ng-container matColumnDef="MTO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.MTO}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.MTO_Cd }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Carrier">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.VesselVoyage}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Carrier }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Remove">
              <th mat-header-cell *matHeaderCellDef>Remove</th>
              <td mat-cell *matCellDef="let element" style="font-size: 10px" > 
                <button mat-icon-button color="primary" (click)=" RemoveContainerFromClaimlist(element.Booking_Num) " > &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
                  <mat-icon style="cursor: pointer; color: #0082be" >delete</mat-icon > </button> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="example-first-header-row" ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
            <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>       -->
          </table>
          <br>
          <span style="font-size: small; color:black; margin-top: 10px;">{{locale.data.Count}} : {{vm_ClaimList.length}}</span>
        </div>
      </div>
      <div style="display: flex; justify-content: flex-start">
        <p *ngIf="ExpSearchError" class="error">
          {{ ExpSearchErrMessage }}
        </p>
      </div>
      <br />
     
    </mat-card>
    <br />
  </div>
  <br />
  <br />
  <div>
    <mat-card style="display: flex;">  
      <div>
        <span class="heading_black" style="padding-left: 0px">
          {{locale.data.not_eligible_bookings}}
        </span>
        <span class="text-color" style="display: block">
          {{locale.data.es_claimed_info}}
        </span>
      </div>
      <span class="fill-remaining-space"></span>
      <div style="justify-content: flex-end; padding-top: 30px;">
        <div class="export-result-details">
          &nbsp;
          <mat-icon mat-icon-button (click)="DownloadPdf_watchlist()" matTooltip="PDF" > <img src="../assets/images/pdfmeduim.png" style=" vertical-align: middle; height: 28px; width: 30px; cursor: pointer; " /></mat-icon>
            &nbsp;
            <mat-icon (click)="DownloadWatchListToXlsx()" matTooltip="EXCEL"> <img src="../assets/images/excel.png" style="vertical-align: middle; height: 23px; cursor: pointer" /> </mat-icon>
          </div>
      </div>
    </mat-card>
    <mat-card>
      <div class="example-container">
        <div class="mat-elevation-z8">
          <table #table2 #watchlistsort="matSort" id="ExportSearchResultWatchListDetails" mat-table [dataSource]="dsWatchList" *ngIf="showWatchListTable" matSort >
            <!-- Checkbox Column -->
            <!-- <ng-container matColumnDef="wl_select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterWatchListToggle() : null"
                  [checked]="watchlistselection.hasValue() && isWatchListAllSelected()"
                  [indeterminate]="watchlistselection.hasValue() && !isWatchListAllSelected()"
                  [disabled]="WatchList_Info.length==0?true:false"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? watchlistselection.toggle(row) : null"
                  [checked]="watchlistselection.isSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container> -->
            <ng-container matColumnDef="Booking">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.Export_Booking}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Booking_Num }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.container_status}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.BookingStatus_Dsc }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Message">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.message}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Message }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Ref">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.Ref_hash}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Ref }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Hold">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.Hold}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Hold }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Exempt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.Exempt}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Exempt }}
              </td>
            </ng-container>

            <ng-container matColumnDef="MTO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.MTO}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.MTO_Cd }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Carrier">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{locale.data.VesselVoyage}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Carrier }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Remove">
              <th mat-header-cell *matHeaderCellDef>Remove</th>
              <td mat-cell *matCellDef="let element" style="font-size: 10px" >
                <button mat-icon-button color="primary" (click)="RemoveContainerFromWatchlist(element.Booking_Num) " > &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <mat-icon style="cursor: pointer; color: #0082be" >delete</mat-icon > </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns_wl; sticky: true" class="example-first-header-row" ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns_wl" ></tr>
            <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>       -->
          </table>
          <br>
          <span style="font-size: small; color:black; margin-top: 10px;">{{locale.data.Count}} : {{vm_WatchList.length}}</span>
        </div>
      </div>

    </mat-card>
  </div>
</div>
