import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nomultipletabs',
  templateUrl: './nomultipletabs.component.html',
  styleUrls: ['./nomultipletabs.component.scss']
})
export class NomultipletabsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  closewindow()
  {
    // window.close();
    // window.open('','_self').close();
    window.top.location.reload();
    window.top.close();
  }

}
