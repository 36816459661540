import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { delay, catchError } from 'rxjs/operators';

export class BackgroundPreloadStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return load().pipe(
      // Add a delay if you want to simulate the module loading time
      delay(3000),
      // catchError is used to handle any errors during module loading
      catchError((error) => {
        console.error('Error preloading:', error);
        return of(null);
      })
    );
  }
}
