import { date } from '@rxweb/reactive-form-validators';
// import { ExportSearchRequestType } from './../../export-search/export-search-types';
export class Typesinfo {
}

export interface UserAccountTypeInfo {
  result: string;
  TMF: boolean;
  CTF: boolean;
  Others: boolean;
  HelpCenter: string;
}

export interface PopupInfo {
  Title: string, 
  Content: string,
}


export interface SummaryDetails {
    User: string, 
    Company: string,
    Account: number,
    Address: string,
    address2: string,
    balance:  string,
    AccountStatus: string,
    CreditStatus: string 
  }

  export interface TN_Booking {
    Booking_Id?: number;
    Booking_Num: string;
    BookingStatus_Cd: string;
    BookingStatus_Dsc: string;
    Hold_Flg: string;
    MTO_Cd: string;
    Carrier: string;
    Company_Id?: number;
    Ref_Num?: any;
    Exempt_Flg: string;
    Ver_Nbr: string;
}



  export interface Terminals {
    moduleName: string;
    name: string;
    url: string;
    is_href: boolean;
    interfaceId: number;
    interfaceUid: string;
    showInMenuFlg: boolean;
    interfaceCd: string;
    children?: Terminals[];
  }



export interface LoadedModuleInfo {
  ParentModule : string;
  ChildModule: string;
}

export interface CSRAccountInContext {
  CompanyId : number;
  CompanyName: string;
  VTXAccountId : number;
  AccountType : string;
}

export interface LoginResponse {
    UserName: string;
    Password: string;
    IsAuthenticated: boolean;
    Result: string;
    Token: string;
    Error : string;
    FirstLoginTime : Date;
    LastRefreshedTime : string;
}


export interface BulletIns {
    Bulletin_Id: number;
    BulletinType_Cd: string;
    Title_Txt: string;
    Content_Txt: string;
    CreatedUser_Id: number;
    Created_DtTm: Date;
    ModifiedUser_Id?: number;
    Modified_DtTm?: Date;
    Hidden_Flg: string;
    Deleted_Flg: string;
    Order_Nbr: number;
}



export interface WebContentRootObject {
    RowError: string;
    RowState: number;
    Table: WebContentType[];
    ItemArray: any[];
    HasErrors: boolean;
}


export interface WebContentType {
    WebContent_Id: number;
    WebContentType_Cd: string;
    Content_Txt: string;
    CreatedUser_Id: number;
    Created_DtTm: Date;
    Deleted_Flg: string;
    RG_WebContentType?: any;
}


export interface RGBCO {
  BCO_Id: number;
  ConMovesRange_Cd: string;
  Import_Flg: string;
  Export_Flg: string;
  BCOAccount_Id: number;
  FeeType_Cd: string;
  VTXAccount_Id?: number;
  VTXAcctStatus_Cd: string;
  VTXCreditStatus_Cd: string;
  TermsAcceptanceUser_Id?: number;
  TermsAcceptance_DtTm?: Date;
}

export interface UserInfoType {
    User_Id: number;
    User_Uid : string;
    Login_Nm: string;
    Login_Pwd: string;
    Pwd: string;
    First_Nm: string;
    Last_Nm: string;
    Email_Addr: string;
    Telephone_Num?: any;
    SecQuestion_Id: number;
    SecAnswer_Txt: string;
    Disabled_Flg: string;
    ResetPwdOnNextLogin_Flg: string;
    VerChange_Flg: string;
    Ver_Nbr: string;
    Company_Id: number;
    BCO_Id?: any;
    Company_Nm: string;
    Addr_1: string;
    Addr_2?: any;
    City_Nm: string;
    State_Cd: string;
    Postal_Cd: string;
    Contact_Num: string;
    CompanyType_Cd: string;
    CompanyGrp_Cd: string;
    HomePage: string;
}

export enum FeeTypeCode {
  TMF = 'TMF',
  CTF = 'CTF'
}