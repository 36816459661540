<!-- <p>pp-register works!</p> -->
<!-- <div class="sub-container">
    <div *ngFor="let wcontent of landingpagecontent"> 
        <div *ngIf="wcontent.WebContentType_Cd == 'R'" [innerHTML]="wcontent.Content_Txt"></div>
    </div>
</div> -->
<h5 style="text-decoration: underline; color:#0082BE; font-size: 16px;font-weight: normal; font-family: sans-serif; padding-left: 5px;">
    Home > Register
 </h5>
<div class="sub-container">
    <table >
        <tr>
           <td valign="top" class="HomePageText">
               <br />{{ locale.data.Regiser_p }}<br /><br />{{ locale.data.Regiser_p1 }}<br /><br />{{ locale.data.Regiser_p2 }}<br />
               <!-- <br />Click <a class="btnLink" href="registernewaccount"><b>here</b></a>  -->
               <br />
               {{ locale.data.Regiser_p3 }} <a [routerLink]="" (click)="openTab()"><b>{{ locale.data.Regiser_p4 }}</b></a> 
               {{ locale.data.Regiser_p5 }}</td>
        </tr>
     </table>
     <!-- <app-testcontent></app-testcontent> -->
</div>