import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { WebapidataService } from 'src/app/common/services/webapidata.service';
import { WebContentType } from 'src/app/common/typesinfo/typesinfo';
import { TranslateService } from 'src/app/common/services/translate.service';

@Component({
  selector: 'app-pp-register',
  templateUrl: './pp-register.component.html',
  styleUrls: ['./pp-register.component.scss']
})
export class PpRegisterComponent implements OnInit {

  landingpagecontent : WebContentType[];
  SiteUrl : string = "";
  
  constructor(private dataService:  WebapidataService,
    public locale: TranslateService
    ) {
    this.dataService.getWebContentAll().subscribe((data:any) => {
      this.landingpagecontent = data[0];
    });

   }

  ngOnInit() {

    this.dataService.getWebContentAll().subscribe((data:any) => {
      this.landingpagecontent = data[0];
    });

    let _instance = environment.portsconfigs.find(x => x.clientid == localStorage.getItem("appInstance_Name").trim());
    this.SiteUrl = _instance.instance;
    
  }
  openTab() 
  {
    //window.open(this.SiteUrl +  "/registernewaccount" , '_blank');
    window.location.replace(this.SiteUrl +  "/registernewaccount");
  }
}
