import { Router } from '@angular/router';
import { stringlog } from 'src/app/csr-containerhistory/csr-containerhistory-type';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'src/app/common/services/translate.service';
import { WebapidataService } from 'src/app/common/services/webapidata.service';
import { LoginResponse, WebContentType } from 'src/app/common/typesinfo/typesinfo';
import { environment } from 'src/environments/environment';
import { MatTabGroup } from '@angular/material';
import { PpGlobalConstants } from 'src/app/ppcommon/pp-global-constants';

@Component({
  selector: 'app-pp-home',
  templateUrl: './pp-home.component.html',
  styleUrls: ['./pp-home.component.scss']
})
export class PpHomeComponent implements OnInit {

  landingpagetabscontent : WebContentType[];
  landingpagetabscontent1 : WebContentType[];
  SiteUrl: string;
  ppTabIndex: number;
  selectedIndex: number;
  ProgramType: string;
  
  constructor(private dataService:  WebapidataService,
    public locale: TranslateService,
    private router: Router) {
    this.dataService.getWebContentAll().subscribe((data:any) => {
      this.landingpagetabscontent1 = data[0];
    });

   }

  ngOnInit() {

    this.ProgramType = localStorage.getItem(PpGlobalConstants.ProgramType);

    stringlog("this ProgramType:" + this.ProgramType);

    if(this.ProgramType == PpGlobalConstants.PierPASS)
    {
      stringlog("this ProgramType: TMF");

      this.router.navigate(["/pierpasshome"]);
    }
    else if(this.ProgramType == PpGlobalConstants.Portcheck)
    {
      stringlog("this ProgramType: CTF");
      this.router.navigate(["/landing"]);
    }

    let AuthInfo: LoginResponse = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AUTH) || "{}"
    );

    // stringlog("App module: Login Info:" + JSON.stringify(AuthInfo));

    if(AuthInfo == null || AuthInfo == undefined ||  JSON.stringify(AuthInfo) === '{}' || AuthInfo.IsAuthenticated == false)
    {
      this.router.navigate(["/pierpasshome"]);
    }
    

    // stringlog("PP Home: sitemode:" + environment.sitemode);

    // if (environment.sitemode == "pierpass") {
     
    //   stringlog("sitemode:" + environment.sitemode);
    //   stringlog("External landing : pierpasshome");

    //   stringlog("redirecting to: " +  window.location.href + "/" + environment.landingURL);

    //   // setTimeout(() => {
    //   //   console.log("waiitng 1 min");
    //   // }, 1000);
      
    //    this.router.navigate(["/pierpasshome"]);

    //   // window.location.href =
    //   //   window.location.href + "/" + environment.landingURL;

    //   return;
    // }
    // else
    // {
    //   this.router.navigate(["/landing"]);
    // }

    // this.dataService.getWebContentAll().subscribe((data:any) => {
    //   this.landingpagetabscontent = data[0];

    //   let regstring = 'href="/Documents';
    //   let finalstring = 'target="_blank" rel="noopener" href="../../assets/Documents';
       
    //   this.landingpagetabscontent.forEach(e => 
    //     {
    //       e.Content_Txt = this.replaceAll(e.Content_Txt, regstring,finalstring);
    //     })

    //     let _instance = environment.portsconfigs.find(
    //       (x) => x.clientid == localStorage.getItem("appInstance_Name").trim()
    //     );
    //     this.SiteUrl = _instance.instance;
        
    // });
    
  }

  openhelplink()
  {
    stringlog("openhelplink clicked");
    window.open(this.SiteUrl +  "/pphelp" , '_blank');
    this.selectedIndex = 1;
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

}
