import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'src/app/common/services/translate.service';
import { WebapidataService } from 'src/app/common/services/webapidata.service';
import { stringlog } from 'src/app/csr-containerhistory/csr-containerhistory-type';
import { PpGlobalConstants } from 'src/app/ppcommon/pp-global-constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pp-help',
  templateUrl: './pp-help.component.html',
  styleUrls: ['./pp-help.component.scss']
})
export class PpHelpComponent implements OnInit {

  panelOpenState = false;
  SiteUrl: string;
  DisplayPortCheck: boolean;

  constructor(public locale: TranslateService,
    private dataService:  WebapidataService
    ) { 
      this.CTFEnabled();
    }

  ngOnInit() {
    let _instance = environment.portsconfigs.find(x => x.clientid == localStorage.getItem("appInstance_Name").trim());
    this.SiteUrl = _instance.instance;
  }
  
  openTab(){
    window.open(this.SiteUrl +  "/pphelpappt" , '_blank');
  }
  private CTFEnabled()  {
    this.dataService.GetTMFCTFForkIn().subscribe((data: any) => {
      // stringlog("CTFEnabled call executed");
      stringlog(JSON.stringify(data));
      if (data.result == "error") {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage,"false");
        this.DisplayPortCheck =  false;
        // stringlog("CTFEnabled call: Error");
      }
      else if(data.result == "good" && data.data == "true")
      {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage,"true");
        this.DisplayPortCheck =  true;
        // stringlog("CTFEnabled call: true");
      }
      else if(data.result == "good" && data.data == "false")
      {
        localStorage.setItem(PpGlobalConstants.ShowTMFCTFForkInPage,"false");
        this.DisplayPortCheck =  false;
        // stringlog("CTFEnabled call: false");
      }
    });

  }
}
