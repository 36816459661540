
<div class="preauth-parent-container">
  <mat-toolbar>
    <!-- ----- Pierpass and portcheck header logo -->
    <div style="display: flex; flex-direction: row; align-content: space-between; width: 62vw;">
      <div class="logo" style="margin-left:1.5%"></div>
      <!-- <span class="example-spacer"></span> -->
      <!--<img *ngIf="DisplayPortCheck" src="../../assets/images/portcheck_header.jpg" style="height: 43px; padding-top: 3px;">-->
    </div>
  </mat-toolbar>
 
  <div  class="main1"> 
  <div class="sub-container">  
   
    <mat-label style="font-size:16px;color: #212121;font-weight: bold;margin-left: 1%;" >Troubleshooting
    </mat-label> <br>
    <mat-label style="font-size:14px;color: #212121;font-weight: bold;margin-left: 1%;">Select from the following for troubleshooting assistance:
    </mat-label> 
    <br><br>
    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Issues related to terminal appointments
          </mat-panel-title>
          <mat-panel-description>
            <!-- Issues related to terminal appointments 2 -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-content">
          <ul>
            <li>
          PierPass has no access to the terminals' appointment systems.</li>
          <li>
          <p>Contact the terminal directly to resolve any appointment issues.</p>
          <!--<p>For a list of terminal appointment system contacts, click <a href="http://wcmtoa.org/appointment-systems/" target="HelpPopout">here</a>.</p>-->
        </li>
        <li>
          <p>
            For a list of terminal appointment system contacts, click
            <a [routerLink]="" (click)="openTab()"><b>here</b></a>
            <!-- <a href="HelpApptSystems.aspx">here</a>. -->
          </p>
        </li>
        </ul>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            TMF has been paid but the terminal still shows HOLD
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-content">
          <p><b>Troubleshooting solutions</b></p>
          <div>
            The TMF may be paid but there may be other HOLDS that the terminal has
            placed on this container, such as demurrage, customs, etc.
            <ul>
              <li>
                Check with the terminal about the HOLD. For terminal contact
                information, click 
                <a [routerLink]="" (click)="openTab()"><b>here</b></a>
                <!-- <a href="HelpTerminalContacts.aspx">here</a>. -->
              </li>
            </ul>
          </div>
          <div>
            The TMF may have been paid, but the payment did not go through because
            the account is suspended.
            <ul>
              <li>
                Log in and check both Import and Export Fees Due for outstanding
                charges.
              </li>
            </ul>
          </div>
          <div>
            The TMF may have been paid, but the payment is still being processed
            at the terminal.
            <ul>
              <li>
                It may take up to one hour before the process releases the HOLD.
                Check back periodically
              </li>
            </ul>
            <i
              >Helpful Hint: In the future, please try to pay the TMF in advance
              of attempting to pick-up the container.</i
            >
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            The container should be at the terminal but PierPass shows it as
            "Pending"
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-content">
          <p><b>Troubleshooting solutions</b></p>
          <div>
            <ul>
              <li>
                Check the marine terminal operator’s website to see if the
                container can be found there. If it does display on the website,
                contact the marine terminal’s customer service department and
                request that they send an announcement to PierPASS.
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                If the container does not display on the marine terminal’s
                website, contact the shipping line, confirm the container number
                and location are correct, if so, request that they re-send the
                container data to the terminal.
              </li>
            </ul>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title> My Booking in PierPASS does not display </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-content">
          <p><b>Troubleshooting solutions</b></p>
          <div>
            <ul>
              <li>
                Check the marine terminal operator’s website to see if the export
                booking number can be found there. If it does display on the
                website, contact the marine terminal’s customer service department
                and request that they send an announcement to PierPASS.
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                If the container does not display on the marine terminal’s
                website, contact the shipping line, confirm the booking number and
                location are correct, if so, request that they re-send the
                container data to the terminal.
              </li>
            </ul>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Container status in PierPASS is active but unable to click on "Pay" tab
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-content">
          <p><b>Troubleshooting solutions</b></p>
          <div>
            This may be a browser compatability issue.<br />
            <ul>
              <li>
                PierPass works with Mozilla Firefox, Google Chrome, or Microsoft Edge.
              </li>
              <li>
                PierPass does not work with Microsoft Internet Explorer(IE).
              </li>
              
            </ul>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            It shows "Pay" in PierPASS but will not allow for a credit card or eCheck payment
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-content">
          <p><b>Troubleshooting solutions</b></p>
          <div>
            <ul>
              <li>Log in.</li>
              <li>On the Account Summary page, check your account balance.</li>
              <li>
                If the Account Balance reads “-$5,000” or more you are required to
                use the credit balance on the payment page to pay the TMF.
              </li>
            </ul>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title> My credit card has been declined </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-content">
          <p><b>Troubleshooting solutions</b></p>
          <div>
            <ul>
              <li>Contact your bank or credit card company.</li>
            </ul>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title> My eCheck has been declined </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-content">
          <p><b>Troubleshooting solutions</b></p>
          <div>
            <ul>
              <li>Contact Telecheck at 1-800-366-2425 or 1-800-366-5010.</li>
            </ul>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>  
    <br>
    <div style="margin-left: 10px;">
      <span>
        PierPASS Customer Service email:  <a href="mailto:pierpass.offpeak-tmf@pierpass.net">pierpass.offpeak-tmf@pierpass.net</a> 
      </span>
      <br>      
      <span>
        Customer Service Call Center Tel. No.: (877) 863-3310
      </span>
      <br>
    </div>
  </div>
  <div class="lfooter1">
    <span>
      &nbsp;&nbsp; Copyright
      <mat-icon inline="true">copyright</mat-icon> 2008-2021 PierPASS. All Rights Reserved.
      &nbsp;&nbsp; | &nbsp;&nbsp; <a title="PierPASS Privacy Policy" target="_blank" href="https://www.wcmtoa.org/wp-content/uploads/2022/05/2022-05-23-PierPASS-Privacy-Policy.pdf">Privacy Policy</a>
      &nbsp;&nbsp; | &nbsp;&nbsp; <a title="PierPASS Terms &amp; Conditions" target="_blank" href="https://www.wcmtoa.org/wp-content/uploads/2022/05/2022-05-20-PierPASS-Terms-Conditions.pdf">Terms &amp; Conditions</a>
      &nbsp;&nbsp; 
    </span>
</div>
</div>
</div>
