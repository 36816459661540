import { WebapidataService } from "./../common/services/webapidata.service";
import { Component, OnInit } from "@angular/core";
import { BulletIns } from "../common/typesinfo/typesinfo";

@Component({
  selector: "app-bulletin",
  templateUrl: "./bulletin.component.html",
  styleUrls: ["./bulletin.component.scss"],
})
export class BulletinComponent implements OnInit {
  bulletins: BulletIns[];
  videobulletins: BulletIns[];

  constructor(private dataService: WebapidataService) {
   
  }

  ngOnInit(): void {
    this.bulletins = [];
    this.dataService.getBulletIns().subscribe((data: any) => {
      this.bulletins = data;

      this.bulletins = this.bulletins.filter( b => b.Hidden_Flg != 'Y');

      this.videobulletins =  this.bulletins.filter( b => b.Hidden_Flg != 'Y' && b.BulletinType_Cd == 'V');

      let regstring = 'href="/Documents';
      let finalstring = 'target="_blank" rel="noopener" href="../../assets/Documents';
       
      this.bulletins.forEach(e => 
        {
          e.Content_Txt = this.replaceAll(e.Content_Txt, regstring,finalstring);
        })

    });
  }
  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

}
