import { ContextinfoService } from 'src/app/common/services/contextinfo.service';
import { stringlog } from 'src/app/csr-containerhistory/csr-containerhistory-type';
import { filter } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatIconRegistry, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { WebapidataService } from '../common/services/webapidata.service';
import { UserInfoType } from '../common/typesinfo/typesinfo';
import { PpGlobalConstants } from '../ppcommon/pp-global-constants';
import { ImportClaimList_SubmitRequestType, ImportSearchRequestType, ImportSearchTypes, ImpSearch_VM, NewTariffDetail, RG_BCO, RG_GetTariffConfigurationDates, TN_ConInventory, TN_ConInventoryType, TN_ImpWatchList, TN_ParameterValues } from './import-search-types';

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { BlockScrollStrategy } from "@angular/cdk/overlay";
import { truncate } from "fs";
import { TranslateService } from '../common/services/translate.service';
import { CheckNullReturnEmpty } from '../common/typesinfo/commonfunctions';

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Component({
  selector: "app-import-searchresult-dialog",
  templateUrl: "./import-searchresult-dialog.component.html",
  styleUrls: ["./import-searchresult-dialog.component.scss"],
})
export class ImportSearchresultDialogComponent  implements OnInit {
  @Input() childMessage: string;
  @ViewChild(MatSort, { static: false }) sort: MatSort | any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | any;

  @ViewChild(MatPaginator, { static: false }) wlpaginator: MatPaginator | any;
  @ViewChild('watchlistsort', { static: false }) public watchlistsort: MatSort;
  //  #table3
  // #nelistsort="matSort"
  @ViewChild('nelistsort', { static: false }) public nelistsort: MatSort;

  gridHistorydata: any;
  showClaimListTable: boolean = false;
  serversideErrorMessage: any;
  // Company_Id: any;
  // Company_Nm: any;
  dsClaimsList: any;
  TotalRecords: any;
  disableRemove: boolean = true;

  PDFColNamesClaimtList: { title: string; dataKey: string }[];

  ClaimList_Info: TN_ConInventory[];

  displayedColumns: string[] = [
    "cl_select",
    "Container",
    "Status",
    "Message",
    // "FeeAmount",
    "Ref_Num",
    "Hold",
    "Size",
    "Exempt",
    "MTO",
    "Carrier",
    "Remove",
  ];


  displayedColumns_wl: string[] = [
    "Container",
    "Status",
    "Message",
    "Ref_Num",
    "Hold",
    "Size",
    "Exempt",
    "MTO",
    "Carrier",
    "Remove",
  ];

  displayedColumns_ne: string[] = [
    "Container",
    "Status",
    "Message",
    "Ref_Num",
    "Hold",
    "Size",
    "Exempt",
    "MTO",
    "Carrier",
  ];



  ShowLoader: boolean;
  // CompanyId: any;

  dsWatchList: any;
  dsNotEliWatchList: any;
  WatchList_Info: TN_ImpWatchList[];
  GetTariffConfigurationDates:TN_ParameterValues[]; 
  CheckTMFCTFEffectiveDate:any[];
  showWatchListTable: boolean;
  LoginName: string;
  UserId: number;

  ImportSearchErr: boolean;
  ImportSearchMessageError: string;

  claimselection: SelectionModel<TN_ConInventory>;
  watchlistselection: SelectionModel<TN_ImpWatchList>;

  BCO_Info: RG_BCO;
  // [<ClaimContainerList>{}];
  // vm_ClaimtList: ImpSearch_VM[];
  vm_ClaimList = [<ImpSearch_VM>{}];
  checkFutureTatiffButtonVerification=[<TN_ConInventory>{}];
  
  vm_WatchList = [<ImpSearch_VM>{}];
  vm_NOTEligibleWatchList = [<ImpSearch_VM>{}];
  Claim_Request: ImportSearchRequestType;

  selectedUserFeesDueList: any;
  directPayment: boolean;
  claimwatchlistdisplay: boolean;

  SUBMITOnly = false;
  PAYplusSUBMIT =  false;
  submitopay: boolean; 
  FeeType_Cd: string;
  NewClaimList_Info: TN_ConInventory[];
  NewTariffDetails: NewTariffDetail[];
  FutureRateExists: boolean;
  StartEndDateRangeExists:boolean;
  Effective_DtTm: Date;
  FutureButtonLabel: string;
  CurrentTariffLabel: string;
  ToolTip: any;
  csrreadonly: boolean;
  
  StartDate:any;
  EndDate:any;
  EffectiveDate:any;
  serverdate:any;
  checkTMFFlag:any;
  checkCTFFlag:any;
  
  // const  claimlist1 = [<ClaimContainerList>{}];

  constructor(
    private router: Router,
    private dataService: WebapidataService,
    fb: FormBuilder,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public locale: TranslateService,
    private context: ContextinfoService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngAfterViewInit() {

    this.ButtonBehaviour();
   
    this.dsClaimsList.sort = this.sort;
    this.dsClaimsList.paginator = this.paginator;

    this.dsWatchList.sort = this.watchlistsort;

    this.dsNotEliWatchList.sort = this.nelistsort;
    // this.dsWatchList.paginator = this.watchlistpaginator;

  }

  ngOnInit() {
    // this.displaypopdiv = true;

    stringlog("this is Search Result");

    if(this.context.IsCSRMode())
    {
      stringlog("Import Claim DirectPay : CSR Mode");
      this.csrreadonly = true;
    }
    else
    {
      stringlog("Import Claim DirectPay : Pure Mode");
      this.csrreadonly = false;
    }


    this.directPayment =  false;
    this.claimwatchlistdisplay = true;

    let UserInfo_Get: UserInfoType = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
    );
    

    this.BCO_Info = this.data.BCOInfo;
    this.Claim_Request = this.data.Claim_Req;    

    // this.CompanyId = 14361;
    this.UserId = UserInfo_Get.User_Id;
    this.LoginName = UserInfo_Get.Login_Nm;
    this.FeeType_Cd = localStorage.getItem(PpGlobalConstants.FeeTypeCTFTMF);
    

    this.ClaimList_Info = this.data.ClaimList;
    this.WatchList_Info = this.data.WatchList;
    
    this.GetTariffConfigurationDates=this.data.GetTariffConfigurationDates;

    this.StartDate=this.GetTariffConfigurationDates[0].ProfileParameter_Val;
    this.EndDate=this.GetTariffConfigurationDates[1].ProfileParameter_Val;
    this.EffectiveDate=this.GetTariffConfigurationDates[2].ProfileParameter_Val
    this.serverdate=this.GetTariffConfigurationDates[0].ServerDate;

    

    //Checking TMF & and CTF Effective_DtTm Date in  RG_TariffVersion table
    
    this.CheckTMFCTFEffectiveDate=this.data.CheckTMFCTFEffectiveDate
    if(this.CheckTMFCTFEffectiveDate[0].FeeType=='TMF')
    {
      this.checkTMFFlag=this.CheckTMFCTFEffectiveDate[0].Flag;
    }
    if(this.CheckTMFCTFEffectiveDate[1].FeeType=='CTF')
    {
      this.checkCTFFlag=this.CheckTMFCTFEffectiveDate[1].Flag;
    }
      
    

    //Checking the Current Date is between StartDate and EndDate
    //based on that enabling Two Buttons
   if(this.serverdate>=this.StartDate && this.serverdate.slice(0,10)<=this.EndDate.slice(0,10))
   {
    //Checking TMF Flag
    if(this.FeeType_Cd.toUpperCase()=='TMF')
    {
      
     if(this.checkTMFFlag=='Y')
     {
      
      this.StartEndDateRangeExists=true;
     }
     else
     {
      
      this.StartEndDateRangeExists=false;
     }
   }
     //Cheking CTF Flag

     if(this.FeeType_Cd.toUpperCase()=='CTF')
     {
     if(this.checkCTFFlag=='Y')
     {
      
      this.StartEndDateRangeExists=true;
     }
     else
     {
      this.StartEndDateRangeExists=false;
     }
    }
     
   }
   else{
    this.StartEndDateRangeExists=false;
   }
   
    //this.NewClaimList_Info = this.data.NewClaimList;
    //this.NewTariffDetails = this.data.NewTariffDetails;

    this.ToolTip =  this.data.ToolTip;

    this.ProcessClaimList();

    this.dsClaimsList = new MatTableDataSource(this.vm_ClaimList);

    this.dsClaimsList.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Container': return item.Container;
        case 'Size': return item.ConSz_Cd;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        default: return item[property];
      }
    };

    this.dsClaimsList.sort = this.sort;
    this.dsClaimsList.paginator = this.paginator;
    this.TotalRecords = this.vm_ClaimList.length;
   

    
    this.claimselection = new SelectionModel<TN_ConInventory>(true, []);

    this.showClaimListTable = true;

    // Watch List

    this.ProcessWatchList();

    this.vm_NOTEligibleWatchList = this.vm_NOTEligibleWatchList.slice(1);

    // change it to Watch Result

    this.dsWatchList = new MatTableDataSource(this.vm_WatchList);

    this.dsWatchList.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Container': return item.Container;
        case 'Size': return item.ConSz_Cd;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        default: return item[property];
      }
    };

    this.dsWatchList.sort = this.watchlistsort;
    this.dsWatchList.paginator = this.paginator;
    this.TotalRecords = this.vm_WatchList.length;
    this.watchlistselection = new SelectionModel<TN_ImpWatchList>(true, []);
    this.showWatchListTable = true;

    // NE Watch List

    this.dsNotEliWatchList =  new MatTableDataSource(this.vm_NOTEligibleWatchList); 
    this.dsNotEliWatchList.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Container': return item.Container;
        case 'Size': return item.ConSz_Cd;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        default: return item[property];
      }
    };

    this.dsNotEliWatchList.sort = this.watchlistsort;
    this.dsNotEliWatchList.paginator = this.paginator;
    // this.watchlistselection = new SelectionModel<TN_ImpWatchList>(true, []);
    this.showWatchListTable = true;

  }

  private ProcessWatchList() {
    this.WatchList_Info.forEach((element) => {
      let container: string;
      let status: string;
      let message: string;
      let hold: string;
      let exempt: string;
      let refnum: string;


      container = element.ConPrefix_Cd + element.Con_Num + CheckNullReturnEmpty(element.ConChkDigit);

      if (element.InventoryStatus_Cd == "P") {
        message = this.locale.data.not_announced;
        refnum = this.Claim_Request.RefNum;
      }

      // check if already claimed by another account
      if (element.Company_Id != null &&
        element.Company_Id != this.Claim_Request.CompanyId) {
        message = this.locale.data.claimed_by_another_com;
        refnum = "";
      }

      if (element.Exempt_Flg == "Y") {
        message = this.locale.data.Exempt;
      }

      if (element.FeeAmt == null || element.FeeAmt == 0.0) {
        message = this.locale.data.No_fee;
      }

      if (element.Hold_Flg == "Y")
        hold = this.locale.data.Yes;
      else
        hold = this.locale.data.No;

      if (element.Exempt_Flg == "Y")
        exempt = this.locale.data.Yes;
      else
        exempt = this.locale.data.No;

      if(element.InventoryStatus_Cd == "A") {
        this.vm_NOTEligibleWatchList.push({
          Container: container,
          Status: element.InventoryStatus_Dsc,
          Message: message,
          Ref_Num: refnum,
          Hold: hold,
          Exempt: exempt,
          MTO: element.MTO_Cd,
          Carrier: element.Carrier,
          ConInventory_Id: element.ConInventory_Id,
          ConPrefix_Cd: element.ConPrefix_Cd,
          Con_Num: element.Con_Num,
          ConChkDigit: CheckNullReturnEmpty(element.ConChkDigit),
          ConSz_Cd: element.ConSz_Cd,
          InventoryStatus_Cd: element.InventoryStatus_Cd,
          InventoryStatus_Dsc: element.InventoryStatus_Dsc,
          Hold_Flg: element.Hold_Flg,
          MTO_Cd: element.MTO_Cd,
          FeeStatus_Cd: element.FeeStatus_Cd,
          Exempt_Flg: element.Exempt_Flg,
          Ver_Nbr: element.Ver_Nbr,
          Company_Id: element.Company_Id,
          FeeAmt: element.FeeAmt,
        });
      }
      else{
        this.vm_WatchList.push({
          Container: container,
          Status: element.InventoryStatus_Dsc,
          Message: message,
          Ref_Num: refnum,
          Hold: hold,
          Exempt: exempt,
          MTO: element.MTO_Cd,
          Carrier: element.Carrier,
          ConInventory_Id: element.ConInventory_Id,
          ConPrefix_Cd: element.ConPrefix_Cd,
          Con_Num: element.Con_Num,
          ConChkDigit: CheckNullReturnEmpty(element.ConChkDigit),
          ConSz_Cd: element.ConSz_Cd,
          InventoryStatus_Cd: element.InventoryStatus_Cd,
          InventoryStatus_Dsc: element.InventoryStatus_Dsc,
          Hold_Flg: element.Hold_Flg,
          MTO_Cd: element.MTO_Cd,
          FeeStatus_Cd: element.FeeStatus_Cd,
          Exempt_Flg: element.Exempt_Flg,
          Ver_Nbr: element.Ver_Nbr,
          Company_Id: element.Company_Id,
          FeeAmt: element.FeeAmt,
        });

      }


    });

    this.vm_WatchList = this.vm_WatchList.slice(1);
  }

  private ProcessClaimList() {
    this.ClaimList_Info.forEach((element) => {
      let container: string;
      let status: string;
      let message: string;
      let hold: string;
      let exempt: string;
      let refnum: string;
      let feeamount: number;

      container = element.ConPrefix_Cd + element.Con_Num + CheckNullReturnEmpty(element.ConChkDigit);

      feeamount = element.FeeAmt;

      if (element.InventoryStatus_Cd == "A") {
        if (this.BCO_Info.VTXCreditStatus_Cd == "D") {
          message = this.locale.data.available_pay;
          if (element.FeeAmt == null || element.FeeAmt == 0.0) {
            feeamount = 0.0;
          }
        }
        else if (this.BCO_Info.VTXCreditStatus_Cd == "C") {
          message = this.locale.data.available_claim;
        }
      } else if (element.InventoryStatus_Cd == "P") {
        message = this.locale.data.not_announced;
      }

      refnum = element.Ref_Num;
      // check if claimed by same account
      if (element.Company_Id == this.Claim_Request.CompanyId) {
        if (this.BCO_Info.VTXCreditStatus_Cd == "D") {
          message = this.locale.data.paid_by_your_com;
        } else
          message = this.locale.data.claimed_by_your_com;
      } else {
        refnum = this.Claim_Request.RefNum;
      }

      if (element.Hold_Flg == "Y")
        hold = this.locale.data.Yes;
      else
        hold = this.locale.data.No;

      if (element.Exempt_Flg == "Y")
        exempt = this.locale.data.Yes;
      else
        exempt = this.locale.data.No;

      this.vm_ClaimList.push({
        Container: container,
        Status: element.InventoryStatus_Dsc,
        Message: message,
        Ref_Num: refnum,
        Hold: hold,
        Exempt: exempt,
        MTO: element.MTO_Cd,
        Carrier: element.Carrier,
        ConInventory_Id: element.ConInventory_Id,
        ConPrefix_Cd: element.ConPrefix_Cd,
        Con_Num: element.Con_Num,
        ConChkDigit: CheckNullReturnEmpty(element.ConChkDigit),
        ConSz_Cd: element.ConSz_Cd,
        InventoryStatus_Cd: element.InventoryStatus_Cd,
        InventoryStatus_Dsc: element.InventoryStatus_Dsc,
        Hold_Flg: element.Hold_Flg,
        MTO_Cd: element.MTO_Cd,
        FeeStatus_Cd: element.FeeStatus_Cd,
        Exempt_Flg: element.Exempt_Flg,
        Ver_Nbr: element.Ver_Nbr,
        Company_Id: element.Company_Id,
        FeeAmt: feeamount
      });
    });

    this.vm_ClaimList = this.vm_ClaimList.slice(1);

  }

  isAllClaimsSelected() {
    const numSelected = this.claimselection.selected.length;
    const numRows = this.dsClaimsList.data.length;

    let allselected = true;

    this.dsClaimsList.data.forEach((row) =>
      {
        if(row.Company_Id != this.Claim_Request.CompanyId)
         if(this.claimselection.isSelected(row))
         {
           ;
         }
         else {
          allselected = false;
         }

      })

    return allselected;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterClaimsToggle() {
    this.isAllClaimsSelected()
      ? this.claimselection.clear()
      : this.dsClaimsList.data.forEach((row) =>
      {
        if(row.Company_Id != this.Claim_Request.CompanyId)
        this.claimselection.select(row)
      }
          
        );
  }

  ButtonBehaviour()
  {

    this.SUBMITOnly = true;
    this.PAYplusSUBMIT = false;

    if(this.vm_ClaimList.length > 0)
      document.getElementById('btnSubmit').innerHTML = this.locale.data.pay_plus_submit;


    let checkedcount = 0;

    if(this.vm_ClaimList.length > 0)
    {
      this.claimselection.selected.forEach((s) => {
        if(s.InventoryStatus_Cd == "A")
          checkedcount = checkedcount + 1;
      });

    }


    if(this.vm_ClaimList.length == 0)
    {
      // this.SUBMITOnly = false;
      this.PAYplusSUBMIT = false;
    }

    if(this.vm_ClaimList.length == 0 && this.vm_WatchList.length > 0)
    {
      this.SUBMITOnly = true;
      this.PAYplusSUBMIT = false;
    }


    if(this.vm_ClaimList.length > 0 && checkedcount > 0 && this.vm_WatchList.length == 0)
    {
      this.SUBMITOnly = false;
      this.PAYplusSUBMIT = true;
      document.getElementById('btnSubmit').innerHTML = this.locale.data.PAY;
    }

    if(this.vm_ClaimList.length > 0 && checkedcount == 0 && this.vm_WatchList.length == 0)
    {
      this.SUBMITOnly = false;
      this.PAYplusSUBMIT = false;
      document.getElementById('btnSubmit').innerHTML = this.locale.data.PAY;
    }

    if(this.vm_ClaimList.length > 0 && checkedcount > 0 && this.vm_WatchList.length > 0)
    {
      this.SUBMITOnly = false;
      this.PAYplusSUBMIT = true;
      // document.getElementById('btnSubmit').innerHTML = 'PAY';
    }
    //Cheking the Current Date is between StartDate and EndDate
    if(this.serverdate>=this.StartDate && this.serverdate.slice(0,10)<=this.EndDate.slice(0,10))
    {

     //Checking TMF Flag
     if(this.FeeType_Cd.toUpperCase()=='TMF')
     {
      
     if(this.checkTMFFlag=='Y' )
     {
      
      stringlog("new tarriff exists");
      this.StartEndDateRangeExists =  true;
      //if(this.NewTariffDetails.length > 0)
      //{
        this.Effective_DtTm = this.EffectiveDate;

        const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const d = new Date(this.Effective_DtTm);
        let nameofthemonth = month[d.getMonth()];
        let dayofthemonth = d.getDate();

        stringlog("day: " + dayofthemonth + ": and month: " +  nameofthemonth)

        this.FutureButtonLabel = "Pay " + nameofthemonth + ". " + dayofthemonth + " Rate";
        this.CurrentTariffLabel  ="Pay Current Rate";
        document.getElementById('btnSubmit').innerHTML = "Pay Current Tariff";
      //}
      
     }else
     {
      
      this.StartEndDateRangeExists=false;
     }
    }
     //Cheking CTF Flag
     if(this.FeeType_Cd.toUpperCase()=='CTF')
     {
     if(this.checkCTFFlag=='Y')
     {
      stringlog("new tarriff exists");
      this.StartEndDateRangeExists =  true;
      //if(this.NewTariffDetails.length > 0)
      //{
        this.Effective_DtTm = this.EffectiveDate;

        const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const d = new Date(this.Effective_DtTm);
        let nameofthemonth = month[d.getMonth()];
        let dayofthemonth = d.getDate();

        stringlog("day: " + dayofthemonth + ": and month: " +  nameofthemonth)

        this.FutureButtonLabel = "Pay " + nameofthemonth + ". " + dayofthemonth + " Rate";
        this.CurrentTariffLabel  ="Pay Current Rate";
        document.getElementById('btnSubmit').innerHTML = "Pay Current Tariff";
      //}
      
     }else
     {
      this.StartEndDateRangeExists=false;
     }
    }     
    }
    else
    {
      stringlog("ONLY current tarriff exists");
      this.StartEndDateRangeExists =  false;
    }

  }



  CheckBoxChange() {
    let checkedcount = 0;
    this.claimselection.selected.forEach((s) => {
      if(s.InventoryStatus_Cd == "A")
        checkedcount = checkedcount + 1;
    });

    this.ButtonBehaviour();

  }

  onClose() {

    let url =  this.router.url;
    stringlog("CLOSE current url: " +  url);
    this.router.navigate(['/']).then(() => { this.router.navigate([url]); })
    
    stringlog("Close");
    this.dialog.closeAll();
  
  }

  Cancel() {

    let url =  this.router.url;
    stringlog("CANCEL current url: " +  url);
    // this.router.navigate(['/']).then(() => { this.router.navigate(["/importsearch"]); })
    this.router.navigate(['/']).then(() => { this.router.navigate([url]); })
    stringlog("Cancel");
    this.dialog.closeAll();
  
   
  }

  PreparationforPayment(tarifftype : string) {
    // claimwatchlistdisplay
    this.ShowLoader = true;

    stringlog("tarifftype:" + tarifftype);
    //  selectedUserFeesDueList

    const futureTariffSubReq = <TN_ConInventoryType>{};
    futureTariffSubReq.CompanyId = this.BCO_Info.Company_Id;
    futureTariffSubReq.FeeTypeCode = this.Claim_Request.FeeTypeCode;
    futureTariffSubReq.LoginName = this.Claim_Request.LoginName;
    futureTariffSubReq.UserId = this.Claim_Request.UserId;
    futureTariffSubReq.RefNum = this.Claim_Request.RefNum;

    this.claimselection.selected.forEach((element) => {            
     this.checkFutureTatiffButtonVerification.push({
      ConInventory_Id: element.ConInventory_Id,
      ConPrefix_Cd: element.ConPrefix_Cd,
      Con_Num: element.Con_Num,
      ConChkDigit: CheckNullReturnEmpty(element.ConChkDigit),
      ConSz_Cd: element.ConSz_Cd,
      InventoryStatus_Cd: element.InventoryStatus_Cd,
      InventoryStatus_Dsc: element.InventoryStatus_Dsc,
      Hold_Flg: element.Hold_Flg,
      MTO_Cd: element.MTO_Cd,
      Carrier: element.Carrier,
      Company_Id: this.BCO_Info.VTXCreditStatus_Cd == "D"? "": element.Company_Id,
      //Company_Id: element.Company_Id,
      FeeStatus_Cd: element.FeeStatus_Cd,      
      FeeAmt: element.FeeAmt,
      Ref_Num: element.Ref_Num,
      Exempt_Flg: element.Exempt_Flg,
      Ver_Nbr: element.Ver_Nbr,      
      });
   
     });
     
     futureTariffSubReq.CheckFutureTariffVerifcationlist=this.checkFutureTatiffButtonVerification.slice(1);
     //Checking Future Tariff Rate
     this.dataService.checkFutureTariffDetails(futureTariffSubReq).subscribe(
      (data: any) => 
      {

       this.NewTariffDetails=data["NewTariffDetails"];   

       this.selectedUserFeesDueList = [<any>{}];

      this.claimselection.selected.forEach((element) => {

      stringlog("element:"+ JSON.stringify(element));

      let trip = { ...element };

      // if(element.InventoryStatus_Cd == "A")
      // {
      //   this.selectedUserFeesDueList.push(element);
      //   this.SelectedTripList.push(element.ConInventory_Id);
      // }

      if(element.InventoryStatus_Cd == "A")
      {
       
        // stringlog("fullcontainer:" + JSON.stringify(element));
        if(tarifftype == 'futurepay')
        {
          //Finding New Tariff Rate from NewTariffDetails table
          let newfeerate = this.NewTariffDetails.find( t => t.ConInventory_Id == element.ConInventory_Id).Rate;
          if(newfeerate == null || newfeerate == 0.0)
          {
            newfeerate = 0.0;
          }
          stringlog("futurepay:" + newfeerate);
          trip.FeeAmt = newfeerate;
        
        }
        else if(tarifftype == 'pay')
        {
          //The Current Date is greater than TarrifEffective Date 
          //Finding NewTariff Rate
          if(this.serverdate>=this.EffectiveDate)
          {
            if(this.FeeType_Cd.toUpperCase()=='TMF')
            {      
            if(this.checkTMFFlag=='Y')
            {
              let newfeerate = this.NewTariffDetails.find( t => t.ConInventory_Id == element.ConInventory_Id).Rate;
              if(newfeerate == null || newfeerate == 0.0)
              {
                newfeerate = 0.0;
              }
              stringlog("futurepay:" + newfeerate);
              trip.FeeAmt = newfeerate;

            }
            else{
              // stringlog("fullcontainer:" + JSON.stringify(element));
              let currentfeerate = this.ClaimList_Info.find( t => t.ConInventory_Id == element.ConInventory_Id).FeeAmt;
              if(currentfeerate == null || currentfeerate == 0.0)
               {
                 currentfeerate = 0.0;
               }
               stringlog("currentrate:" + currentfeerate);
               trip.FeeAmt = currentfeerate;
            }

           }

           if(this.FeeType_Cd.toUpperCase()=='CTF')
            {      
            if(this.checkCTFFlag=='Y')
            {
              let newfeerate = this.NewTariffDetails.find( t => t.ConInventory_Id == element.ConInventory_Id).Rate;
              if(newfeerate == null || newfeerate == 0.0)
              {
                newfeerate = 0.0;
              }
              stringlog("futurepay:" + newfeerate);
              trip.FeeAmt = newfeerate;

            }
            else{
              // stringlog("fullcontainer:" + JSON.stringify(element));
              let currentfeerate = this.ClaimList_Info.find( t => t.ConInventory_Id == element.ConInventory_Id).FeeAmt;
              if(currentfeerate == null || currentfeerate == 0.0)
               {
                 currentfeerate = 0.0;
               }
               stringlog("currentrate:" + currentfeerate);
               trip.FeeAmt = currentfeerate;
            }
            
           }
          }
          else
          {
          // stringlog("fullcontainer:" + JSON.stringify(element));
          let currentfeerate = this.ClaimList_Info.find( t => t.ConInventory_Id == element.ConInventory_Id).FeeAmt;
          if(currentfeerate == null || currentfeerate == 0.0)
          {
            currentfeerate = 0.0;
          }
          stringlog("currentrate:" + currentfeerate);
          trip.FeeAmt = currentfeerate;
         }

        }
        this.selectedUserFeesDueList.push(trip);
      }
    });
  
    
    let totalfee = 0;

    this.selectedUserFeesDueList.forEach(element => {
      totalfee =  totalfee +  this.selectedUserFeesDueList.FeeAmt;
    });

    this.selectedUserFeesDueList = this.selectedUserFeesDueList.slice(1);

    stringlog("selectedUserFeesDueList:" + JSON.stringify(this.selectedUserFeesDueList) );

    this.directPayment = true;
    this.claimwatchlistdisplay = false;
    this.ShowLoader = false;
  }
  );

    // if (this.selectedUserFeesDueList.length > 0) {
    //   this.claimwatchlistdisplay = false;
    //   this.directPayment = true;
    //   this.ShowLoader = false;
    // } else {
    //   this.ShowLoader = false;
    //   return;
    // }
  }

  SubmitToPending() {

    this.directPayment =  false;
    this.claimwatchlistdisplay = true;    

    // return;

    // const  claimSubReq : SubmitClaimRequest  = new SubmitClaimRequest();
    const claimSubReq = <ImportClaimList_SubmitRequestType>{};

    // this.Claim_Request

    claimSubReq.CompanyId = this.BCO_Info.Company_Id;
    claimSubReq.FeeTypeCode = this.Claim_Request.FeeTypeCode;
    claimSubReq.LoginName = this.Claim_Request.LoginName;
    claimSubReq.UserId = this.Claim_Request.UserId;
    claimSubReq.RefNum = this.Claim_Request.RefNum;

    let claimlist1 = [<TN_ConInventory>{}];

    this.vm_ClaimList.forEach((element) => {
      stringlog("Con_Num: "+ element.Con_Num + " --ref num:" + element.Ref_Num);
      claimlist1.push({
        ConInventory_Id: element.ConInventory_Id,
        ConPrefix_Cd: element.ConPrefix_Cd,
        Con_Num: element.Con_Num,
        ConChkDigit: CheckNullReturnEmpty(element.ConChkDigit),
        ConSz_Cd: element.ConSz_Cd,
        InventoryStatus_Cd: element.InventoryStatus_Cd,
        InventoryStatus_Dsc: element.InventoryStatus_Dsc,
        Hold_Flg: element.Hold_Flg,
        MTO_Cd: element.MTO_Cd,
        Carrier: element.Carrier,
        Company_Id: this.BCO_Info.VTXCreditStatus_Cd == "D"? "": element.Company_Id,
        //Company_Id: element.Company_Id,
        FeeStatus_Cd: element.FeeStatus_Cd,      
        FeeAmt: element.FeeAmt,
        Ref_Num: element.Ref_Num,
        Exempt_Flg: element.Exempt_Flg,
        Ver_Nbr: element.Ver_Nbr,
      });
    });


    //  this.vm_ClaimtList = this.vm_ClaimtList.slice(1);

    claimlist1 = claimlist1.slice(1);

    // claimSubReq.claimslist = claimlist1;
    claimSubReq.claimslist = null;

    let watchlist1 = [<TN_ImpWatchList>{}];

    this.WatchList_Info.forEach((element) => {
      watchlist1.push({
        ConInventory_Id: element.ConInventory_Id,
        ConPrefix_Cd: element.ConPrefix_Cd,
        Con_Num: element.Con_Num,
        ConChkDigit: CheckNullReturnEmpty(element.ConChkDigit),
        ConSz_Cd: element.ConSz_Cd,
        InventoryStatus_Cd: element.InventoryStatus_Cd,
        InventoryStatus_Dsc: element.InventoryStatus_Dsc,
        Hold_Flg: element.Hold_Flg,
        MTO_Cd: element.MTO_Cd,
        Carrier: element.Carrier,
        Company_Id: element.Company_Id,
        FeeStatus_Cd: element.FeeStatus_Cd,
        FeeAmt: element.FeeAmt,
        Ref_Num: element.Ref_Num,
        Exempt_Flg: element.Exempt_Flg,
        Ver_Nbr: element.Ver_Nbr,
      });
    });

    watchlist1 = watchlist1.slice(1);

    claimSubReq.watchlist = watchlist1;

    stringlog("watchlist lenght: " + watchlist1.length );

    if(watchlist1.length <= 0)
      return;

    this.ShowLoader = true;   
  
    this.dataService.SubmitPostImportClaims(claimSubReq).subscribe(
      (data: any) => {
        this.ShowLoader = false;
        if (data.result == "error") {
          this.ImportSearchErr = true;
          this.ImportSearchMessageError = data.errormessage;
        } else if (data == true) {

          if(this.submitopay == true)
          {
            return;
          }
          else
          {
            this.dialog.closeAll();
            this.router.navigate(["/importclaimlist"]);
          }
         
          // redirect
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  SubmitButton(funcname: string) {


    // return;

    if(funcname == "submit")
    {
      // return;
      this.submitopay = false;
      this.SubmitToPending();
    }
    else if(funcname == "pay" || funcname == "futurepay")
    {
      this.submitopay = true;
      this.SubmitToPending();
      this.PreparationforPayment(funcname);
    }

    return;

  }

  RemoveContainerFromClaimlist(row: any) {
   
    // return;

    // this.vm_ClaimList.find( e => e.Con_Num ==  row);

    this.claimselection.deselect(this.vm_ClaimList.find( e => e.Con_Num ==  row));


    this.vm_ClaimList = this.vm_ClaimList.filter((obj) => obj.Con_Num !== row);
    


    //  rebind the list

    this.dsClaimsList = new MatTableDataSource(this.vm_ClaimList);
    this.dsClaimsList.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Container': return item.Container;
        case 'Size': return item.ConSz_Cd;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        default: return item[property];
      }
    };

    this.dsClaimsList.sort = this.sort;
    // this.claimselection = new SelectionModel<TN_ConInventory>(true, []);
    this.dsClaimsList.paginator = this.paginator;
    this.TotalRecords = this.vm_ClaimList.length;
    // this.selection = new SelectionModel<TN_Booking>(true, []);

    let checkedcount = 0;
    this.claimselection.selected.forEach((s) => {
      if(s.InventoryStatus_Cd == "A")
        checkedcount = checkedcount + 1;
    });

    // if (checkedcount > 0 && this.BCO_Info.VTXCreditStatus_Cd == "D") {
    //   this.buttonDisabled = false;
    // } else if (checkedcount == 0 && this.BCO_Info.VTXCreditStatus_Cd == "D") {
    //   this.buttonDisabled = true;
    // }

    // if(this.vm_ClaimList.length == 0)  this.buttonDisabled = true;

    this.ButtonBehaviour();

  }

  RemoveContainerFromWatchlist(row: any) {


    // return;

    this.vm_WatchList = this.vm_WatchList.filter((obj) => obj.Con_Num !== row);

    //  rebind the list


    this.dsWatchList = new MatTableDataSource(this.vm_WatchList);

    this.dsWatchList.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Container': return item.Container;
        case 'Size': return item.ConSz_Cd;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        // case 'FullContainer': return item.FullContainer;
        default: return item[property];
      }
    };


  this.dsWatchList.sort = this.watchlistsort;
  
    this.dsWatchList.paginator = this.paginator;
    this.TotalRecords = this.vm_WatchList.length;


    this.ButtonBehaviour();

  }
  DownloadExcel_ClaimList(): void {
    let xlsxcolumnNames = [];
    this.PDFColNamesClaimtList = [];
    // ['Booking_Num','BookingStatus_Dsc', 'BookingStatus_Cd', 'Ref_Num',
    //'Hold_Flg',  'Exempt_Flg','MTO_Cd','Carrier','Remove'];
    this.PDFColNamesClaimtList.push({
      title: "Container",
      dataKey: "Container",
    });
    this.PDFColNamesClaimtList.push({
      title: "Status",
      dataKey: "Status",
    });
    this.PDFColNamesClaimtList.push({
      title: "Message",
      dataKey: "Message",
    });
    this.PDFColNamesClaimtList.push({
      title: "Ref Num",
      dataKey: "Ref_Num",
    });
    this.PDFColNamesClaimtList.push({
      title: "Hold",
      dataKey: "Hold",
    });
    this.PDFColNamesClaimtList.push({
      title: "Size",
      dataKey: "ConSz_Cd",
    });
    this.PDFColNamesClaimtList.push({
      title: "Exempt",
      dataKey: "Exempt",
    });
    this.PDFColNamesClaimtList.push({
      title: "MTO",
      dataKey: "MTO",
    });
    this.PDFColNamesClaimtList.push({
      title: "Vessel Voyage",
      dataKey: "Carrier",
    });

    if (this.vm_ClaimList.length > 0) {
      this.vm_ClaimList.forEach((element) => {
        let row = [];

        // let duedate = moment(element.DueDate).format("MM/DD/YYYY");
        // let invdate = moment(element.InvoiceDate).format("MM/DD/YYYY");

        // ['Booking_Num','BookingStatus_Dsc', 'BookingStatus_Cd', 'Ref_Num',
        //'Hold_Flg',  'Exempt_Flg','MTO_Cd','Carrier','Remove'];

        for (let j = 0; j < this.PDFColNamesClaimtList.length; j++) {
          switch (this.PDFColNamesClaimtList[j].dataKey) {
            case "Container":
              row.push(element.Container);
              break;
            case "Status":
              row.push(element.Status);
              break;
            case "Message":
              row.push(element.Message);
              break;
            case "Ref_Num":
              row.push(element.Ref_Num);
              break;
            case "Hold":
              row.push(element.Hold);
              break;
              case "ConSz_Cd":
                row.push(element.ConSz_Cd);
                break;
            case "Exempt":
              row.push(element.Exempt);
              break;
            case "MTO":
              row.push(element.MTO);
              break;
            case "Carrier":
              row.push(element.Carrier);
              break;
          }
        }
        xlsxcolumnNames.push(row);
      });

      this.exportToExcelFile(
        xlsxcolumnNames,
        "Import Container Claim List",
        this.PDFColNamesClaimtList
      );
    }
  }

  public exportToExcelFile(
    json: any[],
    excelFileName: string,
    columnNames: any
  ) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    var range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (var C = range.s.r; C < columnNames.length; ++C) {
      var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
      if (!worksheet[address]) continue;
      worksheet[address].v = columnNames[worksheet[address].v].title;
    }
    const workbook: XLSX.WorkBook = {
      Sheets: { DisplayList: worksheet },
      SheetNames: ["DisplayList"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  exportTable() {
    // TableUtil.exportTableToExcel('ExportSearchResultDetails','Export Claim Eligible List');
  }
  //Download PDF Data
  DownloadPDF_Claimlist() {
    if (this.vm_ClaimList.length > 0) {
      var doc = new jsPDF("l", "in", [1200, 1401]);
      var col = [
        "Container",
        "Status",
        "Message",
        "Ref Num",
        "Hold",
        "Size",
        "Exempt",
        "MTO",
        "Vessel Voyage",
      ];
      var rows = [];
      this.vm_ClaimList.forEach((item) => {
        // let duedate = moment(item.DueDate).format("MM/DD/YYYY");
        // let invdate = moment(item.InvoiceDate).format("MM/DD/YYYY");

        var temp = [
          item.Container,
          item.Status,
          item.Message,
          item.Ref_Num == null ? "" : item.Ref_Num,
          item.Hold,
          item.ConSz_Cd,
          item.Exempt,
          item.MTO,
          item.Carrier,
          // item.DueDate,
        ];
        rows.push(temp);
      });
      doc.autoTable(col, rows);
      doc.save("Import Container ClaimList.pdf");
    }
  }

  exportTable_watchlist() {
    // TableUtil.exportTableToExcel('ExportSearchResultWatchListDetails','Export Claim Watch List');
  }

  DownloadPDF_Watchlist() {
    if (this.vm_WatchList.length > 0) {
      var doc = new jsPDF("l", "in", [1200, 1401]);
      var col = [
        "Container",
        "Status",
        "Message",
        "Ref Num",
        "Hold",
        "Size",
        "Exempt",
        "MTO",
        "Vessel Voyage",
      ];
      var rows = [];
      this.vm_WatchList.forEach((item) => {
        // let duedate = moment(item.DueDate).format("MM/DD/YYYY");
        // let invdate = moment(item.InvoiceDate).format("MM/DD/YYYY");

        var temp = [
          item.Container,
          item.Status,
          item.Message,
          item.Ref_Num == null ? "" : item.Ref_Num,
          item.Hold,
          item.ConSz_Cd == null ? "": item.ConSz_Cd,
          item.Exempt,
          item.MTO,
          item.Carrier,
          // item.DueDate,
        ];
        rows.push(temp);
      });
      doc.autoTable(col, rows);
      doc.save("Import Container Watch ist.pdf");
    }
  }

  DownloadPDF_NElist()
  {
    if (this.vm_NOTEligibleWatchList.length > 0) {
      var doc = new jsPDF("l", "in", [1200, 1401]);
      var col = [
        "Container",
        "Status",
        "Message",
        "Ref Num",
        "Hold",
        "Size",
        "Exempt",
        "MTO",
        "Vessel Voyage",
      ];
      var rows = [];
      this.vm_NOTEligibleWatchList.forEach((item) => {
        // let duedate = moment(item.DueDate).format("MM/DD/YYYY");
        // let invdate = moment(item.InvoiceDate).format("MM/DD/YYYY");

        var temp = [
          item.Container,
          item.Status,
          item.Message,
          item.Ref_Num == null ? "" : item.Ref_Num,
          item.Hold,
          item.ConSz_Cd == null ? "": item.ConSz_Cd,
          item.Exempt,
          item.MTO,
          item.Carrier,
          // item.DueDate,
        ];
        rows.push(temp);
      });
      doc.autoTable(col, rows);
      doc.save("Import Container Watch ist.pdf");
    }
  }

  DownloadExcel_WatchList() {
    let xlsxcolumnNames = [];
    this.PDFColNamesClaimtList = [];

    this.PDFColNamesClaimtList.push({
      title: "Container",
      dataKey: "Container",
    });
    this.PDFColNamesClaimtList.push({
      title: "Status",
      dataKey: "Status",
    });
    this.PDFColNamesClaimtList.push({
      title: "Message",
      dataKey: "Message",
    });
    this.PDFColNamesClaimtList.push({
      title: "Ref Num",
      dataKey: "Ref_Num",
    });
    this.PDFColNamesClaimtList.push({
      title: "Hold",
      dataKey: "Hold",
    });
    this.PDFColNamesClaimtList.push({
      title: "Size",
      dataKey: "ConSz_Cd",
    });
    this.PDFColNamesClaimtList.push({
      title: "Exempt",
      dataKey: "Exempt",
    });
    this.PDFColNamesClaimtList.push({
      title: "MTO",
      dataKey: "MTO",
    });
    this.PDFColNamesClaimtList.push({
      title: "Vessel Voyage",
      dataKey: "Carrier",
    });

    if (this.vm_WatchList.length > 0) {
      this.vm_WatchList.forEach((element) => {
        let row = [];

        // let duedate = moment(element.DueDate).format("MM/DD/YYYY");
        // let invdate = moment(element.InvoiceDate).format("MM/DD/YYYY");

        // ['Booking_Num','BookingStatus_Dsc', 'BookingStatus_Cd', 'Ref_Num',
        //'Hold_Flg',  'Exempt_Flg','MTO_Cd','Carrier','Remove'];

        for (let j = 0; j < this.PDFColNamesClaimtList.length; j++) {
          switch (this.PDFColNamesClaimtList[j].dataKey) {
            case "Container":
              row.push(element.Container);
              break;
            case "Status":
              row.push(element.Status);
              break;
            case "Message":
              row.push(element.Message);
              break;
            case "Ref_Num":
              row.push(element.Ref_Num);
              break;
            case "Hold":
              row.push(element.Hold);
              break;
              case "ConSz_Cd":
                row.push(element.ConSz_Cd);
                break;
            case "Exempt":
              row.push(element.Exempt);
              break;
            case "MTO":
              row.push(element.MTO);
              break;
            case "Carrier":
              row.push(element.Carrier);
              break;
          }
        }
        xlsxcolumnNames.push(row);
      });

      this.exportToExcelFile(
        xlsxcolumnNames,
        "Import Container Watch List",
        this.PDFColNamesClaimtList
      );
    }
  }

  DownloadExcel_NEList()
  {
    let xlsxcolumnNames = [];
    this.PDFColNamesClaimtList = [];

    this.PDFColNamesClaimtList.push({
      title: "Container",
      dataKey: "Container",
    });
    this.PDFColNamesClaimtList.push({
      title: "Status",
      dataKey: "Status",
    });
    this.PDFColNamesClaimtList.push({
      title: "Message",
      dataKey: "Message",
    });
    this.PDFColNamesClaimtList.push({
      title: "Ref Num",
      dataKey: "Ref_Num",
    });
    this.PDFColNamesClaimtList.push({
      title: "Hold",
      dataKey: "Hold",
    });
    this.PDFColNamesClaimtList.push({
      title: "Size",
      dataKey: "ConSz_Cd",
    });
    this.PDFColNamesClaimtList.push({
      title: "Exempt",
      dataKey: "Exempt",
    });
    this.PDFColNamesClaimtList.push({
      title: "MTO",
      dataKey: "MTO",
    });
    this.PDFColNamesClaimtList.push({
      title: "Vessel Voyage",
      dataKey: "Carrier",
    });

    if (this.vm_NOTEligibleWatchList.length > 0) {
      this.vm_NOTEligibleWatchList.forEach((element) => {
        let row = [];

        // let duedate = moment(element.DueDate).format("MM/DD/YYYY");
        // let invdate = moment(element.InvoiceDate).format("MM/DD/YYYY");

        // ['Booking_Num','BookingStatus_Dsc', 'BookingStatus_Cd', 'Ref_Num',
        //'Hold_Flg',  'Exempt_Flg','MTO_Cd','Carrier','Remove'];

        for (let j = 0; j < this.PDFColNamesClaimtList.length; j++) {
          switch (this.PDFColNamesClaimtList[j].dataKey) {
            case "Container":
              row.push(element.Container);
              break;
            case "Status":
              row.push(element.Status);
              break;
            case "Message":
              row.push(element.Message);
              break;
            case "Ref_Num":
              row.push(element.Ref_Num);
              break;
            case "Hold":
              row.push(element.Hold);
              break;
              case "ConSz_Cd":
                row.push(element.ConSz_Cd);
                break;
            case "Exempt":
              row.push(element.Exempt);
              break;
            case "MTO":
              row.push(element.MTO);
              break;
            case "Carrier":
              row.push(element.Carrier);
              break;
          }
        }
        xlsxcolumnNames.push(row);
      });

      this.exportToExcelFile(
        xlsxcolumnNames,
        "Import Container Not Eligible List",
        this.PDFColNamesClaimtList
      );
    }
  }

 
}
