import { Injectable } from '@angular/core';
import { IInterfacesAccess, IInterfaceActions } from '../models/userinfomodel';
import { environment } from 'src/environments/environment';
import { IJobStatus, IJobTypes, IJPriority, INotificationViewModel } from '../models/notificationmodel';
import { APIService } from './httpservicefactory.service';
import { CustomViewInputModel, UserViewModel } from 'src/app/Interfaces/customview.interface';
import { ParentScreen } from 'src/app/shared/enums/parentscreen';
import { UserInfoType } from '../typesinfo/typesinfo';
import { PpGlobalConstants } from 'src/app/ppcommon/pp-global-constants';

@Injectable({
  providedIn: "root"
})
export class SharedService {
  public accessInterfaces: IInterfacesAccess[] = [];
  public interfaceActions: IInterfaceActions[] = [];
  facilities = [];
  sizetypes = [];
  public currentUri: string = "";
  public email: string;
  public notificationModel: INotificationViewModel;
  constructor(private apiservice: APIService) { }
  public iframeUrl: string = "";
  public CurrentScreen: ParentScreen = ParentScreen.None;
  public username: string;
  errorMessage: any;
  _UserSavedViews: UserViewModel[];
  public jobStatus: IJobStatus[] = [];
  public jobPriority: IJPriority[] = [];
  public jobTypes: IJobTypes[] = [];
 
  public defaultViews: any;
  public customViews: any[] = [];


  AppDateTimeFormats={
    Date:"MM/DD/YYYY",
    DateTime:"MM/DD/YYYY HH:mm",
  };

  EnvironmentConfig(): any {
    if(localStorage.getItem("origin")){
    return   environment.portsconfigs.find(a => (a.instance == localStorage.getItem("origin")) || (a.portcheckinstance == localStorage.getItem("origin")));
    }
    else{      
      let appinstance = JSON.parse(localStorage.getItem("app_instance"))
        .appInstance_Name;
      let portconfig = environment.portsconfigs.find(
        a => a.clientid == appinstance
      );
      return portconfig;
    }

  }

  loadCustomViewData(lAppInterface_Id: number, Interface_Uid: string, custromViewType: string) {
    this.apiservice.UserInfo();

    //sending User_Uid paramenter
    let UserInfo_Get: UserInfoType = JSON.parse(
      localStorage.getItem(PpGlobalConstants.AdditionalUserInfo) || "{}"
    );
     if(UserInfo_Get.User_Uid)
    {
      return new Promise(resolve => {
        this.apiservice.getViewsByUser(lAppInterface_Id, Interface_Uid, UserInfo_Get.User_Uid).subscribe(
          data => {
            this.defaultViews = "";
            this.customViews = [] = [];
            let customViewModel: CustomViewInputModel = null;
            let _DefaultView: UserViewModel = null;
  
            _DefaultView = {
              UserView_Id: 0,
              userViewId:0,
              AppInterface_Id: 0,
              appInterface_Id:0,
              User_Uid: "",
              UserView_Nm: "",
              userViewName:"",
              UserView_Json: "",
              Default_Flg: false,
              DefaultView_Json: "",
              defaultView_Json:"",
              FacilityView_Flg: false
            };
  
            customViewModel = {
              Interface_Uid: Interface_Uid,
              AppInterfaceView: _DefaultView,
              UserSavedViews: this._UserSavedViews,
              UserInterfaceView: _DefaultView,
              AppInterface_Id: lAppInterface_Id,
              User_Uid: UserInfo_Get.User_Uid,
              FilterCriteria: [],
              ColumnOptions: []
            };
  
            if (data && data.length > 0) {
              this.defaultViews = data.filter(x => x.facilityView_Flg == true);
              this.customViews = data.filter(y => y.facilityView_Flg == false);
              if (!!this.defaultViews && this.defaultViews.length) {
                _DefaultView.UserView_Id = 0;
                _DefaultView.AppInterface_Id = this.defaultViews[0].appInterface_Id;
                _DefaultView.User_Uid = customViewModel.User_Uid;
                _DefaultView.UserView_Nm = "";
                _DefaultView.UserView_Json = JSON.parse(this.defaultViews[0].defaultView_Json);
                _DefaultView.Default_Flg = false;
                _DefaultView.DefaultView_Json = JSON.parse(this.defaultViews[0].defaultView_Json);
                customViewModel.AppInterfaceView = JSON.parse(JSON.stringify(_DefaultView));
              }
  
              if (!!this.customViews && this.customViews.length > 0) {
                customViewModel.AppInterface_Id = this.customViews[0].appInterface_Id;
                customViewModel.UserSavedViews = this.customViews;
                customViewModel.UserInterfaceView = this.customViews.find(v => v.default_Flg == true);
                if (!!customViewModel.UserInterfaceView) {
                  customViewModel.UserInterfaceView.UserView_Json = JSON.parse(customViewModel.UserInterfaceView.defaultView_Json);
                }
              } else {
                customViewModel.UserSavedViews = [];
                customViewModel.UserInterfaceView = Object.assign({},_DefaultView);
              }
              localStorage.setItem("customView_" + custromViewType + "_" + environment.portsconfigs.find(a => a.clientid == localStorage.getItem(PpGlobalConstants.appInstance_Name).trim()).clientid, JSON.stringify(customViewModel));
             
              resolve(customViewModel);
            }
          },
          error => {
            this.errorMessage = <any>error;
            resolve({});
          }
        );
      });
    }
  


  }





  SetAppDateTimeFormats(dateFormat, timeFormat){
    if(dateFormat && timeFormat){
      dateFormat = dateFormat.replace(/y/g, 'Y');       // Fix case for years
       //  format = format.replace(/d{4}/g, '#');    // Protect 4-digit DOW sequence
       //  format = format.replace(/d{3}/g, '&');    // Protect 3-digit DOW sequence
       dateFormat = dateFormat.replace(/d/g, 'D');       // Fix case for days
       dateFormat = dateFormat.replace(/#/g, 'dddd');    // Restore our sequence
       dateFormat = dateFormat.replace(/&/g, 'ddd');     // Restore our sequence
       timeFormat = timeFormat.replace(/tt/g, 'A'); 

       this.AppDateTimeFormats.Date = dateFormat;
       this.AppDateTimeFormats.DateTime = dateFormat + " " + timeFormat;
    }
  }

  DatePickerLocale: string = "en-US";

}
