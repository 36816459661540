import { WebContentType } from './../../common/typesinfo/typesinfo';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'src/app/common/services/translate.service';
import { WebapidataService } from 'src/app/common/services/webapidata.service';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-pp-rfid-tags',
  templateUrl: './pp-rfid-tags.component.html',
  styleUrls: ['./pp-rfid-tags.component.scss']
})
export class PpRfidTagsComponent implements OnInit {

  landingpagecontent : WebContentType[];
  RFIDConent : WebContentType

  
  constructor(private dataService:  WebapidataService,
    public locale: TranslateService) {
   }

  ngOnInit() {

    this.dataService.getWebContentAll().subscribe((data:any) => {
      // this.landingpagecontent = data[0];
      this.landingpagecontent= data[0];

      let regstring = 'href="Documents/';
      let finalstring = 'target="_blank" rel="noopener" href="../../assets/Documents/';
       
      this.landingpagecontent.forEach(child => 
        {
          if(child.WebContentType_Cd == 'E')
          {
            this.RFIDConent = this.replaceAll(child.Content_Txt, regstring,finalstring);
          }
        })
        // stringlog("links:"+ JSON.stringify(this.landingpagecontent));

    });
    
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

}
