<div class="preauth-parent-container" >
  <mat-sidenav-container fullscreen>
    <mat-sidenav
      #snav
      [mode]="over"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="0"
      position="start"
    >
      <mat-nav-list>
        <mat-tree [dataSource]="binding" [treeControl]="treeControl" #tree>
          <mat-tree-node
            *matTreeNodeDef="let node"
            class="nav-list-item matdivider"
            [ngStyle]="{ display: node.showInMenuFlg ? 'block' : 'none' }"
          >
            <a
              mat-list-item
              *ngIf="!node.is_href"
              routerLink="{{ node.url }}"
              [routerLinkActive]="['link-active']"
              (click)="changeView(snav); adminTitlename = ''"
              class="nav-list-item"
            >
              {{ node.name }}
            </a>
            <a
              mat-list-item
              *ngIf="node.is_href"
              href="{{ node.url }}"
              (click)="changeView(snav); adminTitlename = ''"
              class="nav-list-item"
            >
              {{ node.name }}
            </a>
          </mat-tree-node>
          <mat-tree-node
            *matTreeNodeDef="let node; when: hasChild"
            matTreeNodeToggle
            class="menu-l1-title matdivider"
          >
            <span style="min-width: 75%">
              {{ node.name }}
            </span>
            <mat-icon class="pointer">{{
              treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
            }}</mat-icon>
          </mat-tree-node>
        </mat-tree>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <mat-toolbar>
        <button mat-button class="btn-menu" (click)="LeftSideNavClicked(snav)">
          <mat-icon>menu</mat-icon>
        </button>
        <!-- ----- Pierpass and portcheck header logo -->
        <div style="display: flex; flex-direction: row; align-content: space-between; width: 62vw;">
          <div class="logo"></div>
          <!-- <span class="example-spacer"></span> -->
          <img *ngIf="DisplayPortCheck" src="../../assets/images/portcheck_header.jpg" style="height: 43px; padding-top: 3px;">
        </div>
        <!-- <span *ngIf="!mobileQuery.matches" class="app-title">{{
          locale.data.title
        }}</span> -->
        <span class="example-spacer"></span>
        <span
          fxLayout="row"
          style="align-items: center"
          *ngIf="!mobileQuery.matches"
        >
          <mat-menu #menu1="matMenu" xPosition="before" id="menu_lang">
            <button mat-menu-item>Languages</button>
          </mat-menu>
          <mat-menu #languagues="matMenu" xPosition="before">
            <ng-container *ngFor="let language of languages">
              <button
                mat-menu-item
                (click)="
                  SaveLocale(language);
                  $event.stopPropagation();
                  setLang(language.key)
                "
              >
                <span class="span-menu-item">{{ language.name }}</span>
                <mat-icon
                  class="maticon"
                  *ngIf="language.key == defaultLocale ? true : false"
                  >check</mat-icon
                >
              </button>
            </ng-container>
          </mat-menu>
          <mat-divider
            [vertical]="true"
            class="mat-divider-vertical"
          ></mat-divider>
          <button
            mat-icon-button
            matTooltip="{{ locale.data.help }}"
            class="header-right-menu-button"
            style="margin: auto"
            [routerLink]="" (click)="openTab()"
          >
            <mat-icon aria-label="help"> help</mat-icon>
          </button>
          
        </span>

        <span *ngIf="mobileQuery.matches" class="push-right">
          <mat-divider
            [vertical]="true"
            class="mat-divider-vertical"
          ></mat-divider>
          <button mat-icon-button class="header-right-menu-button" [routerLink]="" (click)="openTab()">
            <mat-icon aria-label="help">help</mat-icon>
          </button>
        </span>

        <span class="icons-right"> </span>
      </mat-toolbar>
      <!-- TODO main-content-->
      <main class="main1"
      *ngIf="loadOutlet"
      [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
        <div class="hcl">
          <div class="hc">
            <div class="header1">
              <img src="../../assets/images/PierPASS20211022.jpg" style="width:70vw; height:15vh" />
            </div>
            <div class="content-c1">
               <router-outlet #o="outlet"></router-outlet>
            </div>
          </div>
          <div class="pplogon"><app-login></app-login></div>
        </div>
        <div class="ppfooter1">
          <div style="padding-left: 20px; display: flex; flex-direction: row; align-content: space-between; width: 64vw; padding-top: 2px;">
            <img src="../../assets/images/PP_Footer_logo.png" height="60px" style="padding-bottom: 2px;">
            <img *ngIf="DisplayPortCheck" src="../../assets/images/portcheck_footer.jpg" height="60px" style="margin-left: 20px;">
          </div>
          <div style="padding-top: 5px; padding-right: 20px;">
            <img src="../../assets/images/help_outline-24px.svg" height="60px" (click)="openTab()">
          </div>
        </div>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
