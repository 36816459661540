<app-loading-backdrop *ngIf="ShowLoader"></app-loading-backdrop>
<div class="tmfctfcenter">
  <span style="text-align: center; display: inherit; font-size: x-large; flex-flow: column; text-decoration: underline;">
    PortCheck Terms & Conditions
  </span>
  <br>
  <span class="small-heading-dark">
    Please review the following Terms and Conditions for establishing your company as a registered Account in PortCheck. You must check the box labeled ‘Accept Terms and Conditions’ to proceed.
  </span>
  <br>
  <div style="height: 37vh; overflow-y: scroll;">
  <app-login-ctf-tc-content></app-login-ctf-tc-content>
  </div>
  <br>
  <div style="font-size: 12px">
    <mat-checkbox style="font-size: 12px"  name="CheckTermsCondition" class="checkcondition" [(ngModel)]="IsTermsChecked" (change)="TCChecked()">{{locale.data.accept_terms_conditions}}</mat-checkbox> &nbsp; &nbsp;&nbsp;
    <mat-error *ngIf="TCCheckErrorMessageDisplay" style="font-size: 12px"> *{{locale.data.val_t_c}}</mat-error>
  </div>
  <div *ngIf="false">
    <mat-radio-group  [(ngModel)]="TCOption">
     &nbsp;&nbsp;&nbsp; 
     <mat-radio-button  [value]="1" [checked]="TCOption === 1"
     matTooltip="I do NOT accept Terms & Conditions">
     Accept Terms & Conditions</mat-radio-button><br><br>
     &nbsp;&nbsp;&nbsp; 
     
     <mat-radio-button [value]="2" [checked]="TCOption === 2"
     matTooltip="Accept Terms & Conditions">
     Do NOT Accept Terms & Conditions
     </mat-radio-button>
    </mat-radio-group>
  
  </div>
  <br>
  <div>
    <button mat-raised-button style="background-color: #FFFFFF;color: black;" type="button"
    (click)="Cancel()">{{ locale.data.cancel }}</button>&nbsp;
    <button mat-raised-button type="submit" (click)="Submit()" >{{ locale.data.submit }}</button>
  </div>
</div>
