export class CreditCardValidator {
   static COFCheck="Credit Card On File";
   static CreditCardCheck:string = "Credit Card";
   static eCheck:string = "eCheck";
   static CreditBalance:string = "CreditBalance";
   static CreditBalanceSpace:string = "Credit Balance";

   static  CreditCardOnFile="CreditCardOnFile";
   static  CreditCard="CreditCard";
 }
export enum CardType
{
    None,
    MasterCard,
    BankCard,
    Visa,
    AmericanExpress,
    Discover,
    DinersClub,
    JCB
};

export class CCType
{       
    static CCType_MC:string = "MC";
    static CCType_VISA:string = "VISA";
    static CCType_AMEX:string = "AMEX";
    static CCType_DISC:string = "DISC";
    static CCType_INVALID:string = "INVALID";
    static CCType_NOTONFILE:string = "NOTONFILE";
    static CCType_NA:string = "NA";
    //UI Radio button Values
    static rdoVisa:string= "rdoVisa";
    static rdoMC:string= "rdoMC";
    static rdoAmex:string= "rdoAmex";

}

