import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
//import { CookieService } from "ngx-cookie-service";
import { environment } from "../../../environments/environment";
import { SharedService } from "./globalvariable.service";

@Injectable()
export class AuthService {
  userProfile: object;
  isAuthorizedSubscription: Subscription;
  isAuthorized: boolean;
  instanceSuffix:string;

  constructor(
    //private cookieService: CookieService,
    private sharedService: SharedService
  ) { 
    let instanceConfig = environment.portsconfigs.find(a => a.clientid == localStorage.getItem("appInstance_Name").trim());
    this.instanceSuffix = "_" + instanceConfig.clientid;
  }

  ngOnInit() { }

  login() {
    // this.oidcSecurityService.authorize();
  }

  logout() {
    // this.oidcSecurityService.logoff();
  }

  getToken() {
    // this.oidcSecurityService.getToken();
  }

  clearsession() {
    //this.cookieService.deleteAll();
    
    // if(this.cookieService.get("AuthCookie")){
    //   var v=JSON.parse(this.cookieService.get("AuthCookie"));
    //   if(v.client_id == environment.portsconfigs.find(a => a.instance == window.location.origin).clientid.trim())
    //   {
    //     this.cookieService.deleteAll("/", environment.oidcmoduleconfig.domainname);
    //   }
    // }
    if (localStorage.getItem("userInfo" + this.instanceSuffix) !== "") {
      localStorage.setItem("userInfo" + this.instanceSuffix, "");
    }
    localStorage.setItem("userIntefaces" + this.instanceSuffix, "");
    localStorage.setItem("customView_mybooking" + this.instanceSuffix, "");
    localStorage.setItem("customView_mycontainer" + this.instanceSuffix, "");
    localStorage.setItem("customView_vesselschedule" + this.instanceSuffix, "");
    localStorage.setItem("customView_mygroupcodes" + this.instanceSuffix, "");
    localStorage.setItem("customView_myjobs" + this.instanceSuffix, "");
    localStorage.setItem("DrayUnitStatuses" + this.instanceSuffix, "");
    localStorage.setItem("PortFacilities" + this.instanceSuffix, "");
    localStorage.setItem("PreGateStatuses" + this.instanceSuffix, "");
    localStorage.setItem("ShippingLines" + this.instanceSuffix, "");
    localStorage.setItem("dr", "");
    localStorage.setItem("dv", "");
  }
}
