
  <diV class="pp-home">
    <mat-tab-group [(selectedIndex)]="selectedIndex">
      <mat-tab [label]="locale.data.hp_bulletins_lbl">
        <app-bulletin></app-bulletin>
      </mat-tab>
      <mat-tab [label]="locale.data.hp_news_lbl">
        <app-news-info
          [webcontent]="landingpagetabscontent"
        ></app-news-info>
      </mat-tab>
      <mat-tab [label]="locale.data.hp_about_pp_lbl">
        <app-about-pierpass
          [webcontent]="landingpagetabscontent"
        ></app-about-pierpass>
      </mat-tab>
        <mat-tab label="Links">
        <app-pierpass-links
          [webcontent]="landingpagetabscontent"
        ></app-pierpass-links>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
            <span (click)="openhelplink()">Help</span>
        </ng-template>
        <app-bulletin></app-bulletin>
    </mat-tab>
    </mat-tab-group>
  </diV>


