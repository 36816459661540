import { stringlog } from "src/app/csr-containerhistory/csr-containerhistory-type";
import { PpGlobalConstants } from "src/app/ppcommon/pp-global-constants";
import { RGACCESSLIST } from "../models/userinfomodel";

export class Commonfunctions {
}


export function CheckNullReturnEmpty(value : string){
  
    if(value === undefined || value === null)
    return "";
    else
        return value;
  
  }
  