import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { NgModule, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
} from '@angular/material';

@NgModule({
  exports: [
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ScrollingModule,
    FlexLayoutModule,
  ]
})
export class MaterialModule implements OnInit {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {

    iconRegistry.addSvgIcon(
      'pdf-grey-scpa-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/pdf-grey-scpa-icon.svg'));

    iconRegistry.addSvgIcon(
      'xls',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/xls.svg'));

    iconRegistry.addSvgIcon(
      'equipment',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/equipment-icon.svg'));

    iconRegistry.addSvgIcon(
      'baseline-add',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/baseline-add.svg'));

    iconRegistry.addSvgIcon(
      'infoiconhighlighted',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/infoiconhighlighted.svg'));

    iconRegistry.addSvgIcon(
      'timericon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/timericon.svg'));

      iconRegistry.addSvgIcon(
        'edit-icon',
        sanitizer.bypassSecurityTrustResourceUrl('assets/images/edit-icon.svg'));
  }
  ngOnInit() {
  }

}