import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { WebContentType } from "../common/typesinfo/typesinfo";

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: "app-pierpass-links",
  templateUrl: "./pierpass-links.component.html",
  styleUrls: ["./pierpass-links.component.scss"],
})
export class PierpassLinksComponent implements OnInit {
  @Input() webcontent: WebContentType[];
  // pierPASSLinks : WebContentType[];
  Documents: any;

  constructor(private http: HttpClient) {
    this.webcontent = [];
  }

  downloadPdf() {
    const pdfUrl = './assets/Documents/WCMTOA-MTO-Schedule-7_23_15.pdf';

    const pdfName = 'your_pdf_file.pdf';
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  ngOnInit(): void {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");

  }
}
function saveAs(blob: Blob, arg1: string) {
  FileSaver.saveAs(blob, arg1);

}

